import React from 'react';
import {Box, Button, Center, Flex, Image, Text} from "@chakra-ui/react";
import {FaPen} from "react-icons/fa";

const AccountSettingsComponent = () => {
    return (
        <Box width={{ base: "100%", md: "90%" }} color={"white"} m={"auto"} p={5} pt={10}>
            <Box>
                <Text fontSize={{ base: "24px", md: "36px" }}>Account Settings</Text>
                <Text color={"#919191"} fontSize={{ base: "14px", md: "16px" }}>
                    View and manage account details such as name, email address, contact information, etc.
                </Text>
            </Box>
            <Flex direction={{ base: "column", md: "row" }} justifyContent={"space-between"} mt={10}>
                <Box>
                    <Flex direction={{ base: "column", md: "row" }}>
                        <Box display={"flex"}>
                            <Image src={"/img/profile_picture.jpg"} width={"100px"} height={"100px"} borderRadius={"50%"} />
                            <Center width={"40px"} height={"40px"} borderRadius={"50%"}
                                    cursor={"pointer"} _hover={{ backgroundColor: '#555555' }}
                                    backgroundColor={"#222222"} position="relative"
                                    top={{ base: "50px", md: "70px" }} transform={'translateX(-90%)'}
                                    border={"5px solid #000"}
                            >
                                <FaPen fontSize={"14px"} />
                            </Center>
                        </Box>
                        <Box p={5}>
                            <Text mb={0} color={"#919191"}>Reference No</Text>
                            <Text mb={0}>445329476</Text>
                        </Box>
                    </Flex>
                </Box>
                <Center>
                    <Button backgroundColor={"rgba(255,26,46,0.29)"} color={"#ff5555"}
                            _hover={{ backgroundColor: 'rgba(255,26,46,0.68)' }}>Deactivate account</Button>
                </Center>
            </Flex>
            <Box backgroundColor={"#131313"} p={5} mt={10}>
                <Flex direction={{ base: "column", md: "row" }} justifyContent={"space-between"}>
                    <Text mb={0} fontSize={{ base: "20px", md: "24px" }}>Personal information</Text>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} mt={4}>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>First Name</Text>
                        <Text mb={0}>Karim</Text>
                    </Box>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>Last Name</Text>
                        <Text mb={0}>El Amrani</Text>
                    </Box>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>Date of Birth</Text>
                        <Text mb={0}>April 5, 1985</Text>
                    </Box>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>Email Address</Text>
                        <Text mb={0}>karim.elamrani@example.com</Text>
                    </Box>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} mt={4} alignItems={{base: "flex-end"}} justifyContent={{ base: "flex-start", md: "flex-end" }}>
                    <Center order={{ base: 0, md: 1 }}>
                        <Button p={5} backgroundColor={"rgba(255,242,0,0.29)"} color={"#ffc500"}
                                _hover={{ backgroundColor: 'rgba(255,206,3,0.82)' }}>
                            <FaPen />
                            <Text ml={2} mb={0}>Edit</Text>
                        </Button>
                    </Center>
                </Flex>
            </Box>
            <Box backgroundColor={"#131313"} p={5} mt={10}>
                <Flex direction={{ base: "column", md: "row" }} justifyContent={"space-between"}>
                    <Text mb={0} fontSize={{ base: "20px", md: "24px" }}>Address</Text>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} mt={4}>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>Country</Text>
                        <Text mb={0}>Morocco</Text>
                    </Box>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>City/State</Text>
                        <Text mb={0}>Casablanca,Casablanca-Settat</Text>
                    </Box>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>Street Address</Text>
                        <Text mb={0}>123 Avenue des FAR</Text>
                    </Box>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} mt={4} alignItems={{base: "flex-end"}} justifyContent={{ base: "flex-start", md: "flex-end" }}>
                    <Center order={{ base: 0, md: 1 }}>
                        <Button p={5} backgroundColor={"rgba(255,242,0,0.29)"} color={"#ffc500"}
                                _hover={{ backgroundColor: 'rgba(255,206,3,0.82)' }}>
                            <FaPen />
                            <Text ml={2} mb={0}>Edit</Text>
                        </Button>
                    </Center>
                </Flex>
            </Box>
            <Box backgroundColor={"#131313"} p={5} mt={10}>
                <Flex direction={{ base: "column", md: "row" }} justifyContent={"space-between"}>
                    <Text mb={0} fontSize={{ base: "20px", md: "24px" }}>Employer</Text>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} mt={4}>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>Company Name</Text>
                        <Text mb={0}>Atlas Tech Solutions</Text>
                    </Box>
                    <Box mr={{ base: "0", md: "60px" }} mb={{ base: "4", md: "0" }}>
                        <Text mb={0} color={"#919191"}>City/State</Text>
                        <Text mb={0}>Casablanca, Casablanca-Settat</Text>
                    </Box>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} mt={4} alignItems={{base: "flex-end"}} justifyContent={{ base: "flex-start", md: "flex-end" }}>
                    <Center order={{ base: 0, md: 1 }}>
                        <Button p={5} backgroundColor={"rgba(255,242,0,0.29)"} color={"#ffc500"}
                                _hover={{ backgroundColor: 'rgba(255,206,3,0.82)' }}>
                            <FaPen />
                            <Text ml={2} mb={0}>Edit</Text>
                        </Button>
                    </Center>
                </Flex>
            </Box>
        </Box>
    );
};

export default AccountSettingsComponent;