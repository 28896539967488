import React from 'react';
import {Box, Flex, IconButton, Table, Tbody, Td, Text, Thead, Tr} from "@chakra-ui/react";
import {FiMoreVertical} from "react-icons/fi";

const News = () => {
    const news = [
        { title: "Morocco's Government Bonds Reach New Highs", time: "10 min ago" },
        { title: "Corporate Bonds in South Africa Show Resilience", time: "2 min ago" },
        { title: "Yield Curves in Asia Indicate Economic Slowdown", time: "12 hrs ago" },
        { title: "Municipal Bonds in Europe Offer Stable Returns", time: "22 hrs ago" },
        { title: "US Treasury Yields Experience Sharp Fluctuations", time: "2 hrs ago" },
        { title: "African Markets React to Global Bond Trends", time: "3 days ago" }
    ];
    return (
        <Box>
            <Box mt={5}>
                <Box flex="1" color="white">
                    <Flex justify="space-between" align="center" bg="#121212" p="15px 0 0 20px">
                        <Text fontSize="lg" fontWeight="bold">
                            News
                        </Text>
                        <IconButton icon={<FiMoreVertical/>} aria-label="More options" bg="#121212" _hover={{bg: 'gray.600'}}/>
                    </Flex>
                    <Box bgColor="#0A0A0A">
                        <Table className="table-card" variant="simple" colorScheme="whiteAlpha" fontSize="12px" display="block" maxHeight="350px" overflowY="scroll">
                            <Thead>
                                <Tr>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {news.map((news, index) => (
                                    <Tr key={index}>
                                        <Td maxW="50%" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">{news.title}</Td>
                                        <Td width={'100%'} textAlign='end'>{news.time}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default News;