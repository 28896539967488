// Import necessary dependencies and components
import React, { useEffect,useState, useMemo } from 'react'
import {
    Box, Flex, Menu, MenuButton, MenuList, MenuItem, Button, InputGroup, InputLeftElement, Input,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td, Tfoot, Select, Text,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    FormControl, FormLabel, Icon, useDisclosure
} from '@chakra-ui/react'
import { FiChevronDown, FiFilter, FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import { FaSearch } from 'react-icons/fa'
import { TbCaretUpDownFilled } from "react-icons/tb"
import { HiMenuAlt2 } from "react-icons/hi"
import { AiOutlineUpload } from "react-icons/ai"
import axios from 'axios';
import { getAllCompanies } from '../../../../services/marketDataServices/CompanyService'
// Mock transaction data for demonstration purposes
import * as XLSX from 'xlsx';

export const totalAmount = (transactions) => {
    return transactions.reduce((total, transaction) => {
        return total + (transaction.type === "SELL" ? transaction.quantity * transaction.price * -1 : transaction.quantity * transaction.price);
    }, 0);
};

function TransactionTable() {
    // State for pagination
    const [companies, setCompanies] = useState([]);

    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filterType, setFilterType] = useState(''); // New state for filter type

    const [transactionDetails, setTransactionDetails] = useState({
        investor: 1,
        company: '',
        type: '',
        quantity: '',
        price: '',
    });    
    const fetchTransactions = async () => {
        try {
            const response = await axios.get('https://api.xcapitalterminal.com/api/v1/portfolio-data/transactions');
            // const response = await axios.get(`${process.env.REACT_APP_TERMINAL_API_URL}/api/v1/portfolio-data/transactions`);
            setTransactions(response.data.reverse()); // Assuming the API returns an array of transactions
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

        useEffect(() => {
            getAllCompanies()
                .then(response => {
                    const companiesData = response.data
                    setCompanies(companiesData);
                })
                .catch(error => {
                    console.error("Error fetching companies:", error);
                });
        }, []);

    useEffect(() => {
        fetchTransactions();
    }, []);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTransactionDetails(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // const companies = [{id:9, name:'IAM (Maroc Telecom)'}, {id:10,name:'Attijariwafa Bank'}]

    const handleSubmit = async () => {
        try {
            const company = companies.find(c => c.name == transactionDetails.company);
            // console.log("company",company)
            if (!company) {
                throw new Error('Invalid company name');
            }

            const updatedTransactionDetails = {
                ...transactionDetails,
                company: company.id, // Replace name with ID
            };
            
            const response = await axios.post('https://api.xcapitalterminal.com/api/v1/portfolio-data/transactions', updatedTransactionDetails);
            // const response = await axios.post(`${process.env.REACT_APP_TERMINAL_API_URL}/api/v1/portfolio-data/transactions`, updatedTransactionDetails);
            // console.log('Transaction added:', response.data);
            fetchTransactions(); // Refresh the transactions after adding a new one
            setTransactionDetails({
                investor: 1,
                company: '',
                type: '',
                quantity: '',
                price: '',
            });
            onClose();
        } catch (error) {
            console.error('Error adding transaction:', error);
        }
    };
    // total pages
    const totalPages = Math.ceil(transactions.length / rowsPerPage);

    // Get current transactions for the current page

    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');

    const currentTransactions = useMemo(() => {
        let filteredTransactions = transactions;
        if (filterType) {
            filteredTransactions = transactions.filter(
                (transaction) => transaction.type === filterType
            );
        }

        // Filter by date range
    if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999); // Set to the last millisecond of the end date
        // console.log("start",start)
        // console.log("end",end)
        filteredTransactions = filteredTransactions.filter((transaction) => {
            const transactionDate = new Date(transaction.date);
            // console.log("transactionDate",transactionDate)
            return transactionDate >= start && transactionDate <= end;
        });
    }

        return filteredTransactions.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    }, [currentPage, rowsPerPage, transactions, filterType, startDate, endDate]);

    // Function to get page numbers for pagination
    const getPageNumbers = () => {
        if (totalPages <= 4) return Array.from({ length: totalPages }, (_, i) => i + 1);
        if (currentPage <= 2) return [1, 2, 3, 4];
        if (currentPage >= totalPages - 1) return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        return [currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    };

    // Helper function to render menu buttons
    const renderMenuButton = (text, icon) => (
        <MenuButton as={Button}
            height='35px'
            bgColor="#161616"
            color="white"
            m="0 15px 0 7px"
            border="1px solid #303235"
            _hover={{ bgColor: "#242424" }}
            rightIcon={icon}
            _focus={{ bgColor: "#242424" }}
            fontSize="12px"
            flex={1}
        >
            {text}
        </MenuButton>
    );


    const calculateAmount = (quantity, price, type) => {
        return type === "SELL" ? quantity * price * -1 : quantity * price;
    };

    // Calculate total amount
    // const totalAmount = useMemo(() => {
    //     return transactions.reduce((total, transaction) => {
    //         return total + calculateAmount(transaction.quantity, transaction.price);
    //     }, 0);
    // }, [transactions]);
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0'); // Day
        return `${year}-${month}-${day}`;
    };

    const exportToExcel = (data) => {
        if (data.length === 0) {
            console.error("No data available to export.");
            return;
        }
    
        // Convert data to a format suitable for Excel
        const formattedData = data.map((transaction) => ({
            Date: formatDate(transaction.date),
            Company: companies.find((company) => company.id === transaction.company)?.name || '',
            Type: transaction.type,
            Quantity: transaction.quantity,
            Price: transaction.price,
            Amount: Math.abs(calculateAmount(transaction.quantity, transaction.price, transaction.type)),
        }));
        // Create a workbook and a sheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
    
        // Append the sheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');
    
        // Export to Excel file
        XLSX.writeFile(workbook, 'transactions.xlsx');
    };
    
    const [filteredCompanies, setFilteredCompanies] = useState([]);

    
    return (
        <Box>
            {/* Header */}
            <Flex bg="#121212" justifyContent="center" w='100%' h="40px" p={2} mt="40px" fontSize="18px" color='white' position="relative">
                <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                    Transactions
                    <Box position="absolute" right="10px">
                        <HiMenuAlt2 />
                    </Box>
                </Flex>
            </Flex>
            {/* Filter and search section */}
            <Box bg="#171717" w='100%' p={2} fontSize="18px" color='white'>
                <Flex direction={{ base: "column", xl: "row" }} gap={2}>
                    <Flex flex={1}>
                        {/* Date range filter */}
                        <Menu flex={1}>
                            {renderMenuButton('Date range', <FiChevronDown style={{ marginLeft: "20px" }} />)}
                            {/* <MenuList bgColor="#121212" borderColor="transparent">
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 2</MenuItem>
                            </MenuList> */}
                            <MenuList bgColor="#121212" borderColor="transparent" p={4}>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }} disabled>
                                    
                                    <span style={{marginRight:'6px'}}>Start </span>
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        style={{
                                            backgroundColor: '#121212',
                                            color: 'white',
                                            border: 'none',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            // width: '100%',
                                        }}
                                    />
                                </MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }} disabled>
                                    <span style={{marginRight:'13px'}}>End </span>
                                    <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        style={{
                                            backgroundColor: '#121212',
                                            color: 'white',
                                            border: 'none',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            // width: '100%',
                                        }}
                                    />
                                </MenuItem>
                                {/* <MenuItem
                                    bgColor="#121212"
                                    _hover={{ bgColor: "#242424" }}
                                    onClick={() => applyDateFilter()}
                                >
                                    Apply Filter
                                </MenuItem> */}
                                <MenuItem
                                    bgColor="#121212"
                                    _hover={{ bgColor: "#242424" }}
                                    onClick={() => {
                                        setStartDate('');
                                        setEndDate('');
                                        // setFilteredData(data); // Reset to original data
                                    }}
                                >
                                    Clear Filter
                                </MenuItem>
                            </MenuList>
                        </Menu>

                        {/* Transaction type filter */}
                        <Menu flex={1}>
                            {renderMenuButton('Transaction Type', <FiChevronDown style={{ marginLeft: "20px" }} />)}
                            <MenuList bgColor="#121212" borderColor="transparent">
                                {/* <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>BUY</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>SELL</MenuItem>
                                 */}

                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }} onClick={() => setFilterType('BUY')}>BUY</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }} onClick={() => setFilterType('SELL')}>SELL</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }} onClick={() => setFilterType('')}>All</MenuItem>                            </MenuList>
                        </Menu>
                    </Flex>

                    {/* Additional filter and search */}
                    <Flex flex={2} justifyContent={{ base: 'start', md: 'end' }} mr={{ base: 0, md: 10 }} gap={2} alignItems="center" direction={{ base: "column-reverse", sm: "row" }}>
                        {/* Add Transaction Button and Modal */}

                        <Flex>
                            <Button
                                bg="yellow.400"
                                color="black"
                                borderRadius="6px"
                                _hover={{ bg: "yellow.500" }}
                                fontSize="14px"
                                height="30px"
                                mr="4%"
                                onClick={onOpen}
                            >
                                Add Transaction
                            </Button>

                            <Button
                                bg="yellow.400"
                                color="black"
                                borderRadius="6px"
                                _hover={{ bg: "yellow.500" }}
                                fontSize="14px"
                                height="30px"
                                mr="4%"
                                onClick={() => exportToExcel(transactions)}
                            >
                                Import XLS File
                            </Button>
                        </Flex>

                        {/* Modal for adding transactions */}
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent bg="#242424" color="white">
                                <ModalHeader>Add Transaction</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>

                                    <FormControl mb={4} isRequired>
                                        <FormLabel>Name</FormLabel>
                                        {/* <Select
                                            placeholder="Select Company"
                                            value={transactionDetails.company}
                                            onChange={(e) =>
                                            setTransactionDetails((prev) => ({ ...prev, company: e.target.value }))
                                            }
                                        >
                                            {companies.map((company) => (
                                            <option key={company.id} value={company.name}>
                                                {company.name}
                                            </option>
                                            ))}
                                        </Select> */}
                                        <Input
                                            placeholder="Search Company"
                                            value={transactionDetails.company}
                                            onChange={(e) => {
                                            const query = e.target.value.toLowerCase();
                                            setTransactionDetails((prev) => ({ ...prev, company: query }));
                                            setFilteredCompanies(
                                                companies.filter((company) =>
                                                company.name.toLowerCase().includes(query)
                                                )
                                            );
                                            }}
                                        />
                                        {filteredCompanies.length > 0 && (
                                            <Box
                                            position="absolute"
                                            bg="#121212"
                                            boxShadow="md"
                                            maxHeight="150px"
                                            overflowY="auto"
                                            zIndex="10"
                                            >
                                            {filteredCompanies.map((company) => (
                                                <Box
                                                key={company.id}
                                                p="2"
                                                borderBottom="1px solid #e2e8f0"
                                                cursor="pointer"
                                                onClick={() => {
                                                    setTransactionDetails((prev) => ({ ...prev, company: company.name }));
                                                    setFilteredCompanies([]);
                                                }}
                                                >
                                                {company.name}
                                                </Box>
                                            ))}
                                            </Box>
                                        )}
                                    </FormControl>
                                    <FormControl mb={4} isRequired>
                                        <FormLabel>Type</FormLabel>
                                        <Select
                                            name="type"
                                            value={transactionDetails.type}
                                            onChange={handleInputChange}
                                            borderColor="#303235"
                                            placeholder="Select Type"
                                        >
                                            <option value="BUY">BUY</option>
                                            <option value="SELL">SELL</option>
                                        </Select>
                                         {/* <Menu>
                                            <MenuButton
                                                as={Button}
                                                bgColor="#242424"
                                                color="white"
                                                borderColor="transparent"
                                                _hover={{ bgColor: "#121212" }}
                                                width="full"
                                            >
                                                {transactionDetails.type || "Select type"}
                                            </MenuButton>
                                            <MenuList bg="#121212" borderColor="transparent" color="white">
                                                <MenuItem
                                                    onClick={() => handleInputChange({ target: { name: 'type', value: 'BUY' } })}
                                                    bgColor="#121212" _hover={{ bgColor: "#242424" }}
                                                >
                                                    BUY
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => handleInputChange({ target: { name: 'type', value: 'SELL' } })}
                                                    bgColor="#121212" _hover={{ bgColor: "#242424" }}
                                                >
                                                    SELL
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                         */}
                                    </FormControl>
                                    <FormControl mb={4} isRequired>
                                        <FormLabel>Quantity</FormLabel>
                                        <Input
                                            type="number"
                                            name="quantity"
                                            placeholder="Enter quantity"
                                            value={transactionDetails.quantity}
                                            onChange={handleInputChange}
                                            borderColor="#303235"
                                        />
                                    </FormControl>
                                    <FormControl mb={4} isRequired>
                                        <FormLabel>Price (MAD)</FormLabel>
                                        <Input
                                            type="number"
                                            name="price"
                                            placeholder="Enter price"
                                            value={transactionDetails.price}
                                            onChange={handleInputChange}
                                            borderColor="#303235"
                                        />
                                    </FormControl>

                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme="yellow" mr={3} onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                    <Button onClick={onClose} variant="ghost">Cancel</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>


                        <Flex>
                            {/* Filter Button */}
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    height='35px'
                                    bgColor="#161616"
                                    color="white"
                                    borderWidth="1px 0 1px 1px"
                                    borderRadius="5px 0 0px 5px"
                                    borderColor="#303235"
                                    _hover={{ bgColor: "#242424" }}
                                    rightIcon={<FiChevronDown />}
                                    _focus={{ bgColor: "#242424" }}
                                >
                                    <Flex>
                                        <Box m="5px 10px 0 0" fontSize='12px'>
                                            <FiFilter fill="white" />
                                        </Box>
                                        All
                                    </Flex>
                                </MenuButton>
                                <MenuList bgColor="#303235" borderColor="transparent" minWidth='100px'>
                                    <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                                    <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
                                </MenuList>
                            </Menu>
                            {/* Search input */}
                            <InputGroup width={{ base: '', sm: '', md: '300px', lg: '', xl: '' }}>
                                <InputLeftElement pb={1}>
                                    <FaSearch />
                                </InputLeftElement>
                                <Input
                                    type="text"
                                    height='35px'
                                    placeholder="Search"
                                    _placeholder={{ color: '#fff', opacity: 0.7 }}
                                    _focus={{
                                        outline: 'none',
                                        boxShadow: 'none',
                                        borderColor: 'rgba(119,119,119,0.5)'
                                    }}
                                    _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                    borderColor="#303235"
                                    borderWidth="1px"
                                    borderRadius="0px 5px 5px 0px"
                                />
                            </InputGroup>
                        </Flex>
                    </Flex>

                </Flex>
            </Box>
            {/* Transaction table */}
            <Box bg="#121212" w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Date</Th>
                            <Th>Name</Th>
                            <Th>Type</Th>
                            <Th>Quantity</Th>
                            <Th>Price</Th>
                            <Th>Amount</Th>
                        </Tr>
                    </Thead>
                        <Tbody>
                        {currentTransactions.map((transaction, index) => (
                            <Tr key={index}>
                                {/* <Td>{transaction.date}</Td> */}
                                <Td>{formatDate(transaction.date)}</Td>
                                <Td>{transaction.company_name}</Td>
                                <Td>{transaction.type}</Td>
                                <Td>{transaction.quantity}</Td>
                                <Td>{transaction.price}</Td>
                                {/* <Td>{calculateAmount(transaction.quantity, transaction.price, transaction.type)}</Td> */}
                                <Td>{Math.abs(calculateAmount(transaction.quantity, transaction.price, transaction.type))}</Td>
                                
                            </Tr>
                        ))}
                    </Tbody>
                        <Tfoot>
                            <Tr>
                                <Td colSpan={5} textAlign="center" fontWeight="bold" borderRight="1.5px solid #EDF2F7">TOTAL</Td>
                                <Td fontWeight="bold">{totalAmount(transactions)}</Td>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
                {/* Pagination */}
                <Flex direction={{ base: "column", md: "row" }} justifyContent="center" alignItems="center" pt={4} pb={4} gap={4}>
                    <Flex alignItems="center">
                        <Button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            isDisabled={currentPage === 1}
                            mr={2}
                            bgColor="#161616"
                            color="white"
                            _hover={{ bgColor: "#242424" }}
                            leftIcon={<FiChevronLeft />}
                            display={{ base: "none", sm: "block" }}
                        >
                            Previous
                        </Button>
                        {getPageNumbers().map(pageNumber => (
                            <Button
                                key={pageNumber}
                                onClick={() => setCurrentPage(pageNumber)}
                                mx={1}
                                px={3}
                                py={2}
                                borderRadius="md"
                                bg={currentPage === pageNumber ? "#F8CD20" : "#121212"}
                                color={currentPage === pageNumber ? "black" : "white"}
                                fontWeight={currentPage === pageNumber ? "bold" : "normal"}
                                _hover={{ bg: currentPage === pageNumber ? "#F8CD20" : "#242424" }}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                minWidth="32px"
                            >
                                {pageNumber}
                            </Button>
                        ))}
                        <Button
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            isDisabled={currentPage === totalPages}
                            ml={2}
                            mr={4}
                            bgColor="#161616"
                            color="white"
                            _hover={{ bgColor: "#242424" }}
                            rightIcon={<FiChevronRight />}
                            display={{ base: "none", sm: "block" }}
                        >
                            Next
                        </Button>
                    </Flex>
                    <Flex alignItems="center">
                        <Box as="span" mr={2} color="white">
                            Result Per Page:
                        </Box>
                        <Select
                            value={rowsPerPage}
                            onChange={(e) => {
                                setRowsPerPage(Number(e.target.value));
                                setCurrentPage(1);
                            }}
                            width="70px"
                            bgColor="#161616"
                            color="white"
                            borderColor="#303235"
                            _hover={{ borderColor: "rgba(119,119,119,0.5)" }}
                            _focus={{ borderColor: "rgba(119,119,119,0.5)", boxShadow: "none" }}
                            sx={{
                                "& option": {
                                    backgroundColor: "black",
                                    color: "white",
                                    border: "none"
                                },
                                "& > option": {
                                    border: "none"
                                }
                            }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </Select>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    )
}

export default TransactionTable