import React from 'react';
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { Pie } from "react-chartjs-2";
import useFetchSectorsData from '../../../../../hooks/useFetchSectorsData';

const SectorDistributionChart = () => {
    const { data: sectors, loading, error } = useFetchSectorsData();

    // Filter and prepare data for the pie chart
    const validSectors = sectors?.filter(sector =>
        sector.index_value !== null &&
        sector.name !== null
    ) || [];

    const generateColorPalette = (n) => {
        const colors = [];
        const hueStep = 360 / n;
        for (let i = 0; i < n; i++) {
            // Generate a color in HSL format for better color differentiation
            const h = (i * hueStep) % 360;
            const color = `hsl(${h}, 60%, 50%)`; // Adjust saturation and lightness for mature tones
            colors.push(color);
        }
        return colors;
    };

    const data = {
        labels: validSectors.map(sector => sector.name),
        datasets: [
            {
                data: validSectors.map(sector => sector.index_value || 0),
                backgroundColor: generateColorPalette(validSectors.length), // Generate colors dynamically
                borderWidth: 0,
            },
        ],
    };


    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <Box>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} fontSize={"18px"} color='white'>
                Sector Distribution
            </Box>
            <Box bg={"#121212"} w='100%' p={"40px"} color='white'>
                <Flex color='white' height={"100%"} flexWrap={{ base: "wrap", lg: "nowrap" }}>
                    {loading ? (
                        <Box mx={"auto"}>Loading...</Box>
                    ) : error ? (
                        <Box mx={"auto"}>Error: {error}</Box>
                    ) : (
                        <>
                            <Box flex={1} height={"500px"} maxW="100%">
                                <Center height={"500px"}>
                                    <Box w={{ base: "300px", sm: "400px", md: "500px" }} maxW="100%">
                                        <Pie data={data} options={options} style={{ cursor: "pointer" }} />
                                    </Box>
                                </Center>
                            </Box>
                            <Box flex={1} mt={{ base: "50px", md: "100px" }}>
                                <Box minW={"fit-content"} height={"500px"} pl={"30px"} overflowY={"auto"}>
                                    {validSectors.map((sector, index) => (
                                        <Flex key={sector.name} justifyContent={"space-between"} flexDirection={{ base: "column", sm: "row" }}>
                                            <Flex>
                                                <Box style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    borderRadius: '50%',
                                                    margin: '13px 10px 0 0',
                                                    backgroundColor: data.datasets[0].backgroundColor[index]
                                                }} />
                                                <Text fontWeight={'bold'} fontSize={{ base: "16px", sm: "20px", md: "28px" }}>{sector.name}</Text>
                                            </Flex>
                                            <Box mt={{ base: 2, sm: 0 }}>
                                                <Text fontWeight={'bold'} fontSize={{ base: "16px", sm: "20px", md: "28px" }}>{(sector.index_value || 0) + ''}</Text>
                                            </Box>
                                        </Flex>
                                    ))}
                                </Box>
                            </Box>
                        </>
                    )}
                </Flex>
            </Box>
        </Box>
    );
};

export default SectorDistributionChart;
