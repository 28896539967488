import React from 'react';
import Arima from "../Arima";
import Arimax from "../Arimax";
import Lstm from "../Lstm";
import ArimaLstmHybrid from "../ArimaLstmHybrid";
import Bsm from "../Bsm";
import Garch from "../Garch";
import XGBoost from "../XGBoost";
import MonteCarloSimulation from "../MonteCarloSimulation";

const ModelsComponent = () => {
    return (
        <div>
            <Arima/>
            <Arimax/>
            <Garch/>
            <Lstm/>
            <ArimaLstmHybrid/>
            <Bsm/>
            <XGBoost/>
            <MonteCarloSimulation/>
        </div>
    );
};

export default ModelsComponent;