import React from 'react';
import {Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

const TechnicalIndicatorsTable = () => {
    const technicalIndicatorsData = [
        {name: "RSI (14)", value: 57.718, action: "Buy"},
        { name: "STOCH (9,6)", value: 55.584, action: "Buy" },
        { name: "STOCHRSI (14)", value: 100, action: "Overbought" },
        { name: "MACD (12,26)", value: 0.14, action: "Buy" },
        { name: "ADX (14)", value: 64.397, action: "Buy" },
        { name: "Williams %R", value: 0, action: "Overbought" },
        { name: "CCI (14)", value: 197.476, action: "Buy" },
        { name: "ATR (14)", value: 2.3929, action: "Less Volatility" },
        { name: "Highs/Lows (14)", value: 5.9571, action: "Buy" },
        { name: "Ultimate Oscillator", value: 65.062, action: "Buy" },
        { name: "ROC", value: 0.79, action: "Buy" },
        { name: "Bull/Bear Power (13)", value: 7.294, action: "Buy" }
    ]
    return (
        <div>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Technical Indicators
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>Name</Th>
                                <Th>Value</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {technicalIndicatorsData.map((indicator, index) =>
                                <Tr key={index}>
                                    <Td>{indicator.name}</Td>
                                    <Td>{indicator.value}</Td>
                                    <Td color={indicator.action === "Buy" ? "green" : "white"}>{indicator.action}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default TechnicalIndicatorsTable;