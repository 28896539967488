


import React, {useEffect, useState} from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import {Line} from "react-chartjs-2";
import {Utils} from "../../../../../utils/Utils";
import {
    getCpi,
    getFdi,
    getGdp,
    getInflationRate, getInterestRate, getPpi, getTradeBalance,
    getUnemploymentRate
} from "../../../../../services/macroDataServices/MacroEconomicService";
import {FaArrowLeft} from "react-icons/fa6";

const MacroEconomicDataAnalysisComponent = () => {
    const [endYear, setEndYear] = useState(new Date().getFullYear())
    const [startYear, setStartYear] = useState(endYear - 10)
    const [gdp, setGdp] = useState(null)
    const [inflationRate, setInflationRate] = useState(null)
    const [unemploymentRate, setUnemploymentRate] = useState(null)
    const [interestRate, setInterestRate] = useState(null)
    const [fdi, setFdi] = useState(null)
    const [cpi, setCpi] = useState(null)
    const [ppi, setPpi] = useState(null)
    const [tradeBalance, setTradeBalance] = useState(null)
    const [strt, setStrt] = useState({
        'FDI': 1985,
        'GDP': 1967,
        'Unemployment rate': 2012,
        'Trade Balance': 1974,
        'Inflation rate': 2013,
        'PPI': 2019,
        'CPI': 2017,
        'Interest rate (lending rate)': 2010

    });
    
    const [chartData, setChartData] = useState(null)
    const [dateChartYears, setDateChartYears] = useState({})
    // console.log("dateChartyears",dateChartYears?.['Unemployment rate'])
    useEffect(() => {
        const sortByDate = (data, dateKey) => {
            return data.sort((a, b) => new Date(a[dateKey]) - new Date(b[dateKey]));
        };

        getGdp().then(response => {
            const sortedData = sortByDate(response.data, 'year');
            setGdp(sortedData)
            setDateChartYears(prev => ({ ...prev, 'GDP': sortedData.map(d=>d.year) }))
        }).catch(error => {
            console.log(error)
        })

        getFdi().then(response => {
            const sortedData = sortByDate(response.data, 'year');
            setFdi(sortedData)
            setDateChartYears(prev => ({ ...prev, 'FDI': sortedData.map(d=>d.year) }))
        }).catch(error => {
            console.log(error)
        })

        getTradeBalance().then(response => {
            const sortedData = sortByDate(response.data, 'year');
            setTradeBalance(sortedData)
            setDateChartYears(prev => ({ ...prev, 'Trade Balance': sortedData.map(d=>d.year) }))
        }).catch(error => {
            console.log(error)
        })

        getUnemploymentRate().then(response => {
            const sortedData = sortByDate(response.data, 'date');
            setUnemploymentRate(sortedData)
            setDateChartYears(prev => ({ ...prev, 'Unemployment rate': sortedData.map(d=>d.date) }))
        }).catch(error => {
            console.log(error)
        })

        getInflationRate().then(response => {
            const sortedData = sortByDate(response.data, 'date');
            setInflationRate(sortedData)
            setDateChartYears(prev => ({ ...prev, 'Inflation rate': sortedData.map(d=>d.date) }))
        }).catch(error => {
            console.log(error)
        })

        getInterestRate().then(response => {
            const sortedData = sortByDate(response.data, 'date');
            setInterestRate(sortedData)
            setDateChartYears(prev => ({ ...prev, 'Lending rate': sortedData.map(d=>d.date) }))
        }).catch(error => {
            console.log(error)
        })
        getPpi().then(response => {
            const sortedData = sortByDate(response.data, 'date');
            setPpi(sortedData)
            setDateChartYears(prev => ({ ...prev, 'PPI general index': sortedData.map(d=>d.date) }))
        }).catch(error => {
            console.log(error)
        })

        getCpi().then(response => {
            const sortedData = sortByDate(response.data, 'date');
            setCpi(sortedData)
            setDateChartYears(prev => ({ ...prev, 'CPI general index': sortedData.map(d=>d.date) }))

        }).catch(error => {
            console.log(error)
        })


    }, []);

    const unemploymentRateData = [{
        label: 'Unemployment rate',
        data: unemploymentRate?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ur => ur.unemployment_rate),
        fill: false,
        borderColor: "#ff0707",
        borderWidth: 2,
        backgroundColor: '#ff0707',
        tension: 0
    }]
    const inflationRateData = [{
        label: 'Inflation rate',
        data: inflationRate?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ir => ir.inflation_rate),
        fill: false,
        borderColor: "#ff7830",
        borderWidth: 2,
        backgroundColor: '#ff7830',
        tension: 0
    }]
    const interestRateData = [{
        label: 'Lending rate',
        data: interestRate?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ir => ir.lending_rate),
        fill: false,
        borderColor: "#820dff",
        borderWidth: 2,
        backgroundColor: '#820dff',
        tension: 0
    },
    {
        label: 'Cash Advance',
        data: interestRate?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ir => ir.cash_advance),
        fill: false,
        borderColor: "#ff0707",
        borderWidth: 2,
        backgroundColor: '#ff0707',
        tension: 0
    },
    {
        label: 'Equipment Loan',
        data: interestRate?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ir => ir.equipment_loan),
        fill: false,
        borderColor: "#ffff00",
        borderWidth: 2,
        backgroundColor: '#ffff00',
        tension: 0
    },
    {
        label: 'Real Estate Loan',
        data: interestRate?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ir => ir.real_estate_loan),
        fill: false,
        borderColor: "#120bbf",
        borderWidth: 2,
        backgroundColor: '#120bbf',
        tension: 0
    },
    {
        label: 'Consumer Loan',
        data: interestRate?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ir => ir.consumer_loan),
        fill: false,
        borderColor: "#006400",
        borderWidth: 2,
        backgroundColor: '#006400',
        tension: 0
    }]
    const ppiData = [{
        label: 'PPI general index',
        data: ppi?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ppi => ppi.ppi_general_index),
        fill: false,
        borderColor: "#820dff",
        borderWidth: 2,
        backgroundColor: '#820dff',
        tension: 0
    },
    {
        label: 'Water Production Distribution',
        data: ppi?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ppi => ppi.water_production_distribution),
        fill: false,
        borderColor: "#ff0707",
        borderWidth: 2,
        backgroundColor: '#ff0707',
        tension: 0
    },
    {
        label: 'Electricity Production Distribution',
        data: ppi?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ppi => ppi.electricity_production_distribution),
        fill: false,
        borderColor: "#006400",
        borderWidth: 2,
        backgroundColor: '#006400',
        tension: 0
    },
    {
        label: 'Manufacturing Industries',
        data: ppi?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ppi => ppi.manufacturing_Industries),
        fill: false,
        borderColor: "#0d7eff",
        borderWidth: 2,
        backgroundColor: '#0d7eff',
        tension: 0
    },
    {
        label: 'Extractive Industries',
        data: ppi?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(ppi => ppi.extractive_industries),
        fill: false,
        borderColor: "#ffce30",
        borderWidth: 2,
        backgroundColor: '#ffce30',
        tension: 0
    }]
    const cpiData = [{
        label: 'CPI general index',
        data: cpi?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(cpi => cpi.cpi_general_index),
        fill: false,
        borderColor: "#0d7eff",
        borderWidth: 2,
        backgroundColor: '#0d7eff',
        tension: 0
    },
    {
        label: 'Food Products',
        data: cpi?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(cpi => cpi.food_products),
        fill: false,
        borderColor: "#820dff",
        borderWidth: 2,
        backgroundColor: '#820dff',
        tension: 0
    },
    {
        label: 'Non Food Products',
        data: cpi?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(cpi => cpi.non_food_products),
        fill: false,
        borderColor: "#ff7830",
        borderWidth: 2,
        backgroundColor: '#ff7830',
        tension: 0
    }]

    const tradeBalanceData = [{
        label: 'Trade Balance',
        data: tradeBalance?.sort((a, b) => a.year - b.date)
            .map(tb => tb.trade_balance),
        fill: false,
        borderColor: "#30ff3a",
        borderWidth: 2,
        backgroundColor: '#30ff3a',
        tension: 0
    }]

    const gdpData = [{
        label: 'GDP',
        data: gdp?.sort((a, b) => a.year - b.year)
            .map(gdp => gdp.gdp_growth),
        fill: false,
        borderColor: "#ffce30",
        borderWidth: 2,
        backgroundColor: '#ffce30',
        tension: 0
    }]

    const fdiData = [{
        label: 'FDI',
        data: fdi?.sort((a, b) => a.year - b.date)
            .map(fdi => fdi.fdi_net_inflows),
        fill: false,
        borderColor: "#30ffcf",
        borderWidth: 2,
        backgroundColor: '#30ffcf',
        tension: 0
    }]


    const [isMergedRatesChart, setIsMergedRatesChart] = useState(false)
    const economicIndicators = [
        {name: "GDP (Gross Domestic Product)", dataset: gdpData, unit: '%'},
        {name: "FDI Inflows (Foreign Direct Investment)", dataset: fdiData, unit: '%'},
        {name: "Trade Balance", dataset: tradeBalanceData, unit: ''},
        {name: "Unemployment Rate", dataset: unemploymentRateData, unit: '%'},
        {name: "Inflation Rate", dataset: inflationRateData, unit: '%'},
        {name: "Interest Rate", dataset: interestRateData, unit: '%'},
        {name: "PPI (Producer Price Index)", dataset: ppiData, unit: ''},
        {name: "CPI (Consumer Price Index)", dataset: cpiData, unit: ''},

    ];

    const legendMargin = {
        id: 'legendMargin',
        beforeInit(chart, legend, plugins) {
            const originalFit = chart.legend.fit;
            const margin = plugins.margin || 0;
            chart.legend.fit = function fit() {
                if (originalFit) {
                    originalFit.call(this)
                }
                return this.height += margin;
            }
        }
    }

    function chartConfiguration(datasets = []) {
        if (!datasets || datasets.length === 0) return null;
        const datasetLabel = datasets[0]?.label

        const chartData = {
            type: 'line',
            data: {
                labels: dateChartYears?.[datasetLabel],
                datasets: [],
            },
            plugins: [legendMargin],
        };

        datasets.forEach((dataset, index) => {
            chartData.data.datasets.push(dataset);
        });
    
        return chartData;
    }


    
    const options = (economicIndicator) => {
        return {
            responsive: true,
            plugins:
                {
                    legendMargin: {
                        margin: 15
                    }
                    ,
                    legend: {
                        align: 'start',
                        labels:
                            {
                                font: {
                                    size: '10px'
                                },
                                usePointStyle: true,
                                pointStyle:
                                    'circle',
                                boxWidth:
                                    5,
                                boxHeight:
                                    5,
                                color:
                                    'white' // White text for legend labels
                            }
                    }
                },
            elements: {
                point: {
                    radius: 2,
                }
            },
            scales: {
                y: {
                    grid: {
                        color: '#515151',
                    },
                    border: {
                        dash: [4, 2]
                    },
                    beginAtZero: false,
                    ticks:
                        {
                            font: {
                                size: 10
                            }
                            ,
                            color: 'white',
                            callback:
                                (value) => Utils.shortenNumber(value) + economicIndicator.unit,
                            stepSize:
                                5
                        }
                },
                x: {
                    ticks: {
                        font: {
                            size: 10
                        }
                        ,
                        color: 'white'
                    }
                }
            }
        }
    };
    function showCharts() {
        if (!isMergedRatesChart) {
            return economicIndicators.map((indicator) => (
                <Box className="col-md-6" key={indicator.name}>
                    <Box className="chart">
                        <span>{indicator.name}</span>
                        <Line
                            height="220px"
                            data={chartConfiguration(indicator.dataset).data}
                            options={options(indicator)}
                            plugins={chartConfiguration(indicator.dataset).plugins}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setIsMergedRatesChart(true)
                                setChartData(indicator.dataset)
                            }}
                        />
                    </Box>
                </Box>
            ));
        } else {
            const config = chartConfiguration(chartData)
            console.log("chartData",chartData)
            return (
                <Box className="col-md-12">
                    <Box className="chart">
                        <Flex justifyContent={'space-between'}>
                            {/* <Text ml={5} fontSize={'20px'} fontWeight='bold'>All rates</Text> */}
                            <Box fontSize={"20px"} _hover={{color: "#ffce30"}}>
                                <FaArrowLeft onClick={() => setIsMergedRatesChart(false)}
                                             cursor="pointer"
                                />
                            </Box>
                        </Flex>
                        <Line height={'130px'} data={config.data} options={options(economicIndicators[0])} plugins={config.plugins}/>
                    </Box>
                </Box>
            )
        }
    }

    return (
        <Box>
            <Box bg={"#1f1f1f"} w='100%' h="60px" p={"16px 0 16px 24px"} fontSize={"18px"} color='white'>
                Macro-Economic Data Analysis
            </Box>
            <Box bg={"#121212"} w='100%' p={{base: "15px", md: "40px"}} color='white' height={"100%"}>
                <Box className="macro-economic-charts row">
                    {showCharts()}
                </Box>
            </Box>
        </Box>
    )
        ;
};

export default MacroEconomicDataAnalysisComponent;


