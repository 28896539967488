import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import axios from 'axios';

const GovernmentBondsTable = () => {
  const [bondsData, setBondsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("Egypt"); // Default country

  const calculateYieldChange = (current, previous) => {
    if (previous === 0 || previous === null) return 0;
    return (((current - previous) / previous) * 100).toFixed(2);
  };

  const parseDate = (dateStr) => new Date(dateStr);

//   const countries = ["Morocco", "Egypt", "Ivory Coast", "South Africa"]
  const countries = ["Morocco", "Egypt", "Coast Ivory", "South Africa"]

  const fetchBondData = async (country) => {
    const dateFrames = [
      '3months', '6months', '9months', '1year', '2years', '3years',
      '5years', '7years', '10years', '12years', '15years', '20years',
      '25years', '30years'
    ];
    try {
      // Create an array of promises for all dateFrames
      const bondRequests = dateFrames.map((dateFrame) =>
        axios.get(
          `https://api.xcapitalterminal.com/api/v1/bonds-data/governments-bonds/?country=${country.toLowerCase()}&date_frame=${dateFrame}`
        )
      );
  
      // Fetch all bond data concurrently
      const responses = await Promise.all(bondRequests);
  
      // Process the data after all requests are resolved
      const fetchedData = responses.flatMap((response, index) => {
        const data = response.data.results;
  
        if (data && data.length > 0) {
          const sortedData = data
            .map((item) => ({ ...item, date: parseDate(item.date) }))
            .sort((a, b) => b.date - a.date);
  
          const latest = sortedData[0];
          const latestDate = latest.date;
          const latestPrice = latest.price;
  
          const findClosestDate = (targetDate) => {
            return sortedData.reduce((closest, current) => {
              const currentDiff = Math.abs(current.date - targetDate);
              const closestDiff = Math.abs(closest.date - targetDate);
              return currentDiff < closestDiff ? current : closest;
            });
          };
  
          const oneMonthAgo = new Date(latestDate);
          oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  
          const oneYearAgo = new Date(latestDate);
          oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  
          const closestMonthData = findClosestDate(oneMonthAgo);
          const closestYearData = findClosestDate(oneYearAgo);
  
          const dayChange = calculateYieldChange(latestPrice, sortedData[1]?.price || null);
          const monthChange = calculateYieldChange(latestPrice, closestMonthData?.price || null);
          const yearChange = calculateYieldChange(latestPrice, closestYearData?.price || null);
  
          return {
            dateFrame: dateFrames[index],
            bondType: `${country.toUpperCase()} ${dateFrames[index]}`,
            dayChange,
            monthChange,
            yearChange,
            time: latestDate.toLocaleDateString(),
          };
        }
  
        return [];
      });
  
      setBondsData(fetchedData);
      setLoading(false);
    } catch (err) {
      console.error('Fetch error:', err);
      setError('Failed to fetch data');
      setLoading(false);
    }
  };
  
  useEffect(() => {
    setLoading(true);
    fetchBondData(selectedCountry);
  }, [selectedCountry]); // Refetch data whenever the selected country changes

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  if (loading) return <Box color="white" textAlign="center">Loading...</Box>;
  if (error) return <Box color="red" textAlign="center">{error}</Box>;

  return (
    <Box>
      <Box
        bg={'#1f1f1f'}
        textAlign="center"
        w="100%"
        h="40px"
        p={2}
        mt={'40px'}
        fontSize={'18px'}
        color="white"
      >
        Government Bonds
      </Box>

      {/* Filters */}
      <Box bg={"#171717"} w="100%" p={2} fontSize={"18px"} color="white">
        <Flex>
          <Menu>
            <MenuButton
              as={Button}
              height={'35px'}
              bgColor="#161616"
              color="white"
              m={"0 15px 0 7px"}
              border={"1px solid #999999"}
              _hover={{ bgColor: "#242424" }}
              rightIcon={<FiChevronDown style={{ marginLeft: "20px" }} />}
              _focus={{ bgColor: "#242424" }}
            >
              {selectedCountry}
            </MenuButton>
            <MenuList bgColor="#121212" borderColor={"transparent"}>
              {countries.map((country) => (
                <MenuItem
                  key={country}
                  bgColor="#121212"
                  _hover={{ bgColor: "#242424" }}
                  onClick={() => handleCountryChange(country)}
                >
                  {country}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </Box>

      {/* Table */}
      <Box bg={'#121212'} w="100%" color="white">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color="white">Bond Type</Th>
                <Th color="white">1 Day Change (%)</Th>
                <Th color="white">1 Month Change (%)</Th>
                <Th color="white">1 Year Change (%)</Th>
                {/* <Th>Time</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {bondsData.map((bond, index) => (
                <Tr key={index}>
                  <Td>{bond.bondType}</Td>
                  <Td>{bond.dayChange}</Td>
                  <Td>{bond.monthChange}</Td>
                  <Td>{bond.yearChange}</Td>
                  {/* <Td>{bond.time}</Td> */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default GovernmentBondsTable;

