import React from 'react';
import {Box} from "@chakra-ui/react";
import {Utils} from "../../../../../utils/Utils";
import {Line} from "react-chartjs-2";

const YieldCurveChart = ({currentData, monthData, yearData}) => {

    const yieldData = {
        // labels: Utils.getYearsArray(2016, 2024),
        labels: currentData.labels,
        datasets: [
            {
                label: 'Current',
                data: currentData.current,
                fill: false,
                borderColor: "#0000ff",
                borderWidth: 2,
                backgroundColor: '#0000ff',
                tension: 0
            },
            {
                label: '1 Month Ago',
                data: monthData.month,
                fill: false,
                borderColor: "#ff0000",
                borderWidth: 2,
                backgroundColor: '#ff0000',
                tension: 0
            },
            {
                label: '1 Year Ago',
                data: yearData.year,
                fill: false,
                borderColor: "#ffce30",
                borderWidth: 2,
                backgroundColor: '#ffce30',
                tension: 0
            }
        ]
    }
    const legendMargin = {
        id: 'legendMargin',
        beforeInit(chart, legend, plugins) {
            const originalFit = chart.legend.fit;
            const margin = plugins.margin || 0;
            chart.legend.fit = function fit() {
                if (originalFit) {
                    originalFit.call(this)
                }
                return this.height += margin;
            }
        }
    }
    const options = {
        responsive: true,
        plugins: {
            legendMargin: {
                margin: 35
            },
            legend: {
                align: 'start',
                labels: {
                    font: {
                        size: '16px'
                    },
                    usePointStyle: true,
                    pointStyle: 'circle',
                    boxWidth: 10,
                    boxHeight: 10,
                    color: 'white' // White text for legend labels
                }
            }
        },
        elements: {
            point: {
                radius: 2,
            }
        },
        scales: {
            y: {
                grid: {
                    color: '#515151',
                },
                border: {
                    dash: [4, 2]
                },
                beginAtZero: true,
                ticks: {
                    font: {
                        size: 10
                    },
                    color: 'white',
                    callback: (value) => value + '%',
                    stepSize: 2.5
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 10
                    },
                    color: 'white'
                }
            }
        }
    };
    return (
        <Box color={"white"} bg={"#121212"} borderRadius={"20px"}>
            <Box h="60px" p={"30px 0 16px 30px"} fontSize={"18px"}>
                Yield curve chart
            </Box>
            <Box w='100%' p={"40px"}>
                <Box className="chart">
                    <Line height={'130px'} data={yieldData} options={options} plugins={[legendMargin]}/>
                </Box>
            </Box>
        </Box>
    );
};

export default YieldCurveChart;