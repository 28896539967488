import axios from "axios";

const PORTFOLIO_API_URL = process.env.REACT_APP_TERMINAL_API_URL + '/portfolio-data'

export const getAllInvestore = () => {
    return axios.get(PORTFOLIO_API_URL + '/investors')
}
export const getAllTransactions = () => {
    return axios.get(PORTFOLIO_API_URL + '/transactions')
}
// const response = await axios.get('https://api.xcapitalterminal.com/api/v1/portfolio-data/transactions');
