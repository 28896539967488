import React from 'react';
import {Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import OverviewComponent from "../overview/layout/OverviewComponent";
import AnalysisComponent from "../analysis/layout/AnalysisComponent";
import RatiosComponent from "../ratios/layout/RatiosComponent";

const FinancialAnalysisComponent = () => {
    return (
        <div>
            <Tabs color={'white'} variant='unstyled'>
                <TabList>
                    <Tab _selected={{color:"#ffce30"}}>Overview</Tab>
                    <Tab _selected={{color:"#ffce30"}}>Analysis</Tab>
                    <Tab _selected={{color:"#ffce30"}}>Ratios</Tab>
                </TabList>
                <TabIndicator mt='-1.5px' height='2px' bg={"#ffce30"} borderRadius='1px'/>
                <TabPanels>
                    <TabPanel>
                        <OverviewComponent/>
                    </TabPanel>
                    <TabPanel>
                        <AnalysisComponent/>
                    </TabPanel>
                    <TabPanel>
                        <RatiosComponent/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default FinancialAnalysisComponent;