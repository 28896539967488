import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  breakpoints: {
    xl: "1450px",
    lg: "1200px",          
    md: "992px",  
    sm: "768px",   
    base: "0px",
    xs: "480px", 
  }
});

export default theme;

