import React from 'react'
import MarketSummaryComponent from '../MarketSummaryComponent'
import EconomicCalendarTableComponent from '../EconomicCalendarTableComponent'

function ForwardsComponents() {
  return (
    <div>
        <MarketSummaryComponent />
        <EconomicCalendarTableComponent />
    </div>
  )
}

export default ForwardsComponents