import React from 'react'
import TransactionTable from '../TransactionTable'

// TransactionComponent: Renders the transaction section of the portfolio management interface
function TransactionComponent() {
  return (
    <div>
        {/* Render the TransactionTable component */}
        <TransactionTable />
    </div>
  )
}

export default TransactionComponent