import React, { useState } from 'react'
import { CalendarIcon } from "@chakra-ui/icons";
import {
  Grid, GridItem, Input, Select,
  FormControl, FormLabel, InputGroup, InputLeftElement,
  Box, Button, Flex, Text, Heading, Stack
} from '@chakra-ui/react';
import MomentumComponent from '../momentumTrading/MomentumComponent'
import CandlestickChart from '../momentumTrading/CandlestickChart'
import RSIChart from '../momentumTrading/RsiChart'
import MACDChart from '../momentumTrading/MacdChart'
import RiskManagementComponent from '../momentumTrading/RiskManagementComponent';
import ProfitLossChart from '../momentumTrading/ProfitLossChart';
import ZscoreChart from '../strategyTrading/ZscoreChartComponent';
import CorrelationChart from '../strategyTrading/CorrelationChartComponent';
import PerformanceChart from '../strategyTrading/PerformanceChartComponent';
import PairTradingStrategy from '../strategyTrading/PairTradingStrategy';

function StrategyBuilderComponent() {

  const [selectedStrategy, setSelectedStrategy] = useState('momentum');

  const handleStrategyChange = (event) => {
    setSelectedStrategy(event.target.value);
  };

  return (
    <Box>
      <Box overflowX="auto">
        <Grid templateColumns="repeat(5, 1fr)" gap="5" mb="4" alignItems="end" minW="1200px">

          <GridItem>
            <Select
              placeholder="Choose a Strategy"
              bg="blackAlpha.800"
              h="45px"
              onChange={handleStrategyChange}
              value={selectedStrategy}
            >
              <option value="momentum" sx={{ bg: 'black', color: 'white' }}>
                Momentum
              </option>
              <option value="mean_reversion" sx={{ bg: 'black', color: 'white' }}>
                Mean Reversion
              </option>
              <option value="pairs_trading" sx={{ bg: 'black', color: 'white' }}>
                Pairs Trading
              </option>
              <option value="stat_arbitrage" sx={{ bg: 'black', color: 'white' }}>
                Statistical Arbitrage
              </option>
              <option value="factor_investing" sx={{ bg: 'black', color: 'white' }}>
                Factor Investing
              </option>
              <option value="volatility_trading" sx={{ bg: 'black', color: 'white' }}>
                Volatility Trading
              </option>
            </Select>
          </GridItem>

          <GridItem>
            <Select placeholder="Types of Instruments" bg="blackAlpha.800" h="45px" >
              <option value="stocks" sx={{ bg: 'black', color: 'white' }}>Stocks</option>
              <option value="Bond" sx={{ bg: 'black', color: 'white' }}>Bond</option>
              <option value="Derivatives" sx={{ bg: 'black', color: 'white' }}>Derivatives</option>
              <option value="Forex" sx={{ bg: 'black', color: 'white' }}>Forex</option>
              <option value="Commodities" sx={{ bg: 'black', color: 'white' }}>Commodities</option>
              <option value="Cryptoccurency" sx={{ bg: 'black', color: 'white' }}>Cryptoccurency</option>
            </Select>
          </GridItem>

          <GridItem>
            <Select placeholder="List of Stock" bg="blackAlpha.800" h="45px" >
              <option value="ATW" sx={{ bg: 'black', color: 'white' }}>Attijariwafa Bank (ATW)</option>
              <option value="BCP" sx={{ bg: 'black', color: 'white' }}>Banque Centrale Populaire (BCP)</option>
              <option value="CMA" sx={{ bg: 'black', color: 'white' }}>Ciments du Maroc (CMA)</option>
              <option value="LHM" sx={{ bg: 'black', color: 'white' }}>lafargeHolcim Maroc (LHM)</option>
              <option value="Maroc Telecom" sx={{ bg: 'black', color: 'white' }}>Maroc Telecom</option>
            </Select>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel color="#ffce30" mb="1">From</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <CalendarIcon color="#ffce30" />
                </InputLeftElement>
                <Input
                  type="date"
                  bg="blackAlpha.800"
                  borderColor="#ffce30"
                  h="45px"
                  _hover={{ borderColor: '#ffce30' }}
                  _focus={{ borderColor: '#ffce30', boxShadow: '0 0 0 1px #ffce30' }}
                />
              </InputGroup>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel color="#ffce30" mb="1">To</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <CalendarIcon color="#ffce30" />
                </InputLeftElement>
                <Input
                  type="date"
                  bg="blackAlpha.800"
                  borderColor="#ffce30"
                  h="45px"
                  _hover={{ borderColor: '#ffce30' }}
                  _focus={{ borderColor: '#ffce30', boxShadow: '0 0 0 1px #ffce30' }}
                />
              </InputGroup>
            </FormControl>
          </GridItem>
        </Grid>
      </Box>

      {selectedStrategy === 'momentum' && (
        <Stack>
          <MomentumComponent />
          <CandlestickChart />
          <RSIChart />
          <MACDChart />
          <RiskManagementComponent />
          <ProfitLossChart />
        </Stack>
      )}

      {selectedStrategy === 'pairs_trading' && (
        <Stack>
          <PairTradingStrategy />
          <Box bg="black" p="20px">
            <Flex justifyContent="space-between" mb="10px" color="#ccc">
              <Box>
                {['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y', '5Y', 'All'].map((period, index) => (
                  <Button key={index} variant="ghost" color="#ccc" mr="10px">
                    {period}
                  </Button>
                ))}
              </Box>
              <Text>09:27:02 (GMT +1) % log auto</Text>
            </Flex>
            <PerformanceChart/>
            <Box mt="20px">
              <Heading as="h4" size="md" color="white" mb="10px">Zscore</Heading>
              <ZscoreChart />
            </Box>
            <Box mt="20px">
              <Heading as="h4" size="md" bg="black" mb="10px">Correlation and Cointegration</Heading>
              <CorrelationChart />
            </Box>
          </Box>
        </Stack>
      )}
    </Box>
  )
}
export default StrategyBuilderComponent;
