// Import necessary dependencies and components
import React, { useState, useMemo } from 'react'
import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Button, InputGroup, InputLeftElement, Input, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Tfoot, Select } from '@chakra-ui/react'
import { FiChevronDown, FiFilter, FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import { FaSearch } from 'react-icons/fa'
import { TbCaretUpDownFilled } from "react-icons/tb";
import { HiMenuAlt2 } from "react-icons/hi";

// Mock call options data for demonstration purposes
const callOptionsData = [
    { symbol: 'GCQ2024', name: 'Gold Futures Call', strikePrice: 2000, bidPrice: 35.20, askPrice: 35.40, lastPrice: 35.30, change: 0.50, volume: 1250, openInterest: 4500 },
    { symbol: 'ESM2024', name: 'E-mini S&P 500 Call', strikePrice: 4800, bidPrice: 125.50, askPrice: 126.00, lastPrice: 125.75, change: -2.25, volume: 3500, openInterest: 15000 },
    { symbol: 'CLK2024', name: 'Crude Oil Call', strikePrice: 80, bidPrice: 2.15, askPrice: 2.20, lastPrice: 2.18, change: 0.08, volume: 2800, openInterest: 12000 },
    { symbol: 'ZCN2024', name: 'Corn Futures Call', strikePrice: 550, bidPrice: 12.50, askPrice: 12.75, lastPrice: 12.60, change: -0.30, volume: 950, openInterest: 3800 },
    { symbol: 'NGM2024', name: 'Natural Gas Call', strikePrice: 3.50, bidPrice: 0.15, askPrice: 0.16, lastPrice: 0.155, change: 0.005, volume: 1800, openInterest: 8500 },
    { symbol: 'SIQ2024', name: 'Silver Futures Call', strikePrice: 25, bidPrice: 0.85, askPrice: 0.87, lastPrice: 0.86, change: 0.02, volume: 750, openInterest: 2900 },
    { symbol: 'ZWN2024', name: 'Wheat Futures Call', strikePrice: 650, bidPrice: 15.20, askPrice: 15.40, lastPrice: 15.30, change: -0.40, volume: 1100, openInterest: 4200 },
    { symbol: 'HGQ2024', name: 'Copper Futures Call', strikePrice: 400, bidPrice: 5.50, askPrice: 5.60, lastPrice: 5.55, change: 0.15, volume: 900, openInterest: 3500 },
    { symbol: 'ZSQ2024', name: 'Soybean Futures Call', strikePrice: 1200, bidPrice: 25.30, askPrice: 25.50, lastPrice: 25.40, change: 0.60, volume: 1500, openInterest: 6800 },
    { symbol: 'ZBM2024', name: 'T-Bond Futures Call', strikePrice: 150, bidPrice: 2.80, askPrice: 2.85, lastPrice: 2.82, change: -0.08, volume: 2200, openInterest: 9500 }
];

function CallOptionComponent() {
    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Calculate total pages
    const totalPages = Math.ceil(callOptionsData.length / rowsPerPage);

    // Get current options for the current page
    const currentOptions = useMemo(() => 
        callOptionsData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
    [currentPage, rowsPerPage]);

    // Function to get page numbers for pagination
    const getPageNumbers = () => {
        if (totalPages <= 4) return Array.from({ length: totalPages }, (_, i) => i + 1);
        if (currentPage <= 2) return [1, 2, 3, 4];
        if (currentPage >= totalPages - 1) return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        return [currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    };

    // Helper function to render menu buttons
    const renderMenuButton = (text, icon) => (
        <MenuButton as={Button}
            height='35px'
            bgColor="#161616"
            color="white"
            m="0 15px 0 7px"
            border="1px solid #303235"
            _hover={{ bgColor: "#242424" }}
            rightIcon={icon}
            _focus={{ bgColor: "#242424" }}
            fontSize="12px"
        >
            {text}
        </MenuButton>
    );

    return (
        <div>
            {/* Header */}
            <Flex bg="#121212" justifyContent="center" w='100%' h="40px" p={2} fontSize="18px" color='white' position="relative">
                <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                    Call Options
                    <Box position="absolute" right="10px">
                        <HiMenuAlt2 />
                    </Box>
                </Flex>
            </Flex>
            {/* Filter and search section */}
            <Box bg="#171717" w='100%' p={2} fontSize="18px" color='white'>
                <Flex>
                    {/* Date range filter */}
                    <Menu>
                        {renderMenuButton('Date range', <FiChevronDown style={{ marginLeft: "20px" }} />)}
                        <MenuList bgColor="#121212" borderColor="transparent">
                            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 2</MenuItem>
                        </MenuList>
                    </Menu>

                    {/* Additional filter and search */}
                    <Flex flex={1} justifyContent='end' mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height='35px'
                                bgColor="#161616"
                                color="white"
                                borderWidth="1px 0 1px 1px"
                                borderRadius="5px 0 0px 5px"
                                borderColor="#303235"
                                _hover={{ bgColor: "#242424" }}
                                rightIcon={<FiChevronDown />}
                                _focus={{ bgColor: "#242424" }}
                            >
                                <Flex>
                                    <Box m="5px 10px 0 0" fontSize='12px'>
                                        <FiFilter fill="white" />
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#303235" borderColor="transparent" minWidth='100px'>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        {/* Search input */}
                        <InputGroup width='300px'>
                            <InputLeftElement pb={1}>
                                <FaSearch />
                            </InputLeftElement>
                            <Input 
                                type="text" 
                                height='35px' 
                                placeholder="Search"
                                _placeholder={{ color: '#fff', opacity: 0.7 }}
                                _focus={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: 'rgba(119,119,119,0.5)'
                                }}
                                _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                borderColor="#303235"
                                borderWidth="1px"
                                borderRadius="0px 5px 5px 0px"
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            {/* Options table */}
            <Box bg="#121212" w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color='white' className="table-header">
                                {['Symbol', 'Name', 'Strike Price', 'Bid Price', 'Ask Price', 'Last Price', 'Change', 'Volume', 'Open Interest'].map(header => (
                                    <Th key={header}>
                                        <Flex alignItems='center' gap={1}>{header} <TbCaretUpDownFilled /></Flex>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentOptions.map(option => (
                                <Tr key={option.symbol}>
                                    {Object.values(option).map((value, index) => (
                                        <Td key={index}>
                                            {typeof value === 'number' ? value.toFixed(2) : value}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                {/* Pagination */}
                <Flex justifyContent="center" alignItems="center" pt={4} pb={4}>
                    <Button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        isDisabled={currentPage === 1}
                        mr={2}
                        bgColor="#161616"
                        color="white"
                        _hover={{ bgColor: "#242424" }}
                        leftIcon={<FiChevronLeft />}
                    >
                        Previous
                    </Button>
                    {getPageNumbers().map(pageNumber => (
                        <Box
                            key={pageNumber}
                            as="button"
                            onClick={() => setCurrentPage(pageNumber)}
                            mx={1}
                            px={3}
                            py={2}
                            borderRadius="md"
                            bg={currentPage === pageNumber ? "#F8CD20" : "#121212"}
                            color={currentPage === pageNumber ? "black" : "white"}
                            fontWeight={currentPage === pageNumber ? "bold" : "normal"}
                            _hover={{ bg: currentPage === pageNumber ? "#F8CD20" : "#242424" }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            minWidth="32px"
                        >
                            {pageNumber}
                        </Box>
                    ))}
                    <Button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        isDisabled={currentPage === totalPages}
                        ml={2}
                        mr={4}
                        bgColor="#161616"
                        color="white"
                        _hover={{ bgColor: "#242424" }}
                        rightIcon={<FiChevronRight />}
                    >
                        Next
                    </Button>
                    {/* Rows per page selector */}
                    <Box as="span" ml={10} mr={2} color="white">
                        Result Per Page:
                    </Box>
                    <Select
                        value={rowsPerPage}
                        onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        width="70px"
                        bgColor="#161616"
                        color="white"
                        borderColor="#303235"
                        _hover={{ borderColor: "rgba(119,119,119,0.5)" }}
                        _focus={{ borderColor: "rgba(119,119,119,0.5)", boxShadow: "none" }}
                        sx={{
                            "& option": {
                                backgroundColor: "black",
                                color: "white",
                                border: "none"
                            },
                            "& > option": {
                                border: "none"
                            }
                        }}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </Select>
                </Flex>
            </Box>
        </div>
    )
}

export default CallOptionComponent