import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PerformanceChart = () => {
    function generateData(count, { min, max }) {
        const data = [];
        let i = 0;
        while (i < count) {
          const x = new Date().getTime() + i * 3600000;
          const y = Math.random() * (max - min) + min;
          data.push([x, y]);
          i++;
        }
        return data;
    }
  const options = {
    chart: {
      type: 'area',
      height: 300,
      background: 'black',
      foreColor: '#ccc',
      toolbar: { show: false },
    },
    colors: ['#00E396', '#008FFB'],
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth', width: 2 },
    fill: {
      type: 'gradient',
      gradient: { opacityFrom: 0.6, opacityTo: 0.1 }
    },
    xaxis: {
      type: 'datetime',
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      labels: {
        formatter: (value) => `${value}%`,
      },
    },
    grid: { borderColor: '#2A2E39' },
    tooltip: { theme: 'dark' },
  };

  const series = [
    {
      name: 'Series 1',
      data: generateData(20, { min: -5, max: 8 })
    },
    {
      name: 'Series 2',
      data: generateData(20, { min: -4, max: 7 })
    }
  ];

  return <ReactApexChart options={options} series={series} type="area" height={300} />;
};

export default PerformanceChart;
