import React, { useState, useMemo } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Select,
    InputGroup,
    InputLeftElement,
    Input,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from '@chakra-ui/react';
import { FiChevronDown, FiFilter, FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { HiMenuAlt2 } from "react-icons/hi";

const data = [
    { ticker: 'IAM', name: 'Itissalat Al-Maghrib', price: 130, purchaseDate: '2023-11-01', yield: 4.5, pe: 15.2, roe: 14.0, netMargin: 22, revGrowth: 5.0, incomeGrowth: 4.0, analysisTarget: 140 },
    { ticker: 'ATW', name: 'Attijariwafa Bank', price: 450, purchaseDate: '2023-10-15', yield: 3.8, pe: 12.8, roe: 15.0, netMargin: 20, revGrowth: 6.0, incomeGrowth: 5.5, analysisTarget: 470 },
    { ticker: 'CMR', name: 'LafargeHolcim Maroc', price: 1600, purchaseDate: '2023-09-20', yield: 2.5, pe: 10.5, roe: 12.0, netMargin: 18, revGrowth: 4.0, incomeGrowth: 3.8, analysisTarget: 1700 },
    { ticker: 'ADI', name: 'Addoha', price: 25, purchaseDate: '2023-12-10', yield: 0.0, pe: 20.0, roe: 8.0, netMargin: 10, revGrowth: 8.5, incomeGrowth: 7.0, analysisTarget: 1700 },
    { ticker: 'CIB', name: 'Crédit Immobilier', price: 350, purchaseDate: '2023-11-10', yield: 4.0, pe: 13.5, roe: 13.0, netMargin: 19, revGrowth: 4.2, incomeGrowth: 3.5, analysisTarget: 365 },
    { ticker: 'WAF', name: 'Wafa Assurance', price: 870, purchaseDate: '2023-09-25', yield: 2.8, pe: 11.0, roe: 16.0, netMargin: 21, revGrowth: 7.0, incomeGrowth: 5.5, analysisTarget: 920 },
    { ticker: 'SMI', name: 'Société Minière', price: 190, purchaseDate: '2023-12-05', yield: 2.2, pe: 18.5, roe: 12.5, netMargin: 12, revGrowth: 3.0, incomeGrowth: 2.5, analysisTarget: 210 },
    { ticker: 'CBR', name: 'Cosumar Béni Mellal', price: 310, purchaseDate: '2023-11-17', yield: 3.5, pe: 13.0, roe: 15.5, netMargin: 18, revGrowth: 6.2, incomeGrowth: 5.8, analysisTarget: 320 },
    { ticker: 'MAS', name: 'Maroc Telecom', price: 100, purchaseDate: '2023-10-05', yield: 4.7, pe: 14.7, roe: 13.5, netMargin: 23, revGrowth: 5.5, incomeGrowth: 4.7, analysisTarget: 110 },
    { ticker: 'BMCE', name: 'BMCE Bank', price: 430, purchaseDate: '2023-09-30', yield: 4.3, pe: 12.0, roe: 14.8, netMargin: 19, revGrowth: 5.7, incomeGrowth: 5.0, analysisTarget: 450 },
    { ticker: 'AGM', name: 'Agma', price: 675, purchaseDate: '2023-11-08', yield: 3.2, pe: 11.5, roe: 17.0, netMargin: 21, revGrowth: 7.8, incomeGrowth: 6.5, analysisTarget: 700 },
    { ticker: 'MSI', name: 'Marsa Maroc', price: 520, purchaseDate: '2023-09-25', yield: 3.0, pe: 12.5, roe: 15.2, netMargin: 20, revGrowth: 6.0, incomeGrowth: 5.0, analysisTarget: 540 },
    { ticker: 'CDM', name: 'Crédit du Maroc', price: 900, purchaseDate: '2023-11-15', yield: 2.9, pe: 10.8, roe: 16.5, netMargin: 18, revGrowth: 5.9, incomeGrowth: 5.3, analysisTarget: 950 },
    { ticker: 'MOR', name: 'Moulin du Maroc', price: 680, purchaseDate: '2023-10-12', yield: 2.4, pe: 11.7, roe: 14.5, netMargin: 17, revGrowth: 4.9, incomeGrowth: 4.0, analysisTarget: 720 },
    { ticker: 'STE', name: 'Sté des Tabacs', price: 2100, purchaseDate: '2023-12-05', yield: 5.0, pe: 14.0, roe: 20.0, netMargin: 30, revGrowth: 6.5, incomeGrowth: 5.5, analysisTarget: 2200 },
    { ticker: 'SID', name: 'Siderurgie du Maroc', price: 800, purchaseDate: '2023-09-30', yield: 3.5, pe: 12.2, roe: 13.0, netMargin: 15, revGrowth: 4.0, incomeGrowth: 3.2, analysisTarget: 850 },
    { ticker: 'SPM', name: 'Sonasid', price: 350, purchaseDate: '2023-10-10', yield: 4.0, pe: 10.5, roe: 16.0, netMargin: 19, revGrowth: 5.5, incomeGrowth: 5.0, analysisTarget: 370 },
    { ticker: 'ALM', name: 'Aluminium du Maroc', price: 1400, purchaseDate: '2023-09-25', yield: 2.0, pe: 16.8, roe: 12.5, netMargin: 13, revGrowth: 4.2, incomeGrowth: 3.0, analysisTarget: 1450 },
    { ticker: 'COS2', name: 'Cosumar II', price: 310, purchaseDate: '2023-12-03', yield: 3.2, pe: 14.5, roe: 15.0, netMargin: 17, revGrowth: 5.3, incomeGrowth: 4.5, analysisTarget: 325 },
    { ticker: 'BMA', name: 'Banque Marocaine', price: 720, purchaseDate: '2023-11-20', yield: 3.0, pe: 11.5, roe: 14.8, netMargin: 20, revGrowth: 6.2, incomeGrowth: 5.7, analysisTarget: 750 },
    { ticker: 'SAM', name: 'Saham', price: 560, purchaseDate: '2023-10-25', yield: 4.5, pe: 13.2, roe: 16.0, netMargin: 21, revGrowth: 5.9, incomeGrowth: 5.3, analysisTarget: 580 },
    { ticker: 'FIS', name: 'Finances du Maroc', price: 630, purchaseDate: '2023-09-29', yield: 2.8, pe: 12.5, roe: 13.5, netMargin: 18, revGrowth: 4.8, incomeGrowth: 4.1, analysisTarget: 650 },
    { ticker: 'ELM', name: 'Electricité du Maroc', price: 900, purchaseDate: '2023-12-01', yield: 3.7, pe: 10.8, roe: 15.2, netMargin: 22, revGrowth: 5.8, incomeGrowth: 5.2, analysisTarget: 920 },
    { ticker: 'MSO', name: 'Maroc Solaire', price: 540, purchaseDate: '2023-11-17', yield: 2.5, pe: 13.0, roe: 14.0, netMargin: 16,}
];

const FundamentalComponent = ({ searchTerm }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    // Filter data based on search term
    const filteredData = data.filter(
        (row) =>
            row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.ticker.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort data
    const sortedData = useMemo(() => {
        let sortableData = [...filteredData];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [filteredData, sortConfig]);

    const totalPages = Math.ceil(sortedData.length / rowsPerPage);
    const currentCompanies = sortedData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const getPageNumbers = () => {
        if (totalPages <= 4) return Array.from({ length: totalPages }, (_, i) => i + 1);
        if (currentPage <= 2) return [1, 2, 3, 4];
        if (currentPage >= totalPages - 1) return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        return [currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div style={{transform: {base: "scale(0.8)", md: "scale(1)"}, transformOrigin: "top left"}}>
            {/* Header */}
            <Flex bg="#121212" justifyContent="center" w='100%' h="40px" p={2} mt="40px" fontSize={{base: "14px", md: "18px"}} color='white' position="relative">
                <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                    Companies
                    <Box position="absolute" right="10px">
                        <HiMenuAlt2 />
                    </Box>
                </Flex>
            </Flex>

            {/* Filter and Search Section */}
            <Box bg="#171717" w='100%' p={2} fontSize={{base: "14px", md: "18px"}} color='white'>
                <Flex>
                    <Flex flex={1} justifyContent={{ base: 'center', xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start' }} mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height='35px'
                                bgColor="#161616"
                                color="white"
                                borderWidth="1px 0 1px 1px"
                                borderRadius="5px 0 0px 5px"
                                borderColor="#303235"
                                _hover={{ bgColor: "#242424" }}
                                rightIcon={<FiChevronDown />}
                                _focus={{ bgColor: "#242424" }}
                                fontSize={{base: "12px", md: "14px"}}
                            >
                                <Flex>
                                    <Box m="5px 10px 0 0" fontSize={{base: "10px", md: "12px"}}>
                                        <FiFilter />
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#303235" borderColor="transparent" minWidth='100px'>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
                            </MenuList>
                        </Menu>

                        <InputGroup width={{ base: "100%", md: "300px", lg: "500px" }}>
                            <InputLeftElement pb={1}>
                                <FaSearch />
                            </InputLeftElement>
                            <Input
                                type="text"
                                height='35px'
                                placeholder="Search companies"
                                _placeholder={{ color: '#fff', opacity: 0.7 }}
                                _focus={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: 'rgba(119,119,119,0.5)'
                                }}
                                _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                borderColor="#303235"
                                borderWidth="1px"
                                borderRadius="0px 5px 5px 0px"
                                fontSize={{base: "12px", md: "14px"}}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>

            {/* Company Table */}
            <Box bg="#121212" w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple' fontSize={{base: "12px", md: "14px"}}>
                        <Thead>
                            <Tr color='white' className="table-header">
                                <Th onClick={() => requestSort('ticker')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>TICKER <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('name')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>NAME <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('price')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>PRICE(MAD) <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('purchaseDate')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>PURCHASE DATE <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('yield')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>YIELD (%) <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('pe')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>P/E <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('roe')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>ROE (%) <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('netMargin')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>NET MARGIN (%) <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('revGrowth')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>REV GROWTH (%) <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('incomeGrowth')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>INCOME GROWTH (%) <TbCaretUpDownFilled /></Flex>
                                </Th>
                                <Th onClick={() => requestSort('analysisTarget')} fontSize={{base: "10px", md: "12px"}}>
                                    <Flex alignItems='center' gap={1}>ANALYSIS TARGET (%) <TbCaretUpDownFilled /></Flex>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentCompanies.map((row, index) => (
                                <Tr key={index}>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.ticker}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>
                                        <Box maxWidth="200px" overflow="auto" whiteSpace="nowrap">
                                            {row.name}
                                        </Box>
                                    </Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.price}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.purchaseDate}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.yield}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.pe}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.roe}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.netMargin}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.revGrowth}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.incomeGrowth}</Td>
                                    <Td fontSize={{base: "10px", md: "14px"}}>{row.analysisTarget}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                <Flex 
                    justifyContent="center" 
                    alignItems="center" 
                    pt={4} 
                    pb={4}
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap={4}
                    fontSize={{base: "12px", md: "14px"}}
                >
                    <Flex>
                        <Button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            isDisabled={currentPage === 1}
                            mr={2}
                            bgColor="#161616"
                            color="white"
                            _hover={{ bgColor: "#242424" }}
                            leftIcon={<FiChevronLeft />}
                            fontSize={{base: "12px", md: "14px"}}
                        >
                            Previous
                        </Button>

                        {getPageNumbers().map(pageNumber => (
                            <Box
                                key={pageNumber}
                                as="button"
                                onClick={() => setCurrentPage(pageNumber)}
                                mx={1}
                                px={3}
                                py={2}
                                borderRadius="md"
                                bg={currentPage === pageNumber ? "#F8CD20" : "#121212"}
                                color={currentPage === pageNumber ? "black" : "white"}
                                fontWeight={currentPage === pageNumber ? "bold" : "normal"}
                                _hover={{ bg: currentPage === pageNumber ? "#F8CD20" : "#242424" }}
                                display={{ base: "none", xs: "none", md: "flex", lg: "flex" }}
                                alignItems="center"
                                justifyContent="center"
                                minWidth="32px"
                                fontSize={{base: "12px", md: "14px"}}
                            >
                                {pageNumber}
                            </Box>
                        ))}

                        <Button
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            isDisabled={currentPage === totalPages}
                            ml={2}
                            mr={4}
                            bgColor="#161616"
                            color="white"
                            _hover={{ bgColor: "#242424" }}
                            rightIcon={<FiChevronRight />}
                            fontSize={{base: "12px", md: "14px"}}
                        >
                            Next
                        </Button>
                    </Flex>

                    <Flex alignItems="center">
                        <Box as="span" mr={2} color="white" fontSize={{base: "12px", md: "14px"}}>
                            Companies Per Page:
                        </Box>
                        <Select
                            value={rowsPerPage}
                            onChange={(e) => {
                                setRowsPerPage(Number(e.target.value));
                                setCurrentPage(1);
                            }}
                            width="70px"
                            bgColor="#161616"
                            color="white"
                            borderColor="#303235"
                            _hover={{ borderColor: "rgba(119,119,119,0.5)" }}
                            _focus={{ borderColor: "rgba(119,119,119,0.5)", boxShadow: "none" }}
                            fontSize={{base: "12px", md: "14px"}}
                            sx={{
                                "& option": {
                                    backgroundColor: "black",
                                    color: "white",
                                    border: "none"
                                }
                            }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </Select>
                    </Flex>
                </Flex>
            </Box>
        </div>
    );
};

export default FundamentalComponent;
