import React from 'react';
import {Utils} from "../../../../../../../utils/Utils";
import {Bar} from "react-chartjs-2";

const RevenueToProfitChart = () => {
    const revenueToProfitData = {
        labels: Utils.getYearsArray(2018, 2024),
        datasets: [{
            label: 'Revenue',
            data: [-450, -480, -150, 490, -450, -250, -600],
            backgroundColor: 'rgb(119,255,69)',
        }, {
            label: 'profit',
            data: [-150, 490, 200, -480, 75, 600, 0],
            backgroundColor: 'rgb(2,141,255)',
        }]
    };
    const config = {
        type: 'bar',
        data: revenueToProfitData
    }
    const options = {
        plugins: {
            legend: {
                labels: {
                    color: 'white'
                }
            },
        },
        scales: {
            x: {
                grid: {
                    color: 'white'
                },
                ticks: {
                    color: 'white'
                }
            },
            y: {
                grid: {
                    color: 'white'
                },
                ticks: {
                    callback: (value) => {
                        if (value%1000 !== 0) return ''
                        return value
                    },
                    stepSize: 500,
                    color: 'white'
                }
            }
        }
    }
    return (
        <div>
            <Bar height='100px' data={config.data} options={options}/>
        </div>
    );
};

export default RevenueToProfitChart;