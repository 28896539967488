import React, { useState, useMemo } from 'react';
import {
    Box,
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    InputGroup,
    InputLeftElement,
    Input,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Select
} from '@chakra-ui/react';
import { FiChevronDown, FiFilter, FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { HiMenuAlt2 } from "react-icons/hi";

const data = [
    {
        ticker: "IAM",
        name: "Itissalat Al-Maghrib", 
        price: 130,
        change_percent: "0.5 / 2.0 / 8.0",
        ma_50_200: "128 / 125",
        rsi_5_20_250: "60 / 55 / 50",
        macd: 0.25
    },
    {
        ticker: "ATW",
        name: "Attijariwafa Bank",
        price: 450,
        change_percent: "1.0 / 3.5 / 12.0",
        ma_50_200: "445 / 430",
        rsi_5_20_250: "65 / 60 / 55",
        macd: 0.30
    },
    {
        ticker: "CMR",
        name: "LafargeHolcim Maroc",
        price: 1600,
        change_percent: "-0.3 / -1.0 / 5.0",
        ma_50_200: "1610 / 1590",
        rsi_5_20_250: "45 / 50 / 48",
        macd: -0.10
    },
    {
        ticker: "ADI",
        name: "Addoha",
        price: 25,
        change_percent: "2.0 / 4.0 / 15.0",
        ma_50_200: "24 / 23",
        rsi_5_20_250: "70 / 65 / 60",
        macd: 0.35
    },
    {
        ticker: "COS",
        name: "Cosumar",
        price: 280,
        change_percent: "0.8 / 2.5 / 10.0",
        ma_50_200: "275 / 265",
        rsi_5_20_250: "55 / 50 / 52",
        macd: 0.20
    }
];

const TechnicalsComponent = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');

    // Filter data based on search term
    const filteredData = data.filter(row =>
        Object.values(row).some(value => 
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // Calculate total pages
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    // Get current page data
    const currentData = useMemo(() =>
        filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
        [currentPage, rowsPerPage, filteredData]
    );

    // Get page numbers for pagination
    const getPageNumbers = () => {
        if (totalPages <= 4) return Array.from({ length: totalPages }, (_, i) => i + 1);
        if (currentPage <= 2) return [1, 2, 3, 4];
        if (currentPage >= totalPages - 1) return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        return [currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    };

    return (
        <div style={{ transform: { base: 'scale(0.8)', md: 'scale(1)' }, transformOrigin: 'top left' }}>
            {/* Header */}
            <Flex bg="#121212" justifyContent="center" w='100%' h="40px" p={2} mt="40px" fontSize={{ base: "14px", md: "18px" }} color='white' position="relative">
                <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                    Technical Analysis
                    <Box position="absolute" right="10px">
                        <HiMenuAlt2 />
                    </Box>
                </Flex>
            </Flex>

            {/* Filter and Search Section */}
            <Box bg="#171717" w='100%' p={2} fontSize={{ base: "14px", md: "18px" }} color='white'>
                <Flex>
                    <Flex flex={1} justifyContent={{ base: 'center', xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start' }} mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height='35px'
                                bgColor="#161616"
                                color="white"
                                borderWidth="1px 0 1px 1px"
                                borderRadius="5px 0 0px 5px"
                                borderColor="#303235"
                                _hover={{ bgColor: "#242424" }}
                                rightIcon={<FiChevronDown />}
                                _focus={{ bgColor: "#242424" }}
                                fontSize={{ base: "12px", md: "14px" }}
                            >
                                <Flex>
                                    <Box m="5px 10px 0 0">
                                        <FiFilter fill="white" />
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#303235" borderColor="transparent" minWidth='100px'>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
                            </MenuList>
                        </Menu>

                        <InputGroup width={{ base: "100%", md: "300px", lg: "500px" }}>
                            <InputLeftElement pb={1}>
                                <FaSearch />
                            </InputLeftElement>
                            <Input
                                type="text"
                                height='35px'
                                placeholder="Search technicals"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                _placeholder={{ color: '#fff', opacity: 0.7 }}
                                _focus={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: 'rgba(119,119,119,0.5)'
                                }}
                                _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                borderColor="#303235"
                                borderWidth="1px"
                                borderRadius="0px 5px 5px 0px"
                                fontSize={{ base: "12px", md: "14px" }}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>

            {/* Table */}
            <Box bg="#121212" w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple' fontSize={{ base: "12px", md: "14px" }}>
                        <Thead>
                            <Tr color='white' className="table-header">
                                {['Ticker', 'Name', 'Price(MAD)', 'CHANGE(%)(5D/1M/1Y)', 'MA(50/200)', 'RSI 5/20/250', 'MACD(12,26,9)'].map(header => (
                                    <Th key={header} fontSize={{ base: "12px", md: "14px" }}>
                                        <Flex alignItems='center' gap={1}>{header} <TbCaretUpDownFilled /></Flex>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentData.map((row, index) => (
                                <Tr key={index}>
                                    <Td>{row.ticker}</Td>
                                    <Td>
                                        <Box maxWidth="200px" overflow="auto" whiteSpace="nowrap">
                                            {row.name}
                                        </Box>
                                    </Td>
                                    <Td>{row.price}</Td>
                                    <Td>{row.change_percent}</Td>
                                    <Td>{row.ma_50_200}</Td>
                                    <Td>{row.rsi_5_20_250}</Td>
                                    <Td>{row.macd}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                <Flex 
                    justifyContent="center" 
                    alignItems="center" 
                    pt={4} 
                    pb={4}
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap={4}
                    fontSize={{ base: "12px", md: "14px" }}
                >
                    <Flex>
                        <Button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            isDisabled={currentPage === 1}
                            mr={2}
                            bgColor="#161616"
                            color="white"
                            _hover={{ bgColor: "#242424" }}
                            leftIcon={<FiChevronLeft />}
                            fontSize={{ base: "12px", md: "14px" }}
                        >
                            Previous
                        </Button>

                        {getPageNumbers().map(pageNumber => (
                            <Box
                                key={pageNumber}
                                as="button"
                                onClick={() => setCurrentPage(pageNumber)}
                                mx={1}
                                px={3}
                                py={2}
                                borderRadius="md"
                                bg={currentPage === pageNumber ? "#F8CD20" : "#121212"}
                                color={currentPage === pageNumber ? "black" : "white"}
                                fontWeight={currentPage === pageNumber ? "bold" : "normal"}
                                _hover={{ bg: currentPage === pageNumber ? "#F8CD20" : "#242424" }}
                                display={{ base: "none", xs: "none", md: "flex", lg: "flex" }}
                                alignItems="center"
                                justifyContent="center"
                                minWidth="32px"
                                fontSize={{ base: "12px", md: "14px" }}
                            >
                                {pageNumber}
                            </Box>
                        ))}

                        <Button
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            isDisabled={currentPage === totalPages}
                            ml={2}
                            mr={4}
                            bgColor="#161616"
                            color="white"
                            _hover={{ bgColor: "#242424" }}
                            rightIcon={<FiChevronRight />}
                            fontSize={{ base: "12px", md: "14px" }}
                        >
                            Next
                        </Button>
                    </Flex>

                    <Flex alignItems="center">
                        <Box as="span" mr={2} color="white">
                            Companies Per Page:
                        </Box>
                        <Select
                            value={rowsPerPage}
                            onChange={(e) => {
                                setRowsPerPage(Number(e.target.value));
                                setCurrentPage(1);
                            }}
                            width="70px"
                            bgColor="#161616"
                            color="white"
                            borderColor="#303235"
                            _hover={{ borderColor: "rgba(119,119,119,0.5)" }}
                            _focus={{ borderColor: "rgba(119,119,119,0.5)", boxShadow: "none" }}
                            fontSize={{ base: "12px", md: "14px" }}
                            sx={{
                                "& option": {
                                    backgroundColor: "black",
                                    color: "white",
                                    border: "none"
                                },
                                "& > option": {
                                    border: "none"
                                }
                            }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </Select>
                    </Flex>
                </Flex>
            </Box>
        </div>
    );
};

export default TechnicalsComponent;
