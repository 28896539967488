import React from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, FormControl, FormLabel, Image, Input, Link, Text} from "@chakra-ui/react";
import xcapital_logo from "../../assets/logo/logo.svg"

function LoginPage() {
    const navigate = useNavigate();

    return (
        <Box backgroundColor={"#0c0c0c"} height="100vh">
            <Box pb="60px"/>
            <Box 
                width={{ base: "90%", sm: "70%", md: "50%", lg: "30%" }} 
                m="auto" 
                p={"0 20px 60px 20px"} 
                borderWidth="2px" 
                borderRadius="5px"
            >
                <Box>
                    <Image src={xcapital_logo} m="auto" h="150px" w="150px"/>
                </Box>
                <Text color="white" fontSize={{ base: "24px", md: "36px" }} textAlign="center">
                    Welcome !
                </Text>
                <Text color={"#ffce30"} fontSize={{ base: "18px", md: "24px" }} textAlign="center" fontWeight="bold">
                    Login to X Capital Terminal
                </Text>
                <Box color="white">
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" placeholder='Email'/>
                    </FormControl>
                    <FormControl mt={5} mb={10}>
                        <FormLabel>Password</FormLabel>
                        <Input type="password" placeholder='Password'/>
                    </FormControl>
                    <Button width="100%" backgroundColor={"#393939"} color="white"
                            _hover={{backgroundColor: "#ffce30", color: "#000"}}
                            onClick={() => navigate("/client/x-capital-terminal/dashboard")}>
                        Login
                    </Button>
                    <Box textAlign="center">
                        <Link mt={5} color={"#ffce30"} fontSize="12px" textDecoration="underline">
                            Forgot password?
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default LoginPage;
