import React from 'react';
import {
    Box,
    Button,
    Center,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from "@chakra-ui/react";
import {FiChevronDown} from "react-icons/fi";
import {Chart} from "react-chartjs-2";
import {Chart as ChartJS, registerables} from "chart.js";
import {TreemapController, TreemapElement} from "chartjs-chart-treemap";
import {color} from "chart.js/helpers";
import TradingViewWidget from "./TradingViewWidgetHeatmap";

ChartJS.register(
    ...registerables,
    TreemapController,
    TreemapElement
);

const SectorsHeatmap = () => {
    const heatMapData = {
        telecommunication: [
            {name: 'AAPL', capacityMW: 100, dataCoverage: 150},
            {name: 'MSFT', capacityMW: 250, dataCoverage: 90},
            {name: 'NVDA', capacityMW: 100, dataCoverage: 85},
            {name: 'V', capacityMW: 100, dataCoverage: 65}],
        banking: [
            {name: 'AAPL', capacityMW: 100, dataCoverage: 150},
            {name: 'MSFT', capacityMW: 250, dataCoverage: 150},
            {name: 'NVDA', capacityMW: 100, dataCoverage: 100},
            {name: 'ORCL', capacityMW: 100, dataCoverage: 85},
            {name: 'IBM', capacityMW: 100, dataCoverage: 150},
            {name: 'FIS', capacityMW: 250, dataCoverage: 25},
            {name: 'ORCL', capacityMW: 100, dataCoverage: 85},
            {name: 'NAME', capacityMW: 100, dataCoverage: 15},
            {name: 'NAME', capacityMW: 100, dataCoverage: 30}
        ],
        construction: [
            {name: 'AAPL', capacityMW: 100, dataCoverage: 150},
            {name: 'MSFT', capacityMW: 200, dataCoverage: 150},
            {name: 'NVDA', capacityMW: 80, dataCoverage: 100},
            {name: 'ORCL', capacityMW: 50, dataCoverage: 85},
            {name: 'IBM', capacityMW: 150, dataCoverage: 150},
            {name: 'FIS', capacityMW: 249, dataCoverage: 25},
            {name: 'UNH', capacityMW: 120, dataCoverage: 40},
            {name: 'NAME', capacityMW: 148, dataCoverage: 15},
            {name: 'NAME', capacityMW: 120, dataCoverage: 30}
        ],
        realEstate: [
            {name: 'AAPL', capacityMW: 100, dataCoverage: 30},
            {name: 'MSFT', capacityMW: 250, dataCoverage: 35},
            {name: 'NVDA', capacityMW: 100, dataCoverage: 25},
            {name: 'V', capacityMW: 100, dataCoverage: 45},
            {name: 'AAPL', capacityMW: 100, dataCoverage: 30},
            {name: 'MSFT', capacityMW: 250, dataCoverage: 35},
            {name: 'NVDA', capacityMW: 100, dataCoverage: 25},
            {name: 'V', capacityMW: 100, dataCoverage: 45},
            {name: 'AAPL', capacityMW: 100, dataCoverage: 30},
            {name: 'MSFT', capacityMW: 250, dataCoverage: 35},
            {name: 'NVDA', capacityMW: 100, dataCoverage: 25},
            {name: 'V', capacityMW: 100, dataCoverage: 150},
            {name: 'AAPL', capacityMW: 100, dataCoverage: 120},
            {name: 'MSFT', capacityMW: 250, dataCoverage: 200},
            {name: 'NVDA', capacityMW: 100, dataCoverage: 98},
            {name: 'V', capacityMW: 100, dataCoverage: 94}
        ],
        consumerGoods: [
            {name: 'AAPL', capacityMW: 100, dataCoverage: 150},
            {name: 'MSFT', capacityMW: 250, dataCoverage: 90},
            {name: 'NVDA', capacityMW: 100, dataCoverage: 85},
            {name: 'V', capacityMW: 100, dataCoverage: 65}
        ]
    }

    const heatMapOptions = {
        layout: {
            orientation: 'vertical',
        },
        labels: {
            display: true,
            color: 'white',
            align: 'left',
            position: 'top',
            formatter: (context) => context.raw._data.name,
        },
        plugins: {

            title: {
                display: false,
                text: 'Treemap Example',
            },
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    title(items) {
                        return items[0].raw._data.name;
                    },
                    label(item) {
                        const {
                            _data: {capacityMW, dataCoverage},
                        } = item.raw;
                        return [
                            `Export capacity: ${capacityMW} MW`,
                            `Data Coverage: ${dataCoverage}`,
                        ];
                    },
                },
            },
        },
    };

    const getHeatmapDataOf = (sector) => {
        const heatMapConfig = {
            type: 'treemap',
            data: {
                datasets: [
                    {
                        tree: heatMapData[sector],
                        key: 'capacityMW',
                        backgroundColor(context) {
                            if (context.type !== 'data') return 'transparent';
                            const {dataCoverage} = context.raw._data;
                            if (dataCoverage >= 100) return color('rgb(15,239,170)').rgbString();
                            if (dataCoverage < 100 && dataCoverage >= 80) return color('rgb(14,192,154)').rgbString();
                            if (dataCoverage < 80 && dataCoverage >= 60) return color('rgb(11,78,64)').rgbString();
                            if (dataCoverage < 60 && dataCoverage >= 40) return color('rgb(81,25,39)').rgbString();
                            if (dataCoverage < 40 && dataCoverage >= 20) return color('rgb(199,50,88)').rgbString();
                            if (dataCoverage < 20) return color('rgb(246,60,107)').rgbString();
                        },
                    },
                ],
            },
        };
        return heatMapConfig.data
    }

    return (
        <div>
            <Box bg={"#1f1f1f"} w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Sector heatmap
            </Box>
            <Tabs>
                <Box bg={"#1f1f1f"} w='100%' h="40px" fontSize={"18px"} color='white'>
                    <TabList>
                        <Tab _selected={{textShadow:"1px 1px 20px #ffce30"}}>X Capital Heatmap</Tab>
                        <Tab _selected={{textShadow:"1px 1px 20px #ffce30"}}>TradingView Heatmap</Tab>
                    </TabList>
                    <TabIndicator mt='-1.5px' height='2px' bg={"#ffce30"} borderRadius='1px' />
                </Box>
                <TabPanels>
                    <TabPanel padding={0}>
                        <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                            <Flex flexWrap="wrap">
                                <Box m={"0 15px 15px 7px"} minW={"200px"} flex={1}>
                                    <Text m={0}>KPI</Text>
                                    <Menu>
                                        <MenuButton as={Button}
                                                    height={'35px'}
                                                    bgColor="#161616"
                                                    color="white"
                                                    border={"1px solid #999999"}
                                                    _hover={{bgColor: "#242424"}}
                                                    rightIcon={<FiChevronDown style={{marginLeft: "20px"}}/>}
                                                    _focus={{bgColor: "#242424"}}
                                        >
                                            Market Cap
                                        </MenuButton>
                                        <MenuList bgColor="#121212" borderColor={"transparent"}>
                                            <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Cap 1</MenuItem>
                                            <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Cap 2</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Box>
                                <Box className="sectors-heatmap" minW={"200px"} flex={1}>
                                    <Text m={0}>Time frame</Text>
                                    <div className="time-frame">
                                        <input type="checkbox" className="btn-check" id="btn-check-outlined"
                                               autoComplete="off"/>
                                        <label className="btn btn-outline-warning" htmlFor="btn-check-outlined">
                                            D
                                        </label>
                                        <input type="checkbox" className="btn-check" id="btn-check-outlined1"
                                               autoComplete="off"/>
                                        <label className="btn btn-outline-warning" htmlFor="btn-check-outlined1">
                                            W
                                        </label>
                                        <input type="checkbox" className="btn-check" id="btn-check-outlined2"
                                               autoComplete="off"/>
                                        <label className="btn btn-outline-warning" htmlFor="btn-check-outlined2">
                                            M
                                        </label>
                                        <input type="checkbox" className="btn-check" id="btn-check-outlined3"
                                               autoComplete="off"/>
                                        <label className="btn btn-outline-warning" htmlFor="btn-check-outlined3">
                                            Y
                                        </label>
                                    </div>
                                </Box>
                            </Flex>
                        </Box>
                        <Box bg={"#121212"} w='100%' minH={'500px'} color='white' p={4}>
                            <Flex flexWrap="wrap" justifyContent="center" gap={4}>
                                <Box minW={'320px'} flex="1 1 320px">
                                    <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" mt={"10px"} color='white'>
                                        Telecommunication
                                    </Box>
                                    <Box>
                                        <Chart height='280px' type={'treemap'}
                                               data={getHeatmapDataOf('telecommunication')}
                                               options={heatMapOptions}/>
                                    </Box>
                                </Box>
                                
                                <Flex direction="column" minW={'280px'} flex="1 1 280px" gap={4}>
                                    <Box>
                                        <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" color='white'>
                                            Banking
                                        </Box>
                                        <Box>
                                            <Chart height='280px' type={'treemap'} data={getHeatmapDataOf('banking')}
                                                   options={heatMapOptions}/>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" color='white'>
                                            Construction
                                        </Box>
                                        <Box>
                                            <Chart height='130px' type={'treemap'} data={getHeatmapDataOf('construction')}
                                                   options={heatMapOptions}/>
                                        </Box>
                                    </Box>
                                </Flex>

                                <Flex direction="column" minW={'230px'} flex="1 1 230px" gap={4}>
                                    <Box>
                                        <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" color='white'>
                                            Real Estate
                                        </Box>
                                        <Box>
                                            <Chart height='230px' type={'treemap'} data={getHeatmapDataOf('realEstate')}
                                                   options={heatMapOptions}/>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" color='white'>
                                            Consumer Goods
                                        </Box>
                                        <Box>
                                            <Chart height='230px' type={'treemap'} data={getHeatmapDataOf('consumerGoods')}
                                                   options={heatMapOptions}/>
                                        </Box>
                                    </Box>
                                </Flex>

                                <Box fontSize={'10px'} minW={'250px'} flex="0 0 250px">
                                    <Text fontSize={'16px'}>Legends (Capitalization)</Text>
                                    <Box>
                                        <Flex>
                                            <Box w={'40px'} h={'40px'} bgColor={'rgb(15,239,170)'} m={'10px 10px 0 10px'}/>
                                            <Center>Green (High) {'>'} 100 </Center>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Flex>
                                            <Box w={'40px'} h={'40px'} bgColor={'rgb(14,192,154)'} m={'10px 10px 0 10px'}/>
                                            <Center>Green (Medium) 80~100 </Center>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Flex>
                                            <Box w={'40px'} h={'40px'} bgColor={'rgb(11,78,64)'} m={'10px 10px 0 10px'}/>
                                            <Center>Green (Low) 60~80</Center>
                                        </Flex>
                                    </Box>

                                    <Box>
                                        <Flex mt={'20px'}>
                                            <Box w={'40px'} h={'40px'} bgColor={'rgb(81,25,39)'} m={'10px 10px 0 10px'}/>
                                            <Center>Red (Low) 40~60 </Center>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Flex>
                                            <Box w={'40px'} h={'40px'} bgColor={'rgb(199,50,88)'} m={'10px 10px 0 10px'}/>
                                            <Center>Red (Medium) 20~40 </Center>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Flex>
                                            <Box w={'40px'} h={'40px'} bgColor={'rgb(246,60,107)'} m={'10px 10px 0 10px'}/>
                                            <Center>Red (High) &lt; 20</Center>
                                        </Flex>
                                    </Box>
                                    <Text width={'200px'} fontSize={'12px'} p={3}>
                                        Higher opacity indicates higher intensity or magnitude.
                                        <br/>
                                        Lower opacity indicates lower intensity or magnitude.
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    </TabPanel>
                    <TabPanel padding={0}>
                        <Box>
                            <TradingViewWidget/>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default SectorsHeatmap;