import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box } from '@chakra-ui/react';

const TradingViewLineChart = () => {
    // State to store chart data
    const [chartData, setChartData] = useState({
        series: [{
            name: 'Stock Price',
            data: []
        }],
        options: {
            chart: {
                type: 'line',
                height: 500,
                background: '#121212',
                foreColor: '#fff'
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            colors: ['#FFD700'],
            xaxis: {
                type: 'datetime',
                labels: {
                    style: {
                        colors: '#fff'
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#fff'
                    },
                    formatter: (value) => `$${value.toFixed(2)}`
                }
            },
            tooltip: {
                theme: 'dark',
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            grid: {
                borderColor: '#333'
            }
        }
    });

    // Simulate API data fetch
    useEffect(() => {
        const fetchData = async () => {
            // Simulating API call delay
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Generate last 100 days of mock data
            const generateMockData = () => {
                const data = [];
                let currentPrice = 100;
                const endDate = new Date();
                
                for(let i = 99; i >= 0; i--) {
                    const date = new Date();
                    date.setDate(endDate.getDate() - i);
                    
                    // Add some random price movement
                    currentPrice = currentPrice + (Math.random() - 0.5) * 5;
                    
                    data.push({
                        x: date.getTime(),
                        y: currentPrice.toFixed(2)
                    });
                }
                return data;
            };

            // Update chart with new data
            setChartData(prevState => ({
                ...prevState,
                series: [{
                    ...prevState.series[0],
                    data: generateMockData()
                }]
            }));
        };

        fetchData();

        // Simulate real-time updates every 30 seconds
        const interval = setInterval(() => {
            setChartData(prevState => {
                const lastDataPoint = prevState.series[0].data[prevState.series[0].data.length - 1];
                const newPrice = parseFloat(lastDataPoint.y) + (Math.random() - 0.5) * 2;
                
                const newData = [...prevState.series[0].data];
                newData.push({
                    x: new Date().getTime(),
                    y: newPrice.toFixed(2)
                });
                
                // Keep only last 100 points
                if (newData.length > 100) {
                    newData.shift();
                }

                return {
                    ...prevState,
                    series: [{
                        ...prevState.series[0],
                        data: newData
                    }]
                };
            });
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Box p={4} bg="#121212" my={4}>
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={500}
            />
        </Box>
    );
};

export default TradingViewLineChart;
