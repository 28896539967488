import React from 'react';
import GovernmentBondsTable from "../GovernmentBondsTable";
import CorporateBonds from "../CorporateBonds";

const DataAndAnalysisComponent = () => {
    return (
        <>
            <GovernmentBondsTable/>
            <CorporateBonds/>
        </>
    );
};

export default DataAndAnalysisComponent;