// Import necessary components and icons from Chakra UI and React Icons
import { Box, Flex, Text, Menu, MenuButton, MenuList, MenuItem, Button, Icon } from '@chakra-ui/react'
import React, { useState } from 'react'
import { HamburgerIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { FiChevronDown } from 'react-icons/fi'
import { AiFillInfoCircle } from "react-icons/ai";
import { IoNuclear } from "react-icons/io5";
import { CgCalculator } from "react-icons/cg";
import { IoMdSpeedometer } from "react-icons/io";
import { BsCashStack } from "react-icons/bs";
import { FaChartLine } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";

function PortfolioHighlights() {
    // Array of country codes
    const countries = ["MAR", "ZAF", "NGA", "KEN", "EGY", "GHA", "UGA", "MUS"]
    // State to keep track of the selected country, initialized with 'MAR'
    const [selectedCountry, setSelectedCountry] = useState('MAR')

    // Function to update the selected country
    function selectCountry(countryName) {
        setSelectedCountry(countryName)
    }
    return (
        <div>
            {/* Header section */}
            <Box bg="#121212" textAlign="left" p={4} fontSize={"18px"} color="white" position="relative">
                Portfolio Highlights
                <HamburgerIcon position="absolute" right="4" top="50%" transform="translateY(-50%)" boxSize="24px" />
            </Box>
            {/* Main content section */}
            <Box bg="#0A0A0A" p={4} pb={10}>
                {/* Portfolio value and country selector */}
                <Flex justifyContent={"space-between"} flexDirection={{ base: "column", md: "row" }} mb={{ base: 5, md: 0 }}>
                    <Box>
                        <Text>Portfolio Value</Text>
                        <Flex gap={"20px"} alignItems={"center"}>
                            <Text color="#F8CD20" fontSize={"23px"} fontWeight="bold">13,257.77 MAD</Text>
                            <Text color="#16A34A" fontSize={"15px"} display={"flex"} alignItems={"center"}>1.74%    <ArrowUpIcon ml={1} /></Text>
                        </Flex>
                    </Box>
                    <Box ml={{ base: "auto", md: 0 }} >
                        {/* Country selector dropdown */}
                        <Menu>
                            <MenuButton as={Button} bgColor="#121212"
                                _active={{ bgColor: "121212" }}
                                color="white"
                                _hover={{ bgColor: "#242424" }}
                                rightIcon={<FiChevronDown style={{ marginLeft: "20px" }} />}
                                _focus={{ bgColor: "#242424" }}
                            >
                                <i className={"fi fi-" + selectedCountry.substring(0, 2).toLowerCase()} /> {selectedCountry}
                            </MenuButton>
                            <MenuList className='flags-dropdown' bgColor="#121212" borderColor="transparent" minWidth={'90px'}>
                                {countries.filter((countryName => countryName !== selectedCountry)).map((countryName) =>
                                    <MenuItem key={countryName}
                                        bgColor="#121212"
                                        _hover={{ bgColor: "#242424" }}
                                        onClick={() => selectCountry(countryName)}
                                    >
                                        <i className={"fi fi-" + countryName.substring(0, 2).toLowerCase()} />
                                        {countryName}
                                    </MenuItem>
                                )}
                            </MenuList>
                        </Menu>
                    </Box>
                </Flex>

                {/* Portfolio metrics */}
                {/* Stock Score and Beta Risk Factor */}
                <Flex mb={4}>
                    <Box width="50%" textAlign="left" pr={2}>
                        <Text fontSize="sm">Stock Score <AiFillInfoCircle style={{ display: 'inline-block', marginLeft: '5px' }} /></Text>
                        <Flex alignItems="start" gap="10px">
                            <Icon as={IoMdSpeedometer} boxSize="20px" />
                            <Text fontSize="sm" fontWeight="bold" color="green.500" m={0}>1,4%</Text>
                        </Flex>
                    </Box>
                    <Box width="50%" textAlign="left" pl={2}>
                        <Text fontSize="sm">Beta Risk Factor <AiFillInfoCircle style={{ display: 'inline-block', marginLeft: '5px' }} /></Text>
                        <Flex alignItems="start" gap="10px">
                            <Icon as={IoNuclear} boxSize="20px" />
                            <Text fontSize="sm" fontWeight="bold" color="green.500" m={0}>1,4%</Text>
                        </Flex>
                    </Box>
                </Flex>

                {/* Dividend Yield and Dividend Income */}
                <Flex mb={4}>
                    <Box width="50%" textAlign="left" pr={2}>
                        <Text fontSize="sm">Dividend Yield <AiFillInfoCircle style={{ display: 'inline-block', marginLeft: '5px' }} /></Text>
                        <Flex alignItems="start" gap="10px">
                            <Icon as={BsCashStack } boxSize="20px" />
                            <Text fontSize="sm" fontWeight="bold" color="green.500" m={0}>1,4%</Text>
                        </Flex>
                    </Box>
                    <Box width="50%" textAlign="left" pl={2}>
                        <Text fontSize="sm">Dividend Income <AiFillInfoCircle style={{ display: 'inline-block', marginLeft: '5px' }} /></Text>
                        <Flex alignItems="start" gap="10px">
                            <Icon as={CgCalculator} boxSize="20px" />
                            <Text fontSize="sm" fontWeight="bold" color="green.500" m={0}>1,4%</Text>
                        </Flex>
                    </Box>
                </Flex>

                {/* Revenue Growth and Profit Growth */}
                <Flex>
                    <Box width="50%" textAlign="left" pr={2}>
                        <Text fontSize="sm">Revenue Growth <AiFillInfoCircle style={{ display: 'inline-block', marginLeft: '5px' }} /></Text>
                        <Flex alignItems="start" gap="10px">
                            <Icon as={FaChartLine} boxSize="20px" />
                            <Text fontSize="sm" fontWeight="bold" color="green.500" m={0}>1,4%</Text>
                        </Flex>
                    </Box>
                    <Box width="50%" textAlign="left" pl={2}>
                        <Text fontSize="sm">Profit Growth <AiFillInfoCircle style={{ display: 'inline-block', marginLeft: '5px' }} /></Text>
                        <Flex alignItems="start" gap="10px">
                            <Icon as={FaChartBar} boxSize="20px" />
                            <Text fontSize="sm" fontWeight="bold" color="green.500" m={0}>1,4%</Text>
                        </Flex>
                    </Box>
                </Flex>

            </Box>
        </div>
    )
}

export default PortfolioHighlights