import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import {Box, Flex, Image, Text, VStack} from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/navigation";
import {Line} from "react-chartjs-2";

export default function CustomSwiper() {
    const companies = [
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '-0.4%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
    ]

    function createGradient(context, color) {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 100)
        linearGradient(color, gradient)
        return gradient;
    }

    function linearGradient(color, gradient) {
        if (color === "red") {
            gradient.addColorStop(0, "rgba(255,44,44,0.2)")
            gradient.addColorStop(0.3, "rgba(255,67,67,0.1)")
            gradient.addColorStop(0.5, "rgba(255,0,0,0)")
            return gradient
        }
        if (color === "green") {
            gradient.addColorStop(0, "rgba(0,255,0,0.1)")
            gradient.addColorStop(0.3, "rgba(107,255,72,0.05)")
            gradient.addColorStop(0.5, "rgba(0,255,0,0)")
            return gradient;
        }
    }

    // Chart configuration of company
    function getConfigOf(company) {
        return {
            data: {
                labels: Array(company.data.length).fill(''),
                datasets: [
                    {
                        data: company.data,
                        borderColor: company.change.startsWith('+') ? 'green' : 'red',
                        borderWidth: 1,
                        fill: true,
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        beginAtZero: true,
                        display: false,
                    },
                },
                elements: {
                    line: {
                        tension: 0,
                        backgroundColor: (context) => {
                            return createGradient(context, company.change.startsWith("+") ? "green" : "red")
                        }
                    },
                    point: {
                        radius: 0,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            }
        }
    }
    return (
        <Box width="90%" mt={5} bg="black">
            <Swiper
                breakpoints={{
                    base: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    768: { // sm
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    992: { // md
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    1200: { // lg
                        slidesPerView: 4,
                        spaceBetween: 20
                    }
                }}
                navigation={true}
                modules={[Navigation]}
                className="horizontal-swiper"
                style={{backgroundColor: "#1F1F1F", padding: "13px", borderRadius: "15px"}}
            >
                {companies.map((company, index) =>
                    <SwiperSlide key={index}>
                        <Box bg="#121212" color="white" height="fit-content" minWidth="fit-content" borderRadius="15px"
                             p={4}>
                            <Box flexDirection="column" justifyContent="space-between" alignItems="center">
                                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                                    <Flex alignItems="center" justifyContent="flex-start">
                                        <Image src={company.imgSrc} alt="Logo" h="25px" w="25px"/>
                                        <Text fontSize="xs" m={2} textAlign="center" display="flex" alignItems="center"
                                              justifyContent="center" height="100%">{company.name}</Text>
                                    </Flex>
                                    <VStack spacing={0} alignItems="flex-end">
                                        <Text fontSize="xs" textTransform="uppercase" mb={0}>
                                            {company.abbr}
                                        </Text>
                                        <Text fontSize="xs" color={company.change.startsWith('+') ? "green" : "red"}
                                              mb={0}>
                                            {company.change}
                                        </Text>
                                    </VStack>
                                </Flex>
                                <Flex className="row">
                                    <Flex className="col-md-6" flexDirection="column" alignItems="flex-start"
                                          justifyContent="flex-end">
                                        <Text textTransform="capitalize" m="0" fontSize="xs" color="#A0A0A0"
                                              justifySelf="flex-end">current value</Text>
                                        <Text fontSize="1.2em" m="0">{company.currentValue.toFixed(2)}</Text>
                                    </Flex>
                                    <Flex className="col-md-6">
                                        <Line height='50px' data={getConfigOf(company).data} options={getConfigOf(company).options}/>
                                    </Flex>
                                </Flex>
                            </Box>
                        </Box>
                    </SwiperSlide>
                )}
            </Swiper>
        </Box>
    );
}
