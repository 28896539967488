// Import necessary dependencies and components
import React, { useState, useMemo } from 'react'
import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Button, InputGroup, InputLeftElement, Input, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Select } from '@chakra-ui/react'
import { FiChevronDown, FiFilter, FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import { FaSearch } from 'react-icons/fa'
import { TbCaretUpDownFilled } from "react-icons/tb";
import { HiMenuAlt2 } from "react-icons/hi";

// Mock data for the company table
const companyData = [
  { ticker: 'BCP', name: 'Banque Centrale Populaire', price: 150, beta: 1.2, volatility: 15.5, weight: 5.2 },
  { ticker: 'ATW', name: 'Attijariwafa Bank', price: 2800, beta: 0.9, volatility: 12.8, weight: 8.7 },
  { ticker: 'IAM', name: 'Itissalat Al-Maghrib', price: 3400, beta: 0.8, volatility: 10.2, weight: 9.5 },
  { ticker: 'MNG', name: 'Maroc Telecom', price: 800, beta: 1.1, volatility: 14.3, weight: 6.8 },
  { ticker: 'SNEP', name: 'Société Nationale dÉlectricité et de lEau Potable', price: 300, beta: 1.3, volatility: 18.7, weight: 3.1 },
  { ticker: 'LAC', name: 'Lesieur Cristal', price: 200, beta: 0.7, volatility: 9.8, weight: 2.5 },
  { ticker: 'ADH', name: 'Addoha', price: 10, beta: 1.5, volatility: 22.4, weight: 0.8 },
  { ticker: 'CIH', name: 'CIH Bank', price: 280, beta: 1.0, volatility: 13.6, weight: 3.7 },
  { ticker: 'COL', name: 'Cosumar', price: 230, beta: 0.6, volatility: 8.9, weight: 2.9 },
  { ticker: 'CMT', name: 'Ciments du Maroc', price: 1600, beta: 1.2, volatility: 16.8, weight: 7.3 },
  { ticker: 'ALM', name: 'Aluminium du Maroc', price: 1200, beta: 1.4, volatility: 19.5, weight: 5.6 },
  { ticker: 'SAM', name: 'Samir', price: 100, beta: 1.8, volatility: 25.7, weight: 1.2 },
  { ticker: 'WAA', name: 'Wafa Assurance', price: 4000, beta: 0.9, volatility: 11.4, weight: 10.2 },
  { ticker: 'LBV', name: 'Label Vie', price: 2600, beta: 0.8, volatility: 10.9, weight: 8.4 },
  { ticker: 'RDS', name: 'Residences Dar Saada', price: 30, beta: 1.6, volatility: 23.2, weight: 0.9 },
];

function CompanyTable() {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Calculate total pages
  const totalPages = Math.ceil(companyData.length / rowsPerPage);

  // Memoized current companies to display based on pagination
  const currentCompanies = useMemo(() =>
    companyData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
    [currentPage, rowsPerPage]);

  // Function to get page numbers for pagination
  const getPageNumbers = () => {
    if (totalPages <= 4) return Array.from({ length: totalPages }, (_, i) => i + 1);
    if (currentPage <= 2) return [1, 2, 3, 4];
    if (currentPage >= totalPages - 1) return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    return [currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
  };

  // Helper function to render menu buttons
  const renderMenuButton = (text, icon) => (
    <MenuButton as={Button}
      height='35px'
      bgColor="#161616"
      color="white"
      m="0 15px 0 7px"
      border="1px solid #303235"
      _hover={{ bgColor: "#242424" }}
      rightIcon={icon}
      _focus={{ bgColor: "#242424" }}
      fontSize="12px"
    >
      {text}
    </MenuButton>
  );

  return (
    <div style={{ transform: { base: 'scale(0.8)', md: 'scale(1)' }, transformOrigin: 'top left' }}>
      {/* Header */}
      <Flex bg="#121212" justifyContent="center" w='100%' h="40px" p={2} mt="40px" fontSize={{ base: "14px", md: "18px" }} color='white' position="relative">
        <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
          Companies
          <Box position="absolute" right="10px">
            <HiMenuAlt2 />
          </Box>
        </Flex>
      </Flex>

      {/* Filter and Search Section */}
      <Box bg="#171717" w='100%' p={2} fontSize={{ base: "14px", md: "18px" }} color='white'>
        <Flex>
          <Flex flex={1} justifyContent={{ base: 'center', xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start' }} mr={10}>
            {/* Filter Menu */}
            <Menu>
              <MenuButton
                as={Button}
                height='35px'
                bgColor="#161616"
                color="white"
                borderWidth="1px 0 1px 1px"
                borderRadius="5px 0 0px 5px"
                borderColor="#303235"
                _hover={{ bgColor: "#242424" }}
                rightIcon={<FiChevronDown />}
                _focus={{ bgColor: "#242424" }}
                fontSize={{ base: "12px", md: "14px" }}
              >
                <Flex>
                  <Box m="5px 10px 0 0">
                    <FiFilter fill="white" />
                  </Box>
                  All
                </Flex>
              </MenuButton>
              <MenuList bgColor="#303235" borderColor="transparent" minWidth='100px'>
                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
              </MenuList>
            </Menu>
            {/* Search Input */}
            <InputGroup width={{ base: "100%", md: "300px", lg: "500px" }}>
              <InputLeftElement pb={1}>
                <FaSearch />
              </InputLeftElement>
              <Input
                type="text"
                height='35px'
                placeholder="Search companies"
                _placeholder={{ color: '#fff', opacity: 0.7 }}
                _focus={{
                  outline: 'none',
                  boxShadow: 'none',
                  borderColor: 'rgba(119,119,119,0.5)'
                }}
                _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                borderColor="#303235"
                borderWidth="1px"
                borderRadius="0px 5px 5px 0px"
                fontSize={{ base: "12px", md: "14px" }}
              />
            </InputGroup>
          </Flex>
        </Flex>
      </Box>

      {/* Company Table */}
      <Box bg="#121212" w='100%' color='white'>
        <TableContainer>
          <Table variant='simple' fontSize={{ base: "12px", md: "14px" }}>
            <Thead>
              <Tr color='white' className="table-header">
                {['Ticker', 'Name', 'Price (MAD)', 'Beta', 'Volatility', 'Weight (%)'].map(header => (
                  <Th key={header} fontSize={{ base: "12px", md: "14px" }}>
                    <Flex alignItems='center' gap={1}>{header} <TbCaretUpDownFilled /></Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {currentCompanies.map(company => (
                <Tr key={company.ticker}>
                  <Td>{company.ticker}</Td>
                  <Td>
                    <Box maxWidth="200px" overflow="auto" whiteSpace="nowrap">
                      {company.name}
                    </Box>
                  </Td>
                  <Td>{company.price.toLocaleString()}</Td>
                  <Td>{company.beta.toFixed(2)}</Td>
                  <Td>{company.volatility.toFixed(1)}%</Td>
                  <Td>{company.weight.toFixed(1)}%</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <Flex 
          justifyContent="center" 
          alignItems="center" 
          pt={4} 
          pb={4}
          flexDirection={{ base: 'column', md: 'row' }}
          gap={4}
          fontSize={{ base: "12px", md: "14px" }}
        >
          {/* Previous/Next Buttons and Page Numbers */}
          <Flex>
            <Button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              isDisabled={currentPage === 1}
              mr={2}
              bgColor="#161616"
              color="white"
              _hover={{ bgColor: "#242424" }}
              leftIcon={<FiChevronLeft />}
              fontSize={{ base: "12px", md: "14px" }}
            >
              Previous
            </Button>

            {/* Page Numbers */}
            {getPageNumbers().map(pageNumber => (
              <Box
                key={pageNumber}
                as="button"
                onClick={() => setCurrentPage(pageNumber)}
                mx={1}
                px={3}
                py={2}
                borderRadius="md"
                bg={currentPage === pageNumber ? "#F8CD20" : "#121212"}
                color={currentPage === pageNumber ? "black" : "white"}
                fontWeight={currentPage === pageNumber ? "bold" : "normal"}
                _hover={{ bg: currentPage === pageNumber ? "#F8CD20" : "#242424" }}
                display={{ base: "none", xs: "none", md: "flex", lg: "flex" }}
                alignItems="center"
                justifyContent="center"
                minWidth="32px"
                fontSize={{ base: "12px", md: "14px" }}
              >
                {pageNumber}
              </Box>
            ))}

            {/* Next Button */}
            <Button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              isDisabled={currentPage === totalPages}
              ml={2}
              mr={4}
              bgColor="#161616"
              color="white"
              _hover={{ bgColor: "#242424" }}
              rightIcon={<FiChevronRight />}
              fontSize={{ base: "12px", md: "14px" }}
            >
              Next
            </Button>
          </Flex>

          {/* Rows Per Page Selector */}
          <Flex alignItems="center">
            <Box as="span" mr={2} color="white">
              Companies Per Page:
            </Box>
            <Select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
              width="70px"
              bgColor="#161616"
              color="white"
              borderColor="#303235"
              _hover={{ borderColor: "rgba(119,119,119,0.5)" }}
              _focus={{ borderColor: "rgba(119,119,119,0.5)", boxShadow: "none" }}
              fontSize={{ base: "12px", md: "14px" }}
              sx={{
                "& option": {
                  backgroundColor: "black",
                  color: "white",
                  border: "none"
                },
                "& > option": {
                  border: "none"
                }
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </Select>
          </Flex>
        </Flex>
      </Box>
    </div>
  )
}

// CompanyTable component displays a table of company data with pagination and filtering options
export default CompanyTable