import React from 'react';
import {Box, Select, Text} from "@chakra-ui/react";

const Lstm = () => {
    return (
        <div>
            <Box marginTop={8}>
                <Text color={'#ffce30'} fontSize={'24px'} fontWeight={'bold'}>LSTM</Text>
                <Box>
                    <Text fontSize={'18px'}>Description</Text>
                    <Text>
                        Long Short-Term Memory (LSTM). A type of recurrent neural network capable of learning long-term dependencies.
                    </Text>
                    <Text fontSize={'18px'}>Purpose</Text>
                    <Text>
                        Predicting stock prices and trends by capturing complex patterns in historical data. Particularly useful for high-frequency trading and analyzing long-term market trends.
                    </Text>
                </Box>
            </Box>
            <Box>
                <div className="filter-bar d-flex">
                    <div className="element">
                        <Select className="dropdown-select" width="auto">
                            <option selected hidden disabled value="">Select Company</option>
                            <option>Company 1</option>
                            <option>Company 2</option>
                        </Select>
                    </div>
                </div>
            </Box>
            <Box marginTop={10} height={'300px'} bgColor={'#1f1f1f'} borderRadius={'10px'}>
            </Box>
        </div>
    );
};

export default Lstm;