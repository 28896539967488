import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import { FiChevronDown, FiFilter } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import YieldCurveChart from './YieldCurveChart';

const YieldHeatmap = () => {
    const [govBondsYields, setGovBondsYields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState("Morocco"); // Default to Morocco
    const [currentData, setCurrentData] = useState({});
    const [monthData, setMonthData] = useState({});
    const [yearData, setYearData] = useState({});

    const countries = ["Morocco", "Egypt", "Coast Ivory", "South Africa"]
    // Fetch data based on the selected country
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`https://api.xcapitalterminal.com/api/v1/bonds-data/governments-bonds-yields?country=${selectedCountry}`);
                setGovBondsYields(response.data); // Ensure the data structure matches the table requirements
                const labels = response.data.map(yld => yld.term);

                const current = response.data.map(yld => yld.current);
                const month = response.data.map(yld => yld.one_month_ago);
                const year = response.data.map(yld => yld.one_year_ago);

                setCurrentData({current, labels});
                setMonthData({month})
                setYearData({year});
                setError(null); // Clear any previous errors
            } catch (err) {
                console.error('Fetch error:', err);
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [selectedCountry]);

    const handleCountryChange = (country) => {
        setSelectedCountry(country); // Update the selected country, which triggers the data fetch
    };

    return (<>
    <YieldCurveChart currentData={currentData} monthData={monthData} yearData={yearData}/>
        <Box>
            {/* Header */}
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Government Bonds Yields
            </Box>

            {/* Filters */}
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                    <Menu>
                        <MenuButton as={Button}
                                    height={'35px'}
                                    bgColor="#161616"
                                    color="white"
                                    m={"0 15px 0 7px"}
                                    border={"1px solid #999999"}
                                    _hover={{ bgColor: "#242424" }}
                                    rightIcon={<FiChevronDown style={{ marginLeft: "20px" }} />}
                                    _focus={{ bgColor: "#242424" }}
                        >
                            {selectedCountry}
                        </MenuButton>
                        <MenuList bgColor="#121212" borderColor={"transparent"}>
                            {countries.map((country) => (
                                <MenuItem
                                    key={country}
                                    bgColor="#121212"
                                    _hover={{ bgColor: "#242424" }}
                                    onClick={() => handleCountryChange(country)}
                                >
                                    {country}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Flex>
            </Box>

            {/* Table */}
            <Box bg={"#121212"} w='100%' color='white'>
                {loading ? (
                    <Box textAlign="center" p={4}>Loading...</Box>
                ) : error ? (
                    <Box textAlign="center" p={4} color="red">{error}</Box>
                ) : (
                    <TableContainer>
                        <Table variant='simple'>
                            <Thead>
                                <Tr className="table-header">
                                    <Th>Country & Term</Th>
                                    {/* <Th>Yield (%)</Th> */}
                                    <Th>1 Day Change (%)</Th>
                                    <Th>1 Month Change (%)</Th>
                                    <Th>1 Year Change (%)</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {govBondsYields.length > 0 ? govBondsYields.map((yld, index) => (
                                    <Tr key={index}>
                                        <Td>{yld.country} {yld.term}</Td>
                                        {/* <Td>{yld.day_change}</Td> */}
                                        <Td>{yld.current}</Td>
                                        <Td>{yld.one_month_ago}</Td>
                                        <Td>{yld.one_year_ago}</Td>
                                    </Tr>
                                )) : (
                                    <Tr>
                                        <Td colSpan={5} textAlign="center">No data available</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Box>
        </>
    );
};

export default YieldHeatmap;
