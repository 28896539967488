import React, {useState} from 'react';
import {Box, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";

const ForexHeatmap = () => {
    const regions = ['Africa', 'Asia', 'America', 'Europe', 'Australia']
    const [selectedRegion, setSelectedRegion] = useState(null)

    function handleCheckedRegion(index) {
        setSelectedRegion(index === selectedRegion ? null : index)
    }

    const countryCurrencyData = [
        {
            pair: "MOROCCO (MAD)",
            countryCode: "MA",
            morocco: null,
            southAfrica: "+1.75%",
            nigeria: "+3.50%",
            kenya: "+2.00%",
            egypt: "+1.50%",
            ghana: "+2.80%",
            uganda: "+1.25%",
            mauritius: "+1.25%",
        },
        {
            pair: "SOUTH AFRICA (ZAR)",
            countryCode: "ZA",
            morocco: "-1.75%",
            southAfrica: null,
            nigeria: "+1.70%",
            kenya: "+0.25%",
            egypt: "-0.20%",
            ghana: "+1.00%",
            uganda: "-0.50%",
            mauritius: "-0.50%",
        },
        {
            pair: "NIGERIA (NGN)",
            countryCode: "NG",
            morocco: "-3.50%",
            southAfrica: "-1.70%",
            nigeria: null,
            kenya: "-1.45%",
            egypt: "-2.50%",
            ghana: "-0.70%",
            uganda: "-2.20%",
            mauritius: "-2.20%",
        },
        {
            pair: "KENYA (KES)",
            countryCode: "KE",
            morocco: "-2.00%",
            southAfrica: "-0.25%",
            nigeria: "+1.45%",
            kenya: null,
            egypt: "+0.50%",
            ghana: "+1.20%",
            uganda: "+0.30%",
            mauritius: "+0.30%",
        },
        {
            pair: "EGYPT (EGP)",
            countryCode: "EG",
            morocco: "-1.50%",
            southAfrica: "+0.20%",
            nigeria: "+2.50%",
            kenya: "-0.50%",
            egypt: null,
            ghana: "+2.10%",
            uganda: "+0.75%",
            mauritius: "+0.75%",
        },
        {
            pair: "GHANA (GHS)",
            countryCode: "GH",
            morocco: "-2.80%",
            southAfrica: "-1.00%",
            nigeria: "+0.70%",
            kenya: "-1.20%",
            egypt: "-2.10%",
            ghana: null,
            uganda: "-1.50%",
            mauritius: "-1.50%",
        },
        {
            pair: "UGANDA (UGX)",
            countryCode: "UG",
            morocco: "-1.25%",
            southAfrica: "+0.50%",
            nigeria: "+2.20%",
            kenya: "-0.30%",
            egypt: "-0.75%",
            ghana: "+1.50%",
            uganda: null,
            mauritius: "-0.75%",
        },
        {
            pair: "MAURITIUS (MUR)",
            countryCode: "MU",
            morocco: "-1.25%",
            southAfrica: "+0.50%",
            nigeria: "+2.20%",
            kenya: "-0.30%",
            egypt: "-0.75%",
            ghana: "+1.50%",
            uganda: "+1.50%",
            mauritius: null,
        },
    ];

    function cellBackgroundColor(value) {
        if (value === null) return 'rgb(0,0,0)'
        if (value.startsWith('+')) return 'rgb(255,39,39)'
        if (value.startsWith('-')) return 'rgb(21,71,0)'
    }
    return (
        <Box mt={"40px"}>
            <Flex>
                <Text mb={0} fontSize={"24px"} color={"#ffce30"}>Global Markets</Text>
                <Flex className="technical-analysis" p={2}>
                    {regions.map((region, index) =>
                        <Box className="time-frame" key={index}>
                            <input checked={selectedRegion === index}
                                   onClick={() => handleCheckedRegion(index)}
                                   type="checkbox"
                                   className="btn-check"
                                   id={"btn-check-region-" + index}
                                   autoComplete="off"/>
                            <label className="btn btn-outline-warning" htmlFor={"btn-check-region-" + index}>
                                {region}
                            </label>
                        </Box>
                    )}
                </Flex>
            </Flex>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Forex Heatmap
            </Box>
            <Box bg={"#121212"} w="100%" color="white">
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr className="table-header">
                                <Th>CURRENCY PAIR (FX)</Th>
                                <Th>MOROCCO</Th>
                                <Th>SOUTH AFRICA</Th>
                                <Th>NIGERIA</Th>
                                <Th>KENYA</Th>
                                <Th>EGYPT</Th>
                                <Th>GHANA</Th>
                                <Th>UGANDA</Th>
                                <Th>MAURITIUS</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {countryCurrencyData.map((currency) => (
                                <Tr key={currency.pair}>
                                    <Td display="flex">
                                        <Box mr="10px" height="20px" width="20px" borderRadius="50%" display="grid" overflow="hidden" justifyContent="center" alignItems="center">
                                            <i className={"fi fi-" + currency.countryCode.toLowerCase()} style={{fontSize:'20px'}}/>
                                        </Box>
                                        <Text as="span">{currency.pair}</Text>
                                    </Td>
                                    <Td backgroundColor={cellBackgroundColor(currency.morocco)}>
                                        {currency.morocco}
                                    </Td>
                                    <Td backgroundColor={cellBackgroundColor(currency.southAfrica)}>
                                        {currency.southAfrica}
                                    </Td>
                                    <Td backgroundColor={cellBackgroundColor(currency.nigeria)}>
                                        {currency.nigeria}
                                    </Td>
                                    <Td backgroundColor={cellBackgroundColor(currency.kenya)}>
                                        {currency.kenya}
                                    </Td>
                                    <Td backgroundColor={cellBackgroundColor(currency.egypt)}>
                                        {currency.egypt}
                                    </Td>
                                    <Td backgroundColor={cellBackgroundColor(currency.ghana)}>
                                        {currency.ghana}
                                    </Td>
                                    <Td backgroundColor={cellBackgroundColor(currency.uganda)}>
                                        {currency.uganda}
                                    </Td>
                                    <Td backgroundColor={cellBackgroundColor(currency.mauritius)}>
                                        {currency.mauritius}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default ForexHeatmap;