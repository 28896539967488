import React from 'react'
import PortfolioHighlights from '../PortfolioHighlights'
import Balance from '../Balance'
import PortfolioAnalytics from '../PortfolioAnalytics'
import FinancialTable from '../FinancialTable'
import NewsComponent from '../NewsComponent'
import EventComponent from '../EventComponent'
import { Box, Flex, Container } from '@chakra-ui/react'

function OverViewComponent() {
  return (
    <Container maxW="full" p={0}>
      {/* Top section with Portfolio Highlights and Balance */}
      <Flex mb={10} justifyContent="space-between" direction={{ base: "column", lg: "row" }}>
        {/* Left box: Portfolio Highlights */}
        <Box flex={{ base: "1", lg: "0 0 68%" }} maxW={{ base: "100%", lg: "70%" }} mb={{ base: 4, lg: 0 }}>
          <PortfolioHighlights />
        </Box>
        {/* Right box: Balance */}
        <Box flex={{ base: "1", lg: "0 0 30%" }} maxW={{ base: "100%", lg: "70%" }}>
          <Balance />
        </Box>
      </Flex>

      {/* Portfolio Analytics section */}
      <Box mx="auto" w="full">
        <PortfolioAnalytics />
      </Box>

      {/* Financial Table section */}
      <Box mx="auto" w="full">
        <FinancialTable />
      </Box>

      {/* Bottom section with News and Events */}
      <Flex mt={10} justifyContent="space-between" direction={{ base: "column", lg: "row" }}>
        {/* News Component */}
        <Box flex={{ base: "1", lg: "0 0 68%" }} maxW={{ base: "100%", lg: "68%" }} mb={{ base: 4, lg: 0 }}>
          <NewsComponent />
        </Box>
        {/* Event Component */}
        <Box flex={{ base: "1", lg: "0 0 28%" }} maxW={{ base: "100%", lg: "28%" }}>
          <EventComponent />
        </Box>
      </Flex>
    </Container>
  )
}

export default OverViewComponent