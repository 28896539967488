import React, {useEffect, useState} from 'react';
import CompanyOverviewChart from "../CompanyOverviewChart";
import ProducersAndCustomersTable from "../ProducersAndCustomersTable";
import FinancialsSection from "../FinancialsSection";
import {getAllCompanies, getAllStockMarkets} from "../../../../../../../services/marketDataServices/CompanyService";
import {Button, Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/react";
import {FiChevronDown} from "react-icons/fi";

const OverviewComponent = () => {
    const [companies, setCompanies] = useState(null)
    const [selectedCompany, setSelectedCompany] = useState(null)

    useEffect(() => {
        getAllCompanies().then(response => {
            const sortedCompanies = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setCompanies(sortedCompanies);
            // Set selected company only if companies are available
            if (sortedCompanies.length > 0) {
                setSelectedCompany(sortedCompanies[0]);
            }
        }).catch(error => {
            console.log(error)
        })
    }, []);
    // console.log("selectedCompany",selectedCompany)
    return (
        <div>
            <Menu>
                <MenuButton as={Button} bgColor={"#121212"}
                            _active={{bgColor: "#121212"}}
                            color="white"
                            _hover={{bgColor: "#242424"}}
                            rightIcon={<FiChevronDown style={{marginLeft: "20px"}}/>}
                            _focus={{bgColor: "#242424"}}
                >
                    {selectedCompany === null ? 'Select company' : selectedCompany.name}
                </MenuButton>
                <MenuList className='flags-dropdown'
                          bgColor="#121212"
                          borderColor="transparent"
                          maxHeight='400px'
                          overflowY='scroll'
                >
                    {companies?.map(company =>
                        <MenuItem key={company.id}
                                  bgColor="#121212"
                                  _hover={{bgColor: "#242424"}}
                                  onClick={() => setSelectedCompany(company)}
                        >
                            {company.name}
                        </MenuItem>
                    )}
                </MenuList>
            </Menu>
            <CompanyOverviewChart {...selectedCompany}/>
            <ProducersAndCustomersTable {...selectedCompany}/>
            <FinancialsSection/>
        </div>
    );
};

export default OverviewComponent;