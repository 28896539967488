import React, { useState, useEffect, useRef } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Box, Button, Flex } from "@chakra-ui/react";

// Import custom components for different portfolio management sections
import OverViewComponent from '../overview/layout/OverViewComponent'
import TransactionComponent from '../transaction/layout/TransactionComponent'
import DiversificationComponent from '../diversification/layout/DiversificationComponent'
import RiskComponent from '../risk/layout/RiskComponent'
import MarketComponents from "../fundamental/layout/MarketComponents";
import TechnicalsComponent from "../technicals/TechnicalsComponent";
import DividendsComponent from "../dividends/DividendsComponent";
import VotingComponent from "../voting/VotingComponent";

const PortfolioManagementComponent = () => {
    const [showOverflow, setShowOverflow] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const tabListRef = useRef(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (tabListRef.current) {
                const isOverflowing = tabListRef.current.scrollWidth > tabListRef.current.clientWidth;
                setShowMoreButton(isOverflowing && !showOverflow);
                if (!isOverflowing) {
                    setShowOverflow(false);
                }
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        
        return () => window.removeEventListener('resize', checkOverflow);
    }, [showOverflow]);

    const handleShowMore = () => {
        setShowOverflow(true);
        setShowMoreButton(false);
    };

    return (
        <Box>
            <Tabs 
                color="white" 
                variant='unstyled'
                sx={{
                    '[role="tablist"]': {
                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30',
                    }
                }}
            >
                <Box position="relative">
                    <Flex alignItems="center">
                        <Box 
                            overflowX={showOverflow ? "auto" : "hidden"}
                            css={{
                                '&::-webkit-scrollbar': {
                                    height: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#1A1A1A'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#ffce30',
                                    borderRadius: '4px',
                                },
                            }}
                            flex="1"
                        >
                            <TabList 
                                ref={tabListRef}
                                sx={{
                                    ".tab-list": {
                                        margin: "auto", 
                                        justifyContent: "flex-start", 
                                        fontSize: "24px"
                                    },
                                    whiteSpace: "nowrap"
                                }}
                            >
                                <Tab 
                                    _selected={{ 
                                        color: "#ffce30",
                                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30'
                                    }} 
                                    textTransform="uppercase"
                                >
                                    Overview
                                </Tab>
                                <Tab 
                                    _selected={{ 
                                        color: "#ffce30",
                                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30'
                                    }} 
                                    textTransform="uppercase"
                                >
                                    Transactions
                                </Tab>
                                <Tab 
                                    _selected={{ 
                                        color: "#ffce30",
                                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30'
                                    }} 
                                    textTransform="uppercase"
                                >
                                    Diversification
                                </Tab>
                                <Tab 
                                    _selected={{ 
                                        color: "#ffce30",
                                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30'
                                    }} 
                                    textTransform="uppercase"
                                >
                                    Risk
                                </Tab>
                                <Tab 
                                    _selected={{ 
                                        color: "#ffce30",
                                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30'
                                    }} 
                                    textTransform="uppercase"
                                >
                                    Fundamental
                                </Tab>
                                <Tab 
                                    _selected={{ 
                                        color: "#ffce30",
                                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30'
                                    }} 
                                    textTransform="uppercase"
                                >
                                    Technicals
                                </Tab>
                                <Tab 
                                    _selected={{ 
                                        color: "#ffce30",
                                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30'
                                    }} 
                                    textTransform="uppercase"
                                >
                                    Dividends
                                </Tab>
                                <Tab 
                                    _selected={{ 
                                        color: "#ffce30",
                                        borderBottom: showOverflow ? 'none' : '2px solid #ffce30'
                                    }} 
                                    textTransform="uppercase"
                                >
                                    Voting
                                </Tab>
                            </TabList>
                        </Box>
                        {showMoreButton && (
                            <Button
                                onClick={handleShowMore}
                                bg="#ffce30"
                                color="black"
                                _hover={{ bg: "#e6b929" }}
                                ml={2}
                                minW="80px"
                            >
                                More
                            </Button>
                        )}
                    </Flex>
                </Box>
                <TabPanels>
                    <TabPanel>
                        <OverViewComponent />
                    </TabPanel>
                    <TabPanel>
                        <TransactionComponent />
                    </TabPanel>
                    <TabPanel>
                        <DiversificationComponent />
                    </TabPanel>
                    <TabPanel>
                        <RiskComponent />
                    </TabPanel>
                    <TabPanel>
                        <MarketComponents />
                    </TabPanel>
                    <TabPanel>
                        <TechnicalsComponent />
                    </TabPanel>
                    <TabPanel>
                        <DividendsComponent />
                    </TabPanel>
                    <TabPanel>
                        <VotingComponent />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default PortfolioManagementComponent;