// Import necessary components and icons from Chakra UI and react-chartjs-2
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Text, Flex, Box, Image } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { totalAmount } from '../transaction/TransactionTable'
import { getAllInvestore } from '../../../../services/portfolioData/porfolioData';
function Balance() {

    const [transactions, setTransactions] = useState([]);
    const [investors, setInvestors] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get('https://api.xcapitalterminal.com/api/v1/portfolio-data/transactions');
                setTransactions(response.data);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };
        fetchTransactions();


    getAllInvestore()
        .then(response => {
            const investorsData = response.data
            setInvestors(investorsData);
        })
        .catch(error => {
            console.error("Error fetching companies:", error);
        });
    }, []);

    // console.log("investors", investors)
    //   const totalAmount = transactions.reduce((total, transaction) => {
    //     return total + (transaction.quantity * transaction.price);
    // }, 0);

    // console.log("totalamount:", totalAmount);

    return (
        <div style={{ height: "100%" }}>
            {/* Main container for the Balance component */}
            <Box bg={"#0A0A0A"} p={4} minW={{ base: "0", sm: "400px" }} maxH={"410px"} overflowY={"auto"} height={"100%"}>
                {/* Balance section */}
                <Text mb={0} mt={2}>Balance</Text>
                <Flex gap={2}>
                    {/* Display the balance amount */}
                    <Flex flex={3} justifyContent={"center"} alignItems={"center"} bg={"#F8CD20"} p={3} borderRadius={"10px"}>
                        <Text color={"black"} m={0} fontWeight="bold">180000 MAD</Text>
                    </Flex>
                    {/* Display the balance change percentage */}
                    <Flex p={3} borderRadius={"10px"} justifyContent={"center"} alignItems={"center"} bg={"#16A34A"}>
                        <Text color={"white"} m={0} fontWeight="bold">+6.54%</Text>
                    </Flex>
                </Flex>

                {/* Invested amount section */}
                <Text mb={0} mt={2}>Invested</Text>
                <Flex p={2} borderRadius={"10px"} justifyContent={"space-between"} alignItems={"center"} bg={"#1F1F1F"}>
                    <Text color={"white"} m={0} fontWeight="bold">{totalAmount(transactions)} MAD</Text>
                    {/* Arrow icon for invested amount */}
                    <Box
                        bg="#F8CD20"
                        borderRadius="10px"
                        ml={2}
                        p={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ArrowForwardIcon color="black" boxSize={6} />
                    </Box>
                </Flex>

                {/* Top Stock section */}
                <Text mb={0} mt={2}>Top Stock</Text>
                {/* Repeated stock information boxes */}
                <Box borderRadius={"10px"} mb={3} bg={"#121212"} p={3.5}>
                    {/* Stock header with logo, name, and change */}
                    <Flex mb={3}>
                        <Flex flex={3} alignItems={"center"} gap={2}>
                            <Image src="/img/ATW.png" alt="ATW Logo" width={"30px"} />
                            <Text p={0} m={0} fontSize={"14px"}>Attijari Wafa Bank</Text>
                        </Flex>
                        <Flex flexDirection={"column"} justifyContent={"flex-end"} alignItems={"center"} flex={1}>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"} textAlign="right">ATW</Text>
                            <Text p={0} m={0} fontWeight={"bold"} color="#38500B" fontSize={"12px"} textAlign="right">+17.63</Text>
                        </Flex>
                    </Flex>

                    {/* Stock details with invested value, current value, and chart */}
                    <Flex>
                        <Flex flexDirection={"column"} flex={1} justifyContent={"flex-end"} alignItems={"flex-start"}>
                            <Text p={0} m={0} fontSize={"12px"} color="gray.500" textAlign="left">Invested Value</Text>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"} textAlign="left">157.77 MAD</Text>
                        </Flex>
                        <Flex flexDirection={"column"} flex={1} justifyContent={"flex-end"} alignItems={"flex-start"}>
                            <Text p={0} m={0} fontSize={"12px"} color="gray.500">Curent Value</Text>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"}>130.77 MAD</Text>
                        </Flex>
                        {/* Mini chart for stock performance */}
                        <Flex justifyContent={"center"} flex={1} alignItems={"flex-start"}>
                            <Box width="70%" height="40px">
                                <Line
                                    data={{
                                        labels: ['', '', '', '', '', '', '', '', '', ''],
                                        datasets: [{
                                            data: [5, 10, 8, 15, 12, 18, 16, 22, 20, 25],
                                            borderColor: '#38500B',
                                            borderWidth: 2,
                                            fill: 'start',
                                            backgroundColor: (context) => {
                                                const ctx = context.chart.ctx;
                                                const gradient = ctx.createLinearGradient(0, 0, 0, 40);
                                                gradient.addColorStop(0, 'rgba(56, 80, 11, 0.5)');
                                                gradient.addColorStop(1, 'rgba(56, 80, 11, 0)');
                                                return gradient;
                                            },
                                            tension: 0.4,
                                            pointRadius: 0
                                        }]
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: { display: false },
                                            y: { 
                                                display: false,
                                                min: 0,
                                                max: 30
                                            }
                                        },
                                        plugins: {
                                            legend: { display: false }
                                        },
                                        elements: {
                                            line: {
                                                tension: 0.4
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Flex>
                </Box>

                {/* Top Stock section */}
                <Text mb={0} mt={2}>Top Stock</Text>
                {/* Repeated stock information boxes */}
                <Box borderRadius={"10px"} mb={3} bg={"#121212"} p={3.5}>
                    {/* Stock header with logo, name, and change */}
                    <Flex mb={3}>
                        <Flex flex={3} alignItems={"center"} gap={2}>
                            <Image src="/img/ATW.png" alt="ATW Logo" width={"30px"} />
                            <Text p={0} m={0} fontSize={"14px"}>Attijari Wafa Bank</Text>
                        </Flex>
                        <Flex flexDirection={"column"} justifyContent={"flex-end"} alignItems={"center"} flex={1}>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"} textAlign="right">ATW</Text>
                            <Text p={0} m={0} fontWeight={"bold"} color="#38500B" fontSize={"12px"} textAlign="right">+17.63</Text>
                        </Flex>
                    </Flex>

                    {/* Stock details with invested value, current value, and chart */}
                    <Flex>
                        <Flex flexDirection={"column"} flex={1} justifyContent={"flex-end"} alignItems={"flex-start"}>
                            <Text p={0} m={0} fontSize={"12px"} color="gray.500" textAlign="left">Invested Value</Text>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"} textAlign="left">157.77 MAD</Text>
                        </Flex>
                        <Flex flexDirection={"column"} flex={1} justifyContent={"flex-end"} alignItems={"flex-start"}>
                            <Text p={0} m={0} fontSize={"12px"} color="gray.500">Curent Value</Text>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"}>130.77 MAD</Text>
                        </Flex>
                        {/* Mini chart for stock performance */}
                        <Flex justifyContent={"center"} flex={1} alignItems={"flex-start"}>
                            <Box width="70%" height="40px">
                                <Line
                                    data={{
                                        labels: ['', '', '', '', '', '', '', '', '', ''],
                                        datasets: [{
                                            data: [5, 10, 8, 15, 12, 18, 16, 22, 20, 25],
                                            borderColor: '#38500B',
                                            borderWidth: 2,
                                            fill: 'start',
                                            backgroundColor: (context) => {
                                                const ctx = context.chart.ctx;
                                                const gradient = ctx.createLinearGradient(0, 0, 0, 40);
                                                gradient.addColorStop(0, 'rgba(56, 80, 11, 0.5)');
                                                gradient.addColorStop(1, 'rgba(56, 80, 11, 0)');
                                                return gradient;
                                            },
                                            tension: 0.4,
                                            pointRadius: 0
                                        }]
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: { display: false },
                                            y: { 
                                                display: false,
                                                min: 0,
                                                max: 30
                                            }
                                        },
                                        plugins: {
                                            legend: { display: false }
                                        },
                                        elements: {
                                            line: {
                                                tension: 0.4
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Flex>
                </Box>

                {/* Top Stock section */}
                <Text mb={0} mt={2}>Top Stock</Text>
                {/* Repeated stock information boxes */}
                <Box borderRadius={"10px"} mb={3} bg={"#121212"} p={3.5}>
                    {/* Stock header with logo, name, and change */}
                    <Flex mb={3}>
                        <Flex flex={3} alignItems={"center"} gap={2}>
                            <Image src="/img/ATW.png" alt="ATW Logo" width={"30px"} />
                            <Text p={0} m={0} fontSize={"14px"}>Attijari Wafa Bank</Text>
                        </Flex>
                        <Flex flexDirection={"column"} justifyContent={"flex-end"} alignItems={"center"} flex={1}>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"} textAlign="right">ATW</Text>
                            <Text p={0} m={0} fontWeight={"bold"} color="#38500B" fontSize={"12px"} textAlign="right">+17.63</Text>
                        </Flex>
                    </Flex>

                    {/* Stock details with invested value, current value, and chart */}
                    <Flex>
                        <Flex flexDirection={"column"} flex={1} justifyContent={"flex-end"} alignItems={"flex-start"}>
                            <Text p={0} m={0} fontSize={"12px"} color="gray.500" textAlign="left">Invested Value</Text>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"} textAlign="left">157.77 MAD</Text>
                        </Flex>
                        <Flex flexDirection={"column"} flex={1} justifyContent={"flex-end"} alignItems={"flex-start"}>
                            <Text p={0} m={0} fontSize={"12px"} color="gray.500">Curent Value</Text>
                            <Text p={0} m={0} fontWeight={"bold"} fontSize={"12px"}>130.77 MAD</Text>
                        </Flex>
                        {/* Mini chart for stock performance */}
                        <Flex justifyContent={"center"} flex={1} alignItems={"flex-start"}>
                            <Box width="70%" height="40px">
                                <Line
                                    data={{
                                        labels: ['', '', '', '', '', '', '', '', '', ''],
                                        datasets: [{
                                            data: [5, 10, 8, 15, 12, 18, 16, 22, 20, 25],
                                            borderColor: '#38500B',
                                            borderWidth: 2,
                                            fill: 'start',
                                            backgroundColor: (context) => {
                                                const ctx = context.chart.ctx;
                                                const gradient = ctx.createLinearGradient(0, 0, 0, 40);
                                                gradient.addColorStop(0, 'rgba(56, 80, 11, 0.5)');
                                                gradient.addColorStop(1, 'rgba(56, 80, 11, 0)');
                                                return gradient;
                                            },
                                            tension: 0.4,
                                            pointRadius: 0
                                        }]
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: { display: false },
                                            y: { 
                                                display: false,
                                                min: 0,
                                                max: 30
                                            }
                                        },
                                        plugins: {
                                            legend: { display: false }
                                        },
                                        elements: {
                                            line: {
                                                tension: 0.4
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
                
            </Box>
        </div>
    )
}

export default Balance