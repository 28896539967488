import React from 'react';
import {Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

const MovingAveragesTable = () => {
    const movingAveragesData = [
        { name: 'MA5', simple: { number: 518.96, action: 'Buy' }, exponential: { number: 518.93, action: 'Buy' } },
        { name: 'MA10', simple: { number: 517.09, action: 'Buy' }, exponential: { number: 518.01, action: 'Buy' } },
        { name: 'MA20', simple: { number: 516.38, action: 'Buy' }, exponential: { number: 517.37, action: 'Buy' } },
        { name: 'MA50', simple: { number: 518.59, action: 'Buy' }, exponential: { number: 517.09, action: 'Buy' } },
        { name: 'MA100', simple: { number: 517.07, action: 'Buy' }, exponential: { number: 516.55, action: 'Buy' } },
        { name: 'MA200', simple: { number: 514.35, action: 'Buy' }, exponential: { number: 514.52, action: 'Buy' } }
    ];
    return (
        <div>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Technical Indicators
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>Name</Th>
                                <Th>Simple</Th>
                                <Th></Th>
                                <Th>Exponential</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {movingAveragesData.map((average, index) =>
                                <Tr key={index}>
                                    <Td>{average.name}</Td>
                                    <Td>{average.simple.number}</Td>
                                    <Td color={average.simple.action === "Buy" ? "green" : "white"}>{average.simple.action}</Td>
                                    <Td>{average.exponential.number}</Td>
                                    <Td color={average.exponential.action === "Buy" ? "green" : "white"}>{average.exponential.action}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default MovingAveragesTable;