import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box } from '@chakra-ui/react';

const BrushChartComponent = () => {
    // Generate sample data with less fluctuation
    const generateData = (count, yrange, volatility = 0.1) => {
        let i = 0;
        const series = [];
        let lastY = (yrange.max + yrange.min) / 2;
        
        while (i < count) {
            const x = new Date(Date.now() - ((count - i) * 24 * 60 * 60 * 1000));
            // Create smoother transitions between points
            const change = (Math.random() - 0.5) * 2 * volatility * (yrange.max - yrange.min);
            lastY = Math.max(yrange.min, Math.min(yrange.max, lastY + change));
            series.push({
                x,
                y: lastY
            });
            i++;
        }
        return series;
    };

    const series = [
        {
            name: 'Market Trend 1',
            data: generateData(180, { min: 60, max: 75 }, 0.02)
        },
        {
            name: 'Market Trend 2',
            data: generateData(180, { min: 45, max: 60 }, 0.02)
        },
        {
            name: 'Market Trend 3',
            data: generateData(180, { min: 30, max: 45 }, 0.02)
        },
        {
            name: 'Market Trend 4',
            data: generateData(180, { min: 15, max: 30 }, 0.02)
        }
    ];

    const options = {
        chart: {
            id: 'chart2',
            type: 'line',
            height: 230,
            toolbar: {
                autoSelected: 'pan',
                show: false
            },
            background: '#000000',
            animations: {
                enabled: true,
                easing: 'linear',
                speed: 1000,
                dynamicAnimation: {
                    speed: 350
                }
            }
        },
        colors: ['#FF4560', '#00E396', '#008FFB', '#775DD0'],
        stroke: {
            width: 3,
            curve: 'straight'
        },
        grid: {
            borderColor: '#333',
            clipMarkers: false,
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            size: 0,
            hover: {
                size: 0
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: '#fff'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#fff'
                }
            }
        },
        tooltip: {
            theme: 'dark',
            shared: true,
            intersect: false
        },
        legend: {
            labels: {
                colors: '#fff'
            }
        }
    };

    const optionsLine = {
        chart: {
            id: 'chart1',
            height: 130,
            type: 'area',
            brush: {
                target: 'chart2',
                enabled: true,
                autoScaleYaxis: true
            },
            selection: {
                enabled: true,
                fill: {
                    color: '#3B82F6',
                    opacity: 0.2
                },
                stroke: {
                    color: '#3B82F6',
                }
            },
            background: '#000000'
        },
        colors: ['#FFD700'],
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.5,
                opacityTo: 0.1,
            }
        },
        stroke: {
            curve: 'straight',
            width: 2
        },
        xaxis: {
            type: 'datetime',
            tooltip: {
                enabled: false
            },
            labels: {
                style: {
                    colors: '#fff'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#fff'
                }
            }
        }
    };

    return (
        <Box p={5}>
            <Box
                border="2px solid #ffffff"
                borderRadius="4px"
                p={4}
                bg="black"
            >
                <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    height={350}
                />
                <ReactApexChart
                    options={optionsLine}
                    series={[series[0]]}
                    type="area"
                    height={130}
                />
            </Box>
        </Box>
    );
};

export default BrushChartComponent;


