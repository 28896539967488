import React from 'react';
import {Box, Center, Flex, Image, Text} from "@chakra-ui/react";
import {FaPlus} from "react-icons/fa";
import {Navigation} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import {Swiper, SwiperSlide} from "swiper/react";

const WatchList = () => {
    const companies = [
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '-0.4%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
        {
            name: 'Attijariwafa Bank',
            abbr: 'ATW',
            imgSrc: '/img/ATW.png',
            currentValue: 520.00,
            change: '+3.02%',
            data: [5, 6, 7, 5, 6, 5, 6.5, 7, 6.25, 5, 5.5, 6, 6.25, 5.75, 6, 5, 7, 5, 6]
        },
    ]
    return (
        <Box height={'100%'} width={'100%'} borderRadius={"10px"}>
            <Box h="60px" p={4} fontSize={"18px"} color='white' backgroundColor={"#121212"}>
                <Flex justifyContent={"space-between"}>
                    <Text>Watchlist</Text>
                    <Center width="25px" height="25px" backgroundColor={"#ffce30"} borderRadius={2.5}>
                        <FaPlus color={"black"} size={"12px"}/>
                    </Center>
                </Flex>
            </Box>
            <Box height={'100%'} width={'100%'}>
                <Swiper
                    direction="vertical"
                    slidesPerView={3}  // Shows 2.5 slides at once for a partial scroll effect
                    className="vertical-swiper"
                    navigation={true}
                    modules={[Navigation]}
                    style={{ height: '250px', backgroundColor: "#121212", padding: "5px"}}
                >
                    {companies.map(company =>
                        <SwiperSlide>
                            <Box>
                                <Flex color={"white"} fontSize={"12px"} justifyContent={"space-between"}>
                                    <Box>
                                        <Flex ml={3}>
                                            <Center>
                                                <Image src={company.imgSrc} height="20px" width="20px"/>
                                            </Center>
                                            <Box ml={2}>
                                                <Text mb={0}>{company.name}</Text>
                                                <Text mb={0}>{company.abbr}</Text>
                                            </Box>
                                        </Flex>
                                    </Box>
                                    <Box mr={3}>
                                        <Text mb={0}>{company.currentValue.toFixed(2)}</Text>
                                        <Text mb={0}>{company.change}</Text>
                                    </Box>
                                </Flex>
                                <Box height="1px" backgroundColor="white" m={3}></Box>
                            </Box>
                        </SwiperSlide>
                    )}
                </Swiper>
            </Box>
        </Box>
    );
};

export default WatchList;