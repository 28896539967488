import React, { useState } from 'react'
import Swiper from "./CompaniesSwiper"
import { Box, Flex, Select, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import TradingViewWidgetCandlestick from "./TradingViewWidgetCandlestick";
import CompanyDetails from "./CompanyDetails";
import OrderBook from "./OrderBook";
import TimeAndSales from "./TimeAndSales";
import WatchList from "./WatchList";
import StockGlass from "./StockGlass";
import TradingViewLineChart from "./TradingViewLineChart";


export default function ChartingComponent() {
    return (
        <Box pb={5} sx={{
            '@keyframes slideIn': {
                from: { transform: 'translateX(-100%)' },
                to: { transform: 'translateX(0)' }
            },
            animation: 'slideIn 0.5s ease-out'
        }}>
            <Box>
                <Swiper />
            </Box>

            <Box mb={5} mt={5}>
                <Select
                    w="fit-content"
                    color="white"
                    placeholder="Select company"
                >
                    <option value="company1">Company 1</option>
                    <option value="company2">Company 2</option>
                    <option value="company3">Company 3</option>
                </Select>
            </Box>

            <Tabs variant="unstyled">
                <TabList justifyContent="start">
                    <Tab
                        color="white"
                        _selected={{
                            color: 'yellow.400',
                            borderBottom: '2px solid',
                            borderColor: 'yellow.400'
                        }}
                        transition="all 0.2s"
                    >X CAPITAL</Tab>
                    <Tab
                        color="white"
                        _selected={{
                            color: 'yellow.400',
                            borderBottom: '2px solid',
                            borderColor: 'yellow.400'
                        }}
                        transition="all 0.2s"
                    >TRADING VIEW</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <TradingViewLineChart />
                    </TabPanel>
                    <TabPanel>
                        <Flex mt={5} mb={5} gap={4} direction={{ base: "column", md: "row" }}>
                            <Box flex={{ base: "1", md: "0.75" }}>
                                <Box>
                                    <CompanyDetails />
                                </Box>
                                <Box h="500px">
                                    <TradingViewWidgetCandlestick />
                                </Box>
                            </Box>
                            <Box flex={{ base: "1", md: "0.25" }}>
                                <Box>
                                    <WatchList />
                                </Box>
                                <Box mt={5}>
                                    <OrderBook />
                                </Box>
                                <Box mt={5} bg="#1f1f1f">
                                    <StockGlass />
                                </Box>
                                <Box mt={5}>
                                    <TimeAndSales />
                                </Box>
                            </Box>
                        </Flex>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}