// Import necessary components from React and Chakra UI
import React from 'react';
import { Box, Table, TableContainer, Tbody, Td, Tr, Tabs, TabList, Tab, TabPanels, TabPanel, Flex } from "@chakra-ui/react";

const NewsComponent = () => {
	// Mock news data
	const news = [
		{ title: "Retail Sales Slump Takes Toll on Market, Stocks Dip", time: "10 min ago" },
		{ title: "Tech Giant's Earnings Soar, Stock Hits All-Time High", time: "2 min ago" },
		{ title: "High-Profile IPO Falls Short of Expectations, Stocks Slump", time: "12 hrs ago" },
		{ title: "Electric Vehicle Stocks Skyrocket as Demand Surges", time: "22 hrs ago" },
		{ title: "Market Sentiment Turns Bearish, Stocks Tumble", time: "2 hrs ago" },
		{ title: "Chip Shortage Woes Continue, Tech Stocks Face Uncertainty", time: "3 days ago" }
	];
	return (
		<div style={{ width: "100%" }}>
			<Box>
				{/* Header for the news component */}
				<Flex alignItems={"center"} bg={"#1f1f1f"} h="60px" p={2} px={4} fontSize={"18px"} color='white'>
					Portfolio News
				</Flex>

				<Box bg={"#121212"} color='white'>
					<Tabs>
						{/* Tab list for different news categories */}
						<TabList borderBottom={"none"} overflowX="auto" whiteSpace="nowrap">
							<Tab whiteSpace="nowrap" _selected={{ color: "#F8CD20", borderBottom: "2px solid #F8CD20" }}>All News</Tab>
							<Tab whiteSpace="nowrap" _selected={{ color: "#F8CD20", borderBottom: "2px solid #F8CD20" }}>Market News</Tab>
							<Tab whiteSpace="nowrap" _selected={{ color: "#F8CD20", borderBottom: "2px solid #F8CD20" }}>Company News</Tab>
						</TabList>

						<TabPanels>
							{/* All News Tab */}
							<TabPanel>
								<Box maxHeight="180px" overflowY="auto">
									<TableContainer>
										<Table variant='unstyled'>
											<Tbody>
												{news.map((n, index) =>
													<Tr key={index}>
														<Td>{n.title}</Td>
														<Td textAlign={'end'}>{n.time}</Td>
													</Tr>
												)}
											</Tbody>
										</Table>
									</TableContainer>
								</Box>
							</TabPanel>

							{/* Market News Tab */}
							<TabPanel>
								<Box maxHeight="180px" overflowY="auto">
									<TableContainer>
										<Table variant='unstyled'>
											<Tbody>
												{/* Display only first 4 news items for Market News */}
												{news.slice(0, 4).map((n, index) =>
													<Tr key={index}>
														<Td>{n.title}</Td>
														<Td textAlign={'end'}>{n.time}</Td>
													</Tr>
												)}
											</Tbody>
										</Table>
									</TableContainer>
								</Box>
							</TabPanel>

							{/* Company News Tab */}
							<TabPanel>
								<Box maxHeight="180px" overflowY="auto">
									<TableContainer>
										<Table variant='unstyled'>
											<Tbody>
												{/* Display only first 3 news items for Company News */}
												{news.slice(0, 3).map((n, index) =>
													<Tr key={index}>
														<Td>{n.title}</Td>
														<Td textAlign={'end'}>{n.time}</Td>
													</Tr>
												)}
											</Tbody>
										</Table>
									</TableContainer>
								</Box>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Box>
			</Box>
		</div>
	);
};

export default NewsComponent;