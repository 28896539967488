import {BrowserRouter, Routes, Route} from "react-router-dom";
import XCapitalTerminalLayout from "./hocs/XCapitalTerminal/XCapitalTerminalLayout";
import DashboardPage from "./pages/XCapitalTerminal/DashboardPage";
import LoginPage from "./pages/XCapitalTerminal/LoginPage";
import {ChakraProvider} from '@chakra-ui/react'
import "flag-icons/css/flag-icons.min.css"
import "bootstrap/dist/js/bootstrap.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/style.css"

import ChartingComponent from "./components/XCapitalTerminal/chartingComponents/ChartingComponent";
import StockMarketComponent from "./components/XCapitalTerminal/marketAnalysisComponents/stockMarketComponents/layout/StockMarketComponent";
import AccountSettingsComponent from "./components/XCapitalTerminal/settingsComponents/AccountSettingsComponent";
import BondMarketsComponent from "./components/XCapitalTerminal/marketAnalysisComponents/bondMarketComponents/layout/BondMarketsComponent";
import PortfolioManagementComponent from "./components/XCapitalTerminal/portfolioManagementComponents/layout/PortfolioManagementComponent";
import ForexMarketComponent from "./components/XCapitalTerminal/marketAnalysisComponents/forexMarketComponents/layout/ForexMarketComponent";
import DerivativesMarketsComponent from "./components/XCapitalTerminal/marketAnalysisComponents/derivativeMarketComponents/layout/DerivativesMarketsComponent";
import QuantitativeComponent from "./components/XCapitalTerminal/quantitativeComponents/layout/QuantitativeComponent";
import theme from "./theme";


function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>

          {/* This button only to access the terminal */}
          <Route path="/" element={<LoginPage />} />

          {/* XCapital Terminal links */}
          <Route path="/client/x-capital-terminal" element={<XCapitalTerminalLayout />}>
            <Route path="dashboard" index element={<DashboardPage />} /> {/* This is the default route */}
            <Route path="market-analysis/stock-market" element={<StockMarketComponent />} />
            <Route path="market-analysis/bond-market" element={<BondMarketsComponent/>}/>
            <Route path="market-analysis/forex-market" element={<ForexMarketComponent/>}/>
            <Route path="market-analysis/derivative-market" element={<DerivativesMarketsComponent/>}/>
            <Route path="charting" element={<ChartingComponent />} />
            <Route path="account-settings" element={<AccountSettingsComponent/>} />
            <Route path="portfolio-management" element={<PortfolioManagementComponent />} />
            <Route path="quantitative" element={<QuantitativeComponent />} />
          </Route>

        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
