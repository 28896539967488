import axios from "axios"

const token = process.env.REACT_APP_BEARER_TOKEN
const COUNTRIES_API_URL = process.env.REACT_APP_TERMINAL_API_URL + '/market-data/countries'
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
}

export const getAllCountries = () => {
    return axios.get(process.env.REACT_APP_API_COUNTRIES, config)
}