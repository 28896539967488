import React from 'react';
import {
    Box,
    Button,
    Flex, Input, InputGroup, InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer, Tbody, Td, Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";

const MarketSummaryComponent = () => {
    const forwardsContractData = [
        { 
            underlyingAsset: 'Gold',
            spotPrice: '$2,015.30',
            forwardPrice: '$2,025.50',
            contractSize: '100 troy ounces',
            settlementDate: 'Dec 2024',
            settlementMethod: 'Physical',
            marginRequirements: '$12,100'
        },
        {
            underlyingAsset: 'Silver',
            spotPrice: '$23.45',
            forwardPrice: '$23.85',
            contractSize: '5,000 troy ounces',
            settlementDate: 'Dec 2024',
            settlementMethod: 'Physical',
            marginRequirements: '$11,000'
        },
        {
            underlyingAsset: 'Crude Oil',
            spotPrice: '$75.80',
            forwardPrice: '$76.45',
            contractSize: '1,000 barrels',
            settlementDate: 'Nov 2024',
            settlementMethod: 'Physical',
            marginRequirements: '$7,150'
        },
        {
            underlyingAsset: 'Natural Gas',
            spotPrice: '$2.85',
            forwardPrice: '$2.95',
            contractSize: '10,000 MMBtu',
            settlementDate: 'Nov 2024',
            settlementMethod: 'Physical',
            marginRequirements: '$4,950'
        },
        {
            underlyingAsset: 'Corn',
            spotPrice: '$4.75',
            forwardPrice: '$4.85',
            contractSize: '5,000 bushels',
            settlementDate: 'Dec 2024',
            settlementMethod: 'Physical',
            marginRequirements: '$2,970'
        }
    ];
    return (
        <div>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Market Summary
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                    <Flex flex={1} justifyContent={'start'} mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Active</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Expired</MenuItem>
                            </MenuList>
                        </Menu>
                        <InputGroup width={'300px'}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search contracts"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>Underlying Asset</Th>
                                <Th>Spot Price</Th>
                                <Th>Forward Price</Th>
                                <Th>Contract Size</Th>
                                <Th>Settlement Date</Th>
                                <Th>Settlement Method</Th>
                                <Th>Margin Requirements</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {forwardsContractData.map((contract, index) =>
                                <Tr key={index}>
                                    <Td>{contract.underlyingAsset}</Td>
                                    <Td>{contract.spotPrice}</Td>
                                    <Td>{contract.forwardPrice}</Td>
                                    <Td>{contract.contractSize}</Td>
                                    <Td>{contract.settlementDate}</Td>
                                    <Td>{contract.settlementMethod}</Td>
                                    <Td>{contract.marginRequirements}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default MarketSummaryComponent;