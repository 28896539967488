import React from 'react'
import { CalendarIcon } from "@chakra-ui/icons";
import {
    Grid, GridItem, Input, Select, Button, Box,
    FormControl, FormLabel, InputGroup, InputLeftElement,
} from '@chakra-ui/react';

// BackTestComponent - Main component for backtesting configuration
function BackTestComponent() {
    return (
        <div>
            {/* Main grid layout with 6 columns */}
            <Grid templateColumns={{
                xs: "repeat(2, 1fr)", 
                lg: "repeat(3, 1fr)",
                xl: "repeat(6, 1fr)"
            }} gap="6" mb="8" alignItems="end">
                
                {/* Start Date Input */}
                <GridItem>
                    <FormControl>
                        <FormLabel color="yellow.400" mb="1">From</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <CalendarIcon color="yellow.400" />
                            </InputLeftElement>
                            <Input
                                type="date"
                                bg="blackAlpha.800"
                                borderColor="yellow.400"
                                h="45px"
                                _hover={{ borderColor: 'yellow.400' }}
                                _focus={{ borderColor: 'yellow.400', boxShadow: '0 0 0 1px yellow.400' }}
                            />
                        </InputGroup>
                    </FormControl>
                </GridItem>

                {/* End Date Input */}
                <GridItem>
                    <FormControl>
                        <FormLabel color="yellow.400" mb="1">To</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <CalendarIcon color="yellow.400" />
                            </InputLeftElement>
                            <Input
                                type="date"
                                bg="blackAlpha.800"
                                borderColor="yellow.400"
                                h="45px"
                                _hover={{ borderColor: 'yellow.400' }}
                                _focus={{ borderColor: 'yellow.400', boxShadow: '0 0 0 1px yellow.400' }}
                            />
                        </InputGroup>
                    </FormControl>
                </GridItem>

                {/* Lookback Period Input */}
                <GridItem>
                    <FormControl>
                        <FormLabel mb="1">Lookback period</FormLabel>
                        <InputGroup>
                            <Input borderColor="#ffce30" value={6} width="60px" /> 
                            <FormLabel  ml="4" mb="2">Months</FormLabel>
                        </InputGroup>
                    </FormControl>
                </GridItem>

                {/* Rebalancing Frequency Dropdown */}
                <GridItem>
                    <FormLabel mb="1" fontSize={'15px'}>Rebalancing frequency</FormLabel>
                    <Select bg="blackAlpha.800" h="45px" borderColor="#ffce30">
                        {/* Frequency options with black background */}
                        <option value="stocks" style={{ backgroundColor: 'black', color: 'white' }}>Daily</option>
                        <option value="Bond" style={{ backgroundColor: 'black', color: 'white' }}>Weekly</option>
                        <option value="Derivatives" style={{ backgroundColor: 'black', color: 'white' }}>Monthly</option>
                        <option value="Forex" style={{ backgroundColor: 'black', color: 'white' }}>Quarterly</option>

                    </Select>
                </GridItem>

                {/* Portfolio Value Input */}
                <GridItem>
                    <FormControl>
                        <FormLabel mb="1">Portfolio value</FormLabel>
                        <InputGroup>
                            <Input borderColor="#ffce30" value={6000} width="90px" /> 
                            <FormLabel  ml="4" mb="2">MAD</FormLabel>
                        </InputGroup>
                    </FormControl>
                </GridItem>
                
                {/* Run Backtest Button */}
                <GridItem>
                    <Button  bgColor="#ffce30" color={"black"} fontWeight="bold" size="lg" fontSize={"xl"}>Run Backtest</Button>
                </GridItem>

            </Grid>
        </div>
    )
}

// Export the component
export default BackTestComponent
