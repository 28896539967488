import React from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";

const MajorGlobalForexIndexesTable = () => {
    const mgfIndexes = [
        {
            symbol: "DXY",
            name: "U.S. Dollar Currency Index",
            price: 102.60,
            percentChange: -0.51,
            change: -0.526,
            high: 103.27,
            low: 102.55,
            techRating: "Strong Sell",
        },
        {
            symbol: "EXY",
            name: "Euro Currency Index",
            price: 109.92,
            percentChange: 0.55,
            change: 0.60,
            high: 110.00,
            low: 109.26,
            techRating: "Strong Buy",
        },
        {
            symbol: "BXY",
            name: "British Pound Currency Index",
            price: 128.58,
            percentChange: 0.72,
            change: 0.92,
            high: 128.74,
            low: 127.79,
            techRating: "Strong Buy",
        },
        {
            symbol: "SXY",
            name: "Swiss Franc Currency Index",
            price: 115.65,
            percentChange: 0.10,
            change: 0.12,
            high: 115.79,
            low: 115.18,
            techRating: "Buy",
        },
        {
            symbol: "JXY",
            name: "Japanese Yen Currency Index",
            price: 68.08,
            percentChange: 0.21,
            change: 0.15,
            high: 68.21,
            low: 67.79,
            techRating: "Buy",
        },
        {
            symbol: "CXY",
            name: "Canadian Dollar Currency Index",
            price: 72.98,
            percentChange: 0.29,
            change: 0.21,
            high: 72.98,
            low: 72.77,
            techRating: "Buy",
        },
        {
            symbol: "AXY",
            name: "Australian Dollar Currency Index",
            price: 66.35,
            percentChange: 0.76,
            change: 0.50,
            high: 66.39,
            low: 65.90,
            techRating: "Buy",
        },
        {
            symbol: "ZXY",
            name: "New Zealand Dollar Currency Index",
            price: 60.72,
            percentChange: -0.51,
            change: 0.52,
            high: 60.83,
            low: 60.38,
            techRating: "Buy",
        },
    ];
    return (
        <Box>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Major Global Forex Indexes
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                    <Menu>
                        <MenuButton as={Button}
                                    height={'35px'}
                                    bgColor="#161616"
                                    color="white"
                                    m={"0 15px 0 7px"}
                                    border={"1px solid #999999"}
                                    _hover={{bgColor: "#242424"}}
                                    rightIcon={<FiChevronDown style={{marginLeft: "20px"}}/>}
                                    _focus={{bgColor: "#242424"}}
                        >
                            Date range
                        </MenuButton>
                        <MenuList bgColor="#121212" borderColor={"transparent"}>
                            <MenuItem bgColor="#121212" _hover={{bgColor:"#242424"}}>Metric 1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{bgColor:"#242424"}}>Metric 2</MenuItem>
                        </MenuList>
                    </Menu>
                    <Flex flex={1} justifyContent={'end'} mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        <InputGroup width={'300px'}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w="100%" color="white">
                <TableContainer>
                    <Table variant="simple">
                        <Thead className="table-header">
                            <Tr>
                                <Th>SYMBOL</Th>
                                <Th>NAME</Th>
                                <Th>PRICE</Th>
                                <Th>% CHANGE</Th>
                                <Th>CHANGE</Th>
                                <Th>HIGH</Th>
                                <Th>LOW</Th>
                                <Th>TECH RATING</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {mgfIndexes.map((index) => (
                                <Tr key={index.symbol}>
                                    <Td>{index.symbol}</Td>
                                    <Td>{index.name}</Td>
                                    <Td>{index.price.toFixed(2)}</Td>
                                    <Td color={index.percentChange > 0 ? "green.400" : "red.400"}>
                                        {index.percentChange > 0 ? (
                                            <Text as="span">+{index.percentChange.toFixed(2)}%</Text>
                                        ) : (
                                            <Text as="span">{index.percentChange.toFixed(2)}%</Text>
                                        )}
                                    </Td>
                                    <Td color={index.change > 0 ? "green.400" : "red.400"}>
                                        {index.change.toFixed(3)}
                                    </Td>
                                    <Td>{index.high.toFixed(2)}</Td>
                                    <Td>{index.low.toFixed(2)}</Td>
                                    <Td>{index.techRating}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default MajorGlobalForexIndexesTable;