import React from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import { FiChevronDown, FiFilter } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";

const CashFlowTable = () => {
    const cashFlowData = [
        {
            item: "Examples",
            fy1: 40,
            revenue1: 250,
            fy2: 15.2,
            revenue2: 4.5,
            change: 4.5,
        },
        {
            item: "Cash and Cash Equivalents",
            fy1: 20,
            revenue1: 70,
            fy2: 18.0,
            revenue2: 3.8,
            change: 3.8,
        },
        {
            item: "Loans and Advances",
            fy1: 15,
            revenue1: 50,
            fy2: 16.0,
            revenue2: 3.5,
            change: 3.5,
        },
        {
            item: "Investments",
            fy1: 40,
            revenue1: 130,
            fy2: 15.2,
            revenue2: 4.5,
            change: 4.5,
        },
        {
            item: "Other Assets",
            fy1: 20,
            revenue1: 70,
            fy2: 18.0,
            revenue2: 3.8,
            change: 3.8,
        },
        {
            item: "Deposits",
            fy1: 20,
            revenue1: 70,
            fy2: 18.0,
            revenue2: 3.8,
            change: 3.8,
        },
        {
            item: "Borrowings",
            fy1: 20,
            revenue1: 70,
            fy2: 18.0,
            revenue2: 3.8,
            change: 3.8,
        },
        {
            item: "Other Liabilities",
            fy1: 20,
            revenue1: 70,
            fy2: 18.0,
            revenue2: 3.8,
            change: 3.8,
        },
    ];
    return (
        <div>
            <div>
                <Box mt={10}>
                    <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} fontSize={"18px"} color='white'>
                        Cash Flow Statement
                    </Box>

                    {/* Filter and search section */}
                    <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                        <Flex flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={{ base: "center", md: "end" }} gap={{ base: "10px", md: "0" }}>
                            {/* Date range dropdown */}
                            <Menu>
                                <MenuButton as={Button}
                                    height={'35px'}
                                    bgColor="#161616"
                                    color="white"
                                    m={{ base: "0 37px 0 35px", md: "0 15px 0 7px" }}
                                    border={"1px solid #999999"}
                                    _hover={{ bgColor: "#242424" }}
                                    rightIcon={<FiChevronDown style={{ marginLeft: "20px" }} />}
                                    _focus={{ bgColor: "#242424" }}
                                    width={{ base: '60%', md: 'auto' }}
                                >
                                    Date range
                                </MenuButton>
                                <MenuList bgColor="#121212" borderColor={"transparent"}>
                                    <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>range 1</MenuItem>
                                    <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>range 2</MenuItem>
                                </MenuList>
                            </Menu>
                            <Flex flex={1} justifyContent={{ base: 'center', md: 'end' }} mr={10}>
                                {/* Filter dropdown */}
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        height={'35px'}
                                        bgColor="#161616"
                                        color="white"
                                        borderWidth={"1px 0 1px 1px"}
                                        borderRadius={"5px 0 0px 5px"}
                                        borderColor={"rgba(119,119,119,0.5)"}
                                        _hover={{ bgColor: "#242424" }}
                                        rightIcon={<FiChevronDown />}
                                        _focus={{ bgColor: "#242424" }}
                                    >
                                        <Flex>
                                            <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                                <FiFilter fill={"white"} />
                                            </Box>
                                            All
                                        </Flex>
                                    </MenuButton>
                                    <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                        <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                                        <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
                                    </MenuList>
                                </Menu>
                                {/* Search input */}
                                <InputGroup width={{ base: '60%', md: '300px' }}>
                                    <InputLeftElement pb={1}>
                                        <FaSearch />
                                    </InputLeftElement>
                                    <Input type="text" height='35px' placeholder="Search"
                                        _placeholder={{ color: '#fff', opacity: 0.7 }}
                                        _focus={{
                                            outline: 'none',
                                            boxShadow: 'none',
                                            borderColor: 'rgba(119,119,119,0.5)'
                                        }}
                                        _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                        borderColor={"rgba(119,119,119,0.5)"}
                                        borderWidth="1px"
                                        borderRadius={"0px 5px 5px 0px"}
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                    </Box>

                    <Box bg={"#121212"} w='100%' color='white'>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead className='table-header'>
                                    <Tr>
                                        <Th>ITEM</Th>
                                        <Th>FY 1 (MAD MILLIONS)</Th>
                                        <Th>% OF REVENUE 1</Th>
                                        <Th>FY 2 (MAD MILLIONS)</Th>
                                        <Th>% OF REVENUE 2</Th>
                                        <Th>% CHANGE 1-2</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {cashFlowData.map((cashFlow, index) => (
                                        <Tr key={index}>
                                            <Td>{cashFlow.item}</Td>
                                            <Td>{cashFlow.fy1}</Td>
                                            <Td>{cashFlow.revenue1}</Td>
                                            <Td>{cashFlow.fy2}</Td>
                                            <Td>{cashFlow.revenue2}</Td>
                                            <Td>{cashFlow.change}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default CashFlowTable;