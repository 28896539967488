import React from 'react';
import ReactApexChart from 'react-apexcharts';

const CorrelationChart = () => {
    function generateDataSeries(count, { min, max }) {
        const data = [];
        let value = (max + min) / 2;
        const timeStep = 3600000;
        const startTime = new Date().getTime() - (count - 1) * timeStep;
      
        for (let i = 0; i < count; i++) {
          const x = startTime + i * timeStep;
          const change = (Math.random() - 0.5) * (max - min) * 0.2;
          value += change;
          value = Math.max(min, Math.min(max, value));
          data.push([x, parseFloat(value.toFixed(2))]);
        }
        return data;
      }
      
      function generateNegativeDataSeries(count, { min, max }) {
        const data = [];
        let value = (max + min) / 2;
        const timeStep = 3600000;
        const startTime = new Date().getTime() - (count - 1) * timeStep;
      
        for (let i = 0; i < count; i++) {
          const x = startTime + i * timeStep;
          const change = (Math.random() - 0.5) * (max - min) * -0.2; 
          value += change;
          value = Math.max(min, Math.min(max, value));
          data.push([x, parseFloat(value.toFixed(2))]);
        }
        return data;
      }
  const options = {
    chart: {
      type: 'area',
      height: 300,
      background: '#000000',
      foreColor: '#ffffff',
      toolbar: { show: false },
    },
    colors: ['#00FF00', '#FF0000'],
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: '#00FF00',
            opacity: 0.8,
          },
          {
            offset: 100,
            color: '#FF0000',
            opacity: 0.8,
          },
        ],
      },
    },
    xaxis: {
      type: 'datetime',
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      min: 22.00,
      max: 22.70,
      tickAmount: 7,
      labels: {
        formatter: (value) => value.toFixed(2),
        style: {
          colors: '#ffffff',
        },
      },
    },
    grid: {
      borderColor: '#707070',
      yaxis: {
        lines: { show: true },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 22.18,
          borderColor: '#FFD700',
          label: {
            borderColor: '#FFD700',
            style: {
              color: '#000000',
              background: '#FFD700',
            },
            text: '22.18',
            position: 'right',
          },
        },
      ],
    },
    tooltip: {
      theme: 'dark',
    },
  };

  const series = [
    {
      name: 'Correlation',
      data: generateDataSeries(50, { min: 22.00, max: 22.70 }),
    },
    {
      name: 'Cointegration',
      data: generateNegativeDataSeries(50, { min: 22.00, max: 22.70 }),
    },
  ];

  return <ReactApexChart options={options} series={series} type="area" height={300} />;
};

export default CorrelationChart;
