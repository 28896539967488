import React, { useState } from 'react';
import "chartjs-plugin-datalabels"
import FundamentalComponent from "../FundamentalComponent";
import SentimentComponent from "../SentimentComponent";
import { Link as RouterLink } from "react-router-dom";
import { Link, Box, Input } from '@chakra-ui/react';


const MarketComponents = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const[show,setshow]=useState(true)
    const handelclick1=()=>{
        setshow(true)
    }
    const handelclick2=()=>{
        setshow(false)
    }
    return (
        <div className="m-auto">
             <Input
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                mb={4}
                width="300px"
            />
            <Box mt={4} display="flex" justifyContent="left">
                <RouterLink  style={{ marginRight: 16 }}>
                    <Link color={show?"#FFCE30":"white"} fontSize="lg" onClick={handelclick1}>MARKET FUNDAMENTAL</Link>
                </RouterLink>
                <RouterLink >
                    <Link color={show?"white":"#FFCE30"} fontSize="lg" onClick={handelclick2}>MARKET SENTIMENT</Link>
                </RouterLink>
            </Box>
            {show ? <FundamentalComponent searchTerm={searchTerm}  />: <SentimentComponent searchTerm={searchTerm}  />}
        </div>
    );
};

export default MarketComponents;