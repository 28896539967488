import React from 'react'
import GoldFuturesComponent from '../GoldFuturesComponent'
import MarketSummaryComponent from '../MarketSummaryComponent'
import MarketDataTableComponent from '../MarketDataTableComponent'
function FuturesComponent() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4rem' }}>
        <GoldFuturesComponent />
        <MarketSummaryComponent />
        <MarketDataTableComponent />
    </div>
  )
}

export default FuturesComponent