import axios from "axios";

const token = process.env.REACT_APP_BEARER_TOKEN
const MACRO_ECONOMIC_API_URL = process.env.REACT_APP_TERMINAL_API_URL + '/macro-data'
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
}

export const getGdp = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/gdp', config)
}
export const getInflationRate = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/inflation-rate', config)
}
export const getUnemploymentRate = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/unemployment-rate', config)
}
export const getFdi = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/fdi', config)
}
export const getCpi = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/cpi', config)
}
export const getPpi = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/ppi', config)
}
export const getTradeBalance = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/trade-balance', config)
}
export const getInterestRate = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/interest-rate', config)
}
export const getEconomicCalendar = () => {
    return axios.get(MACRO_ECONOMIC_API_URL + '/economic-calendar', config)
}