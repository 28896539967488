import React from 'react';
import {
    Box,
    Button,
    Flex, Input, InputGroup, InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer, Tbody, Td, Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";

const MarketDataTableComponent = () => {
    const futuresContractData = [
        { 
            contractName: 'Gold Futures',
            currentPrice: '$2,015.30',
            bidPrice: '$2,015.20',
            askPrice: '$2,015.40',
            openInterest: '458,723',
            volume: '127,845',
            dailyHigh: '$2,025.50',
            dailyLow: '$2,008.90'
        },
        {
            contractName: 'Silver Futures',
            currentPrice: '$23.45',
            bidPrice: '$23.44',
            askPrice: '$23.46', 
            openInterest: '132,567',
            volume: '45,678',
            dailyHigh: '$23.85',
            dailyLow: '$23.20'
        },
        {
            contractName: 'Crude Oil Futures',
            currentPrice: '$75.80',
            bidPrice: '$75.79',
            askPrice: '$75.81',
            openInterest: '287,654',
            volume: '98,432',
            dailyHigh: '$76.45',
            dailyLow: '$75.20'
        },
        {
            contractName: 'Natural Gas Futures',
            currentPrice: '$2.85',
            bidPrice: '$2.84',
            askPrice: '$2.86',
            openInterest: '178,543',
            volume: '65,432',
            dailyHigh: '$2.95',
            dailyLow: '$2.80'
        },
        {
            contractName: 'Corn Futures',
            currentPrice: '$4.75',
            bidPrice: '$4.74',
            askPrice: '$4.76',
            openInterest: '98,765',
            volume: '34,567',
            dailyHigh: '$4.85',
            dailyLow: '$4.70'
        }
    ];
    return (
        <div>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Market Data Table
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                    <Flex flex={1} justifyContent={'start'} mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Active</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Expired</MenuItem>
                            </MenuList>
                        </Menu>
                        <InputGroup width={'300px'}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search contracts"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>CONTRACT NAME</Th>
                                <Th>CURRENT PRICE</Th>
                                <Th>BID PRICE</Th>
                                <Th>ASK PRICE</Th>
                                <Th>OPEN INTEREST</Th>
                                <Th>VOLUME</Th>
                                <Th>DAILY HIGH</Th>
                                <Th>DAILY LOW</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {futuresContractData.map((contract, index) =>
                                <Tr key={index}>
                                    <Td>{contract.contractName}</Td>
                                    <Td>{contract.currentPrice}</Td>
                                    <Td>{contract.bidPrice}</Td>
                                    <Td>{contract.askPrice}</Td>
                                    <Td>{contract.openInterest}</Td>
                                    <Td>{contract.volume}</Td>
                                    <Td>{contract.dailyHigh}</Td>
                                    <Td>{contract.dailyLow}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default MarketDataTableComponent;