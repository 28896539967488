import React from 'react';
import MarketSummaryTable from "../MarketSummaryTable";
import SectorHeatmap from "../SectorHeatmap";
import VolumeLeadersTable from "../VolumeLeadersTable";
import TopGainersLosers from "../../../../dashboardComponents/TopGainersLosers";
import EconomicCalendar from "../../../../dashboardComponents/EconomicCalendar";

const OverviewComponent = () => {
    return (
        <div>
            <MarketSummaryTable/>
            <SectorHeatmap/>
            <VolumeLeadersTable/>
            <TopGainersLosers/>
            <EconomicCalendar/>
        </div>
    );
};

export default OverviewComponent;