import React from 'react';
import {
    Box,
    Button,
    Flex, Input, InputGroup, InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer, Tbody, Td, Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import { FiChevronDown, FiFilter } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import useFetchSectorsData from '../../../../../hooks/useFetchSectorsData';
import useFetchCompaniesData from '../../../../../hooks/useFetchCompaniesData';

const TopSectorsTable = () => {
    const { data: sectors, loading, error } = useFetchSectorsData();
    const { data: companies, loading: companiesLoading, error: companiesError } = useFetchCompaniesData();

    console.log(companies);

    const topSectorsData = sectors?.sort((a, b) => {
        const aChange = parseFloat(a.change_percentage) || -Infinity;
        const bChange = parseFloat(b.change_percentage) || -Infinity;
        return bChange - aChange;
    }).slice(0, 5) || [];

    return (
        <Box>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Top Sectors
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={{ base: "center", md: "flex-start" }}>
                    <Flex flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={{ base: "center", md: "flex-start" }}>
                        <Menu>
                            <MenuButton as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                m={"0 15px 15px 7px"}
                                border={"1px solid #999999"}
                                _hover={{ bgColor: "#242424" }}
                                rightIcon={<FiChevronDown style={{ marginLeft: "20px" }} />}
                                _focus={{ bgColor: "#242424" }}
                            >
                                Date range
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"}>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 2</MenuItem>
                            </MenuList>
                        </Menu>
                        <Flex flex={1} justifyContent={{ base: "center", md: "flex-end" }} mr={10}>
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    height={'35px'}
                                    bgColor="#161616"
                                    color="white"
                                    borderWidth={"1px 0 1px 1px"}
                                    borderRadius={"5px 0 0px 5px"}
                                    borderColor={"rgba(119,119,119,0.5)"}
                                    _hover={{ bgColor: "#242424" }}
                                    rightIcon={<FiChevronDown />}
                                    _focus={{ bgColor: "#242424" }}
                                >
                                    <Flex>
                                        <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                            <FiFilter fill={"white"} />
                                        </Box>
                                        All
                                    </Flex>
                                </MenuButton>
                                <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                    <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                                    <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
                                </MenuList>
                            </Menu>
                            <InputGroup width={'50%'}>
                                <InputLeftElement pb={1}>
                                    <FaSearch />
                                </InputLeftElement>
                                <Input type="text" height='35px' placeholder="Search"
                                    _placeholder={{ color: '#fff', opacity: 0.7 }}
                                    _focus={{
                                        outline: 'none',
                                        boxShadow: 'none',
                                        borderColor: 'rgba(119,119,119,0.5)'
                                    }}
                                    _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                    borderColor={"rgba(119,119,119,0.5)"}
                                    borderWidth="1px"
                                    borderRadius={"0px 5px 5px 0px"}
                                />
                            </InputGroup>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>Sector</Th>
                                <Th>Market Cap (MAD)</Th>
                                <Th>Revenue (MAD)</Th>
                                <Th isNumeric>P/E Ratio</Th>
                                <Th>Dividend Yield</Th>
                                <Th>Sector Growth (YOY)</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            { error ? <Tr><Td>Error...</Td></Tr> : loading ? <Tr><Td>Loading...</Td></Tr> : topSectorsData.map(sector => (
                                <Tr key={sector.name}>
                                    <Td>{sector.name}</Td>
                                    <Td>{sector.marketCap ? `${sector.marketCap} billion` : 'N/A'}</Td>
                                    <Td>{sector.revenue ? `${sector.revenue} billion` : 'N/A'}</Td>
                                    <Td>{sector.peRatio || 'N/A'}</Td>
                                    <Td>{sector.dividendYield || 'N/A'}</Td>
                                    <Td>{sector.growth || 'N/A'}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default TopSectorsTable;