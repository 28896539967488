import React from "react";
import IndicesCard from "../../components/XCapitalTerminal/dashboardComponents/IndicesCard";
import IndexesChart from "../../components/XCapitalTerminal/dashboardComponents/IndexesChart";
import CompaniesList from "../../components/XCapitalTerminal/dashboardComponents/CompaniesList";
import EarningsCalendar from "../../components/XCapitalTerminal/dashboardComponents/EarningCalender";
import EconomicCalendar from "../../components/XCapitalTerminal/dashboardComponents/EconomicCalendar";
import TopGainersLosers from "../../components/XCapitalTerminal/dashboardComponents/TopGainersLosers";
import {Box} from "@chakra-ui/react";

function DashboardPage() {
  return (
    <Box width={"90%"} m={"auto"}>
      {/* <IndicesCard /> */}
      <IndexesChart />
      <CompaniesList />
      <EarningsCalendar />
      <EconomicCalendar />
      <TopGainersLosers />
    </Box>
  );
}

export default DashboardPage;
