import React from 'react';
import { ChakraProvider, Box, Text, Input, Button, VStack } from '@chakra-ui/react';

const PairTradingStrategy = () => {
    return (
        <ChakraProvider>
            <Box
                bg="black"
                color="white"
                minH="100vh"
                p={8}
                fontFamily="Arial, sans-serif"
            >
                <Text fontSize="2xl" fontWeight="bold" mb={4} color="#ffce30">
                    Pair Trading Strategy
                </Text>
                <Text mb={6} lineHeight="tall" fontSize="lg">
                    Pairs trading, also known as statistical arbitrage or spread trading, is a market-neutral<br />
                    strategy that involves trading two correlated assets. The idea is to take advantage of the <br />
                    price divergence between the two assets, expecting that they will revert to their<br />
                    historical mean relationship.
                </Text>

                <VStack spacing={4} align="flex-start">
                    <Text fontSize="2xl" fontWeight="bold" color="#ffce30">
                        Check Correlation
                    </Text>
                    <Text>Moving Averages (MA):</Text>
                    <Input
                        placeholder="20 Days"
                        color="black"
                        width="100px" 
                        borderColor="#ffce30"
                    />
                    <Button
                        colorScheme="yellow"
                        fontWeight="bold" 
                        size="lg" 
                        fontSize={"xl"}
                        bg="#ffce30"
                        color="black"
                    >
                        Test the Correlation
                    </Button>
                </VStack>

                <Box height="8" />

                <VStack spacing={4} align="flex-start">
                    <Text fontSize="2xl" fontWeight="bold" color="#ffce30">
                        Check Cointegration
                    </Text>
                    <Text>Moving Averages (MA):</Text>
                    <Input
                        placeholder="50 Days"
                        color="black"
                        width="100px" 
                        borderColor="#ffce30"
                    />
                    <Button
                        colorScheme="yellow"
                        bg="#ffce30"
                        color="black"
                        fontWeight="bold" 
                        size="lg" 
                        fontSize={"xl"}
                    >
                        Test the Cointegration
                    </Button>
                </VStack>
            </Box>
        </ChakraProvider>
    );
};

export default PairTradingStrategy;
