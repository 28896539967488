import React from 'react';
import {
    Box,
    Button,
    Flex, Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaArrowDown, FaArrowUp, FaSearch} from "react-icons/fa";

const RealTimeCurrencyTable = () => {
    const currencyData = [
        { pair: 'USD/MAD', bid: 9.90, ask: 9.92, change: 0.05, high: 10.00, low: 9.85, time: '15:30', contributor: 'Reuters' },
        { pair: 'EUR/MAD', bid: 11.20, ask: 11.23, change: -0.02, high: 11.25, low: 11.15, time: '15:30', contributor: 'Bloomberg' },
        { pair: 'MAD/ZAR', bid: 1.75, ask: 1.77, change: 0.03, high: 1.80, low: 1.72, time: '15:30', contributor: 'Forex.com' },
        { pair: 'MAD/NGN', bid: 43.00, ask: 43.05, change: 0.10, high: 43.10, low: 42.85, time: '15:30', contributor: 'TradingView' },
        { pair: 'MAD/KES', bid: 15.00, ask: 15.05, change: 0.08, high: 15.10, low: 14.90, time: '15:30', contributor: 'OANDA' },
        { pair: 'EUR/ZAR', bid: 20.00, ask: 20.03, change: 0.15, high: 20.10, low: 19.85, time: '15:30', contributor: 'Reuters' },
        { pair: 'USD/NGN', bid: 775.00, ask: 776.00, change: -2.00, high: 780.00, low: 770.00, time: '15:30', contributor: 'Bloomberg' },
        { pair: 'GBP/MAD', bid: 13.50, ask: 13.55, change: 0.12, high: 13.60, low: 13.40, time: '15:30', contributor: 'Forex.com' },
    ];

    return (
        <Box>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Real-Time Currency Quotes
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                    <Menu>
                        <MenuButton as={Button}
                                    height={'35px'}
                                    bgColor="#161616"
                                    color="white"
                                    m={"0 15px 0 7px"}
                                    border={"1px solid #999999"}
                                    _hover={{bgColor: "#242424"}}
                                    rightIcon={<FiChevronDown style={{marginLeft: "20px"}}/>}
                                    _focus={{bgColor: "#242424"}}
                        >
                            Date range
                        </MenuButton>
                        <MenuList bgColor="#121212" borderColor={"transparent"}>
                            <MenuItem bgColor="#121212" _hover={{bgColor:"#242424"}}>Metric 1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{bgColor:"#242424"}}>Metric 2</MenuItem>
                        </MenuList>
                    </Menu>
                    <Flex flex={1} justifyContent={'end'} mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        <InputGroup width={'300px'}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr className="table-header">
                                <Th>CURRENCY PAIR (FX)</Th>
                                <Th>BID</Th>
                                <Th>ASK</Th>
                                <Th>CHANGE</Th>
                                <Th>HIGH</Th>
                                <Th>LOW</Th>
                                <Th>TIME</Th>
                                <Th>CONTRIBUTOR</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currencyData.map((currency) => (
                                <Tr key={currency.pair}>
                                    <Td>{currency.pair}</Td>
                                    <Td>{currency.bid}</Td>
                                    <Td>{currency.ask}</Td>
                                    <Td color={currency.change > 0 ? "green.400" : "red.400"}>
                                        {currency.change.toFixed(2)}
                                        <Text as="span" pb={2}>
                                            {currency.change > 0 ? (
                                                <Icon as={FaArrowUp} color="green.400" />
                                            ) : (
                                                <Icon as={FaArrowDown} color="red.400" />
                                            )}
                                        </Text>
                                    </Td>
                                    <Td>{currency.high}</Td>
                                    <Td>{currency.low}</Td>
                                    <Td>{currency.time}</Td>
                                    <Td>{currency.contributor}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default RealTimeCurrencyTable;