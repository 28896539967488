import React from 'react';
import {Outlet} from 'react-router-dom';
import {Box} from '@chakra-ui/react';
import Sidebar from '../../components/XCapitalTerminal/layoutComponents/Sidebar';
import {FiHome, FiSettings} from 'react-icons/fi';
import {PiBagFill, PiStrategyBold} from "react-icons/pi";
import {TbPresentationAnalytics} from "react-icons/tb";
import {MdCandlestickChart} from "react-icons/md";
import Header from '../../components/XCapitalTerminal/layoutComponents/Header';


const XCapitalTerminalLayout = () => {
 {/* 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
   
  const user = useSelector(state => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    dispatch(checkAuthenticated());
    dispatch(load_user());
  }, [dispatch]);
*/}
  const sidebarItems = [
    { name: 'Dashboard', icon: FiHome, link: '/client/x-capital-terminal/dashboard' },
    { name: 'Market analysis', icon: TbPresentationAnalytics,
      subMenu: [
        { name: 'Stock Markets', link: '/client/x-capital-terminal/market-analysis/stock-market' },
        { name: 'Bond Markets' , link: '/client/x-capital-terminal/market-analysis/bond-market'},
        { name: 'Derivatives Markets', link: '/client/x-capital-terminal/market-analysis/derivative-market' },
        { name: 'Forex Market', link: '/client/x-capital-terminal/market-analysis/forex-market' },
        { name: 'Commodities Markets' },
      ],

     },
    { 
      name: 'Portfolio management', 
      icon: PiBagFill, link: '/client/x-capital-terminal/portfolio-management'
    },
    { name: 'Charting', icon: MdCandlestickChart, link: '/client/x-capital-terminal/charting'},
    { name: 'Quantitative strategies', icon: PiStrategyBold, link: '/client/x-capital-terminal/quantitative' },
    { name: 'Settings', icon: FiSettings, link: '/client/x-capital-terminal/account-settings' },
  ];

  return (
    <Box id="main-layout" bgColor="black" as="main" minH="100vh">
      <Sidebar items={sidebarItems} username={"John Doe"} />
      <Header />
      <Box as="main" minH="80vh" width="80%" ml="15%">
        <Outlet />
      </Box>
    </Box>
  );
};

export default XCapitalTerminalLayout;
