import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Icon,
  Text,
  Stack,
  Menu,
  Button,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { FaChevronDown } from "react-icons/fa";
import { getAllIndexes } from "../../../services/marketDataServices/CompanyService";
import IndicesCard from "./IndicesCard";

const IndexesChart = () => {
  const indexCategories = [
    "Real Estate",
    "Chemical",
    "FTSE CSE Morocco All-Liquid",
    "Hotels, Restaurants & Leisure",
    "Beverages",
    "Insurance",
    "Transportation",
    "Food Producer & Processors",
    "Invest Companies & Other Finance",
    "Forestry & Papers",
  ];
  const [indexes, setIndexes] = useState([]);
  const [timeRange, setTimeRange] = useState("days");
  const [selectedCategory, setSelectedCategory] = useState(indexCategories[0]); // Default to "all"
  // const [selectedCategory, setSelectedCategory] = useState("all"); // Default to "all"


  const fetchCategoryData = async (category) => {
    try {
      const response = await getAllIndexes(category);
      const sortedData = response.data.results.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      return {
        category,
        data: sortedData,
      };
    } catch (error) {
      console.error(`Error fetching data for category: ${category}`, error);
      return { category, data: [] }; // Return empty data on failure
    }
  };

  const fetchIndexes = async () => {
    try {
      const responses = await Promise.all(
        indexCategories.map((category) => fetchCategoryData(category))
      );

      setIndexes(responses);
    } catch (error) {
      console.error("Error fetching indexes:", error);
    }
  };

  useEffect(() => {
    fetchIndexes();
  }, []);

  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const filteredIndexes =
    selectedCategory === "all"
      ? indexes
      : indexes.filter((index) => index.category === selectedCategory);

  const datasets = filteredIndexes.map((indexGroup, idx) => {
    // const color = `hsl(${(idx * 360) / indexCategories.length}, 70%, 50%)`;
    const color = selectedCategory !== "all" ? "white" : `hsl(${(idx * 360) / indexCategories.length}, 70%, 50%)`;
    const groupedData =
      timeRange === "days"
        ? indexGroup.data
        : indexGroup.data.reduce((acc, curr) => {
            const key =
              timeRange === "months"
                ? new Date(curr.date).toISOString().slice(0, 7)
                : new Date(curr.date).getFullYear().toString();
            if (!acc[key]) acc[key] = [];
            acc[key].push(curr.price);
            return acc;
          }, {});

    const groupedPoints =
      timeRange === "days"
        ? indexGroup.data
        : Object.keys(groupedData).map((key) => ({
            date: key,
            price: groupedData[key].reduce((sum, price) => sum + parseFloat(price), 0) / groupedData[key].length,
          }));

    return {
      label: indexGroup.category,
      data: groupedPoints.map((point) => ({
        x: point.date,
        y: parseFloat(point.price),
      })),
      borderColor: color,
      borderWidth: 2,
      tension: 0.4,
      pointRadius: 0,
    };
  });

  const chartData = {
    datasets,
  };

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: timeRange === "years" ? "year" : timeRange === "months" ? "month" : "day",
        },
        title: { display: true, text: "Date" },
      },
      y: {
        title: { display: true, text: "Price" },
      },
    },
    plugins: {
      legend: { display: true, position: "bottom" },
    },
  };

  return (
    <Box mt="25px" color="white" mx="auto">
      <IndicesCard
        indicesData={indexes.map((index, idx) => ({
          name: index.category,
          color: idx % 2 === 0 ? "green.500" : "red.500",
          data: index.data,
        }))}
      />

      <Flex justify="space-between" h="65px" align="center" bg="#121212" p="10px">
        <Stack direction="row" spacing={4} align="center">
          <Text fontSize="16px" fontWeight="bold" mt="10px" ml="10px">
            Indexes
          </Text>
          {/* Indexes Selection Button */}
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<Icon as={FaChevronDown} />}
              bg="#0A0A0A"
              color="white"
              border="1px solid #242424"
              _hover={{ bg: "#121212" }}
            >
              Select Index
            </MenuButton>
            <MenuList bg="#0A0A0A" border="1px solid #242424" maxH="300px" overflowY="auto">
              <MenuItem onClick={() => handleCategorySelect("all")} _hover={{ bg: "#333" }}>
                All Indexes
              </MenuItem>
              {indexCategories.map((category) => (
                <MenuItem
                  key={category}
                  onClick={() => handleCategorySelect(category)}
                  bg={selectedCategory === category ? "#333" : "#0A0A0A"}
                  _hover={{ bg: "#242424" }}
                >
                  {category}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Stack>
          {/* Time Range Button */}
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<Icon as={FaChevronDown} />}
              bg="#0A0A0A"
              color="white"
              border="1px solid #242424"
              _hover={{ bg: "#121212" }}
            >
              Select Time Range
            </MenuButton>
            <MenuList bg="#0A0A0A" border="1px solid #242424">
              <MenuItem
                bg={timeRange === "days" ? "#333" : "#0A0A0A"}
                color="white"
                onClick={() => handleTimeRangeChange("days")}
                _hover={{ bg: "#333" }}
              >
                Days
              </MenuItem>
              <MenuItem
                bg={timeRange === "months" ? "#333" : "#0A0A0A"}
                color="white"
                onClick={() => handleTimeRangeChange("months")}
                _hover={{ bg: "#333" }}
              >
                Months
              </MenuItem>
              <MenuItem
                bg={timeRange === "years" ? "#333" : "#0A0A0A"}
                color="white"
                onClick={() => handleTimeRangeChange("years")}
                _hover={{ bg: "#333" }}
              >
                Years
              </MenuItem>
            </MenuList>
          </Menu>

      </Flex>
      <Box bg="#0A0A0A" pt={5}>
        <Box h="500px">
          {datasets.length > 0 ? (
            <Line data={chartData} options={chartOptions} />
          ) : (
            <Text color="white" textAlign="center">
              Loading...
              {/* No data to display. Please select a category. */}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IndexesChart;
