import React from 'react';
import NewsComponent from "../../stockMarketComponents/news/NewsComponent";
import {Box} from "@chakra-ui/react";

const News = () => {
    return (
        <Box>
            <NewsComponent/>
        </Box>
    );
};

export default News;