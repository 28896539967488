import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Box } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DividendsComponent = () => {
  const data = {
    labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6', 'Category 7'],
    datasets: [
      {
        label: 'MAD',
        data: [80000, 50000, 40000, 30000, 20000, 10000, 25000],
        backgroundColor: [
          '#4ac4f3',
          '#a3ead6',
          '#fbc1c7',
          '#f7a24e',
          '#b6a8ed',
          '#cbd5e0',
          '#2a7df3',
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '12-month forward cash flow: 18,354 MAD  Yield: 1.44%',
        color: 'white',
        font: {
          size: 20,
        },
        padding: {
          top: 20,
          bottom: 20,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) =>`${value} MAD`,
        },
        title: {
          display: true,
          text: 'MAD',
        },
      },
      x: {
        title: {
          display: true,
          text: '',
        },
      },
    },
  };

  return <Bar data={data} options={options} height={{ base: "220vh", xs: "220vh", sm: "100vh", md: "100vh", lg: "100vh" }} width={{ base: "100vw", xs: "100vw", sm: "100vw", md: "100vw", lg: "100vw" }} />;
};

export default DividendsComponent;