import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

const ImpliedVolatilityChart = () => {
    const [timeframe, setTimeframe] = useState('1M'); // Default timeframe

    // Function to filter data based on selected timeframe
    const getFilteredData = () => {
        const now = new Date();
        const data = series[0].data;
        
        switch(timeframe) {
            case '1D':
                return data.slice(-1);
            case '5D':
                return data.slice(-5);
            case '1M':
                return data.slice(-30);
            case '3M':
                return data.slice(-90);
            case '6M':
                return data.slice(-180);
            case 'YTD':
                const startOfYear = new Date(now.getFullYear(), 0, 1);
                return data.filter(item => item.x >= startOfYear);
            case '1Y':
                return data.slice(-365);
            case '5Y':
                return data.slice(-1825);
            case 'ALL':
                return data;
            default:
                return data;
        }
    };

    const series = [{
        data: [
            { x: new Date(2024, 0, 1), y: [20.34, 21.53, 19.88, 21.00] },
            { x: new Date(2024, 0, 2), y: [21.00, 22.75, 20.95, 22.15] },
            { x: new Date(2024, 0, 3), y: [22.15, 22.45, 21.25, 21.65] },
            { x: new Date(2024, 0, 4), y: [21.65, 23.10, 21.45, 22.85] },
            { x: new Date(2024, 0, 5), y: [22.85, 23.55, 22.65, 23.25] },
            { x: new Date(2024, 0, 8), y: [23.25, 23.80, 22.90, 23.15] },
            { x: new Date(2024, 0, 9), y: [23.15, 23.35, 22.45, 22.80] },
            { x: new Date(2024, 0, 10), y: [22.80, 23.25, 22.15, 22.35] },
            { x: new Date(2024, 0, 11), y: [22.35, 22.90, 21.95, 22.75] },
            { x: new Date(2024, 0, 12), y: [22.75, 23.45, 22.55, 23.15] },
            { x: new Date(2024, 0, 15), y: [23.15, 23.75, 22.85, 23.50] },
            { x: new Date(2024, 0, 16), y: [23.50, 24.00, 23.30, 23.85] },
            { x: new Date(2024, 0, 17), y: [23.85, 24.25, 23.65, 24.10] },
            { x: new Date(2024, 0, 18), y: [24.10, 24.50, 23.90, 24.35] },
            { x: new Date(2024, 0, 19), y: [24.35, 24.85, 24.15, 24.65] },
            { x: new Date(2024, 0, 22), y: [24.65, 25.10, 24.45, 24.90] },
            { x: new Date(2024, 0, 23), y: [24.90, 25.35, 24.70, 25.15] },
            { x: new Date(2024, 0, 24), y: [25.15, 25.60, 24.95, 25.40] },
            { x: new Date(2024, 0, 25), y: [25.40, 25.85, 25.20, 25.65] },
            { x: new Date(2024, 0, 26), y: [25.65, 26.10, 25.45, 25.90] },
            { x: new Date(2024, 0, 29), y: [25.90, 26.35, 25.70, 26.15] },
            { x: new Date(2024, 0, 30), y: [26.15, 26.60, 25.95, 26.40] },
            { x: new Date(2024, 0, 31), y: [26.40, 26.85, 26.20, 26.65] },
            { x: new Date(2024, 1, 20), y: [26.65, 27.10, 26.45, 26.90] },
            { x: new Date(2024, 1, 2), y: [26.90, 27.35, 26.70, 27.15] },
            { x: new Date(2024, 1, 5), y: [27.15, 27.60, 26.95, 27.40] },
            { x: new Date(2024, 1, 6), y: [27.40, 27.85, 27.20, 27.65] },
            { x: new Date(2024, 1, 7), y: [27.65, 28.10, 27.45, 27.90] },
            { x: new Date(2024, 1, 8), y: [27.90, 28.35, 27.70, 28.15] },
            { x: new Date(2024, 1, 9), y: [28.15, 28.60, 27.95, 28.40] },
            { x: new Date(2024, 1, 12), y: [28.40, 28.85, 28.20, 28.65] },
            { x: new Date(2024, 1, 13), y: [28.65, 29.10, 28.45, 28.90] },
            { x: new Date(2024, 1, 14), y: [28.90, 29.35, 28.70, 29.15] },
            { x: new Date(2024, 1, 15), y: [29.15, 29.60, 28.95, 29.40] },
            { x: new Date(2024, 1, 16), y: [29.40, 29.85, 29.20, 29.65] },
            { x: new Date(2024, 1, 19), y: [29.65, 30.10, 29.45, 29.90] },
            { x: new Date(2024, 1, 20), y: [29.90, 30.35, 29.70, 30.15] },
            { x: new Date(2024, 1, 21), y: [30.15, 30.60, 29.95, 30.40] },
            { x: new Date(2024, 1, 22), y: [30.40, 30.85, 30.20, 30.65] },
            { x: new Date(2024, 1, 23), y: [30.65, 31.10, 30.45, 30.90] },
            { x: new Date(2024, 1, 26), y: [30.90, 31.35, 30.70, 31.15] },
            { x: new Date(2024, 1, 27), y: [31.15, 31.60, 30.95, 31.40] },
            { x: new Date(2024, 1, 28), y: [31.40, 31.85, 31.20, 31.65] },
            { x: new Date(2024, 1, 29), y: [31.65, 32.10, 31.45, 31.90] },
            { x: new Date(2024, 2, 1), y: [31.90, 32.35, 31.70, 32.15] },
            { x: new Date(2024, 2, 4), y: [32.15, 32.60, 31.95, 32.40] },
            { x: new Date(2024, 2, 5), y: [32.40, 32.85, 32.20, 32.65] },
            { x: new Date(2024, 2, 6), y: [32.65, 33.10, 32.45, 32.90] },
            { x: new Date(2024, 2, 7), y: [32.90, 33.35, 32.70, 33.15] },
            { x: new Date(2024, 2, 8), y: [33.15, 33.60, 32.95, 33.40] }
        ]
    }];

    const options = {
        chart: {
            type: 'candlestick',
            height: 400,
            background: '#000000',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800
            }
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#26A69A',
                    downward: '#EF5350'
                },
                wick: {
                    useFillColor: true,
                }
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: '#ffffff'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#ffffff'
                }
            }
        },
        grid: {
            borderColor: '#ffffff',
            strokeDashArray: 0,
            position: 'back'
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            style: {
                fontSize: '12px',
                fontFamily: undefined
            }
        },
        stroke: {
            width: 2,
            colors: ['#ffffff']
        }
    };

    const timeframes = ['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y', '5Y', 'ALL'];

    return (
        <Box p={5}>
            <Flex direction="column" gap={4}>
                <Box
                    border="2px solid #ffffff"
                    borderRadius="4px"
                    p={4}
                >
                    <Text
                        fontSize="24px"
                        fontWeight="bold"
                        color="white"
                        textAlign="center"
                        mb={4}
                    >
                        IMPLIED VOLATILITY CHART
                    </Text>
                    <Flex justify="flex-end" gap={2} mb={4}>
                        {timeframes.map((tf) => (
                            <Button
                                key={tf}
                                size="sm"
                                variant={timeframe === tf ? 'solid' : 'outline'}
                                colorScheme="blue"
                                onClick={() => setTimeframe(tf)}
                            >
                                {tf}
                            </Button>
                        ))}
                    </Flex>
                    <ReactApexChart 
                        options={options} 
                        series={[{ ...series[0], data: getFilteredData() }]} 
                        type="candlestick" 
                        height={400}
                    />
                </Box>
            </Flex>
        </Box>
    );
};

export default ImpliedVolatilityChart;
