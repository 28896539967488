import {
  Box,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Text,
  Button,
  Input,
} from "@chakra-ui/react";

const RiskManagementComponent = () => {
  return (
    <Box bg="black" p={4} borderRadius={8}>
      <Heading color="#ffce30" fontSize="2xl" mb="4" fontWeight="bold">Risk Management</Heading>
      <Flex direction="column" gap={4}>

        <Text >Stop-loss</Text>
        <HStack spacing={3}>
          <Checkbox colorScheme="yellow" marginBottom={'11px'}></Checkbox>
          <Text color="#ffce30">Fixed Percentage:</Text>
          <Text>Fixed Percentage drops by:</Text>
          <Input borderColor="#ffce30" value={'1%'} width="60px" />
          <Text>below the entry point.</Text>
        </HStack>

        <HStack spacing={3}>
          <Checkbox colorScheme="yellow" marginBottom={'11px'}></Checkbox>
          <Text color="#ffce30">Trailing Stop:</Text>
          <Text>Selling if the price falls <Input borderColor="#ffce30" value={'1%'} width="60px" /> from its peak.</Text>
        </HStack>

        <Text>Profit-Taking</Text>
        <HStack spacing={3}>
          <Checkbox colorScheme="yellow" marginBottom={'11px'}></Checkbox>
          <Text color="#ffce30">Target Price:</Text>
          <Text>Sell when the asset reaches <Input borderColor="#ffce30" value={'1%'} width="60px" /> price target.</Text>
        </HStack>

        <HStack spacing={3}>
          <Checkbox colorScheme="yellow" marginBottom={'11px'}></Checkbox>
          <Text color="#ffce30">Percentage Gain:</Text>
          <Text>Exit positions after achieving <Input borderColor="#ffce30" value={'1%'} width="60px" /> percentage gain.</Text>
        </HStack>

      </Flex>

      <Box width="100%" display="flex" justifyContent="center" mt={4}>
        <Button bgColor="#ffce30" color="black" fontWeight="bold" size="lg" fontSize="xl">
          Apply Conditions
        </Button>
      </Box>
    </Box>

  );
};

export default RiskManagementComponent;