import React from 'react';
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box,
    Button,
    Flex, Input, InputGroup, InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer, Tbody, Td, Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";

const TopSectorPlayersTable = () => {
    const topSectorPlayers = [
        {
            category: "Telecommunication",
            companies: [
                {name: 'Itissalat Al-Maghrib', marketCap: 130, revenue: 40, peRatio: 15.2, dividendYield: '4.5%'}
            ]
        },
        {
            category: "banking",
            companies: [
                {name: 'Attijari wafa bank', marketCap: 130, revenue: 40, peRatio: 15.2, dividendYield: '4.5%'},
                {name: 'Banque central popular', marketCap: 70, revenue: 20, peRatio: 18, dividendYield: '3.8%'},
                {name: 'BMCE Bank', marketCap: 50, revenue: 15, peRatio: 16, dividendYield: '3.5%'}
            ]
        },
        {
            category: "Consumer goods",
            companies: [
                {name: 'LafarageHolcim maroc', marketCap: 130, revenue: 40, peRatio: 15.2, dividendYield: '4.5%'},
                {name: 'Ciment du Maroc', marketCap: 70, revenue: 20, peRatio: 18, dividendYield: '3.8%'},
                {name: 'Cosumar', marketCap: 50, revenue: 15, peRatio: 16, dividendYield: '3.5%'}
            ]
        }
    ];
    return (
        <div>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Top Sector Players
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex flexWrap={{base: "wrap", md: "nowrap"}} justifyContent={{base: "center", md: "flex-start"}}>
                    <Menu>
                        <MenuButton as={Button}
                                    height={'35px'}
                                    bgColor="#161616"
                                    color="white"
                                    m={"0 15px 15px 7px"}
                                    border={"1px solid #999999"}
                                    _hover={{bgColor: "#242424"}}
                                    rightIcon={<FiChevronDown style={{marginLeft: "20px"}}/>}
                                    _focus={{bgColor: "#242424"}}
                        >
                            Date range
                        </MenuButton>
                        <MenuList bgColor="#121212" borderColor={"transparent"}>
                            <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Metric 1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Metric 2</MenuItem>
                        </MenuList>
                    </Menu>
                    <Flex flex={1} justifyContent={'end'} mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        <InputGroup width={'50%'}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th width={'20%'}>Sector</Th>
                                <Th width={'20%'}>Market Cap (MAD)</Th>
                                <Th width={'20%'}>Revenue (MAD)</Th>
                                <Th width={'20%'}>P/E Ratio</Th>
                                <Th width={'20%'}>Dividend Yield</Th>
                            </Tr>
                        </Thead>
                    </Table>
                </TableContainer>
                <Accordion defaultIndex={[0]} allowMultiple maxH={'400px'} overflow={'hidden'} overflowY={"scroll"}>
                    {topSectorPlayers.map(sector =>
                        <AccordionItem>
                            <AccordionButton>
                                <AccordionIcon/>
                                <Box as='span' flex='1' textAlign='left' color={'#ffce30'}>
                                    {sector.category}
                                </Box>
                            </AccordionButton>
                            {sector.companies.map((company, index) =>
                                <AccordionPanel p={0}>
                                    <Table>
                                        <Tbody>
                                            <Tr key={index}>
                                                <Td width={'20%'}>{company.name}</Td>
                                                <Td width={'20%'}>{company.marketCap} billion</Td>
                                                <Td width={'20%'}>{company.revenue} billion</Td>
                                                <Td width={'20%'}>{company.peRatio}</Td>
                                                <Td width={'20%'}>{company.dividendYield}</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </AccordionPanel>
                            )}
                        </AccordionItem>
                    )}
                </Accordion>
            </Box>
        </div>
    );
};

export default TopSectorPlayersTable;