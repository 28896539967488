import React, {useMemo, useState} from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {HiMenuAlt2} from "react-icons/hi";
import {FiChevronDown, FiChevronLeft, FiChevronRight, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";
import {TbCaretUpDownFilled} from "react-icons/tb";

const rtCurrencies = [
    {
        pair: "USD/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "EUR/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "MAD/ZAR",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "MAD/NGN",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "MAD/RES",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "EUR/ZAR",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "USD/NGN",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "GBP/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "MAD/RES",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "USD/NGN",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "GBP/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "EUR/ZAR",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "MAD/ZAR",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "EUR/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "USD/NGN",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "GBP/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },

    {
        pair: "GBP/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "MAD/RES",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "USD/NGN",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "GBP/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "EUR/ZAR",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "MAD/ZAR",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "EUR/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "USD/NGN",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    },
    {
        pair: "GBP/MAD",
        techRating: "Strong Buy",
        maRating: 3.3,
        osRating: 3.3,
        rsi: 3.3,
        mom: 3.3,
        ao: 3.3,
        cci: 3.3,
        stochK: 3.3,
        stochD: 3.3,
        macdLevel: 3.3,
        macdSignal: 3.3
    }
];

const TechnicalRealTimeCurrencyTable = () => {
    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Calculate total pages
    const totalPages = Math.ceil(rtCurrencies.length / rowsPerPage);

    // Get current currencies for the current page
    const currentCurrencies = useMemo(() =>
            rtCurrencies.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
        [currentPage, rowsPerPage]);

    // Function to get page numbers for pagination
    const getPageNumbers = () => {
        if (totalPages <= 4) return Array.from({ length: totalPages }, (_, i) => i + 1);
        if (currentPage <= 2) return [1, 2, 3, 4];
        if (currentPage >= totalPages - 1) return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        return [currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    };

    // Helper function to render menu buttons
    const renderMenuButton = (text, icon) => (
        <MenuButton as={Button}
                    height='35px'
                    bgColor="#161616"
                    color="white"
                    m="0 15px 0 7px"
                    border="1px solid #303235"
                    _hover={{ bgColor: "#242424" }}
                    rightIcon={icon}
                    _focus={{ bgColor: "#242424" }}
                    fontSize="12px"
        >
            {text}
        </MenuButton>
    );

    return (
        <div>
            {/* Header */}
            <Flex bg="#121212" justifyContent="center" w='100%' h="40px" p={2} mt="40px" fontSize="18px" color='white' position="relative">
                <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                    Real-Time currency quotes
                    <Box position="absolute" right="10px">
                        <HiMenuAlt2 />
                    </Box>
                </Flex>
            </Flex>
            {/* Filter and search section */}
            <Box bg="#171717" w='100%' p={2} fontSize="18px" color='white'>
                <Flex>
                    {/* Date range filter */}
                    <Menu>
                        {renderMenuButton('Date range', <FiChevronDown style={{ marginLeft: "20px" }} />)}
                        <MenuList bgColor="#121212" borderColor="transparent">
                            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 2</MenuItem>
                        </MenuList>
                    </Menu>

                    {/* Additional filter and search */}
                    <Flex flex={1} justifyContent='end' mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height='35px'
                                bgColor="#161616"
                                color="white"
                                borderWidth="1px 0 1px 1px"
                                borderRadius="5px 0 0px 5px"
                                borderColor="#303235"
                                _hover={{ bgColor: "#242424" }}
                                rightIcon={<FiChevronDown />}
                                _focus={{ bgColor: "#242424" }}
                            >
                                <Flex>
                                    <Box m="5px 10px 0 0" fontSize='12px'>
                                        <FiFilter fill="white" />
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#303235" borderColor="transparent" minWidth='100px'>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        {/* Search input */}
                        <InputGroup width='300px'>
                            <InputLeftElement pb={1}>
                                <FaSearch />
                            </InputLeftElement>
                            <Input
                                type="text"
                                height='35px'
                                placeholder="Search"
                                _placeholder={{ color: '#fff', opacity: 0.7 }}
                                _focus={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: 'rgba(119,119,119,0.5)'
                                }}
                                _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                borderColor="#303235"
                                borderWidth="1px"
                                borderRadius="0px 5px 5px 0px"
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            {/* Currencies table */}
            <Box bg="#121212" w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color='white' className="table-header">
                                {['Currency Pair', 'Tech rating', 'MA rating', 'OS rating', 'RSI', 'MOM', 'AO', 'CCI', 'Stoch %K', 'Stoch %D', 'MACD Level', 'MACD Signal'].map(header => (
                                    <Th key={header}>
                                        <Flex alignItems='center' gap={1}>{header} <TbCaretUpDownFilled /></Flex>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentCurrencies.map(rtCurrency => (
                                <Tr key={rtCurrency.date}>
                                    {Object.entries(rtCurrency).map(([key, value], index) => (
                                        <Td key={index}>
                                            {value}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                {/* Pagination */}
                <Flex justifyContent="center" alignItems="center" pt={4} pb={4}>
                    <Button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        isDisabled={currentPage === 1}
                        mr={2}
                        bgColor="#161616"
                        color="white"
                        _hover={{ bgColor: "#242424" }}
                        leftIcon={<FiChevronLeft />}
                    >
                        Previous
                    </Button>
                    {getPageNumbers().map(pageNumber => (
                        <Box
                            key={pageNumber}
                            as="button"
                            onClick={() => setCurrentPage(pageNumber)}
                            mx={1}
                            px={3}
                            py={2}
                            borderRadius="md"
                            bg={currentPage === pageNumber ? "#F8CD20" : "#121212"}
                            color={currentPage === pageNumber ? "black" : "white"}
                            fontWeight={currentPage === pageNumber ? "bold" : "normal"}
                            _hover={{ bg: currentPage === pageNumber ? "#F8CD20" : "#242424" }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            minWidth="32px"
                        >
                            {pageNumber}
                        </Box>
                    ))}
                    <Button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        isDisabled={currentPage === totalPages}
                        ml={2}
                        mr={4}
                        bgColor="#161616"
                        color="white"
                        _hover={{ bgColor: "#242424" }}
                        rightIcon={<FiChevronRight />}
                    >
                        Next
                    </Button>
                    {/* Rows per page selector */}
                    <Box as="span" ml={10} mr={2} color="white">
                        Result Per Page:
                    </Box>
                    <Select
                        value={rowsPerPage}
                        onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        width="70px"
                        bgColor="#161616"
                        color="white"
                        borderColor="#303235"
                        _hover={{ borderColor: "rgba(119,119,119,0.5)" }}
                        _focus={{ borderColor: "rgba(119,119,119,0.5)", boxShadow: "none" }}
                        sx={{
                            "& option": {
                                backgroundColor: "black",
                                color: "white",
                                border: "none"
                            },
                            "& > option": {
                                border: "none"
                            }
                        }}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </Select>
                </Flex>
            </Box>
        </div>
    )
};

export default TechnicalRealTimeCurrencyTable;