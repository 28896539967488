import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

const CandlestickChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'candlestick',
      height: 350,
      background: '#000000',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false
      }
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        formatter: (value) => value.toFixed(0)
      }
    },
    grid: {
      borderColor: '#333333'
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: '#00B746',
          downward: '#EF403C'
        }
      }
    },
    tooltip: {
      theme: 'dark'
    }
  });

  const [series, setSeries] = useState([{
    data: [
      { x: new Date(2024, 1, 8, 6, 0), y: [15400, 15430, 15380, 15410] },
      { x: new Date(2024, 1, 8, 12, 0), y: [15410, 15450, 15390, 15440] },
      { x: new Date(2024, 1, 8, 18, 0), y: [15440, 15470, 15420, 15430] },
      { x: new Date(2024, 1, 9, 6, 0), y: [15430, 15460, 15280, 15320] },
      { x: new Date(2024, 1, 9, 12, 0), y: [15320, 15400, 15310, 15380] },
      { x: new Date(2024, 1, 9, 18, 0), y: [15380, 15520, 15370, 15510] },
      { x: new Date(2024, 1, 10, 6, 0), y: [15510, 15560, 15490, 15540] },
      { x: new Date(2024, 1, 10, 12, 0), y: [15540, 15570, 15510, 15530] },
      { x: new Date(2024, 1, 10, 18, 0), y: [15530, 15560, 15410, 15416] }
    ]
  }]);

  const periods = ['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y', '5Y', 'All'];

  return (
    <Box bg="#000000" p="20px">
      <Flex justifyContent="space-between" mb="10px">
        <Box>
          {periods.map((period, index) => (
            <Button
              key={index}
              variant="ghost"
              color="white"
              mr="10px"
              _hover={{ bg: 'transparent' }}
            >
              {period}
            </Button>
          ))}
        </Box>
        <Text color="white">09:27:02 (GMT +1) % log auto</Text>
      </Flex>
      <ReactApexChart options={chartOptions} series={series} type="candlestick" height={350} />
    </Box>
  );
};

export default CandlestickChart;