import React from 'react'
import { Box } from '@chakra-ui/react'
import BetaRiskFactorChart from '../BetaRiskFactorChart'
import CompanyTable from '../CompanyTable'

// RiskComponent: Renders the risk analysis section of the portfolio management interface
function RiskComponent() {
  return (
    <div>
        {/* Container for risk analysis components */}
        <Box width="100%" p={4}>
            {/* Chart displaying beta risk factors */}
            <BetaRiskFactorChart />

            {/* Table showing company-specific risk data */}
            <CompanyTable />
        </Box>
    </div>
  )
}

export default RiskComponent