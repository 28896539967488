// Import required dependencies
import React from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";

// Component that displays balance sheet assets in a table format
const BalanceSheetAssetsTable = () => {
    // Sample data for balance sheet assets
    const balanceSheetAssets = [
        {
            item: "Total Assets",
            fy1: 40,
            revenuePercentage1: 250,
            fy2: 15.2,
            revenuePercentage2: 4.5,
            change: 4.5
        },
        {
            item: "Cash and Cash Equivalents",
            fy1: 20,
            revenuePercentage1: 70,
            fy2: 18.0,
            revenuePercentage2: 3.8,
            change: 3.8
        },
        {
            item: "Loans and Advances",
            fy1: 15,
            revenuePercentage1: 50,
            fy2: 16.0,
            revenuePercentage2: 3.5,
            change: 3.5
        },
        {
            item: "Investments",
            fy1: 40,
            revenuePercentage1: 130,
            fy2: 15.2,
            revenuePercentage2: 4.5,
            change: 4.5
        },
        {
            item: "Other Assets",
            fy1: 20,
            revenuePercentage1: 70,
            fy2: 18.0,
            revenuePercentage2: 3.8,
            change: 3.8
        }
    ];

    return (
        <div>
            {/* Header section */}
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} fontSize={"18px"} color='white'>
                Balance sheet assets
            </Box>

            {/* Filter and search section */}
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex flexWrap={{base: "wrap", md: "nowrap"}} justifyContent={{base: "center", md: "end"}} gap={{base: "10px", md: "0"}}>
                    {/* Date range dropdown */}
                    <Menu>
                        <MenuButton as={Button}
                                    height={'35px'}
                                    bgColor="#161616"
                                    color="white"
                                    m={{base: "0 37px 0 35px", md: "0 15px 0 7px"}}
                                    border={"1px solid #999999"}
                                    _hover={{bgColor: "#242424"}}
                                    rightIcon={<FiChevronDown style={{marginLeft: "20px"}}/>}
                                    _focus={{bgColor: "#242424"}}
                                    width={{base: '60%', md: 'auto'}}
                        >
                            Date range
                        </MenuButton>
                        <MenuList bgColor="#121212" borderColor={"transparent"}>
                            <MenuItem bgColor="#121212" _hover={{bgColor:"#242424"}}>range 1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{bgColor:"#242424"}}>range 2</MenuItem>
                        </MenuList>
                    </Menu>
                    <Flex flex={1} justifyContent={{base: 'center', md: 'end'}} mr={10}>
                        {/* Filter dropdown */}
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        {/* Search input */}
                        <InputGroup width={{base: '60%', md: '300px'}}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>

            {/* Table section */}
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        {/* Table header */}
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>ITEM</Th>
                                <Th>FY 2022 (MAD MILLIONS)</Th>
                                <Th>% OF REVENUE 2022</Th>
                                <Th>FY 2023 (MAD MILLIONS)</Th>
                                <Th>% OF REVENUE 2023</Th>
                                <Th>% CHANGE 2022-2023</Th>
                            </Tr>
                        </Thead>
                        {/* Table body */}
                        <Tbody>
                            {balanceSheetAssets.map((balance, index) => (
                                <Tr key={index}>
                                    <Td fontWeight={balance.item === "Total Assets" ? "bold" : "normal"}>{balance.item}</Td>
                                    <Td>{balance.fy1} billion</Td>
                                    <Td>{balance.revenuePercentage1} billion</Td>
                                    <Td>{balance.fy2}</Td>
                                    <Td>{balance.revenuePercentage2}%</Td>
                                    <Td>{balance.change}%</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default BalanceSheetAssetsTable;