import React from 'react';
import {
    Box,
    Button,
    Flex, Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Table, TableContainer, Tbody, Td,
    Text, Th, Thead, Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";

const CurrentRatioTable = () => {
    const currentRatioData = [
        {year: 2023, q1: 1.2, q2: 1.3, q3: 1.1, q4: 1.2},
        {year: 2022, q1: 1.2, q2: 1.3, q3: 1.1, q4: 1.2},
        {year: 2021, q1: 1.2, q2: 1.3, q3: 1.1, q4: 1.2},
        {year: 2020, q1: 1.2, q2: 1.3, q3: 1.1, q4: 1.2},
        {year: 2019, q1: 1.2, q2: 1.3, q3: 1.1, q4: 1.2}
    ]
    return (
        <div>
            <Box mt={20}>
                <Text fontSize={'24px'} color={'#ffce30'}>Current ratio</Text>
                <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} fontSize={"18px"} color='white'>
                    Current ratio historical data
                </Box>
                <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                    <Flex>
                        <Menu>
                            <MenuButton as={Button}
                                        height={'35px'}
                                        bgColor="#161616"
                                        color="white"
                                        m={"0 15px 0 7px"}
                                        border={"1px solid #999999"}
                                        _hover={{bgColor: "#242424"}}
                                        rightIcon={<FiChevronDown style={{marginLeft: "20px"}}/>}
                                        _focus={{bgColor: "#242424"}}
                            >
                                Date range
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"}>
                                <MenuItem bgColor="#121212" _hover={{bgColor:"#242424"}}>Metric 1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor:"#242424"}}>Metric 2</MenuItem>
                            </MenuList>
                        </Menu>
                        <Flex flex={1} justifyContent={'end'} mr={10}>
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    height={'35px'}
                                    bgColor="#161616"
                                    color="white"
                                    borderWidth={"1px 0 1px 1px"}
                                    borderRadius={"5px 0 0px 5px"}
                                    borderColor={"rgba(119,119,119,0.5)"}
                                    _hover={{bgColor: "#242424"}}
                                    rightIcon={<FiChevronDown/>}
                                    _focus={{bgColor: "#242424"}}
                                >
                                    <Flex>
                                        <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                            <FiFilter fill={"white"}/>
                                        </Box>
                                        All
                                    </Flex>
                                </MenuButton>
                                <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                    <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                                    <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                                </MenuList>
                            </Menu>
                            <InputGroup width={'300px'}>
                                <InputLeftElement pb={1}>
                                    <FaSearch/>
                                </InputLeftElement>
                                <Input type="text" height='35px' placeholder="Search"
                                       _placeholder={{color: '#fff', opacity: 0.7}}
                                       _focus={{
                                           outline: 'none',
                                           boxShadow: 'none',
                                           borderColor: 'rgba(119,119,119,0.5)'
                                       }}
                                       _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                       borderColor={"rgba(119,119,119,0.5)"}
                                       borderWidth="1px"
                                       borderRadius={"0px 5px 5px 0px"}
                                />
                            </InputGroup>
                        </Flex>
                    </Flex>
                </Box>
                <Box bg={"#121212"} w='100%' color='white'>
                    <TableContainer>
                        <Table variant='simple'>
                            <Thead>
                                <Tr color={'white'} className="table-header">
                                    <Th>Year</Th>
                                    <Th>Q1</Th>
                                    <Th>Q2</Th>
                                    <Th>Q3</Th>
                                    <Th>Q4</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentRatioData.map((ratio, index) =>
                                    <Tr key={index}>
                                        <Td>{ratio.year}</Td>
                                        <Td>{ratio.q1}</Td>
                                        <Td>{ratio.q2}</Td>
                                        <Td>{ratio.q3}</Td>
                                        <Td>{ratio.q4}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </div>
    );
};

export default CurrentRatioTable;