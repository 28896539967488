import React, { useState } from 'react';
import YieldCurveChart from "../YieldCurveChart";
import YieldHeatmap from "../YieldHeatmap";

const YieldAnalysisComponent = () => {
    return (
        <>
            {/* <YieldCurveChart/> */}
            <YieldHeatmap/>
        </>
    );
};

export default YieldAnalysisComponent;