import React from 'react';
import {Box} from "@chakra-ui/react";
import EconomicCalendar from "../../../dashboardComponents/EconomicCalendar";

const EconomicCalendarTable = () => {
    return (
        <Box width={"100%"}>
            <EconomicCalendar/>
        </Box>
    );
};

export default EconomicCalendarTable;