import React from 'react';
import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, TableContainer } from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';

const topNewsData = [
    { title: 'Retail Sales Slump Takes Toll on Markets', time: '10 min ago' },
    { title: 'Tech Giant’s Earnings Soar, Stock Hits Record High', time: '2 hrs ago' },
    { title: 'High-Profile IPO Falls Short of Expectations', time: '12 hrs ago' },
    { title: 'Electric Vehicle Stocks Skyrocket Amid New Policies', time: '1 day ago' },
    { title: 'Market Sentiment Turns Bearish, Analysts Predict Downtrend', time: '2 days ago' },
    { title: 'Chip Shortage Woes Continue, Tech Firms Struggle', time: '3 days ago' },
];

const topGainersData = [
    { symbol: 'ADH', name: 'Douja Prom Addoha', price: '24.46', change: '+5.00%' },
    { symbol: 'ADI', name: 'Alliances', price: '80', change: '+3.84%' },
    { symbol: 'GAZ', name: 'Afriquia Gaz', price: '435', change: '+5.85%' },
    { symbol: 'ATW', name: 'Attijariwafa Bank', price: '436', change: '+9.54%' },
    { symbol: 'ATH', name: 'Auto Hall', price: '123', change: '+4.90%' },
    { symbol: 'BCP', name: 'Banque Centrale Populaire', price: '123', change: '+18.94%' },
];

const topLosersData = [
    { symbol: 'IAM', name: 'Maroc Telecom', price: '130.00', change: '-3.50%' },
    { symbol: 'ADH', name: 'Addoha', price: '58.00', change: '-4.20%' },
    { symbol: 'CDM', name: 'Crédit du Maroc', price: '510.00', change: '-2.90%' },
    { symbol: 'SNEP', name: 'SNEP', price: '540.00', change: '-3.00%' },
    { symbol: 'HPS', name: 'HPS', price: '650.00', change: '-2.30%' },
    { symbol: 'MSA', name: 'Mutandis SCA', price: '250.00', change: '-2.85%' },
];

const TopGainersLosers = () => {
    return (
        <Box m={'auto'} pb={"60px"}>
            <Flex direction={{base: "column", lg: "row"}} gap={4}>
                {/*Top Gainers*/}
                <Box flex="1" mt={5}>
                    <Box color="white">
                        <Flex justify="space-between" align="center" bg="#121212" p="15px 0 0 20px">
                            <Text fontSize="lg" fontWeight="bold">
                                Top Gainers
                            </Text>
                            <IconButton icon={<FiMoreVertical />} aria-label="More options" bg="#121212" _hover={{ bg: 'gray.600' }} />
                        </Flex>
                        <Box bgColor="#0A0A0A">
                            <TableContainer>
                                <Table variant="simple" colorScheme="whiteAlpha" fontSize="12px" width={"100%"}>
                                    <Thead display={"block"}>
                                        <Tr fontSize={'12px'}>
                                            <Th color={'white'} width={"33%"}>Symbole</Th>
                                            <Th color={'white'} width={"33%"}>Name</Th>
                                            <Th color={'white'} width={"33%"}>Price</Th>
                                            <Th color={'white'} >Change</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody display={"block"} height="300px" overflowY="auto">
                                        {topGainersData.map((topGainer, index) => (
                                            <Tr key={index}>
                                                <Td width={"33%"}>{topGainer.symbol}</Td>
                                                <Td width={"33%"}>{topGainer.name}</Td>
                                                <Td width={"33%"}>{topGainer.price}</Td>
                                                <Td color={topGainer.change.startsWith('+') ? 'green.500' : 'red.500'}>{topGainer.change}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
                {/*Top Losers*/}
                <Box flex="1" mt={5}>
                    <Box color="white">
                        <Flex justify="space-between" align="center" bg="#121212" p="15px 0 0 20px">
                            <Text fontSize="lg" fontWeight="bold">
                                Top Losers
                            </Text>
                            <IconButton icon={<FiMoreVertical />} aria-label="More options" bg="#121212" _hover={{ bg: 'gray.600' }} />
                        </Flex>
                        <Box bgColor="#0A0A0A">
                            <TableContainer>
                                <Table variant="simple" colorScheme="whiteAlpha" fontSize="12px" width={"100%"}>
                                    <Thead display={"block"}>
                                        <Tr fontSize={'12px'}>
                                            <Th color={'white'} width={"33%"}>Symbole</Th>
                                            <Th color={'white'} width={"33%"}>Name</Th>
                                            <Th color={'white'} width={"33%"}>Price</Th>
                                            <Th color={'white'}>Change</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody display={"block"} height="300px" overflowY="auto">
                                        {topLosersData.map((topLoser, index) => (
                                            <Tr key={index}>
                                                <Td width={"33%"}>{topLoser.symbol}</Td>
                                                <Td width={"33%"}>{topLoser.name}</Td>
                                                <Td width={"33%"}>{topLoser.price}</Td>
                                                <Td color={topLoser.change.startsWith('+') ? 'green.500' : 'red.500'}>{topLoser.change}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Flex>
            {/*Top News*/}
            <Box mt={5}>
                <Box flex="1" color="white">
                    <Flex justify="space-between" align="center" bg="#121212" p="15px 0 0 20px">
                        <Text fontSize="lg" fontWeight="bold">
                            Top News
                        </Text>
                        <IconButton icon={<FiMoreVertical />} aria-label="More options" bg="#121212" _hover={{ bg: 'gray.600' }} />
                    </Flex>
                    <Box bgColor="#0A0A0A">
                        <TableContainer>
                            <Table variant="simple" colorScheme="whiteAlpha" fontSize="12px" display="block" height="300px" overflowY="scroll">
                                <Thead>
                                    <Tr>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {topNewsData.map((news, index) => (
                                        <Tr key={index}>
                                            <Td maxW="50%" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">{news.title}</Td>
                                            <Td width={'100%'} textAlign='end'>{news.time}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TopGainersLosers;
