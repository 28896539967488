import React, { useState } from 'react'
import { Flex, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import CallOptionComponent from '../CallOptionComponent'
import PutOptionComponent from '../PutOptionComponent'
import OptionChainsComponent from '../OptionChainsComponent'
import ImpliedVolatilityChart from '../ImpliedVolatilityChart'
import BrushChartComponent from '../BrushChartComponent'
import PutCallRatioComponent from '../PutCallRatioComponent'

function OptionsComponent() {
    return (
        <div>
            <Tabs variant="enclosed">
                <TabList className="tab-list" m={"auto"} justifyContent={"center"} fontSize={"24px"}>
                    <Tab _selected={{ color: "#ffce30" }}>Overview</Tab>
                    <Tab _selected={{ color: "#ffce30" }}>Call Options</Tab>
                    <Tab _selected={{ color: "#ffce30" }}>Put Options</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Flex direction="column" gap="40px">
                            <CallOptionComponent />
                            <PutOptionComponent /> 
                            <OptionChainsComponent />
                            <ImpliedVolatilityChart />
                            <BrushChartComponent />
                            <PutCallRatioComponent />
                        </Flex>
                    </TabPanel>

                    <TabPanel>
                        <Flex direction="column" gap="40px">
                            <CallOptionComponent />
                        </Flex>
                    </TabPanel>

                    <TabPanel>
                        <Flex direction="column" gap="40px">
                            <PutOptionComponent />
                        </Flex>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    )
}

export default OptionsComponent