import React from 'react';
import {Box, Select, Text} from "@chakra-ui/react";

const MonteCarloSimulation = () => {
    return (
        <div>
            <Box marginTop={8}>
                <Text color={'#ffce30'} fontSize={'24px'} fontWeight={'bold'}>Monte Carlo Simulation</Text>
                <Box>
                    <Text fontSize={'18px'}>Description</Text>
                    <Text>
                        A computational algorithm that relies on repeated random sampling to obtain numerical results.
                    </Text>
                    <Text fontSize={'18px'}>Purpose</Text>
                    <Text>
                        Simulating various market scenarios for risk assessment, option pricing, and portfolio performance evaluation. Crucial for stress testing trading strategies and estimating Value at Risk (VaR).
                    </Text>
                </Box>
            </Box>
            <Box>
                <div className="filter-bar d-flex">
                    <div className="element">
                        <Select className="dropdown-select" width="auto">
                            <option selected hidden disabled value="">Select Company</option>
                            <option>Company 1</option>
                            <option>Company 2</option>
                        </Select>
                    </div>
                </div>
            </Box>
            <Box marginTop={10} height={'300px'} bgColor={'#1f1f1f'} borderRadius={'10px'}>
            </Box>
        </div>
    );
};

export default MonteCarloSimulation;