import React from 'react';
import {Utils} from "../../../../../../utils/Utils";
import {Line} from "react-chartjs-2";
import {Box, Text} from "@chakra-ui/react";

const CurrentRatioTrendChart = () => {
    const legendMargin = {
        id: 'legendMargin',
        beforeInit(chart, legend, plugins) {
            const originalFit = chart.legend.fit;
            const margin = plugins.margin || 0;
            chart.legend.fit = function fit() {
                if (originalFit) {
                    originalFit.call(this)
                }
                return this.height += margin;
            }
        }
    }
    const labels = Utils.getYearsArray(2016, 2024)
    const currentRatioTrendData = {
        labels: labels,
        datasets: [{
            label: 'Legend 1',
            data: [2.5, 4.8, 2, 8, 6.5, 4, 6, 3, 0],
            fill: false,
            borderWidth: 2,
            borderColor: "#fff",
            backgroundColor: '#fff',
            tension: 0
        }]
    };
    const config = {
        type: 'line',
        data: currentRatioTrendData,
        plugins: [legendMargin]
    };
    const options = {
        responsive: true,
        plugins: {
            legendMargin: {
                margin: 15
            },
            legend: {
                align: 'start',
                labels: {
                    font: {
                        size: '10px'
                    },
                    usePointStyle: true,
                    pointStyle: 'circle',
                    boxWidth: 5,
                    boxHeight: 5,
                    color: 'white' // White text for legend labels
                }
            }
        },
        elements: {
            point: {
                radius: 2,
            }
        },
        scales: {
            y: {
                grid: {
                    color: '#515151',
                },
                border: {
                    dash: [4, 2]
                },
                beginAtZero: true,
                ticks: {
                    font: {
                        size: 10
                    },
                    color: 'white',
                    callback: (value) => value + '%',
                    stepSize: 5
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 10
                    },
                    color: 'white'
                }
            }
        }
    };
    return (
        <div>
            <Box w='100%' p={"40px"} color='white' height={"100%"}>
                <Box className="" width={'75%'} m={'auto'}>
                    <Box bgColor={'#151515'}>
                        <Box textAlign='center' backgroundColor={'#1f1f1f'} height='50px' pt={2} mb={2}>
                            <Text fontSize={'20px'}>Current ratio trend line chart</Text>
                        </Box>
                        <Box p={4}>
                            <Line height={'130px'} data={config.data} options={options} plugins={config.plugins}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default CurrentRatioTrendChart;