import React from 'react';
import {
    Box,Flex, Button, Grid, Input, Select, Text,
    Checkbox,Icon, VStack, Heading
} from '@chakra-ui/react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const MomentumComponent = () => {

    return (
        <Box p="8" bg="blackAlpha.900" color="white">
            <Text fontSize="2xl" mb="4" fontWeight="bold" color="#ffce30">Momentum Trading Strategy</Text>
            <Text fontSize="lg" mb="8">
                Momentum trading strategies seek to capitalize on the continuance of existing trends in<br />
                the market.This strategy involves buinig asstes that have shown an upward trend and <br />
                selling them when they appear to have peaked , or convesely, short-selling assets that <br />
                have shown a dowwnward trend and buying them back when they appear to have <br />
                bottomed out.
            </Text>
            <Text fontSize="2xl" mb="4" fontWeight="bold" color="#ffce30">Conditions</Text>

            <Grid templateColumns={{base: "1fr", md: "repeat(2, 1fr)"}} gap={10} p={8} bg="black" color="white">
                {/* Buy Section */}
                <Box>
                    <Heading size="lg" color="green.300">
                        Buy <Icon as={FaArrowUp} />
                    </Heading>
                    <VStack align="flex-start" mt={4} spacing={4}>
                        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={10}>
                            {/* Moving Averages (MA) */}
                            <Box>
                                <Text fontWeight="bold">Moving Averages (MA):</Text>
                                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                    <Box>
                                        <Text>Short term</Text>
                                        <Input defaultValue={50} width="60px" borderColor="#ffce30" />
                                    </Box>
                                    <Box>
                                        <Text>Long term</Text>
                                        <Input defaultValue={200} width="60px" borderColor="#ffce30" />
                                    </Box>
                                </Grid>
                            </Box>

                            {/* Relative Strength Index (RSI) */}
                            <Box>
                                <Text fontWeight="bold">Relative Strength Index (RSI):</Text>
                                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                    <Box>
                                        <Text>Below</Text>
                                        <Input defaultValue={30} width="60px" borderColor="#ffce30" />
                                    </Box>
                                    <Box></Box>
                                </Grid>
                            </Box>
                        </Grid>

                        {/* Momentum Indicator */}
                        <Box>
                            <Text fontWeight="bold">Momentum Indicator</Text>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                <Box>
                                    <Text>Current price</Text>
                                    <Input placeholder="Value" width="100px" borderColor="#ffce30" />
                                </Box>
                                <Box>
                                    <Text>Price n Periods Ago</Text>
                                    <Select width="100px" borderColor="#ffce30">
                                        <option>Days</option>
                                    </Select>
                                </Box>
                            </Grid>
                        </Box>

                        {/* Stochastic Oscillator */}
                        <Box>
                            <Text fontWeight="bold">Stochastic Oscillator:</Text>
                            <Input defaultValue={14} width="60px" borderColor="#ffce30" />
                            <span style={{ marginLeft: '8px' }}>Days</span>
                        </Box>

                        {/* Moving Average Convergence Divergence (MACD) */}
                        <Box>
                            <Text fontWeight="bold">Moving Average Convergence Divergence (MACD)</Text>
                            <Input placeholder="Value" width="100px" borderColor="#ffce30" />
                        </Box>
                    </VStack>
                </Box>

                {/* Sell Section */}
                <Box>
                    <Heading size="lg" color="red.300">
                        Sell <Icon as={FaArrowDown} />
                    </Heading>
                    <VStack align="flex-start" mt={4} spacing={4}>
                        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={10}>
                            <Box>
                                <Text fontWeight="bold">Moving Averages (MA):</Text>
                                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                    <Box>
                                        <Text>Short term</Text>
                                        <Input defaultValue={50} width="60px" borderColor="#ffce30" />
                                    </Box>
                                    <Box>
                                        <Text>Long term</Text>
                                        <Input defaultValue={200} width="60px" borderColor="#ffce30" />
                                    </Box>
                                </Grid>
                            </Box>

                            {/* RSI */}
                            <Box>
                                <Text fontWeight="bold">Relative Strength Index (RSI):</Text>
                                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                    <Box>
                                        <Text>Above</Text>
                                        <Input defaultValue={70} width="60px" borderColor="#ffce30" />
                                    </Box>
                                    <Box></Box> 
                                </Grid>
                            </Box>
                        </Grid>

                        <Box>
                            <Text fontWeight="bold">Momentum Indicator</Text>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                <Box>
                                    <Text>Current price</Text>
                                    <Input placeholder="Value" width="100px" borderColor="#ffce30" />
                                </Box>
                                <Box>
                                    <Text>Price n Periods Ago</Text>
                                    <Select width="100px" borderColor="#ffce30">
                                        <option>Days</option>
                                    </Select>
                                </Box>
                            </Grid>
                        </Box>

                        <Box>
                            <Text fontWeight="bold">Stochastic Oscillator:</Text>
                            <Input defaultValue={14} width="60px" borderColor="#ffce30" />
                            <span style={{ marginLeft: '8px' }}>Days</span>
                        </Box>

                        <Box>
                            <Text fontWeight="bold">Moving Average Convergence Divergence (MACD)</Text>
                            <Input placeholder="Value" width="100px" borderColor="#ffce30" />
                        </Box>
                    </VStack>
                </Box>
            </Grid>

            <Text fontSize="2xl" fontWeight="bold" mb="4" color="#ffce30">Add indicators</Text>
            <Flex direction="column" align="flex-start">
                <Checkbox colorScheme="yellow" fontWeight="bold">
                    Commodity Channel Index (CCI)
                </Checkbox>
                <Checkbox colorScheme="yellow" fontWeight="bold">
                    Rate of Change (ROC)
                </Checkbox>
            </Flex>
            
            <Box width="100%" display="flex" justifyContent="center" mt={4}>
                <Button bgColor="#ffce30" color="black" fontWeight="bold" size="lg" fontSize="xl">
                    Apply Conditions
                </Button>
            </Box>
        </Box>
    );
};

export default MomentumComponent;
