import React from 'react';
import {Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Box} from "@chakra-ui/react";
import SectorAnalysisComponent from "../sectorAnalysisComponents/layout/SectorAnalysisComponent";
import MacroEconomicDataAnalysisComponent from "../macroEconomicDataAnalysis/MacroEconomicDataAnalysisComponent";
import TechnicalAnalysisComponent from "../technicalAnalysis/layout/TechnicalAnalysisComponent";
import ModelsComponent from "../models/layout/ModelsComponent";
import NewsComponent from "../news/NewsComponent";
import FinancialAnalysisComponent from "../financialAnalysis/layout/FinancialAnalysisComponent";

const StockMarketComponent = () => {
    return (
        <Box>
            <Tabs color="white" variant='unstyled'>
                <Box overflowX={{base: "auto", md: "auto"}}>
                    <TabList className="tab-list" m={"auto"} justifyContent={"center"} fontSize={"24px"} whiteSpace="nowrap">
                        <Tab _selected={{color: "#ffce30"}}>Sector analysis</Tab>
                        <Tab _selected={{color: "#ffce30"}}>Macro-Economic data analysis</Tab>
                        <Tab _selected={{color: "#ffce30"}}>Financial analysis</Tab>
                        <Tab _selected={{color: "#ffce30"}}>Technical analysis</Tab>
                        <Tab _selected={{color: "#ffce30"}}>Models</Tab>
                        <Tab _selected={{color: "#ffce30"}}>News</Tab>
                    </TabList>
                </Box>
                <TabIndicator display={{base: "none", md: "block"}} marginTop="-1.5px" height="2px" bg="#ffce30" borderRadius="1px"/>
                <TabPanels>
                    <TabPanel>
                        <SectorAnalysisComponent/>
                    </TabPanel>
                    <TabPanel>
                        <MacroEconomicDataAnalysisComponent/>
                    </TabPanel>
                    <TabPanel>
                        <FinancialAnalysisComponent/>
                    </TabPanel>
                    <TabPanel>
                        <TechnicalAnalysisComponent/>
                    </TabPanel>
                    <TabPanel>
                        <ModelsComponent/>
                    </TabPanel>
                    <TabPanel>
                        <NewsComponent/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default StockMarketComponent;