import React from 'react';
import {Utils} from "../../../../../../../utils/Utils";
import {Line} from "react-chartjs-2";

const EarningChart = () => {
    const earningData = {
        labels: Utils.getYearsArray(2018, 2024),
        datasets: [{
            data: [-700, 1000, 0, 750, -850, 550, 700],
            backgroundColor: '#ffce30',
            borderColor: '#ffce30',
            borderWidth: 3,
            tension: 0
        }]
    };
    const config = {
        type: 'line',
        data: earningData
    }
    const options = {
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: 'white'
                }
            },
        },
        elements: {
            point: {
                radius: 4,
            }
        },
        scales: {
            x: {
                grid: {
                    color: 'white'
                },
                ticks: {
                    color: 'white'
                }
            },
            y: {
                grid: {
                    color: 'white'
                },
                ticks: {
                    callback: (value) => {
                        if (value%1000 !== 0) return ''
                        return value
                    },
                    stepSize: 500,
                    color: 'white'
                }
            }
        }
    }
    return (
        <div>
            <Line height='100px' data={config.data} options={options}/>
        </div>
    );
};

export default EarningChart;