import { useState, useEffect } from 'react';

const useFetchCompaniesData = (url, params = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const queryParams = new URLSearchParams(params).toString();
                const response = await fetch(`${url}?${queryParams}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url, params]);

    return { data, loading, error };
};

export default useFetchCompaniesData;
