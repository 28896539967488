import React from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tabs,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";

const VolumeLeadersTable = () => {
    const companiesData = [
        {
            company: "Crude Oil Futures",
            rank: 1,
            currentPrice: 75.50,
            dailyChangeRatio: "-0.69%",
            volume: 100000
        },
        {
            company: "S&P 500 Futures",
            rank: 2,
            currentPrice: 4500.50,
            dailyChangeRatio: "+1.35%",
            volume: 200000
        },
        {
            company: "Gold Futures",
            rank: 3,
            currentPrice: 1750.30,
            dailyChangeRatio: "+0.17%",
            volume: 75000
        },
        {
            company: "EUR/USD Options",
            rank: 4,
            currentPrice: 1.1950,
            dailyChangeRatio: "+0.06%",
            volume: 150000
        }
    ];
    return (
        <div>
            <Box bg="black" color="white" m="auto" mt={3}>
                <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"}
                     color='white'>
                    Volume Leaders
                </Box>
                <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                    <Flex>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        <InputGroup width={'300px'}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Box>
                <Tabs color="white" variant='unstyled'>
                    <Box bg={"#121212"} w='100%' color='white'>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr color={'white'} className="table-header">
                                        <Th>Rank</Th>
                                        <Th>Instrument Name</Th>
                                        <Th>Current price</Th>
                                        <Th>Daily change (%)</Th>
                                        <Th>Volume</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {companiesData.map((company, index) => (
                                        <Tr key={index}>
                                            <Td>{company.rank}</Td>
                                            <Td>{company.company}</Td>
                                            <Td>{company.currentPrice}</Td>
                                            <Td>{company.dailyChangeRatio}</Td>
                                            <Td>{company.volume}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Tabs>
            </Box>
        </div>
    );
};

export default VolumeLeadersTable;