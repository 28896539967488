import React from 'react';
import {Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

const TimeAndSales = () => {
    const timeAndSales = [
        {time: new Date("2024-10-07T16:59:32"), price: 420.56, quantity: 25},
        {time: new Date("2024-10-07T16:59:32"), price: 420.56, quantity: 25},
        {time: new Date("2024-10-07T16:59:32"), price: 420.56, quantity: 25}
    ]
    return (
        <Box>
            <Box bg={"#1f1f1f"} h="40px" p={2} fontSize={"18px"} color='white'>
                Time & Sales
            </Box>
            <Box bg={"#121212"} color='white'>
                <TableContainer height={'100%'} p={3} pt={0}>
                    <Table variant='unstyled'>
                        <Thead h={0}>
                            <Tr>
                                <Th p={0}/>
                                <Th p={0}/>
                                <Th p={0}/>
                            </Tr>
                        </Thead>
                        <Tbody fontSize={"14px"}>
                            {timeAndSales.map((tas, index) =>
                                <Tr key={index}>
                                    <Td pr={0}>{tas.time.getHours() + ":" + tas.time.getMinutes() + ":" + tas.time.getSeconds()}</Td>
                                    <Td pr={1} pl={1}>{tas.price}</Td>
                                    <Td pr={1} pl={1}>{tas.quantity}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default TimeAndSales;