import React from 'react';
import {Utils} from "../../../../../../../utils/Utils";
import {Bar} from "react-chartjs-2";

const DeptAndCoverageChart = () => {
    const performanceData = {
        labels: Utils.getYearsArray(2018, 2024),
        datasets: [{
            label: 'Dept',
            data: [180, 175, 95, 75, 400, 305, 10],
            backgroundColor: 'rgb(255,118,150)',
        }, {
            label: 'Cash & equivalents',
            data: [190, 340, 65, 205, 100, 150, 65],
            backgroundColor: 'rgb(90,180,255)',
        }]
    };
    const config = {
        type: 'bar',
        data: performanceData
    }
    const options = {
        plugins: {
            legend: {
                labels: {
                    color: 'white'
                }
            },
        },
        scales: {
            x: {
                grid: {
                    color: 'white'
                },
                ticks: {
                    color: 'white'
                }
            },
            y: {
                grid: {
                    color: 'white'
                },
                ticks: {
                    stepSize: 100,
                    color: 'white'
                }
            }
        }
    }
    return (
        <div>
            <Bar height='100px' data={config.data} options={options}/>
        </div>
    );
};

export default DeptAndCoverageChart;