import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PerformanceChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'area',
      height: 350,
      toolbar: {
        show: false
      },
      foreColor: '#ccc',
      background: 'black'
    },
    colors: ['#00E396', '#008FFB'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.1
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      tickAmount: 4,
      floating: false,
      labels: {
        formatter: (value) => `${value.toFixed(0)}%`,
        style: {
          colors: '#8e8da4'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    tooltip: {
      theme: 'dark',
      x: {
        format: 'dd MMM yyyy'
      },
      y: {
        formatter: (value) => `${value.toFixed(2)}%`
      }
    },
    grid: {
      borderColor: '#555',
      yaxis: {
        lines: {
          show: false
        }
      },
      padding: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
  });

  const [series, setSeries] = useState([
    {
      name: 'Momentum Strategy',
      data: generateDayWiseTimeSeries(new Date('11 Feb 2024').getTime(), 20, {
        min: -2,
        max: 8
      })
    },
    {
      name: 'Benchmark',
      data: generateDayWiseTimeSeries(new Date('11 Feb 2024').getTime(), 20, {
        min: -6,
        max: 8
      })
    }
  ]);

  function generateDayWiseTimeSeries(baseval, count, yrange) {
    let i = 0;
    let series = [];
    while (i < count) {
      const x = baseval;
      const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push([x, y]);
      baseval += 86400000;
      i++;
    }
    return series;
  }

  const periods = ['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y', '5Y', 'All'];

  return (
    <div style={{ background: 'black', padding: '20px', borderRadius: '8px', border: '1px solid #ccc'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          {periods.map((period, index) => (
            <button
              key={index}
              style={{
                background: 'transparent',
                border: 'none',
                color: '#ccc',
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              {period}
            </button>
          ))}
        </div>
        <div style={{ color: '#ccc' }}>09:27:02 (GMT +1) % log auto</div>
      </div>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default PerformanceChart;