import React from 'react';
import {Box, Select, Text} from "@chakra-ui/react";

const Bsm = () => {
    return (
        <div>
            <Box marginTop={8}>
                <Text color={'#ffce30'} fontSize={'24px'} fontWeight={'bold'}>BSM</Text>
                <Box>
                    <Text fontSize={'18px'}>Description</Text>
                    <Text>
                        Black-Scholes-Merton model. A mathematical model for pricing options contracts.
                    </Text>
                    <Text fontSize={'18px'}>Purpose</Text>
                    <Text>
                        Pricing European-style options and calculating implied volatility. Fundamental for options trading and risk management strategies.
                    </Text>
                </Box>
            </Box>
            <Box>
                <div className="filter-bar d-flex">
                    <div className="element">
                        <Select className="dropdown-select" width="auto">
                            <option selected hidden disabled value="">Select Company</option>
                            <option>Company 1</option>
                            <option>Company 2</option>
                        </Select>
                    </div>
                </div>
            </Box>
            <Box marginTop={10} height={'300px'} bgColor={'#1f1f1f'} borderRadius={'10px'}>
            </Box>
        </div>
    );
};

export default Bsm;