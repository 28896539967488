import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Box, Button, HStack, Text, Tabs, TabList, TabPanels, Tab, TabPanel, VStack } from '@chakra-ui/react'

function GoldFuturesComponent() {
  const [timeframe, setTimeframe] = useState('1D')
  const [selectedFuture, setSelectedFuture] = useState('Gold Futures')
  const [showMore, setShowMore] = useState(false)

  const futuresData = {
    'Gold Futures': {
      color: '#ffce30',
      data: {
        '1D': {
          data: [1800, 1810, 1805, 1815, 1820, 1818, 1825, 1830, 1828, 1835, 1840, 1845],
          categories: ['9:30', '10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30']
        },
        '5D': {
          data: [1800, 1820, 1810, 1830, 1825, 1840, 1835, 1850, 1845, 1860, 1855, 1865],
          categories: ['Mon', 'Mon', 'Tue', 'Tue', 'Wed', 'Wed', 'Thu', 'Thu', 'Fri', 'Fri', 'Fri', 'Fri']
        },
        '1M': {
          data: [1800, 1850, 1825, 1875, 1860, 1890, 1870, 1900, 1880, 1920, 1900, 1950],
          categories: ['Week 1', 'Week 1', 'Week 2', 'Week 2', 'Week 3', 'Week 3', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4']
        },
        '6M': {
          data: [1800, 1850, 1900, 1875, 1925, 1890, 1950, 2000, 1975, 2050, 2100, 2075],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '1Y': {
          data: [1700, 1800, 1850, 1900, 1950, 2000, 2050, 2100, 2150, 2200, 2250, 2300],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '5Y': {
          data: [1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600],
          categories: ['2019', '2020', '2020', '2021', '2021', '2022', '2022', '2023', '2023', '2024', '2024', '2024']
        }
      }
    },
    'Silver Futures': {
      color: '#C0C0C0',
      data: {
        '1D': {
          data: [25, 26, 25.5, 26.5, 26, 27, 26.5, 27.5, 27, 28, 27.5, 28.5],
          categories: ['9:30', '10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30']
        },
        '5D': {
          data: [25, 26, 27, 26, 28, 27, 29, 28, 30, 29, 31, 30],
          categories: ['Mon', 'Mon', 'Tue', 'Tue', 'Wed', 'Wed', 'Thu', 'Thu', 'Fri', 'Fri', 'Fri', 'Fri']
        },
        '1M': {
          data: [25, 27, 26, 28, 27, 29, 28, 30, 29, 31, 30, 32],
          categories: ['Week 1', 'Week 1', 'Week 2', 'Week 2', 'Week 3', 'Week 3', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4']
        },
        '6M': {
          data: [25, 27, 29, 28, 30, 29, 31, 30, 32, 31, 33, 32],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '1Y': {
          data: [20, 22, 24, 23, 25, 24, 26, 25, 27, 26, 28, 27],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '5Y': {
          data: [15, 17, 19, 18, 20, 19, 21, 20, 22, 21, 23, 22],
          categories: ['2019', '2020', '2020', '2021', '2021', '2022', '2022', '2023', '2023', '2024', '2024', '2024']
        }
      }
    },
    'Copper Futures': {
      color: '#B87333',
      data: {
        '1D': {
          data: [3.5, 3.6, 3.55, 3.65, 3.6, 3.7, 3.65, 3.75, 3.7, 3.8, 3.75, 3.85],
          categories: ['9:30', '10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30']
        },
        '5D': {
          data: [3.5, 3.6, 3.7, 3.6, 3.8, 3.7, 3.9, 3.8, 4.0, 3.9, 4.1, 4.0],
          categories: ['Mon', 'Mon', 'Tue', 'Tue', 'Wed', 'Wed', 'Thu', 'Thu', 'Fri', 'Fri', 'Fri', 'Fri']
        },
        '1M': {
          data: [3.5, 3.7, 3.6, 3.8, 3.7, 3.9, 3.8, 4.0, 3.9, 4.1, 4.0, 4.2],
          categories: ['Week 1', 'Week 1', 'Week 2', 'Week 2', 'Week 3', 'Week 3', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4']
        },
        '6M': {
          data: [3.5, 3.7, 3.9, 3.8, 4.0, 3.9, 4.1, 4.0, 4.2, 4.1, 4.3, 4.2],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '1Y': {
          data: [3.0, 3.2, 3.4, 3.3, 3.5, 3.4, 3.6, 3.5, 3.7, 3.6, 3.8, 3.7],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '5Y': {
          data: [2.5, 2.7, 2.9, 2.8, 3.0, 2.9, 3.1, 3.0, 3.2, 3.1, 3.3, 3.2],
          categories: ['2019', '2020', '2020', '2021', '2021', '2022', '2022', '2023', '2023', '2024', '2024', '2024']
        }
      }
    },
    'Crude Oil Futures': {
      color: '#8B4513',
      data: {
        '1D': {
          data: [70, 71, 70.5, 71.5, 71, 72, 71.5, 72.5, 72, 73, 72.5, 73.5],
          categories: ['9:30', '10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30']
        },
        '5D': {
          data: [70, 71, 72, 71, 73, 72, 74, 73, 75, 74, 76, 75],
          categories: ['Mon', 'Mon', 'Tue', 'Tue', 'Wed', 'Wed', 'Thu', 'Thu', 'Fri', 'Fri', 'Fri', 'Fri']
        },
        '1M': {
          data: [70, 72, 71, 73, 72, 74, 73, 75, 74, 76, 75, 77],
          categories: ['Week 1', 'Week 1', 'Week 2', 'Week 2', 'Week 3', 'Week 3', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4']
        },
        '6M': {
          data: [70, 72, 74, 73, 75, 74, 76, 75, 77, 76, 78, 77],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '1Y': {
          data: [65, 67, 69, 68, 70, 69, 71, 70, 72, 71, 73, 72],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '5Y': {
          data: [60, 62, 64, 63, 65, 64, 66, 65, 67, 66, 68, 67],
          categories: ['2019', '2020', '2020', '2021', '2021', '2022', '2022', '2023', '2023', '2024', '2024', '2024']
        }
      }
    },
    'Natural Gas Futures': {
      color: '#4169E1',
      data: {
        '1D': {
          data: [2.5, 2.6, 2.55, 2.65, 2.6, 2.7, 2.65, 2.75, 2.7, 2.8, 2.75, 2.85],
          categories: ['9:30', '10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30']
        },
        '5D': {
          data: [2.5, 2.6, 2.7, 2.6, 2.8, 2.7, 2.9, 2.8, 3.0, 2.9, 3.1, 3.0],
          categories: ['Mon', 'Mon', 'Tue', 'Tue', 'Wed', 'Wed', 'Thu', 'Thu', 'Fri', 'Fri', 'Fri', 'Fri']
        },
        '1M': {
          data: [2.5, 2.7, 2.6, 2.8, 2.7, 2.9, 2.8, 3.0, 2.9, 3.1, 3.0, 3.2],
          categories: ['Week 1', 'Week 1', 'Week 2', 'Week 2', 'Week 3', 'Week 3', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4']
        },
        '6M': {
          data: [2.5, 2.7, 2.9, 2.8, 3.0, 2.9, 3.1, 3.0, 3.2, 3.1, 3.3, 3.2],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '1Y': {
          data: [2.0, 2.2, 2.4, 2.3, 2.5, 2.4, 2.6, 2.5, 2.7, 2.6, 2.8, 2.7],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '5Y': {
          data: [1.5, 1.7, 1.9, 1.8, 2.0, 1.9, 2.1, 2.0, 2.2, 2.1, 2.3, 2.2],
          categories: ['2019', '2020', '2020', '2021', '2021', '2022', '2022', '2023', '2023', '2024', '2024', '2024']
        }
      }
    },
    'Corn Futures': {
      color: '#DAA520',
      data: {
        '1D': {
          data: [550, 560, 555, 565, 560, 570, 565, 575, 570, 580, 575, 585],
          categories: ['9:30', '10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30']
        },
        '5D': {
          data: [550, 560, 570, 560, 580, 570, 590, 580, 600, 590, 610, 600],
          categories: ['Mon', 'Mon', 'Tue', 'Tue', 'Wed', 'Wed', 'Thu', 'Thu', 'Fri', 'Fri', 'Fri', 'Fri']
        },
        '1M': {
          data: [550, 570, 560, 580, 570, 590, 580, 600, 590, 610, 600, 620],
          categories: ['Week 1', 'Week 1', 'Week 2', 'Week 2', 'Week 3', 'Week 3', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4', 'Week 4']
        },
        '6M': {
          data: [550, 570, 590, 580, 600, 590, 610, 600, 620, 610, 630, 620],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '1Y': {
          data: [500, 520, 540, 530, 550, 540, 560, 550, 570, 560, 580, 570],
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        '5Y': {
          data: [450, 470, 490, 480, 500, 490, 510, 500, 520, 510, 530, 520],
          categories: ['2019', '2020', '2020', '2021', '2021', '2022', '2022', '2023', '2023', '2024', '2024', '2024']
        }
      }
    }
  }

  const getChartOptions = (futureType) => ({
    chart: {
      type: 'area',
      height: '70vh',
      background: 'transparent',
      toolbar: {
        show: false
      },
      foreColor: '#fff'
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: [futuresData[futureType].color]
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.1,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: futuresData[futureType].color,
            opacity: 0.4
          },
          {
            offset: 100,
            color: futuresData[futureType].color,
            opacity: 0
          }
        ]
      }
    },
    markers: {
      size: 4,
      colors: [futuresData[futureType].color],
      strokeWidth: 0
    },
    grid: {
      show: true,
      borderColor: '#2d2d2d',
      strokeDashArray: 3
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '12px'
      },
      x: {
        show: true
      },
      y: {
        formatter: function(value) {
          return '$' + value
        }
      }
    },
    xaxis: {
      categories: futuresData[futureType].data[timeframe].categories,
      labels: {
        style: {
          colors: '#fff'
        }
      }
    },
    yaxis: {
      opposite: true,
      labels: {
        style: {
          colors: '#fff'
        }
      }
    }
  })

  const timeframes = ['1D', '5D', '1M', '6M', '1Y', '5Y']
  const timeframesFullNames = {
    '1D': '1 Day',
    '5D': '5 Days',
    '1M': '1 Month',
    '6M': '6 Months',
    '1Y': '1 Year',
    '5Y': '5 Years'
  }
  const randomPercentages = timeframes.reduce((acc, tf) => {
    acc[tf] = `+${(Math.random() * 5).toFixed(2)}%`
    return acc
  }, {})
  
  const mainFutures = ['Gold Futures', 'Silver Futures', 'Copper Futures', 'Crude Oil Futures']
  const additionalFutures = ['Natural Gas Futures', 'Corn Futures']
  const allFutures = [...mainFutures, ...additionalFutures]

  const handleTabChange = (future) => {
    setSelectedFuture(future)
  }

  return (
    <Box>
      <Tabs variant="unstyled" onChange={(index) => handleTabChange(allFutures[index])}>
        <HStack justify="center" align="center">
          <TabList borderRadius="lg" overflow="hidden" gap={2}>
            {mainFutures.map((future) => (
              <Tab
                key={future}
                _selected={{ 
                  color: "black", 
                  bg: "#ffce30",
                  fontWeight: "bold"
                }}
                bg="gray"
                color="white"
                px={4}
                py={2}
                borderRadius="md"
                fontWeight="bold"
              >
                {future}
              </Tab>
            ))}
            {!showMore && (
              <Button
                variant="ghost"
                color="#ffce30"
                _hover={{ bg: 'transparent' }}
                fontWeight="bold"
                onClick={() => setShowMore(!showMore)}
              >
                Show More
              </Button>
            )}
            {showMore && additionalFutures.map((future) => (
              <Tab
                key={future}
                _selected={{ 
                  color: "black", 
                  bg: "#ffce30",
                  fontWeight: "bold"
                }}
                bg="gray"
                color="white"
                px={4}
                py={2}
                borderRadius="md"
                fontWeight="bold"
              >
                {future}
              </Tab>
            ))}
            {showMore && (
              <Button
                variant="ghost"
                color="#ffce30"
                _hover={{ bg: 'transparent' }}
                fontWeight="bold"
                onClick={() => setShowMore(!showMore)}
              >
                Show Less
              </Button>
            )}
          </TabList>
        </HStack>

        <TabPanels>
          {allFutures.map((future) => (
            <TabPanel key={future}>
              <Box height="70vh">
                <HStack justify="space-between" mb={4} px={4}>
                  <Text fontSize="xl" color="white" fontWeight="bold">{future}</Text>
                  <HStack spacing={2}>
                    {timeframes.map((tf) => (
                      <Button
                        key={tf}
                        size="sm"
                        variant={timeframe === tf ? 'solid' : 'ghost'}
                        colorScheme="yellow"
                        onClick={() => setTimeframe(tf)}
                        fontWeight="bold"
                      >
                        {tf}
                      </Button>
                    ))}
                  </HStack>
                </HStack>
                
                <ReactApexChart
                  options={getChartOptions(future)}
                  series={[{
                    name: future,
                    data: futuresData[future].data[timeframe].data
                  }]}
                  type="area"
                  height="100%"
                />

                {/* <VStack justify="center" align="center" mt={4} bg="black" w="100%">
                  <HStack spacing={2} bg="black" justify="center">
                    {timeframes.map((tf) => (
                      <Button
                        key={tf}
                        size="sm"
                        variant={timeframe === tf ? 'solid' : 'ghost'}
                        colorScheme="yellow"
                        onClick={() => setTimeframe(tf)}
                        fontWeight="bold"
                        minW="120px"
                        bg="black"
                      >
                        <VStack spacing={1}>
                          <Text>{timeframesFullNames[tf]}</Text>
                          <Text
                            color="green.400"
                            fontSize="sm"
                          >
                            {randomPercentages[tf]}
                          </Text>
                        </VStack>
                      </Button>
                    ))}
                  </HStack>
                </VStack> */}
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default GoldFuturesComponent