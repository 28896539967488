import React from 'react';
import {Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {Utils} from "../../../utils/Utils";

const OrderBook = () => {
    const orderBooks = [
        {price: 520, amount: 215140839, total: 53},
        {price: 520, amount: 215140839, total: 53},
        {price:520, amount:215140839, total:53}
    ]
    return (
        <Box>
            <Box bg={"#1f1f1f"} h="80px" p={2} fontSize={"18px"} color='white'>
                Order book
            </Box>
            <Box bg={"#121212"} color='white'>
                <TableContainer height={'100%'} padding={3}>
                    <Table variant='unstyled'>
                        <Thead>
                            <Tr borderBottom={"1px solid"} borderColor={"rgba(250,250,250,0.25)"}>
                                <Th pr={1} pl={1} color={"#aaa"} fontSize={"10px"}>Price (MAD)</Th>
                                <Th pr={1} pl={1} color={"#aaa"} fontSize={"10px"}>Amount (ATW)</Th>
                                <Th pr={1} pl={1} color={"#aaa"} fontSize={"10px"}>Total (BIL)</Th>
                            </Tr>
                        </Thead>
                        <Tbody fontSize={"14px"}>
                            {orderBooks.map((orderBook, index) =>
                                <Tr key={index} style={{borderBottom:"1px solid", borderColor:"rgba(250,250,250,0.25)"}}>
                                    <Td>{orderBook.price}</Td>
                                    <Td pr={1} pl={1}>{Utils.formatNumber(orderBook.amount, " ")}</Td>
                                    <Td pr={1} pl={1}>{orderBook.total}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default OrderBook;