import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FiChevronDown, FiFilter } from "react-icons/fi";
import axios from 'axios';

const CorporateBonds = () => {
  const [companiesBonds, setCompaniesBonds] = useState([]);
//   const [companies, setCompanies] = useState([]); // To store all companies for the dropdown
  const [selectedCompany, setSelectedCompany] = useState('LYDEC'); // Store selected company
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all companies for dropdown
//   const fetchCompanies = async () => {
//     try {
//       const response = await axios.get('http://127.0.0.1:8000/api/companies-bonds'); // Adjust endpoint if needed
//       const companySet = new Set(response.data.results.map(item => item.company)); // Create a set to ensure unique companies
//       setCompanies(Array.from(companySet)); // Convert set back to array
//     } catch (err) {
//       console.error('Error fetching companies:', err);
//       setError('Failed to fetch companies');
//     }
//   };
    const companies = ['BANK OF AFRICA','Société Générale', 'Attijariwafa Bank', 'LYDEC', 'OCPGroup', 'Crédit agricole du Maroc']
    // setSelectedCompany(companies[0]);
  // Fetch bonds based on selected company and page
  const fetchCompaniesBonds = async (page = 1, company = '') => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.xcapitalterminal.com/api/v1/bonds-data/companies-bonds?company=${company}&page=${page}`
      );
      setCompaniesBonds(response.data.results); // Adjust based on API structure
      setTotalPages(response.data.count || 1); // Assuming the API returns `total_pages`
      setLoading(false);
    } catch (err) {
      console.error('Fetch error:', err);
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchCompanies(); // Fetch companies when component mounts
    fetchCompaniesBonds(currentPage, selectedCompany); // Fetch bonds based on selected company
  }, [currentPage, selectedCompany]);

  // Handle company selection from the dropdown
  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
    setCurrentPage(1); // Reset to the first page when a new company is selected
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (loading) return <Box color="white" textAlign="center">Loading...</Box>;
  if (error) return <Box color="red" textAlign="center">{error}</Box>;

  return (
    <Box>
      <Box bg={"#1f1f1f"} textAlign="center" w="100%" h="40px" p={2} mt={"40px"} fontSize={"18px"} color="white">
        Corporate Bonds
      </Box>
      <Box bg={"#171717"} w="100%" p={2} fontSize={"18px"} color="white">
        <Flex>
          <Menu>
            <MenuButton
              as={Button}
              height={'35px'}
              bgColor="#161616"
              color="white"
              m={"0 15px 0 7px"}
              border={"1px solid rgb(158, 158, 158)"}
              _hover={{ bgColor: "#242424" }}
              rightIcon={<FiChevronDown style={{ marginLeft: "20px" }} />}
              _focus={{ bgColor: "#242424" }}
            >
              {selectedCompany} 
            </MenuButton>
            <MenuList bgColor="#121212" borderColor={"transparent"}>
              {companies.map((company) => ( 
                <MenuItem
                  key={company}
                  bgColor="#121212"
                  _hover={{ bgColor: "#242424" }}
                  onClick={() => handleCompanyChange(company)} 
                >
                  {company} 
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          {/* <Flex flex={1} justifyContent={'end'} mr={10}>
            <Button colorScheme="teal" onClick={() => fetchCompaniesBonds(currentPage, selectedCompany)}>Filter</Button>
          </Flex> */}
        </Flex>
      </Box>
      <Box bg={"#121212"} w="100%" color="white">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color="white">Company</Th>
                <Th color="white">Session Date</Th>
                <Th color="white">Issuer</Th>
                <Th color="white">Instrument</Th>
                <Th color="white">Ticker</Th>
                <Th color="white">Reference Price</Th>
                <Th color="white">Opening Price</Th>
                <Th color="white">Last Price</Th>
                <Th color="white">Shares Traded</Th>
                <Th color="white">Trading Volume</Th>
                <Th color="white">Contracts Number</Th>
                <Th color="white">Adjusted Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {companiesBonds.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.company}</Td>
                  <Td>{item.session_date}</Td>
                  <Td>{item.issuer}</Td>
                  <Td>{item.instrument}</Td>
                  <Td>{item.ticker}</Td>
                  <Td>{item.reference_price}</Td>
                  <Td>{item.opening_price}</Td>
                  <Td>{item.last_price}</Td>
                  <Td>{item.shares_traded}</Td>
                  <Td>{item.trading_volume}</Td>
                  <Td>{item.contracts_number}</Td>
                  <Td>{item.adjusted_price}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Flex justifyContent="center" mt={4}>
        <Button
          colorScheme="teal"
          mr={2}
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
          bg="#ffce30"
          _hover={{ bg: "#ffce30" }}
        >
          Previous
        </Button>
        <Box color="white" mx={2}>{`Page ${currentPage} of ${totalPages}`}</Box>
        <Button
          colorScheme="teal"
          ml={2}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
          bg="#ffce30"
          _hover={{ bg: "#ffce30" }}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default CorporateBonds;
