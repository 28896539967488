import React from 'react';
import ReactApexChart from 'react-apexcharts';

const DrawdownChart = () => {
    const options = {
      chart: {
        type: 'line',
        height: 350,
        foreColor: '#ccc',
        toolbar: {
          autoSelected: 'pan',
          show: false
        },
        background: 'black'
      },
      colors: ['#00E396', '#008FFB'],
      stroke: {
        width: [3, 3],
        curve: 'smooth'
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: ['solid', 'gradient'],
        gradient: {
          opacityFrom: 0.55,
          opacityTo: 0
        }
      },
      markers: {
        size: 5,
        colors: ["#000524"],
        strokeColor: "#00E396",
        strokeWidth: 3
      },
      tooltip: {
        theme: 'dark'
      },
      xaxis: {
        type: 'category',
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yaxis: {
        min: -60,
        max: 0,
        tickAmount: 4
      }
    };
  
    const series = [
      {
        name: 'Area Line',
        type: 'area',
        data: [-20, -40, -25, -50, -30, -30, -50, -10, -20, -40, -30, -50]
      },
      {
        name: 'Solid Line',
        type: 'area',
        data: [-10, -30, -20, -40, -25, -20, -40, -5, -15, -35, -25, -45]
      }
    ];
  
    return (
      <ReactApexChart options={options} series={series} type="line" height={350} style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '10px' }} />
    );
  };
export default DrawdownChart;