import React, { useState } from 'react';
import { Box, Select, Text, Input, Button } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import axios from 'axios';

const Arimax = () => {
    const [ticker, setTicker] = useState(""); // Selected company symbol
    const [startDate, setStartDate] = useState(""); // Start date
    const [endDate, setEndDate] = useState(""); // End date
    const [exogVariables, setExogVariables] = useState([]); // List of exogenous variables
    const [chartData, setChartData] = useState(null); // Data for the chart

    // Function to fetch data from the ARIMAX API
    const fetchData = async () => {
        // if (!ticker || !startDate || !endDate || exogVariables.length === 0) {
        //     alert("Please select all fields.");
        //     return;
        // }

        const apiURL = `https://api.xcapitalterminal.com/api/v1/arimax-predict?ticker=${ticker}&start_date=${startDate}&end_date=${endDate}&list_exog=${exogVariables.join(",")}`;

        try {
            const response = await axios.get(apiURL);
            const { test_actuals, test_predictions, test_dates } = response.data;

            // Combine data into an array of objects for sorting
            const combinedData = test_dates.map((date, index) => ({
                date,
                actual: test_actuals[index],
                predicted: test_predictions[index],
            }));

            // Sort data by date
            combinedData.sort((a, b) => new Date(a.date) - new Date(b.date));

            // Separate sorted data back into individual arrays
            const sortedDates = combinedData.map(item => item.date);
            const sortedActualValues = combinedData.map(item => item.actual);
            const sortedPredictedValues = combinedData.map(item => item.predicted);

            // Update chart data
            setChartData({
                labels: sortedDates,
                datasets: [
                    {
                        label: "Actual Values",
                        data: sortedActualValues,
                        borderColor: "#ffce30",
                        borderWidth: 2,
                        pointBackgroundColor: "#ffce30",
                        tension: 0.4,
                    },
                    {
                        label: "Predicted Values",
                        data: sortedPredictedValues,
                        borderColor: "#6b6b6b",
                        borderWidth: 2,
                        pointBackgroundColor: "#6b6b6b",
                        borderDash: [5, 5],
                        tension: 0.4,
                    },
                ],
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("Failed to fetch data. Please try again.");
        }
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
        },
        scales: {
            x: {
                grid: {
                    color: "#444444",
                },
                ticks: {
                    color: "#ffffff",
                },
            },
            y: {
                grid: {
                    color: "#444444",
                },
                ticks: {
                    color: "#ffffff",
                },
            },
        },
    };

    return (
        <div>
            <Box marginTop={8}>
                <Text color={'#ffce30'} fontSize={'24px'} fontWeight={'bold'}>ARIMAX</Text>
                <Box>
                    <Text fontSize={'18px'}>Description</Text>
                    <Text>
                        ARIMA with exogenous variables. An extension of ARIMA that incorporates external factors.
                    </Text>
                    <Text fontSize={'18px'}>Purpose</Text>
                    <Text>
                        Predicting stock performance while considering external economic indicators.
                    </Text>
                </Box>
            </Box>
            <Box>
                <div className="filter-bar d-flex">
                    <div className="element">
                        <Select
                            placeholder="Select Company"
                            value={ticker}
                            onChange={(e) => setTicker(e.target.value)}
                        >
                            <option value="ATW">ATW</option>
                            <option value="GAZ">GAZ</option>
                        </Select>
                    </div>
                    <div className="element">
                        <Input
                            type="date"
                            placeholder="Start Date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="element">
                        <Input
                            type="date"
                            placeholder="End Date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div className="element">
                        <Input
                            type="text"
                            placeholder="Exogenous Variables (comma-separated)"
                            value={exogVariables.join(",")}
                            onChange={(e) => setExogVariables(e.target.value.split(","))}
                        />
                    </div>
                    <div className="element">
                        <Button colorScheme="yellow" onClick={fetchData}>
                            Fetch Data
                        </Button>
                    </div>
                </div>
            </Box>
            {/* <Box marginTop={10} height={'300px'} bgColor={'#1f1f1f'} borderRadius={'10px'}> */}
            <Box marginTop={10} height={'500px'} borderRadius={'10px'}>
                {chartData ? (
                    <Line data={chartData} options={chartOptions}/>
                ) : (
                    <Text color={"#ffffff"}>No data to display. Please select parameters and fetch data.</Text>
                )}
            </Box>
        </div>
    );
};

export default Arimax;
