import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

const PutCallRatioComponent = () => {
    const [timeframe, setTimeframe] = useState('1M');

    // Sample data - you would replace this with real data
    const series = [{
        name: 'PUT',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    }, {
        name: 'CALL', 
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    }];

    const getXAxisCategories = () => {
        switch(timeframe) {
            case '1D':
                return ['9:30', '10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:00'];
            case '5D':
                return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
            case '1M':
                return ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
            case '6M':
                return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
            case '1Y':
                return ['2023 Q1', '2023 Q2', '2023 Q3', '2023 Q4'];
            case '5Y':
                return ['2019', '2020', '2021', '2022', '2023'];
            case 'MAX':
                return ['2015', '2017', '2019', '2021', '2023'];
            default:
                return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];
        }
    };

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            background: '#000000',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                dataLabels: {
                    position: 'top'
                }
            }
        },
        colors: ['#26A69A', '#EF5350'],
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toFixed(2);
            },
            style: {
                colors: ['#fff']
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: getXAxisCategories(),
            title: {
                text: 'Time (Date)',
                style: {
                    color: '#fff'
                }
            },
            labels: {
                style: {
                    colors: '#fff'
                }
            }
        },
        yaxis: {
            title: {
                text: 'Put/Call Ratio',
                style: {
                    color: '#fff'
                }
            },
            labels: {
                style: {
                    colors: '#fff'
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            labels: {
                colors: '#fff'
            }
        },
        fill: {
            opacity: 1
        },
        grid: {
            borderColor: '#ffffff33'
        }
    };

    const timeframes = ['1D', '5D', '1M', '6M', '1Y', '5Y', 'MAX'];

    return (
        <Box p={5}>
            <Box
                border="2px solid #ffffff"
                borderRadius="4px"
                p={4}
            >
                <Flex justify="space-between" align="center" mb={4}>
                    <Text fontSize="24px" fontWeight="bold" color="white">
                        PUT/CALL RATIO
                    </Text>
                    <Flex gap={2}>
                        {timeframes.map((tf) => (
                            <Button
                                key={tf}
                                size="sm"
                                variant={timeframe === tf ? 'solid' : 'outline'}
                                colorScheme="blue"
                                onClick={() => setTimeframe(tf)}
                            >
                                {tf}
                            </Button>
                        ))}
                    </Flex>
                </Flex>
                <ReactApexChart 
                    options={options} 
                    series={series} 
                    type="bar" 
                    height={350}
                />
            </Box>
        </Box>
    );
};

export default PutCallRatioComponent;
