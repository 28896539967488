// Import necessary dependencies from React and Chakra UI
import React from 'react'
import { Flex, Box } from '@chakra-ui/react'
// Import the custom DoughnutPieChat component
import DoughnutPieChat from '../DoughnutPieChat'

// DiversificationComponent: Renders the diversification section of the portfolio management interface
function DiversificationComponent() {
  // Sample data for the charts
  const chartData = {
    labels: ['Stocks', 'Bonds', 'Real Estate', 'Cash'],
    datasets: [{
      data: [40, 30, 20, 10],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
    }]
  };

  // Text to be displayed in the center of the charts
  const centerText = {
    main: "Total",
    sub: "$100,000"
  };

  return (
    <Box>
      {/* Flex container for arranging the charts */}
      <Flex flexWrap="wrap" justifyContent="center" alignItems="center" gap={7}>
        {/* Asset Class Exposure chart */}
        <Flex width={{ base: "90%", md: "48%" }} mb={4} alignItems="center" justifyContent="center"minW={'fit-content'}>
          <DoughnutPieChat
            title="Asset Class Exposure"
            chartData={chartData}
            centerText={centerText}
          />
        </Flex>

        {/* Country Exposure chart */}
        <Flex width={{ base: "90%", md: "48%" }} mb={4} alignItems="center" justifyContent="center" minW={'fit-content'}>
          <DoughnutPieChat
            title="Country Exposure"
            chartData={chartData}
            centerText={centerText}
          />
        </Flex>

        {/* Equities by industry exposure chart */}
        <Flex width={{ base: "90%", md: "48%" }} mb={4} alignItems="center" justifyContent="center" minW={'fit-content'}>
          <DoughnutPieChat
            title="Equities by industry exposure"
            chartData={chartData}
            centerText={centerText}
          />
        </Flex>

        {/* Company Exposure chart */}
        <Flex width={{ base: "90%", md: "48%" }} mb={4} alignItems="center" justifyContent="center" minW={'fit-content'}>
          <DoughnutPieChat
            title="Company Exposure"
            chartData={chartData}
            centerText={centerText}
          />
        </Flex>

        {/* X Capital Exposure chart */}
        <Flex width={{ base: "90%", md: "48%" }} mb={4} alignItems="center" justifyContent="center" minW={'fit-content'}>
          <DoughnutPieChat
            title="X Capital Exposure"
            chartData={chartData}
            centerText={centerText}
          />
        </Flex>
        
        {/* Additional Asset Class Exposure chart (consider renaming or removing if redundant) */}
        <Flex width={{ base: "90%", md: "48%" }} mb={4} alignItems="center" justifyContent="center" minW={'fit-content'}>
          <DoughnutPieChat
            title="Asset Class Exposure"
            chartData={chartData}
            centerText={centerText}
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default DiversificationComponent