import React, {useState} from 'react';
import {
    Button,
    Flex,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Box,
} from '@chakra-ui/react';
import {FiBell, FiChevronDown, FiSearch, FiUser} from 'react-icons/fi';
import {useNavigate} from "react-router-dom";

const Header = () => {
    const navigate = useNavigate()
    const accountSettingsLink = "/client/x-capital-terminal/account-settings"
    const countries = ["MAR", "ZAF", "NGA", "KEN", "EGY", "GHA", "UGA", "MUS"]
    const [selectedCountry, setSelectedCountry] = useState('MAR')

    function selectCountry(countryName) {
        setSelectedCountry(countryName)
    }

    return (
        <Flex
            bg="black"
            color="white"
            align="center"
            p={4}
            justify="space-between"
            width="80%"
            ml="auto"
            position="relative"
            zIndex={200}
        >
            <InputGroup width="50%" mx="auto">
                <InputLeftElement pointerEvents="none">
                    <Box as={FiSearch} color="white"/>
                </InputLeftElement>
                <Input 
                    placeholder="Search" 
                    bg="#121212" 
                    border="none" 
                    mr="15px"
                    _hover={{ borderColor: 'transparent' }}
                    _focus={{ borderColor: 'transparent', boxShadow: 'none' }}
                />
                <Menu>
                    <MenuButton
                        as={Button}
                        bg="#121212"
                        _active={{ bg: "#121212" }}
                        color="white"
                        _hover={{ bg: "#242424" }}
                        rightIcon={<Box as={FiChevronDown} ml="20px"/>}
                        _focus={{ bg: "#242424" }}
                    >
                        <Box as="span" className={"fi fi-" + selectedCountry.substring(0, 2).toLowerCase()}/>
                    </MenuButton>
                    <MenuList bg="#121212" border="none" minW="90px">
                        {countries.filter((countryName => countryName !== selectedCountry)).map((countryName) =>
                            <MenuItem
                                key={countryName}
                                bg="#121212"
                                _hover={{ bg: "#242424" }}
                                onClick={() => selectCountry(countryName)}
                            >
                                <Box 
                                    as="span" 
                                    className={"fi fi-" + countryName.substring(0, 2).toLowerCase()}
                                    mr={2}
                                />
                                {countryName}
                            </MenuItem>
                        )}
                    </MenuList>
                </Menu>
            </InputGroup>
            <Flex align="center" gap={4}>
                <IconButton
                    aria-label="Notifications"
                    icon={<FiBell/>}
                    bg="#121212"
                    color="white"
                    _hover={{ bg: "#ffce30" }}
                />
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Profile"
                        icon={<FiUser/>}
                        bg="#121212"
                        color="white"
                        _hover={{ bg: "#ffce30" }}
                        _active={{ bg: "#ffce30" }}
                    />
                    <MenuList bg="#1f1f1f" border="none">
                        <MenuItem
                            bg="#1f1f1f"
                            _hover={{ bg: "#242424" }}
                            onClick={() => navigate(accountSettingsLink)}
                        >
                            Account settings
                        </MenuItem>
                        <MenuItem
                            bg="#1f1f1f"
                            _hover={{ bg: "#242424" }}
                        >
                            Logout
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    );
};

export default Header;
