import React from 'react';
import ReactApexChart from 'react-apexcharts';
const MonthlyReturnsChart = () => {
    const options = {
        chart: {
          type: 'bar',
          height: 350,
          foreColor: '#ccc',
          background: 'black'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          title: {
            text: 'Returns (%)'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%"
            }
          }
        },
        legend: {
          position: 'top'
        },
        colors: ['#F9CE1D', '#00E396', '#008FFB']
      };
    
      const series = [
        {
          name: 'Benchmark',
          data: [30, -20, 15, -10, 25, -5, 20, -15, 30, -10, 15, -5]
        },
        {
          name: 'Price Momentum Strategy',
          data: [35, -15, 20, -5, 30, 0, 25, -10, 35, -5, 20, 0]
        },
        {
          name: 'Alternative Strategy',
          data: [25, -10, 10, -15, 20, 5, 15, -20, 25, -15, 10, 5]
        }
      ];
    
      return (
        <ReactApexChart options={options} series={series} type="bar" height={350} style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '10px' }} />
      );
    };
export default MonthlyReturnsChart;
  