import React from 'react';
import "chartjs-plugin-datalabels"
import SectorDistributionChart from "../SectorDistributionChart";
import TopSectorsTable from "../TopSectorsTable";
import SectorsHeatmap from "../SectorsHeatmap";
import TopSectorPlayersTable from "../TopSectorPlayersTable";

const SectorAnalysisComponent = () => {
    return (
        <div className="m-auto">
            {/*Sector Distribution (chart)*/}
            <SectorDistributionChart/>
            {/*Top Sectors (table)*/}
            <TopSectorsTable/>
            {/*Sectors Heatmap (treemap chart)*/}
            <SectorsHeatmap/>
            {/*Top Sector Players (table)*/}
            <TopSectorPlayersTable/>
        </div>
    );
};

export default SectorAnalysisComponent;