import React from 'react';
import {Box, Table, TableContainer, Tbody, Td, Tr} from "@chakra-ui/react";

const NewsComponent = () => {
    const news = [
        { title: "Retail Sales Slump Takes Toll on Market, Stocks Dip", time: "10 min ago" },
        { title: "Tech Giant's Earnings Soar, Stock Hits All-Time High", time: "2 min ago" },
        { title: "High-Profile IPO Falls Short of Expectations, Stocks Slump", time: "12 hrs ago" },
        { title: "Electric Vehicle Stocks Skyrocket as Demand Surges", time: "22 hrs ago" },
        { title: "Market Sentiment Turns Bearish, Stocks Tumble", time: "2 hrs ago" },
        { title: "Chip Shortage Woes Continue, Tech Stocks Face Uncertainty", time: "3 days ago" }
    ];
    return (
        <div>
            <Box marginTop={10}>
                <Box bg={"#1f1f1f"} h="60px" p={4} fontSize={"18px"} color='white'>
                    News
                </Box>
                <Box bg={"#121212"} color='white'>
                    <TableContainer>
                        <Table variant='unstyled'>
                            <Tbody>
                                {news.map((n, index) =>
                                    <Tr key={index}>
                                        <Td>{n.title}</Td>
                                        <Td textAlign={'end'}>{n.time}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </div>
    );
};

export default NewsComponent;