import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidgetHeatmap() {
    const container = useRef(HTMLDivElement);

    useEffect(() => {
        // Check if the script already exists
        if (!container.current.querySelector("script")) {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
            {
              "exchanges": [],
              "dataSource": "CSEMAMASI",
              "grouping": "sector",
              "blockSize": "market_cap_basic",
              "blockColor": "change",
              "locale": "en",
              "symbolUrl": "",
              "colorTheme": "dark",
              "hasTopBar": false,
              "isDataSetEnabled": false,
              "isZoomEnabled": true,
              "hasSymbolTooltip": true,
              "isMonoSize": false,
              "width": "100%",
              "height": 500
            }`;
            container.current.appendChild(script);
        }
    }, []);

    return (
        <div className="tradingview-widget-container" ref={container}>
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
}

export default memo(TradingViewWidgetHeatmap);