import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MACDChart = () => {
  const series = [
    {
      name: "MACD Line",
      type: "line",
      data: [35, 36, 38, 40, 45, 50, 52, 48, 45, 40, 38, 35, 30, 28, 35, 40, 48, 52, 50, 45, 40, 36, 35]
    },
    {
      name: "Histogram",
      type: "bar",
      data: [-5, -4, 0, 5, 10, 15, 20, 12, 8, 5, 0, -3, -8, -12, -6, 0, 8, 12, 10, 5, 0, -4, -5]
    }
  ];

  const options = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      background: '#000',  
      toolbar: {
        show: false  
      }
    },
    title: {
      text: 'MACD (6,14,24)',  
      align: 'left',  
      margin: 20,
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: '#fff' 
      }
    },
    colors: ['#FFB800', '#FFC107'],  
    stroke: {
      width: [2, 0],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '70%',
        borderRadius: 5,
        colors: {
          ranges: [
            {
              from: -100,
              to: 0,
              color: '#FFC107'
            },
            {
              from: 0,
              to: 100,
              color: '#FFB800'
            }
          ]
        }
      }
    },
    fill: {
      opacity: [1, 0.9],
    },
    xaxis: {
      categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      labels: {
        style: {
          colors: '#fff',
        },
      }
    },
    yaxis: [
      {
        title: {
          text: 'MACD Line',
          style: {
            color: '#FFB800',
          }
        },
        min: 25,
        max: 60,
        labels: {
          style: {
            colors: '#fff',
          }
        }
      },
      {
        opposite: true,
        title: {
          text: 'Histogram',
          style: {
            color: '#FFC107',
          }
        },
        min: -20,
        max: 30,
        labels: {
          style: {
            colors: '#fff',
          }
        }
      }
    ],
    grid: {
      borderColor: '#fff',
    },
    tooltip: {
      theme: 'dark',
    },
    legend: {
      show: false
    }
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default MACDChart;
