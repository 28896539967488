import React, {memo, useEffect, useRef} from 'react';
import { Box } from '@chakra-ui/react';

const TradingViewWidgetCandlestick = () => {
    const container = useRef();

    useEffect(() => {
        if (!container.current.querySelector("script")) {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
        {
          "autosize": true,
          "symbol": "CSEMA:ATW",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "withdateranges": true,
          "hide_side_toolbar": false,
          "allow_symbol_change": true,
          "details": true,
          "hotlist": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
            container.current.appendChild(script);
        }
    }, []);
    return (
        <Box ref={container} className="tradingview-widget-container">
            <Box></Box>
        </Box>
    );
};

export default memo(TradingViewWidgetCandlestick);