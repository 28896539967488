import React, { useState } from 'react';
import { Avatar, Box, Button, Collapse, Flex, IconButton, Image, Text, VStack, Icon } from '@chakra-ui/react';
import { FiChevronDown, FiChevronRight, FiMenu, FiX } from 'react-icons/fi';
import logo from "../../../assets/logo/logo.svg";
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ items, username }) => {
    const navigate = useNavigate()
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');
    const [activeItem, setActiveItem] = useState('');

    const navigateTo = (link) => {
        navigate(link)
    }

    const handleToggle = () => setIsCollapsed(!isCollapsed);

    const handleSubMenuToggle = (name) => {
        setActiveMenu(activeMenu === name ? '' : name);
    };

    const handleItemClick = (name, link) => {
        setActiveItem(name);
        if (activeMenu !== name) setActiveMenu('');
        if (link !== null) navigateTo(link)
    };

    const activeColor = "#ffce30";

    return (
        <Box 
            transition="1s"
            bg="#121212"
            color="white"
            minH="95vh"
            mt="2vh"
            ml="0.5em"
            position="fixed"
            borderRadius={10}
            zIndex={300}
        >
            <Flex 
                direction="column" 
                align={isCollapsed ? 'center' : 'center'} 
                p={4} 
                gap={5}
            >
                <Image 
                    src={logo}
                    w={isCollapsed ? '50px' : 'auto'}
                    h={isCollapsed ? '50px' : 'auto'}
                    mt={isCollapsed ? '2em' : '0'}
                />

                <IconButton
                    aria-label="Toggle sidebar"
                    icon={isCollapsed ? <Icon as={FiMenu} /> : <Icon as={FiX} />}
                    onClick={handleToggle}
                    mb={4}
                    position="absolute"
                    right={0}
                    top='0'
                    borderRadius='none'
                    bg="transparent"
                    color="white"
                    _hover={{
                        bg: "#ffce30",
                        color: "white"
                    }}
                    borderTopRightRadius={10}
                    borderBottomLeftRadius={7}
                />

                <VStack 
                    spacing={4} 
                    align="stretch" 
                    mt={isCollapsed ? "1em" : "4em"} 
                    maxH="350px" 
                    overflowY="scroll"
                >
                    {items.map((item) => (
                        <Box key={item.name} w="full" mb={5}>
                            <Flex
                                align="center"
                                w="full"
                                onClick={() => {
                                    handleItemClick(item.name, item.link);
                                    if (item.subMenu) handleSubMenuToggle(item.name);
                                }}
                                bg='transparent'
                                color={activeItem === item.name ? activeColor : 'white'}
                                _hover={{
                                    color: activeColor,
                                    cursor: 'pointer'
                                }}
                            >
                                <Icon 
                                    as={item.icon}
                                    fontSize={isCollapsed ? '1.5em' : "1.2em"}
                                />
                                {!isCollapsed && (
                                    <Text ml={4} flex="1" mb={0}>
                                        {item.name}
                                    </Text>
                                )}
                                {!isCollapsed && item.subMenu && (
                                    <IconButton
                                        aria-label="Toggle submenu"
                                        icon={activeMenu === item.name ? 
                                            <Icon as={FiChevronDown} /> : 
                                            <Icon as={FiChevronRight} />
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSubMenuToggle(item.name);
                                        }}
                                        variant="link"
                                        transition="all 0.3s"
                                        transform={activeMenu === item.name ? 'rotate(180deg)' : 'rotate(0deg)'}
                                    />
                                )}
                            </Flex>
                            <Collapse in={activeMenu === item.name}>
                                {!isCollapsed && item.subMenu && (
                                    <VStack align="stretch" pl={8} mt={2}>
                                        {item.subMenu.map((subItem) => (
                                            <Text
                                                key={subItem.name}
                                                onClick={() => handleItemClick(subItem.name, subItem.link)}
                                                bg={activeItem === subItem.name ? activeColor : 'transparent'}
                                                _hover={{ color: activeColor, cursor: 'pointer' }}
                                                p={1}
                                                mb={0}
                                            >
                                                {subItem.name}
                                            </Text>
                                        ))}
                                    </VStack>
                                )}
                            </Collapse>
                        </Box>
                    ))}
                </VStack>
                <Flex 
                    mt="auto"
                    direction="column"
                    position="absolute"
                    bottom={5}
                    left={3}
                    right={0}
                >
                    <Flex 
                        gap={2} 
                        cursor="pointer"
                        onClick={() => navigateTo("/client/x-capital-terminal/account-settings")}
                    >
                        <Avatar 
                            name={username} 
                            src={"/img/profile_picture.jpg"}
                            mb={4}
                        />
                        {!isCollapsed && (
                            <Text mt={3} _hover={{color: "#ffce30"}}>
                                {username}
                            </Text>
                        )}
                    </Flex>
                    <Button
                        variant="outline"
                        mt={2}
                        w="90%"
                        color="white"
                        p={3}
                        borderColor="white"
                    >
                        Logout
                    </Button>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Sidebar;
