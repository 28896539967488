import React from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import {Utils} from "../../../utils/Utils";
import {Bar} from "react-chartjs-2";

const StockGlass = () => {
    const buyingData = {
        labels: Utils.getYearsArray(0, 8).fill(''),
        datasets: [{
            label: 'Buy',
            categoryPercentage: 1,
            barPercentage: 1,
            data: [150, 125, 100, 50, 40, 30, 20, 5],
            borderWidth: {
                top:1,
                right:0,
                bottom:0,
                left:0
            },
            borderColor:'rgb(32,80,0)',
            backgroundColor: 'rgb(138,255,130)',
        }]
    };
    const sellingData = {
        labels: Utils.getYearsArray(0, 8).fill(''),
        datasets: [{
            label: 'Sell',
            categoryPercentage: 1,
            barPercentage: 1,
            data: [5, 20, 30, 40, 50, 100, 125, 150],
            borderWidth: {
                top:1,
                right:0,
                bottom:0,
                left:0
            },
            borderColor: 'rgb(255,0,0)',
            backgroundColor: 'rgb(255,105,105)',
        }]
    };
    const options = {
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: 'white'
                }
            },
        },
        scales: {
            x: {
                grid: {
                    color: 'transparent'
                },
                ticks: {
                    display: false,
                    color: 'white'
                }
            },
            y: {
                grid: {
                    color: 'transparent'
                },
                ticks: {
                    display: false,
                    color: 'white'
                }
            }
        }
    }
    return (
        <Box color={'white'}>
            <Box bg={"#1f1f1f"} h="80px" p={6} fontSize={"20px"} fontWeight={"bold"} color='white'>
                Stock Glass
            </Box>
            <Flex className="row">
                <Box className="col-md-6">
                    <Box height="200px">
                        <Bar data={buyingData} options={options} width="100%"/>
                    </Box>
                    <Box p={2}>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>495.4</Text>
                            <Text mb={0} color={"green"}>286</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>487</Text>
                            <Text mb={0} color={"green"}>145</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>501.69</Text>
                            <Text mb={0} color={"green"}>97</Text>
                        </Flex>
                    </Box>
                </Box>
                <Box className="col-md-6">
                    <Box height="200px">
                        <Bar data={sellingData} options={options} width="100%"/>
                    </Box>
                    <Box p={2}>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0} color={"red"}>184</Text>
                            <Text mb={0}>500.94</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0} color={"red"}>64</Text>
                            <Text mb={0}>505.60</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0} color={"red"}>104</Text>
                            <Text mb={0}>521.88</Text>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default StockGlass;