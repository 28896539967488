import React from 'react';
import CompanyTechnicalAnalysis from "../CompanyTechnicalAnalysis";
import TechnicalIndicatorsTable from "../TechnicalIndicatorsTable";
import MovingAveragesTable from "../MovingAveragesTable";
import PivotPointsTable from "../PivotPointsTable";

const TechnicalAnalysisComponent = () => {
    return (
        <div className='m-auto'>
            {/*Company Technical Analysis*/}
            <CompanyTechnicalAnalysis/>
            {/*Company Technical Indicators (Table)*/}
            <TechnicalIndicatorsTable/>
            {/*Company Moving Averages (Table)*/}
            <MovingAveragesTable/>
            {/*Company Pivot Points (Table)*/}
            <PivotPointsTable/>
        </div>
    );
};

export default TechnicalAnalysisComponent;