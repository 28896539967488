import React from 'react';
import FinancialRatiosTable from "../FinancialRatiosTable";
import CurrentRatioTable from "../CurrentRatioTable";
import CurrentRatioTrendChart from "../CurrentRatioTrendChart";

const RatiosComponent = () => {
    return (
        <div>
            <FinancialRatiosTable/>
            <CurrentRatioTable/>
            <CurrentRatioTrendChart/>
        </div>
    );
};

export default RatiosComponent;