import React, { useState } from 'react';
import {
  Box, Flex, Table, Thead, Tbody, Tr, Th, Td, IconButton, Collapse,
} from '@chakra-ui/react';
import { AiOutlineDown, AiOutlineRight } from 'react-icons/ai';
import { Line } from 'react-chartjs-2';

const FinancialTable = () => {
  const [isBondsOpen, setIsBondsOpen] = useState(true);
  const [isEquityOpen, setIsEquityOpen] = useState(true);

  const toggleBonds = () => setIsBondsOpen(!isBondsOpen);
  const toggleEquity = () => setIsEquityOpen(!isEquityOpen);

  const generateRandomPrices = (basePrice, count) => {
    const prices = [basePrice];
    for (let i = 1; i < count; i++) {
      const change = (Math.random() - 0.5) * 0.02 * basePrice; // Random change within ±2%
      prices.push(Math.round((prices[i - 1] + change) * 100) / 100);
    }
    return prices;
  };

  const bondsData = [
    { name: "Moroccan Treasury Bond 2026", price: 1020, priceChange: "1.5%", smartScore: 8, shares: 100, value: 102000, weight: "20%", profitLoss: "1,500 (45%)", consensus: generateRandomPrices(1020, 30) },
    { name: "OCP Green Bond 2025", price: 980, priceChange: "-0.5%", smartScore: 7, shares: 150, value: 147000, weight: "15%", profitLoss: "-750 (-5%)", consensus: generateRandomPrices(980, 30) },
    { name: "BMCE Bank Subordinated Bond 2024", price: 1050, priceChange: "2.1%", smartScore: 9, shares: 80, value: 84000, weight: "18%", profitLoss: "1,680 (52%)", consensus: generateRandomPrices(1050, 30) },
    { name: "Attijariwafa Bank Perpetual Bond", price: 1100, priceChange: "3.2%", smartScore: 8, shares: 120, value: 132000, weight: "22%", profitLoss: "3,840 (60%)", consensus: generateRandomPrices(1100, 30) },
  ];

  const equityData = [
    { name: "Maroc Telecom (IAM)", price: 138, priceChange: "2.2%", smartScore: 9, shares: 500, value: 69000, weight: "25%", profitLoss: "3,000 (55%)", consensus: generateRandomPrices(138, 30) },
    { name: "Attijariwafa Bank", price: 452, priceChange: "-0.8%", smartScore: 7, shares: 200, value: 90400, weight: "20%", profitLoss: "-1,600 (-10%)", consensus: generateRandomPrices(452, 30) },
    { name: "LafargeHolcim Maroc", price: 1850, priceChange: "1.1%", smartScore: 8, shares: 50, value: 92500, weight: "18%", profitLoss: "2,500 (30%)", consensus: generateRandomPrices(1850, 30) },
    { name: "Cosumar", price: 215, priceChange: "0.5%", smartScore: 6, shares: 300, value: 64500, weight: "15%", profitLoss: "750 (12%)", consensus: generateRandomPrices(215, 30) },
  ];

  const renderMiniChart = (consensus) => {
    const isPositive = consensus[consensus.length - 1] >= consensus[0];
    const color = isPositive ? 'green' : 'red';

    return (
      <Box width="100px" height="20px">
        <Line
          data={{
            labels: new Array(consensus.length).fill(''),
            datasets: [{
              data: consensus,
              borderColor: color,
              borderWidth: 1.5,
              fill: false,
              tension: 0.1
            }]
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: { display: false },
              y: { display: false }
            },
            plugins: {
              legend: { display: false }
            },
            elements: {
              point: { radius: 0 },
              line: { tension: 0.1 }
            }
          }}
        />
      </Box>
    );
  };

  return (
    <Box bg="#121212" p={4} color="white">
      <Box overflowX="auto">
        <Table variant="simple" colorScheme="gray" size={{ base: 'sm', md: 'md', lg: 'lg' }}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Price</Th>
              <Th>Price Change</Th>
              <Th>Analysis Consensus</Th>
              <Th>Smart Score</Th>
              <Th>Number of Shares</Th>
              <Th>Value</Th>
              <Th>Weight</Th>
              <Th>Profit & Loss</Th>
            </Tr>
          </Thead>
          <Tbody>
            {/* Bonds Section */}
            <Tr>
              <Td colSpan={9}>
                <Flex alignItems="center">
                  <IconButton
                    onClick={toggleBonds}
                    icon={isBondsOpen ? <AiOutlineDown /> : <AiOutlineRight />}
                    size="sm"
                    variant="ghost"
                    color="white"
                    _hover={{ color: "black", bg: "white" }}
                    aria-label="Toggle Bonds"
                  />
                  <Box as="span" ml={2} fontWeight="bold">Bonds</Box>
                </Flex>
              </Td>
            </Tr>

            {/* Toggle Visibility, not table rows */}
            {isBondsOpen && bondsData.map((bond, index) => (
              <Tr key={index}>
                <Td>{bond.name}</Td>
                <Td>{bond.price}</Td>
                <Td>{bond.priceChange}</Td>
                <Td>{renderMiniChart(bond.consensus)}</Td>
                <Td>{bond.smartScore}</Td>
                <Td>{bond.shares}</Td>
                <Td>{bond.value}</Td>
                <Td>{bond.weight}</Td>
                <Td color={bond.profitLoss.includes('-') ? "red.400" : "green.400"}>{bond.profitLoss}</Td>
              </Tr>
            ))}

            {/* Equity Section */}
            <Tr>
              <Td colSpan={9}>
                <Flex alignItems="center">
                  <IconButton
                    onClick={toggleEquity}
                    icon={isEquityOpen ? <AiOutlineDown /> : <AiOutlineRight />}
                    size="sm"
                    variant="ghost"
                    color="white"
                    _hover={{ color: "black", bg: "white" }}
                    aria-label="Toggle Equity"
                  />
                  <Box as="span" ml={2} fontWeight="bold">Equity</Box>
                </Flex>
              </Td>
            </Tr>

            {/* Toggle Visibility, not table rows */}
            {isEquityOpen && equityData.map((equity, index) => (
              <Tr key={index}>
                <Td>{equity.name}</Td>
                <Td>{equity.price}</Td>
                <Td>{equity.priceChange}</Td>
                <Td>{renderMiniChart(equity.consensus)}</Td>
                <Td>{equity.smartScore}</Td>
                <Td>{equity.shares}</Td>
                <Td>{equity.value}</Td>
                <Td>{equity.weight}</Td>
                <Td color={equity.profitLoss.includes('-') ? "red.400" : "green.400"}>{equity.profitLoss}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default FinancialTable;
