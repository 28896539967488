import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select,
    Tab,
    TabIndicator,
    Table,
    TableContainer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiFilter } from 'react-icons/fi';
import { Line } from 'react-chartjs-2';
import { FaSearch } from "react-icons/fa";

const CompaniesList = () => {
    const [companiesData, setCompaniesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_COMPANIES, {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    
                    const processedData = data.map((company) => {
                        const previousClosePrice = parseFloat(company.price) / (1 + parseFloat(company.change_percentage) / 100);
                        const netChange = parseFloat(company.price) - previousClosePrice;
                        const pctChange = parseFloat(company.change_percentage);

                        const startOfYearPrice = parseFloat(company.start_of_year_price || previousClosePrice);
                        const ytdChange = ((parseFloat(company.price) - startOfYearPrice) / startOfYearPrice) * 100;

                        const startOfCurrentPeriodPrice = parseFloat(company.start_of_period_price || previousClosePrice);
                        const ytdCurChange = ((parseFloat(company.price) - startOfCurrentPeriodPrice) / startOfCurrentPeriodPrice) * 100;

                        return {
                            ...company,
                            netChg: netChange.toFixed(2),
                            pctChg: pctChange.toFixed(2),
                            ytd: ytdChange.toFixed(2),
                            ytdCur: ytdCurChange.toFixed(2),
                            value: company.market_cap
                        };
                    });

                    setCompaniesData(processedData);
                } else {
                    console.error("Failed to fetch companies data:", response.status);
                }
            } catch (error) {
                console.error("Error fetching companies data:", error);
            }
        };

        fetchCompanies();
    }, []);

    const totalPages = Math.ceil(companiesData.length / rowsPerPage);
    const displayedCompanies = companiesData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const getPageNumbers = () => {
        const pages = [];
        if (totalPages <= 3) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage === 1) {
                pages.push(1, 2, 3);
            } else if (currentPage === totalPages) {
                pages.push(totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(currentPage - 1, currentPage, currentPage + 1);
            }
        }
        return pages;
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: { display: false },
            y: { display: false },
        },
        elements: {
            line: { tension: 0 },
            point: { radius: 0 },
        },
        plugins: { legend: { display: false } },
    };

    const getDataOfCompany = (company) => ({
        labels: ['Previous', 'Current'],
        datasets: [
            {
                data: [0, parseFloat(company.change_percentage)],
                borderColor: company.change_percentage >= 0 ? 'green' : 'red',
                borderWidth: 1,
                fill: true,
            },
        ],
    });

    return (
        <Box bg="black" color="white" mx="auto" mt={3}>
            <Box bg="#1f1f1f" textAlign="center" w="full" h="40px" p={2} mt="40px" fontSize="18px" color="white">
                <Text>List of Companies</Text>
            </Box>
            <Box bg="#171717" w="full" p={2} fontSize="18px" color="white">
                <Flex>
                    <Menu>
                        <MenuButton
                            as={Button}
                            h="35px"
                            bg="#161616"
                            color="white"
                            borderWidth="1px 0 1px 1px"
                            borderRadius="5px 0 0px 5px"
                            borderColor="rgba(119,119,119,0.5)"
                            _hover={{ bg: "#242424" }}
                            rightIcon={<FiChevronDown />}
                            _focus={{ bg: "#242424" }}
                        >
                            <Flex alignItems={"center"}>
                                <Box m="0 10px 0 0" fontSize="12px">
                                    <FiFilter fill="white" />
                                </Box>
                                <Text m={0}>All</Text>
                            </Flex>
                        </MenuButton>
                        <MenuList bg="#121212" borderColor="transparent" minW="100px">
                            <MenuItem bg="#121212" _hover={{ bg: "#242424" }}>1</MenuItem>
                            <MenuItem bg="#121212" _hover={{ bg: "#242424" }}>2</MenuItem>
                        </MenuList>
                    </Menu>
                    <InputGroup w="300px">
                        <InputLeftElement pb={1}>
                            <FaSearch />
                        </InputLeftElement>
                        <Input
                            type="text"
                            h="35px"
                            placeholder="Search"
                            _placeholder={{ color: 'white', opacity: 0.7 }}
                            _focus={{
                                outline: 'none',
                                boxShadow: 'none',
                                borderColor: 'rgba(119,119,119,0.5)'
                            }}
                            _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                            borderColor="rgba(119,119,119,0.5)"
                            borderWidth="1px"
                            borderRadius="0px 5px 5px 0px"
                        />
                    </InputGroup>
                </Flex>
            </Box>
            <Tabs color="white" variant="unstyled">
                <Box>
                    <TabList>
                        <Tab _selected={{ color: "#ffce30" }}>All</Tab>
                    </TabList>
                    <TabIndicator mt="-1.5px" h="2px" bg="#ffce30" borderRadius="1px" />
                </Box>
                <Box bg="#121212" w="full" color="white">
                    <TabPanels>
                        <TabPanel p={0}>
                            <TableContainer>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr color="white">
                                            <Th>No</Th>
                                            <Th>Company</Th>
                                            <Th>Status</Th>
                                            <Th>Value</Th>
                                            <Th>Net Chg</Th>
                                            <Th>% Chg</Th>
                                            {/* <Th>Time</Th> */}
                                            <Th>% YTD</Th>
                                            <Th>% YTDCur</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {displayedCompanies.map((company, index) => (
                                            <Tr key={index}>
                                                <Td>{(currentPage - 1) * rowsPerPage + index + 1}</Td>
                                                <Td>{company.name}</Td>
                                                <Td>
                                                    <Box w="80px" h="30px">
                                                        <Line data={getDataOfCompany(company)} options={options} />
                                                    </Box>
                                                </Td>
                                                <Td>{company.value}</Td>
                                                <Td>{company.netChg}</Td>
                                                <Td>{company.pctChg}</Td>
                                                {/* <Td>{company.time}</Td> */}
                                                <Td>{company.ytd}%</Td>
                                                <Td>{company.ytdCur}%</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    </TabPanels>
                </Box>
            </Tabs>

            <Flex direction={{ base: "column", md: "row" }} justify="center" align="center" pt={4} pb={4} gap={4}>
                <Flex align="center">
                    <Button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        isDisabled={currentPage === 1}
                        mr={2}
                        bg="#161616"
                        color="white"
                        _hover={{ bg: "#242424" }}
                        leftIcon={<FiChevronLeft />}
                        display={{ base: "none", sm: "flex" }}
                    >
                        Previous
                    </Button>
                    {getPageNumbers().map(pageNumber => (
                        <Button
                            key={pageNumber}
                            onClick={() => setCurrentPage(pageNumber)}
                            mx={1}
                            px={3}
                            py={2}
                            borderRadius="md"
                            bg={currentPage === pageNumber ? "#F8CD20" : "#121212"}
                            color={currentPage === pageNumber ? "black" : "white"}
                            fontWeight={currentPage === pageNumber ? "bold" : "normal"}
                            _hover={{ bg: currentPage === pageNumber ? "#F8CD20" : "#242424" }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            minW="32px"
                        >
                            {pageNumber}
                        </Button>
                    ))}
                    <Button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        isDisabled={currentPage === totalPages}
                        ml={2}
                        mr={4}
                        bg="#161616"
                        color="white"
                        _hover={{ bg: "#242424" }}
                        rightIcon={<FiChevronRight />}
                        display={{ base: "none", sm: "flex" }}
                    >
                        Next
                    </Button>
                </Flex>
                <Flex align="center">
                    <Text as="span" mr={2} color="white">
                        Result Per Page:
                    </Text>
                    <Select
                        value={rowsPerPage}
                        onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        w="70px"
                        bg="#161616"
                        color="white"
                        borderColor="#303235"
                        _hover={{ borderColor: "rgba(119,119,119,0.5)" }}
                        _focus={{ borderColor: "rgba(119,119,119,0.5)", boxShadow: "none" }}
                        sx={{
                            "& option": {
                                bg: "black",
                                color: "white",
                                border: "none"
                            }
                        }}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </Select>
                </Flex>
            </Flex>
        </Box>
    );
};

export default CompaniesList;
