import React from 'react';
import {Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

const PivotPointsTable = () => {
    const pivotPointsData = [
        { name: "Classic", S3: 508.74, S2: 511.87, S1: 513.44, pivotPoints: 516.57, R1: 518.14, R2: 521.27, R3: 522.84 },
        { name: "Fibonacci", S3: 511.87, S2: 513.67, S1: 514.77, pivotPoints: 516.57, R1: 518.37, R2: 519.47, R3: 521.27 },
        { name: "Camarilla", S3: 513.71, S2: 514.14, S1: 514.57, pivotPoints: 516.57, R1: 515.43, R2: 515.86, R3: 516.29 },
        { name: "Woodie's", S3: 507.96, S2: 511.48, S1: 512.66, pivotPoints: 516.18, R1: 517.36, R2: 520.88, R3: 522.06 },
        { name: "DeMark's", S3: null, S2: null, S1: null, pivotPoints: 516.17, R1: 517.35, R2: null, R3: null }
    ];
    return (
        <div>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Pivot Points
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>Name</Th>
                                <Th>S3</Th>
                                <Th>S2</Th>
                                <Th>S1</Th>
                                <Th>PIVOT POINTS</Th>
                                <Th>R1</Th>
                                <Th>R2</Th>
                                <Th>R3</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {pivotPointsData.map((pivot, index) =>
                                <Tr key={index}>
                                    <Td>{pivot.name}</Td>
                                    <Td>{pivot.S3 !== null ? pivot.S3 : '-'}</Td>
                                    <Td>{pivot.S2 !== null ? pivot.S2 : '-'}</Td>
                                    <Td>{pivot.S1 !== null ? pivot.S1 : '-'}</Td>
                                    <Td>{pivot.pivotPoints}</Td>
                                    <Td>{pivot.R1}</Td>
                                    <Td>{pivot.R2 !== null ? pivot.R2 : '-'}</Td>
                                    <Td>{pivot.R3 !== null ? pivot.R3 : '-'}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default PivotPointsTable;