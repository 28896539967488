import React from 'react';
import {Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import StrategyBuilderComponent from "../strategyBuilder/layout/StrategyBuilderComponent";
import BackTestingComponents from '../backtesting/layout/BackTestingComponents';

const QuantitativeComponent = () => {
    return (
        <div>
            <Tabs color="white" variant='unstyled'>
                <TabList className="tab-list" m={"auto"} justifyContent={"center"} fontSize={"24px"} overflowX={"auto"} display="flex" flexWrap="no-wrap">
                    <Tab _selected={{color: "#ffce30"}} minW={"fit-content"}>STRATEGY BUILDER</Tab>
                    <Tab _selected={{color: "#ffce30"}}>BACKTESTING</Tab>
                    <Tab _selected={{color: "#ffce30"}}>PERFORMANCE</Tab>
                </TabList>
                {/* <TabIndicator mt='-1.5px' height='2px' bg={"#ffce30"} borderRadius='1px'/> */}
                <TabPanels>
                    <TabPanel>
                        <StrategyBuilderComponent/>
                    </TabPanel>
                    <TabPanel>
                        <BackTestingComponents/>
                    </TabPanel>
                    <TabPanel>
                       
                    </TabPanel>   
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default QuantitativeComponent;