import React from 'react';
import {Box, Text} from "@chakra-ui/react";
import RevenueChart from "./financialCharts/RevenueChart";
import RevenueToProfitChart from "./financialCharts/RevenueToProfitChart";
import DeptAndCoverageChart from "./financialCharts/DeptAndCoverageChart";
import EarningChart from "./financialCharts/EarningChart";

const FinancialsSection = () => {
    return (
        <div>
            <Box mt={5}>
                <Text fontSize={'24px'} mb={0} color={'#ffce30'}>Financials</Text>
                <Box>
                    <Box className="row">
                        <Box className='col-md-6'>
                            <Box padding={4}>
                                <Box bgColor={'#101010'} p={2}>
                                    <Text textAlign='center'>Performance</Text>
                                    <RevenueChart/>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='col-md-6'>
                            <Box padding={4}>
                                <Box bgColor={'#101010'} p={2}>
                                    <Text textAlign='center'>Revenue to profit conversion</Text>
                                    <RevenueToProfitChart/>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='col-md-6'>
                            <Box padding={4}>
                                <Box bgColor={'#101010'} p={2}>
                                    <Text textAlign='center'>Dept level and coverage</Text>
                                    <DeptAndCoverageChart/>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='col-md-6'>
                            <Box padding={4}>
                                <Box bgColor={'#101010'} p={2}>
                                    <Text textAlign='center'>Earning</Text>
                                    <EarningChart/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default FinancialsSection;