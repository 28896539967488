import React from 'react';
import {Box, Button, Center, Flex, Menu, MenuButton, MenuItem, MenuList, Text} from "@chakra-ui/react";
import {FiChevronDown} from "react-icons/fi";
import {Chart} from "react-chartjs-2";
import {color} from "chart.js/helpers";


const SectorHeatmap = () => {
    const heatMapData = {
        equities: [
            {name: 'S&P 500 Futures', capacityMW: 100, dataCoverage: 150},
            {name: 'Nasdaq 100 Futures', capacityMW: 250, dataCoverage: 90},
            {name: 'Dow Jones Futures', capacityMW: 100, dataCoverage: 85},
            ],
        commodities: [
            {name: 'Crude Oil Futures', capacityMW: 100, dataCoverage: 150},
            {name: 'Gold Futures', capacityMW: 250, dataCoverage: 150},
            {name: 'Silver Futures', capacityMW: 100, dataCoverage: 100},
        ],
        currencies: [
            {name: 'EUR/USD Futures', capacityMW: 100, dataCoverage: 150},
            {name: 'GBP/USD Futures', capacityMW: 200, dataCoverage: 150},
            {name: 'JPY/USD Futures', capacityMW: 80, dataCoverage: 100},
        ],
        bonds: [
            {name: '10-Year Treasury', capacityMW: 100, dataCoverage: 30},
            {name: '30-Year Treasury', capacityMW: 100, dataCoverage: 35},
        ],
        interestRates: [
            {name: 'Eurodollar Futures', capacityMW: 100, dataCoverage: 150},
            {name: 'Fed Funds Futures', capacityMW: 250, dataCoverage: 90},
        ]
    }

    const heatMapOptions = {
        layout: {
            orientation: 'horizontal',
        },
        labels: {
            display: true,
            color: 'white',
            align: 'left',
            position: 'top',
            formatter: (context) => context.raw._data.name,
        },
        plugins: {

            title: {
                display: false,
                text: 'Treemap Example',
            },
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    title(items) {
                        return items[0].raw._data.name;
                    },
                    label(item) {
                        const {
                            _data: {capacityMW, dataCoverage},
                        } = item.raw;
                        return [
                            `Export capacity: ${capacityMW} MW`,
                            `Data Coverage: ${dataCoverage}`,
                        ];
                    },
                },
            },
        },
    };

    const getHeatmapDataOf = (sector) => {
        const heatMapConfig = {
            type: 'treemap',
            data: {
                datasets: [
                    {
                        tree: heatMapData[sector],
                        key: 'capacityMW',
                        backgroundColor(context) {
                            if (context.type !== 'data') return 'transparent';
                            const {dataCoverage} = context.raw._data;
                            if (dataCoverage >= 100) return color('rgb(15,239,170)').rgbString();
                            if (dataCoverage < 100 && dataCoverage >= 80) return color('rgb(14,192,154)').rgbString();
                            if (dataCoverage < 80 && dataCoverage >= 60) return color('rgb(11,78,64)').rgbString();
                            if (dataCoverage < 60 && dataCoverage >= 40) return color('rgb(81,25,39)').rgbString();
                            if (dataCoverage < 40 && dataCoverage >= 20) return color('rgb(199,50,88)').rgbString();
                            if (dataCoverage < 20) return color('rgb(246,60,107)').rgbString();
                        },
                    },
                ],
            },
        };
        return heatMapConfig.data
    }

    return (
        <div>
            <Box bg={"#1f1f1f"} w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Sector heatmap
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                    <Box m={"10px"} flex={1}>
                        <Menu>
                            <MenuButton as={Button}
                                        height={'35px'}
                                        bgColor="#161616"
                                        color="white"
                                        border={"1px solid #999999"}
                                        _hover={{bgColor: "#242424"}}
                                        rightIcon={<FiChevronDown style={{marginLeft: "20px"}}/>}
                                        _focus={{bgColor: "#242424"}}
                            >
                                Futures
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Cap 1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Cap 2</MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                    <Box className="sectors-heatmap" flex={1}>
                        <Text m={0}>Time frame</Text>
                        <div className="time-frame">
                            <input type="checkbox" className="btn-check" id="btn-check-outlined"
                                   autoComplete="off"/>
                            <label className="btn btn-outline-warning" htmlFor="btn-check-outlined">
                                D
                            </label>
                            <input type="checkbox" className="btn-check" id="btn-check-outlined1"
                                   autoComplete="off"/>
                            <label className="btn btn-outline-warning" htmlFor="btn-check-outlined1">
                                W
                            </label>
                            <input type="checkbox" className="btn-check" id="btn-check-outlined2"
                                   autoComplete="off"/>
                            <label className="btn btn-outline-warning" htmlFor="btn-check-outlined2">
                                M
                            </label>
                            <input type="checkbox" className="btn-check" id="btn-check-outlined3"
                                   autoComplete="off"/>
                            <label className="btn btn-outline-warning" htmlFor="btn-check-outlined3">
                                Y
                            </label>
                        </div>
                    </Box>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' h={'500px'} color='white'>
                <div className="d-flex row">
                    <div className="d-flex col-md-12 m-4">
                        <div className="flex-row">
                            <Box m={'0 15px 0 15px'} w={'320px'}>
                                <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" mt={"10px"} color='white'>
                                    Equities
                                </Box>
                                <Box>
                                    <Chart height='280px' type={'treemap'}
                                           data={getHeatmapDataOf('equities')}
                                           options={heatMapOptions}/>
                                </Box>
                            </Box>
                        </div>
                        <div className="d-flex flex-column">
                            <Box m={'0 15px 0 15px'} w={'280px'}>
                                <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" mt={"10px"} color='white'>
                                    Commodities
                                </Box>
                                <Box>
                                    <Chart height='280px' type={'treemap'} data={getHeatmapDataOf('commodities')}
                                           options={heatMapOptions}/>
                                </Box>
                            </Box>
                            <Box m={'0 15px 0 15px'} w={'280px'}>
                                <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" mt={"10px"} color='white'>
                                    Currencies
                                </Box>
                                <Box>
                                    <Chart height='130px' type={'treemap'} data={getHeatmapDataOf('currencies')}
                                           options={heatMapOptions}/>
                                </Box>
                            </Box>
                        </div>
                        <div className="d-flex flex-column">
                            <Box m={'0 15px 0 15px'} w={'230px'}>
                                <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" mt={"10px"} color='white'>
                                    Bonds
                                </Box>
                                <Box>
                                    <Chart height='230px' type={'treemap'} data={getHeatmapDataOf('bonds')}
                                           options={heatMapOptions}/>
                                </Box>
                            </Box>
                            <Box m={'0 15px 0 15px'} w={'230px'}>
                                <Box bg={"#1f1f1f"} p={'1px 0 0 7px'} h="30px" mt={"10px"} color='white'>
                                    Interest Rates
                                </Box>
                                <Box>
                                    <Chart height='230px' type={'treemap'} data={getHeatmapDataOf('interestRates')}
                                           options={heatMapOptions}/>
                                </Box>
                            </Box>
                        </div>
                        <Box fontSize={'10px'} m={'0 15px 0 15px'} bgColor={'transparent'} w={'250px'} height={'400px'}>
                            <Text fontSize={'16px'}>Legends (Capitalization)</Text>
                            <Box>
                                <Flex>
                                    <Box w={'40px'} h={'40px'} bgColor={'rgb(15,239,170)'} m={'10px 10px 0 10px'}/>
                                    <Center>Green (High) > 100 </Center>
                                </Flex>
                            </Box>
                            <Box>
                                <Flex>
                                    <Box w={'40px'} h={'40px'} bgColor={'rgb(14,192,154)'} m={'10px 10px 0 10px'}/>
                                    <Center>Green (Medium) 80~100 </Center>
                                </Flex>
                            </Box>
                            <Box>
                                <Flex>
                                    <Box w={'40px'} h={'40px'} bgColor={'rgb(11,78,64)'} m={'10px 10px 0 10px'}/>
                                    <Center>Green (Low) 60~80</Center>
                                </Flex>
                            </Box>

                            <Box>
                                <Flex mt={'20px'}>
                                    <Box w={'40px'} h={'40px'} bgColor={'rgb(81,25,39)'} m={'10px 10px 0 10px'}/>
                                    <Center>Red (Low) 40~60 </Center>
                                </Flex>
                            </Box>
                            <Box>
                                <Flex>
                                    <Box w={'40px'} h={'40px'} bgColor={'rgb(199,50,88)'} m={'10px 10px 0 10px'}/>
                                    <Center>Red (Medium) 20~40 </Center>
                                </Flex>
                            </Box>
                            <Box>
                                <Flex>
                                    <Box w={'40px'} h={'40px'} bgColor={'rgb(246,60,107)'} m={'10px 10px 0 10px'}/>
                                    <Center>Red (High) &lt; 20</Center>
                                </Flex>
                            </Box>
                            <Text width={'200px'} fontSize={'12px'} p={3}>
                                Higher opacity indicates higher intensity or magnitude.
                                <br/>
                                Lower opacity indicates lower intensity or magnitude.
                            </Text>
                        </Box>
                    </div>
                </div>
            </Box>

        </div>
    );
};

export default SectorHeatmap;