// Import necessary dependencies
import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { FiInfo } from 'react-icons/fi';
import { Box, Flex, Text } from '@chakra-ui/react';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// Chart configuration options
const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                color: 'white',
                usePointStyle: true,
                pointStyle: 'circle',
                padding: 20,
            },
        },
        title: {
            display: false,
        }
    },
    scales: {
        x: {
            ticks: { color: 'white' },
            grid: { color: 'rgba(255, 255, 255, 0.1)' },
        },
        y: {
            ticks: {
                color: 'white',
                callback: function (value) {
                    return value + '%';
                },
                stepSize: 5,
            },
            grid: { color: 'rgba(255, 255, 255, 0.1)' },
            min: 0,
            max: 10,
        },
    },
    elements: {
        line: {
            tension: 0,
        },
        point: {
            radius: 0,
            borderWidth: 0,
            hitRadius: 0,
        },
    },
};

// X-axis labels
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'];

// Chart data
const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => Math.random() * 10),
            borderColor: '#FFCE30',
            backgroundColor: 'rgba(255, 206, 48, 0.5)',
            pointRadius: [8, 0, 0, 0, 0, 0, 0, 0, 0, 8],
            pointHoverRadius: 8,
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => Math.random() * 10),
            borderColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            pointRadius: [8, 0, 0, 0, 0, 0, 0, 0, 0, 8],
            pointHoverRadius: 8,
        },
    ],
};

// Chart container style
const chartStyle = {
    borderRadius: '10px',
    backgroundColor: '#121212',
    padding: '20px',
    height: '60vh',
};

// BetaRiskFactorChart component
function BetaRiskFactorChart() {
    return (
        <Box>
            <Box borderRadius="10px" bg="#121212" p="20px" h="60vh">
                {/* Chart title and info icon */}
                <Flex alignItems="center" justifyContent="flex-start" mb={4}>
                    <Text fontSize="18px" fontWeight="bold" color="white" mr={2}>Beta Risk Factor</Text>
                    <Box as={FiInfo} size="20px" color="white" />
                </Flex>
                {/* Chart container with responsive width and horizontal scroll */}
                <Box h="calc(50vh - 60px)" overflowX={{base: "auto", sm: "hidden"}}>
                    <Box width={{base: "800px", sm: "100%"}} h="100%">
                        <Line options={options} data={data} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default BetaRiskFactorChart