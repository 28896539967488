// Import necessary dependencies and components
import React, { useState, useMemo } from 'react'
import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Button, InputGroup, InputLeftElement, Input, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Tfoot, Select } from '@chakra-ui/react'
import { FiChevronDown, FiFilter, FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import { FaSearch } from 'react-icons/fa'
import { TbCaretUpDownFilled } from "react-icons/tb";
import { HiMenuAlt2 } from "react-icons/hi";

// Mock options chain data for demonstration purposes
const optionsChainData = [
    { symbol: 'GCQ2024', name: 'Gold Futures', expirationDate: '2024-08-15', strikePrice: 2000, bidPrice: 35.20, askPrice: 35.40, type: 'Call', delta: 0.65, gamma: 0.02, vega: 0.15, theta: -0.08, impliedVolatility: 18.5 },
    { symbol: 'ESM2024', name: 'E-mini S&P 500', expirationDate: '2024-06-21', strikePrice: 4800, bidPrice: 125.50, askPrice: 126.00, type: 'Put', delta: -0.45, gamma: 0.01, vega: 0.25, theta: -0.12, impliedVolatility: 22.3 },
    { symbol: 'CLK2024', name: 'Crude Oil', expirationDate: '2024-05-15', strikePrice: 80, bidPrice: 2.15, askPrice: 2.20, type: 'Call', delta: 0.55, gamma: 0.03, vega: 0.18, theta: -0.06, impliedVolatility: 25.7 },
    { symbol: 'ZCN2024', name: 'Corn Futures', expirationDate: '2024-07-19', strikePrice: 550, bidPrice: 12.50, askPrice: 12.75, type: 'Put', delta: -0.35, gamma: 0.02, vega: 0.12, theta: -0.05, impliedVolatility: 20.1 },
    { symbol: 'NGM2024', name: 'Natural Gas', expirationDate: '2024-06-26', strikePrice: 3.50, bidPrice: 0.15, askPrice: 0.16, type: 'Call', delta: 0.48, gamma: 0.04, vega: 0.10, theta: -0.03, impliedVolatility: 28.4 },
    { symbol: 'SIQ2024', name: 'Silver Futures', expirationDate: '2024-08-28', strikePrice: 25, bidPrice: 0.85, askPrice: 0.87, type: 'Put', delta: -0.52, gamma: 0.02, vega: 0.14, theta: -0.07, impliedVolatility: 24.6 },
    { symbol: 'ZWN2024', name: 'Wheat Futures', expirationDate: '2024-07-12', strikePrice: 650, bidPrice: 15.20, askPrice: 15.40, type: 'Call', delta: 0.58, gamma: 0.03, vega: 0.16, theta: -0.09, impliedVolatility: 21.8 },
    { symbol: 'HGQ2024', name: 'Copper Futures', expirationDate: '2024-08-20', strikePrice: 400, bidPrice: 5.50, askPrice: 5.60, type: 'Put', delta: -0.42, gamma: 0.02, vega: 0.13, theta: -0.06, impliedVolatility: 19.9 },
    { symbol: 'ZSQ2024', name: 'Soybean Futures', expirationDate: '2024-08-23', strikePrice: 1200, bidPrice: 25.30, askPrice: 25.50, type: 'Call', delta: 0.62, gamma: 0.02, vega: 0.19, theta: -0.10, impliedVolatility: 23.5 },
    { symbol: 'ZBM2024', name: 'T-Bond Futures', expirationDate: '2024-06-19', strikePrice: 150, bidPrice: 2.80, askPrice: 2.85, type: 'Put', delta: -0.38, gamma: 0.01, vega: 0.11, theta: -0.04, impliedVolatility: 17.2 }
];

function OptionChainsComponent() {
    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Calculate total pages
    const totalPages = Math.ceil(optionsChainData.length / rowsPerPage);

    // Get current options for the current page
    const currentOptions = useMemo(() => 
        optionsChainData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
    [currentPage, rowsPerPage]);

    // Function to get page numbers for pagination
    const getPageNumbers = () => {
        if (totalPages <= 4) return Array.from({ length: totalPages }, (_, i) => i + 1);
        if (currentPage <= 2) return [1, 2, 3, 4];
        if (currentPage >= totalPages - 1) return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        return [currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    };

    // Helper function to render menu buttons
    const renderMenuButton = (text, icon) => (
        <MenuButton as={Button}
            height='35px'
            bgColor="#161616"
            color="white"
            m="0 15px 0 7px"
            border="1px solid #303235"
            _hover={{ bgColor: "#242424" }}
            rightIcon={icon}
            _focus={{ bgColor: "#242424" }}
            fontSize="12px"
        >
            {text}
        </MenuButton>
    );

    return (
        <div>
            {/* Header */}
            <Flex bg="#121212" justifyContent="center" w='100%' h="40px" p={2} fontSize="18px" color='white' position="relative">
                <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                    Options Chain
                    <Box position="absolute" right="10px">
                        <HiMenuAlt2 />
                    </Box>
                </Flex>
            </Flex>
            {/* Filter and search section */}
            <Box bg="#171717" w='100%' p={2} fontSize="18px" color='white'>
                <Flex>
                    {/* Date range filter */}
                    <Menu>
                        {renderMenuButton('Date range', <FiChevronDown style={{ marginLeft: "20px" }} />)}
                        <MenuList bgColor="#121212" borderColor="transparent">
                            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Metric 2</MenuItem>
                        </MenuList>
                    </Menu>

                    {/* Additional filter and search */}
                    <Flex flex={1} justifyContent='end' mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height='35px'
                                bgColor="#161616"
                                color="white"
                                borderWidth="1px 0 1px 1px"
                                borderRadius="5px 0 0px 5px"
                                borderColor="#303235"
                                _hover={{ bgColor: "#242424" }}
                                rightIcon={<FiChevronDown />}
                                _focus={{ bgColor: "#242424" }}
                            >
                                <Flex>
                                    <Box m="5px 10px 0 0" fontSize='12px'>
                                        <FiFilter fill="white" />
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#303235" borderColor="transparent" minWidth='100px'>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        {/* Search input */}
                        <InputGroup width='300px'>
                            <InputLeftElement pb={1}>
                                <FaSearch />
                            </InputLeftElement>
                            <Input 
                                type="text" 
                                height='35px' 
                                placeholder="Search"
                                _placeholder={{ color: '#fff', opacity: 0.7 }}
                                _focus={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: 'rgba(119,119,119,0.5)'
                                }}
                                _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                borderColor="#303235"
                                borderWidth="1px"
                                borderRadius="0px 5px 5px 0px"
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            {/* Options table */}
            <Box bg="#121212" w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color='white' className="table-header">
                                {['Symbol', 'Name', 'Expiration Date', 'Strike Price', 'Premium (Bid/Ask)', 'Type', 'Delta', 'Gamma', 'Vega', 'Theta', 'Implied Volatility'].map(header => (
                                    <Th key={header}>
                                        <Flex alignItems='center' gap={1}>{header} <TbCaretUpDownFilled /></Flex>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentOptions.map(option => (
                                <Tr key={option.symbol}>
                                    <Td>{option.symbol}</Td>
                                    <Td>{option.name}</Td>
                                    <Td>{option.expirationDate}</Td>
                                    <Td>{option.strikePrice.toFixed(2)}</Td>
                                    <Td>{`${option.bidPrice.toFixed(2)}/${option.askPrice.toFixed(2)}`}</Td>
                                    <Td>{option.type}</Td>
                                    <Td>{option.delta.toFixed(2)}</Td>
                                    <Td>{option.gamma.toFixed(3)}</Td>
                                    <Td>{option.vega.toFixed(2)}</Td>
                                    <Td>{option.theta.toFixed(2)}</Td>
                                    <Td>{option.impliedVolatility.toFixed(1)}%</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                {/* Pagination */}
                <Flex justifyContent="center" alignItems="center" pt={4} pb={4}>
                    <Button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        isDisabled={currentPage === 1}
                        mr={2}
                        bgColor="#161616"
                        color="white"
                        _hover={{ bgColor: "#242424" }}
                        leftIcon={<FiChevronLeft />}
                    >
                        Previous
                    </Button>
                    {getPageNumbers().map(pageNumber => (
                        <Box
                            key={pageNumber}
                            as="button"
                            onClick={() => setCurrentPage(pageNumber)}
                            mx={1}
                            px={3}
                            py={2}
                            borderRadius="md"
                            bg={currentPage === pageNumber ? "#F8CD20" : "#121212"}
                            color={currentPage === pageNumber ? "black" : "white"}
                            fontWeight={currentPage === pageNumber ? "bold" : "normal"}
                            _hover={{ bg: currentPage === pageNumber ? "#F8CD20" : "#242424" }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            minWidth="32px"
                        >
                            {pageNumber}
                        </Box>
                    ))}
                    <Button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        isDisabled={currentPage === totalPages}
                        ml={2}
                        mr={4}
                        bgColor="#161616"
                        color="white"
                        _hover={{ bgColor: "#242424" }}
                        rightIcon={<FiChevronRight />}
                    >
                        Next
                    </Button>
                    {/* Rows per page selector */}
                    <Box as="span" ml={10} mr={2} color="white">
                        Result Per Page:
                    </Box>
                    <Select
                        value={rowsPerPage}
                        onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        width="70px"
                        bgColor="#161616"
                        color="white"
                        borderColor="#303235"
                        _hover={{ borderColor: "rgba(119,119,119,0.5)" }}
                        _focus={{ borderColor: "rgba(119,119,119,0.5)", boxShadow: "none" }}
                        sx={{
                            "& option": {
                                backgroundColor: "black",
                                color: "white",
                                border: "none"
                            },
                            "& > option": {
                                border: "none"
                            }
                        }}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </Select>
                </Flex>
            </Box>
        </div>
    )
}

export default OptionChainsComponent