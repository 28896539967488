import React from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import {Bar} from "react-chartjs-2";

const LiabilitiesChart = () => {
    const liabilitiesData = {
        labels: ['item 1', 'item 2', 'item 3', 'item 4', 'item 5', 'item 6', 'item 7'],
        datasets: [{
            label: '2022',
            data: [180, 175, 95, 75, 400, 305, 10],
            backgroundColor: 'rgb(255,118,150)',
        }, {
            label: '2023',
            data: [190, 340, 65, 205, 100, 150, 65],
            backgroundColor: 'rgb(90,180,255)',
        }]
    };
    const config = {
        type: 'bar',
        data: liabilitiesData
    }
    const options = {
        plugins: {
            legend: {
                labels: {
                    color: 'white'
                }
            },
        },
        scales: {
            x: {
                grid: {
                    color: 'white'
                },
                ticks: {
                    color: 'white'
                }
            },
            y: {
                grid: {
                    color: 'white'
                },
                ticks: {
                    stepSize: 100,
                    color: 'white'
                }
            }
        }
    }
    return (
        <div>
            <Flex justifyContent={'center'}>
                <Box bgColor={'#101010'} p={2} mt={10} w={'70%'}>
                    <Text textAlign='center'>Liabilities</Text>
                    <Bar height='100px' data={config.data} options={options}/>
                </Box>
            </Flex>
        </div>
    );
};

export default LiabilitiesChart;