import React from 'react';
import RealTimeCurrencyTable from "../RealTimeCurrencyTable";
import {Box} from "@chakra-ui/react";
import ForexHeatmap from "../ForexHeatmap";
import MajorGlobalForexIndexesTable from "../MajorGlobalForexIndexesTable";
import EconomicCalendarTable from "../EconomicCalendarTable";

const Overview = () => {
    return (
        <Box pb={10}>
            <RealTimeCurrencyTable/>
            <ForexHeatmap/>
            <MajorGlobalForexIndexesTable/>
            <EconomicCalendarTable/>
        </Box>
    );
};

export default Overview;