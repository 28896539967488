import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';


const ContinentCountriesTable = () => {
  const [bondsData, setBondsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const countries = ["Morocco", "Egypt", "Coast Ivory", "South Africa"]; // List of countries

  const calculateYieldChange = (current, previous) => {
    if (previous === 0 || previous === null) return 0;
    return (((current - previous) / previous) * 100).toFixed(2);
  };

  const parseDate = (dateStr) => new Date(dateStr);

  const fetchAllBondsData = async () => {
    const dateFrames = [
      '3months', '6months', '9months', '1year', '2years', '3years',
      '5years', '7years', '10years', '12years', '15years', '20years',
      '25years', '30years'
    ];
  
    try {
      // Array of promises for all countries and dateFrames
      const allRequests = countries.flatMap((country) =>
        dateFrames.map((dateFrame) =>
          axios.get(
            `https://api.xcapitalterminal.com/api/v1/bonds-data/governments-bonds/?country=${country.toLowerCase()}&date_frame=${dateFrame}`
          )
          .then(response => ({
            country,
            dateFrame,
            data: response.data.results
          }))
        )
      );
  
      // Wait for all requests to complete
      const responses = await Promise.all(allRequests);
  
      // Process data for each country and date frame
      const allBondsData = countries.map((country) => {
        const countryData = responses.filter(res => res.country === country);
  
        const fetchedData = countryData.map(({ dateFrame, data }) => {
          if (data && data.length > 0) {
            const sortedData = data
              .map((item) => ({ ...item, date: parseDate(item.date) }))
              .sort((a, b) => b.date - a.date);
  
            const latest = sortedData[0];
            const latestDate = latest.date;
            const latestPrice = latest.price;
  
            const findClosestDate = (targetDate) => {
              return sortedData.reduce((closest, current) => {
                const currentDiff = Math.abs(current.date - targetDate);
                const closestDiff = Math.abs(closest.date - targetDate);
                return currentDiff < closestDiff ? current : closest;
              });
            };
  
            const oneMonthAgo = new Date(latestDate);
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  
            const oneYearAgo = new Date(latestDate);
            oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  
            const closestMonthData = findClosestDate(oneMonthAgo);
            const closestYearData = findClosestDate(oneYearAgo);
  
            const dayChange = calculateYieldChange(latestPrice, sortedData[1]?.price || null);
            const monthChange = calculateYieldChange(latestPrice, closestMonthData?.price || null);
            const yearChange = calculateYieldChange(latestPrice, closestYearData?.price || null);
  
            return {
              dateFrame,
              bondType: `${country.toUpperCase()}`,
              dayChange,
              monthChange,
              yearChange,
            };
          }
          return null;
        }).filter(Boolean);
  
        // Add the last dateFrame's data for the current country
        return fetchedData[fetchedData.length - 1];
      }).filter(Boolean);
  
      setBondsData(allBondsData);
      setLoading(false);
    } catch (err) {
      console.error('Fetch error:', err);
      setError('Failed to fetch data');
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchAllBondsData();
  }, []);

  if (loading) return <Box color="white" textAlign="center">Loading...</Box>;
  if (error) return <Box color="red" textAlign="center">{error}</Box>;

  return (
    <Box>
      <Box
        bg={'#1f1f1f'}
        textAlign="center"
        w="100%"
        h="40px"
        p={2}
        mt={'40px'}
        fontSize={'18px'}
        color="white"
      >
        Government Bonds by Country
      </Box>

      {/* Table */}
      <Box bg={'#121212'} w="100%" color="white">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Bond Type</Th>
                <Th>1 Day Change (%)</Th>
                <Th>1 Month Change (%)</Th>
                <Th>1 Year Change (%)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {bondsData.map((bond, index) => (
                <Tr key={index}>
                  <Td>{bond.bondType}</Td>
                  <Td>{bond.dayChange}</Td>
                  <Td>{bond.monthChange}</Td>
                  <Td>{bond.yearChange}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ContinentCountriesTable;

