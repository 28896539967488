import React from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Table,
    TableContainer,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";

const FinancialRatiosTable = () => {
    const financialRatios = [
        {
            category: "Profitability Ratios",
            data: [
                {ratio: "Earnings Per Share (EPS)", value1: 4.5, value2: 4.0, value3: 3.5},
                {ratio: "Return on Assets (ROA)", value1: 8, value2: 7, value3: 6},
                {ratio: "Return on Equity (ROE)", value1: 12, value2: 11, value3: 10},
                {ratio: "Return on Investment (ROI)", value1: 10, value2: 9, value3: 8},
                {ratio: "Operating Profit Margin", value1: 17, value2: 16, value3: 15},
                {ratio: "Net Profit Margin", value1: 13, value2: 10, value3: 12},
                {ratio: "Gross Profit Margin", value1: 36, value2: 32, value3: 30},
            ],
        },
        {
            category: "Liquidity Ratios",
            data: [
                {ratio: "Quick Ratio", value1: 1.4, value2: 1.3, value3: 1.2},
                {ratio: "Current Ratio", value1: 1.7, value2: 1.7, value3: 1.5},
                {ratio: "Cash Ratio", value1: 0.7, value2: 0.6, value3: 0.5},
                {ratio: "Cash Flow Coverage Ratio", value1: 2.5, value2: 2.2, value3: 2.0},
                {ratio: "Net Working Capital", value1: 15000000, value2: 12000000, value3: 10000000},
                {ratio: "Cash Conversion Cycle", value1: 40, value2: 42, value3: 45},
                {ratio: "Inventory Turnover", value1: 6.0, value2: 5.5, value3: 5.0},
                {ratio: "Asset Turnover", value1: 1.0, value2: 0.9, value3: 0.8},
            ],
        },
        {
            category: "Solvency Ratios",
            data: [
                {ratio: "Debt Ratio", value1: 0.3, value2: 0.35, value3: 0.4},
                {ratio: "Equity Ratio", value1: 0.7, value2: 0.65, value3: 0.6},
                {ratio: "Debt-to-Equity Ratio", value1: 0.43, value2: 0.55, value3: 0.67},
                {ratio: "Debt Service Coverage Ratio", value1: 3.0, value2: 2.8, value3: 2.5},
                {ratio: "Interest Coverage Ratio", value1: 8.0, value2: 7.5, value3: 6.5},
                {ratio: "Cash Flow to Debt Ratio", value1: 0.4, value2: 0.35, value3: 0.3},
                {ratio: "Long-term Debt to Assets", value1: 0.16, value2: 0.18, value3: 0.2},
                {ratio: "Long-term Debt to Equity", value1: 0.23, value2: 0.26, value3: 0.28},
            ],
        },
        {
            category: "Valuation Ratios",
            data: [
                {ratio: "The Price-to-Earnings (P/E)", value1: 17, value2: 16, value3: 15},
                {ratio: "The Price-to-Sales (P/S)", value1: 9.2, value2: 9.1, value3: 8.0},
                {ratio: "The Dividend Yield", value1: 2.7, value2: 2.5, value3: 2.5},
                {ratio: "Price-to-Cash Flow (P/CF)", value1: 14, value2: 13, value3: 12},
                {ratio: "Price-to-Book (P/B)", value1: 1.9, value2: 1.8, value3: 1.5},
                {ratio: "Enterprise Value", value1: 65000000, value2: 56000000, value3: 50000000},
                {ratio: "Enterprise Value-to-EBITDA", value1: 9, value2: 8.5, value3: 8},
            ],
        },
    ];
    return (
        <Box mt={10}>
            <Box bg={"#1f1f1f"} textAlign="center" w="100%" h="40px" p={2} fontSize={"18px"} color="white">
                Financial Ratios Table
            </Box>
            <TableContainer>
                <Table variant="simple" color="white">
                    <Thead className='table-header'>
                        <Tr>
                            <Th width={'25%'}>Ratio</Th>
                            <Th width={'25%'}>Value 2024</Th>
                            <Th width={'25%'}>Value 2023</Th>
                            <Th width={'25%'}>Value 2022</Th>
                        </Tr>
                    </Thead>
                </Table>
                <Accordion allowMultiple defaultIndex={Array.from({length: financialRatios.length + 1}, (_, index) => index)}>
                    {financialRatios.map(category => (
                        <AccordionItem aria-expanded={true}>
                            <AccordionButton bgColor={'#1f1f1f'} _hover={{backgroundColor: '#272727'}}>
                                <AccordionIcon/>
                                <Box as='span' flex='1' textAlign='left' color={'#ffce30'}>
                                    {category.category}
                                </Box>
                            </AccordionButton>
                            <AccordionPanel p={0}>
                                <Table>
                                    {category.data.map((ratio, index) => (
                                        <Tr key={index}>
                                            <Td width={'25%'}>{ratio.ratio}</Td>
                                            <Td width={'25%'}>{ratio.value1}</Td>
                                            <Td width={'25%'}>{ratio.value2}</Td>
                                            <Td width={'25%'}>{ratio.value3}</Td>
                                        </Tr>
                                    ))}
                                </Table>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </TableContainer>
        </Box>
    );
};

export default FinancialRatiosTable;