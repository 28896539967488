import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ZscoreChart = () => {

    function generateVolatileData(count, { min, max }) {
        const data = [];
        let i = 0;
        let value = (max + min) / 2;
        const timeStep = 3600000;
        const startTime = new Date().getTime() - (count - 1) * timeStep;
      
        while (i < count) {
          const x = startTime + i * timeStep;
          const change = (Math.random() - 0.5) * (max - min) * 0.2; 
          value += change;
          value = Math.max(min, Math.min(max, value));
          data.push([x, parseFloat(value.toFixed(2))]);
          i++;
        }
        return data;
      }

  const options = {
    chart: {
      type: 'area',
      height: 150,
      background: '#000000',
      foreColor: '#ffffff',
      toolbar: { show: false },
    },
    colors: ['#FFD700'],
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 2,
      colors: ['#FFD700'],
    },
    fill: {
      type: 'solid',
      colors: ['#FFD700'],
      opacity: 0.4,
    },
    xaxis: {
      type: 'datetime',
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      min: -2,
      max: 2,
      tickAmount: 4,
      labels: {
        formatter: (value) => value.toFixed(0),
        style: {
          colors: '#ffffff',
        },
      },
    },
    grid: {
      borderColor: '#707070',
      yaxis: {
        lines: { show: true },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 1.2,
          borderColor: '#FFD700',
          label: {
            borderColor: '#FFD700',
            style: { color: '#000', background: '#FFD700' },
            text: '1.2 threshold',
            position: 'right',
          },
        },
        {
          y: -1.2,
          borderColor: '#FFD700',
          label: {
            borderColor: '#FFD700',
            style: { color: '#000', background: '#FFD700' },
            text: '-1.2 threshold',
            position: 'right',
          },
        },
      ],
    },
    tooltip: {
      theme: 'dark',
    },
  };

  const series = [
    {
      name: 'Zscore',
      data: generateVolatileData(50, { min: -2, max: 2 }),
    },
  ];

  return <ReactApexChart options={options} series={series} type="area" height={150} />
 
};

export default ZscoreChart;
