import axios from "axios";

const token = process.env.REACT_APP_BEARER_TOKEN
const MARKET_API_URL = process.env.REACT_APP_TERMINAL_API_URL + '/market-data'
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
}

export const getAllCompanies = () => {
    return axios.get(process.env.REACT_APP_API_COMPANIES, config)
}
export const getAllProducers = () => {
    return axios.get(MARKET_API_URL + '/producers', config)
}
export const getAllCustomers = () => {
    return axios.get(MARKET_API_URL + '/customers', config)
}

export const getAllIndexes = (searchQuery = '', page = 1, limit = 10) => {
    const baseUrl = process.env.REACT_APP_API_INDEXES;
    const url = `${baseUrl}?${searchQuery ? `search=${searchQuery}&` : ''}page=${page}&limit=${limit}`;
    return axios.get(url, config);
  };
  
export const getAllStockMarkets = () => {
    return axios.get(MARKET_API_URL + '/stockmarkets', config)
}

