import React from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import { MdBarChart, MdCandlestickChart, MdOutlineAreaChart } from "react-icons/md";
import {FaBell, FaEye} from "react-icons/fa";

const CompanyDetails = () => {
    return (
        <Flex>
            <Flex className="row" width="100%">
                <Box flex={1.02}>
                    <Flex display={"column"} color={"white"}>
                        <Flex>
                            <Text mb={0} fontSize={"18px"}>ATW</Text>
                            <Text m={"0 0 0 10px"} fontSize={"14px"}>Attijariwafa Bank</Text>
                            <FaEye style={{margin:"0 5px 0 15px"}}/>
                            <FaBell style={{margin:"0 5px 0 5px"}}/>
                        </Flex>
                        <Flex>
                            <Text mb={0} color={"#ffce30"} fontSize={"36px"} fontWeight={"bold"}>520,00</Text>
                            <Flex display={"column"} ml={2}>
                                <Text mb={0} color="green">+2.24%</Text>
                                <Text mb={0} color="green">+0.26%</Text>
                            </Flex>
                            <Flex alignItems={"end"} ml={5} pb={1}>
                                <MdCandlestickChart style={{marginRight:"20px"}}/>
                                <MdOutlineAreaChart style={{marginRight:"20px"}}/>
                                <MdBarChart ml={5}/>
                            </Flex>
                        </Flex>
                        <Flex fontSize={"14px"}>
                            <Text>After hours:</Text>
                            <Text color={"red"} ml={2}>525,40 -0.07%</Text>
                            <Text ml={2}> | 19:59 04/26 EDT</Text>
                        </Flex>
                    </Flex>
                </Box>
                <Box flex={1}>
                    <Box color={"white"} fontSize={"14px"}>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>Open</Text>
                            <Text mb={0} color={"green"}>525.40</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>Low</Text>
                            <Text mb={0} color={"red"}>520.00</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>High</Text>
                            <Text mb={0} color={"green"}>525.40</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                        <Text mb={0}>52 wk high</Text>
                        <Text mb={0}>509.00</Text>
                    </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>52 wk low</Text>
                            <Text mb={0}>507.00</Text>
                        </Flex>

                    </Box>
                </Box>
                <Box flex={1}>
                    <Box color={"white"} fontSize={"14px"}>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>AVG Vol (3M)</Text>
                            <Text mb={0}>13M</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>Shares Outstanding</Text>
                            <Text mb={0}>215M</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>Market cap</Text>
                            <Text mb={0}>111B</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>Div Yield</Text>
                            <Text mb={0}>0.15%</Text>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Text mb={0}>View all</Text>
                            <Text mb={0}> = </Text>
                        </Flex>

                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
};

export default CompanyDetails;