import React from 'react';
import {Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import OverViewComponent from "../overview/layout/OverViewComponent";
import DataAndAnalysisComponent from "../dataAndAnalysis/layout/DataAndAnalysisComponent";
import YieldAnalysisComponent from "../yieldAnalysis/layout/YieldAnalysisComponent";
import News from "../yieldAnalysis/News";

const BondMarketsComponent = () => {
    return (
        <Tabs color="white" variant='unstyled'>
            <TabList className="tab-list" m={"auto"} justifyContent={"center"} fontSize={"24px"}>
                <Tab _selected={{color: "#ffce30"}}>Overview</Tab>
                <Tab _selected={{color: "#ffce30"}}>Data & Analysis</Tab>
                <Tab _selected={{color: "#ffce30"}}>Yield analysis</Tab>
                <Tab _selected={{color: "#ffce30"}}>News</Tab>
            </TabList>
            <TabIndicator mt='-1.5px' height='2px' bg={"#ffce30"} borderRadius='1px'/>
            <TabPanels>
                {/*overview*/}
                <TabPanel>
                    <OverViewComponent/>
                </TabPanel>
                {/*Data & analysis*/}
                <TabPanel>
                    <DataAndAnalysisComponent/>
                </TabPanel>
                {/*Yield analysis*/}
                <TabPanel>
                    <YieldAnalysisComponent/>
                </TabPanel>
                {/*News*/}
                <TabPanel>
                    <News/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default BondMarketsComponent;