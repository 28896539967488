import React from 'react';
import {Box, Select, Text} from "@chakra-ui/react";

const ArimaLstmHybrid = () => {
    return (
        <div>
            <Box marginTop={8}>
                <Text color={'#ffce30'} fontSize={'24px'} fontWeight={'bold'}>ARIMA-LSTM Hybrid</Text>
                <Box>
                    <Text fontSize={'18px'}>Description</Text>
                    <Text>
                        A combination of ARIMA and LSTM models, leveraging both statistical and deep learning approaches.
                    </Text>
                    <Text fontSize={'18px'}>Purpose</Text>
                    <Text>
                        Enhancing stock price predictions by capturing both linear trends (via ARIMA) and complex non-linear patterns (via LSTM) in market data.
                    </Text>
                </Box>
            </Box>
            <Box>
                <div className="filter-bar d-flex">
                    <div className="element">
                        <Select className="dropdown-select" width="auto">
                            <option selected hidden disabled value="">Select Company</option>
                            <option>Company 1</option>
                            <option>Company 2</option>
                        </Select>
                    </div>
                </div>
            </Box>
            <Box marginTop={10} height={'300px'} bgColor={'#1f1f1f'} borderRadius={'10px'}>
            </Box>
        </div>
    );
};

export default ArimaLstmHybrid;