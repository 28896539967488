// Import necessary dependencies and components
import React, {useRef, useEffect, useState} from "react";
import { Box, Flex, Text, Select } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Define colors for chart
const gridColor = "#444";
const textColor = "#FFF";

// Chart options configuration
const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: true, 
        color: gridColor, 
        borderDash: [5, 5],
      },
      ticks: {
        color: textColor,
        maxTicksLimit: 5,
      },
    },
    y: {
      position: 'right',
      grid: {
        color: gridColor,
        borderDash: [5, 5], 
      },
      ticks: {
        color: textColor,
        maxTicksLimit: 5,
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: true,
      backgroundColor: '#f8d24e',
      titleColor: '#000',
      bodyColor: '#000',
      intersect: false, 
      mode: 'index', 
      callbacks: {
        label: function (context) {
          return `${context.dataset.label}: ${context.formattedValue} MAD`;
        },
      },
    },
    legend: {
      display: false,
    },
  },
  hover: {
    mode: 'index',
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      borderWidth: 2,
      tension: 0,
    },
  },
  interaction: {
    mode: 'index',
    intersect: false,
    axis: 'x',
  },
  animation: false,
};

// Main component
const PortfolioAnalytics = () => {
  // State for selected time frame
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("1D");

  // Available time frames
  const timeFs = ["1D", "5D", "1M", "6M", "1Y", "5Y", "Max"];

  // Initial data and labels
  const [Data, setData] = useState([120, 125, 130, 128, 135, 140, 145]); 
  const [labels, setLabels] = useState(["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM"]);
  
  // Chart data configuration
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Portfolio Value (MAD)",
        data: Data,
        borderColor:'rgba(255, 215, 0, 1)',
        backgroundColor: '',
        tension: 0,
        pointRadius: 0,
        fill: true,
      },
    ],
  };

  // Data for different time frames
  const timeFrames = {
    "1D": {
      data: [120, 115, 130, 125, 140, 135, 145], 
      labels: ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM"],
    },
    "5D": {
      data: [100, 110, 105, 115, 108, 120, 118], 
      labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"], 
    },
    "1M": {
      data: [90, 100, 95, 105, 98, 110, 108], 
      labels: ["Week 1", "Week 2", "Week 3", "Week 4"], 
    },
    "6M": {
      data: [80, 90, 85, 95, 88, 100, 98], 
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    "1Y": {
      data: [70, 80, 75, 85, 78, 90, 88],
      labels: ["Jan", "Mar", "May", "Jul", "Sep", "Nov"], 
    },
    "5Y": {
      data: [50, 60, 55, 65, 58, 70, 68], 
      labels: ["2019", "2020", "2021", "2022", "2023"],
    },
    "Max": {
      data: [40, 50, 45, 55, 48, 60, 58],
      labels: ["2000", "2005", "2010", "2015", "2020", "2023"], 
    },
  };
  
  // Handle time frame selection
  const handleTimeFrameClick = (timeFrame) => {
    setData(timeFrames[timeFrame].data);
    setLabels(timeFrames[timeFrame].labels);
    setSelectedTimeFrame(timeFrame);
  };

  // Reference to the chart
  const chartRef = useRef(null);
  
  // Effect to update chart gradient
  useEffect(() => {
    const chart = chartRef.current;
  
    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 300);
  
      gradient.addColorStop(0, 'rgba(255, 215, 0, 0.3)');
      gradient.addColorStop(0.5, 'rgba(255, 215, 0, 0.2)');
      gradient.addColorStop(1, 'rgba(255, 215, 0, 0.05)');
  
      const newData = {
        ...data,
        datasets: [
          {
            ...data.datasets[0],
            backgroundColor: gradient,
          },
        ],
      };
  
      chart.data = newData;
      chart.update();
    }
  }, [data, chartRef, Data]);

  // Render component
  return (
    <Box bg="#121212" minH="fit-content" p={4} mb={10}>
      <Box bg="#121212" p={4}>
        <Flex 
          justifyContent="space-between" 
          flexDirection={{ base: "column", md: "row" }}
          gap={4}
        >
          <Flex 
            justifyContent="space-between" 
            alignItems="center" 
            mb={4}
            flexDirection={{ base: "column", sm: "row" }}
            gap={4}
          >
            <Box>
              <Text color="white" as="h1" size="lg" fontSize={"24px"} fontWeight="bold" pl={0} mb={5}>
                Portfolio Analytics
              </Text>
              <Box
                
                display="flex"
                flexDirection={{ base: "column", sm: "row" }}
                gap={2}
              >
                <Select
                  bg="#333"
                  color="white"
                  border="none"
                  size={{ base: "xs", md: "xs", lg: "sm", xl: "sm" }}
                  borderRadius="5px"
                >
                  <option value="performance">Performance</option>
                </Select>
                <Select
                  bg="#333"
                  color="white"
                  border="none"
                  size={{ base: "xs", md: "xs", lg: "sm", xl: "sm" }}
                  borderRadius="5px"
                >
                  <option value="benchmark">Benchmark</option>
                </Select>
              </Box>
            </Box>
          </Flex>

          <Flex justify={{ base: "center", md: "flex-end" }}>
            <Flex 
              color="white" 
              fontWeight="bold"
              flexWrap="wrap"
              justifyContent="center"
              gap={2}
            >
              {timeFs.map((timeFrame) => (
                <Box
                  as="button"
                  key={timeFrame}
                  onClick={() => handleTimeFrameClick(timeFrame)}
                  color={selectedTimeFrame === timeFrame ? "yellow.400" : "white"}
                  _hover={{ color: "yellow.600" }}
                >
                  {timeFrame}
                </Box>
              ))}
            </Flex>
          </Flex>
        </Flex>

        {/* Render the Line chart */}
        <Line
          data={data}
          options={options}
          ref={chartRef}
        />
      </Box>
    </Box>
  );
};

export default PortfolioAnalytics;
