import React from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tabs,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";
import {Line} from "react-chartjs-2";

const MarketSummaryTable = () => {
    const companiesData = [
        {
            company: "S&P 500 Futures",
            status: [12, 13, 11, 14, 13, 12, 13, 12, 14, 11, 10, 9, 12, 11, 13, 14, 15, 14, 13],
            currentValue: 4500.50,
            dailyChangePts: "+1.20",
            dailyChangeRatio: "+1.35%",
        },
        {
            company: "Gold Futures",
            status: [12, 13, 11, 14, 13, 12, 13, 12, 14, 11, 10, 9, 12, 11, 13, 14, 15, 14, 13],
            currentValue: 1750.30,
            dailyChangePts: "-12.10",
            dailyChangeRatio: "-0.69%",
        },
        {
            company: "EUR/USD Futures",
            status: [12, 13, 11, 14, 13, 12, 13, 12, 14, 11, 10, 9, 12, 11, 13, 14, 15, 14, 13],
            currentValue: 1.1950,
            dailyChangePts: "+0.0020",
            dailyChangeRatio: "+0.17%",
        },
        {
            company: "10-Year Treasury Futures",
            status: [12, 13, 11, 14, 13, 12, 13, 12, 14, 11, 10, 9, 12, 11, 13, 14, 15, 14, 13],
            currentValue: 132.16,
            dailyChangePts: "+0.08",
            dailyChangeRatio: "+0.06%",
        }
    ];

    function getDataOfCompany(company) {
        return {
            labels: Array(company.status.length).fill(''),
            datasets: [
                {
                    data: company.status,
                    borderColor: company.dailyChangeRatio.startsWith('+') ? 'green' : 'red',
                    borderWidth: 1,
                    fill: true,
                    backgroundColor: (context) => {
                        return createGradient(context, company.dailyChangeRatio.startsWith("+") ? "green" : "red")
                    },
                    tension: 0.2
                },
            ]
        }
    }

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
        elements: {
            line: {
                tension: 0,
            },
            point: {
                radius: 0,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        }
    }

    function createGradient(context, color) {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 100)
        linearGradient(color, gradient)
        return gradient;
    }

    function linearGradient(color, gradient) {
        if (color === "red") {
            gradient.addColorStop(0, "rgba(255,44,44,0.2)")
            gradient.addColorStop(0.3, "rgba(255,67,67,0.1)")
            gradient.addColorStop(0.5, "rgba(255,0,0,0)")
            return gradient
        }
        if (color === "green") {
            gradient.addColorStop(0, "rgba(0,255,0,0.1)")
            gradient.addColorStop(0.3, "rgba(107,255,72,0.05)")
            gradient.addColorStop(0.5, "rgba(0,255,0,0)")
            return gradient;
        }
    }

    return (
        <Box>
            <Box bg="black" color="white" m="auto" mt={3}>
                <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"}
                     color='white'>
                    Market Summary
                </Box>
                <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                    <Flex>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                            </MenuList>
                        </Menu>
                        <InputGroup width={'300px'}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Box>
                <Tabs color="white" variant='unstyled'>
                    <Box bg={"#121212"} w='100%' color='white'>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr color={'white'} className="table-header">
                                        <Th>Company</Th>
                                        <Th>Current value</Th>
                                        <Th>Daily change (Points)</Th>
                                        <Th>Daily change (%)</Th>
                                        <Th>Status</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {companiesData.map((company, index) => (
                                        <Tr key={index}>
                                            <Td>{company.company}</Td>
                                            <Td>{company.currentValue}</Td>
                                            <Td>{company.dailyChangePts}</Td>
                                            <Td>{company.dailyChangeRatio}</Td>
                                            <Td width={'40px'}>
                                                <Line height='30px' data={getDataOfCompany(company)}
                                                      options={options}/>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Tabs>
            </Box>
        </Box>
    );
};

export default MarketSummaryTable;