import React, { useRef, useState, useEffect } from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const indicesData = [
  { name: 'MASI', value: '13,227.44', change: '-0.21%', color: 'red.500', data: [12, 13, 11, 14, 13, 12,13,12,14,11,10,9,12,11,13,14,15,14,13] },
  { name: 'MASI 20', value: '1,077.62', change: '-0.06%', color: 'red.500', data: [12, 13, 11, 14, 13, 12,13,12,14,11,10,9,12,11,13,14,15,14,13] },
  { name: 'MASI ESG', value: '969.37', change: '-0.23%', color: 'red.500', data: [12, 13, 11, 14, 13, 12,13,12,14,11,10,9,12,11,13,14,15,14,13] },
  { name: 'MASI MID', value: '1,212.24', change: '+0.45%', color: 'green.500', data: [12, 25, 20, 23, 26, 25,30, 25, 20, 23, 26, 25,30, 25, 20, 23, 26, 25] },
  { name: 'MASI HEALTH', value: '2,601.33', change: '+0.02%', color: 'green.500', data: [12, 25, 20, 23, 26, 25,30, 25, 20, 23, 26, 25,30, 25, 20, 23, 26, 25] },
  { name: 'MASI 20', value: '1,077.62', change: '-0.06%', color: 'red.500', data: [12, 13, 11, 14, 13, 12,13,12,14,11,10,9,12,11,13,14,15,14,13] },
  { name: 'MASI ESG', value: '969.37', change: '-0.23%', color: 'red.500', data: [12, 13, 11, 14, 13, 12,13,12,14,11,10,9,12,11,13,14,15,14,13] },
  { name: 'MASI MID', value: '1,212.24', change: '+0.45%', color: 'green.500', data: [12, 25, 20, 23, 26, 25,30, 25, 20, 23, 26, 25,30, 25, 20, 23, 26, 25] },
  { name: 'MASI HEALTH', value: '2,601.33', change: '+0.02%', color: 'green.500', data: [12, 25, 20, 23, 26, 25,30, 25, 20, 23, 26, 25,30, 25, 20, 23, 26, 25] },
  { name: 'MASI 20', value: '1,077.62', change: '-0.06%', color: 'red.500', data: [12, 13, 11, 14, 13, 12,13,12,14,11,10,9,12,11,13,14,15,14,13] },
];

function createGradient(context, color) {
  const ctx = context.chart.ctx;
  const gradient = ctx.createLinearGradient(0,0,0,100)
  linearGradient(color, gradient)
  return gradient;
}

function linearGradient(color, gradient) {
  if (color === "red") {
    gradient.addColorStop(0, "rgba(255,44,44,0.2)")
    gradient.addColorStop(0.3, "rgba(255,67,67,0.1)")
    gradient.addColorStop(0.5, "rgba(255,0,0,0)")
    return gradient
  }
  if (color === "green") {
    gradient.addColorStop(0, "rgba(0,255,0,0.1)")
    gradient.addColorStop(0.3, "rgba(107,255,72,0.05)")
    gradient.addColorStop(0.5, "rgba(0,255,0,0)")
    return gradient;
  }
}

const IndicesCard = ({indicesData}) => {
  const scrollRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  // console.log("indicesData",indicesData)
  useEffect(() => {
    const updateScrollButtons = () => {
      if (scrollRef.current) {
        setCanScrollLeft(scrollRef.current.scrollLeft > 0);
        setCanScrollRight(
          scrollRef.current.scrollLeft < scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        );
      }
    };
    updateScrollButtons();
    scrollRef.current.addEventListener('scroll', updateScrollButtons);
    // return () => scrollRef.current.removeEventListener('scroll', updateScrollButtons);
  }, []);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -800, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 800, behavior: 'smooth'});
  };

  return (
    <Flex align="center" p={4} bg="#121212" position="relative" gap={5} ml="auto" mr="auto">
      {canScrollLeft && (
        <IconButton
          icon={<FiArrowLeft />}
          onClick={scrollLeft}
          aria-label="Scroll Left"
          position="absolute"
          left={2}
          colorScheme="yellow"
          zIndex={1}
        />
      )}
      <Box
        ref={scrollRef}
        display="flex"
        overflowX="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        scrollSnapType="x mandatory"
      >
        {indicesData.map((index) => (
          <Flex key={index.name} className="index-card column">
            <Flex className="index-card-info col-md-12" display="column"  gap={5}>
              <Text fontSize="md" fontWeight="semibold" color="white" style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
              >
              {index.name}
              </Text>
              {/* <Text color="white">{index.value}</Text>
              <Text color={index.color}>{index.change}</Text> */}
            </Flex>
            <Box className="index-card-chart col-md-6">
            <Line
              data={{
                labels: Array(index.data.length).fill(''),
                datasets: [
                  {
                    data: index.data.map(item => item.price),
                    borderColor: 'green',
                    borderWidth: 1,
                    fill: true,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
                elements: {
                  line: {
                    tension: 0,
                    backgroundColor: (context) => {
                      return createGradient(context, "green")
                      // return createGradient(context, index.change.startsWith("+") ? "green" : "red")
                    }
                  },
                  point: {
                    radius: 0,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}

            />
            </Box>
          </Flex>
        ))}
      </Box>
      {canScrollRight && (
        <IconButton
          icon={<FiArrowRight />}
          onClick={scrollRight}
          aria-label="Scroll Right"
          position="absolute"
          right={2}
          colorScheme="yellow"
          zIndex={1}
        />
      )}
    </Flex>
  );
};

export default IndicesCard;
