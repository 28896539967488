import React, {useState} from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import {Line} from "react-chartjs-2";
import "chartjs-adapter-moment"
import {Utils} from "../../../../../../utils/Utils";

const CompanyOverviewChart = (selectedCompany) => {
    const timeFrame = [
        {label: '1H', timeUnit: 'hour', range: 1},
        {label: '3H', timeUnit: 'hour', range: 3},
        {label: '5H', timeUnit: 'hour', range: 5},
        {label: '1D', timeUnit: 'day', range: 5},
        {label: '1W', timeUnit: 'week', range: 5},
        {label: '1M', timeUnit: 'month', range: 5},
        {label: '1Y', timeUnit: 'year', range: 5}
    ]
    const [selectedTimeFrameIndex, setSelectedTimeFrameIndex] = useState(3)
    const data = [
        {time: "2024-07-17T13:00:00", earning: 512.0},
        {time: "2024-07-17T15:00:00", earning: 512.6},
        {time: "2024-07-17T21:00:00", earning: 512.8},
        {time: "2024-07-17T23:00:00", earning: 513.8},

        {time: "2024-07-18T01:00:00", earning: 513.9},
        {time: "2024-07-18T03:00:00", earning: 513.5},
        {time: "2024-07-18T09:00:00", earning: 514.5},
        {time: "2024-07-18T11:00:00", earning: 514.5},
        {time: "2024-07-18T15:00:00", earning: 514.0},
        {time: "2024-07-18T17:00:00", earning: 514.4},
        {time: "2024-07-18T19:00:00", earning: 514.0},
        {time: "2024-07-18T21:00:00", earning: 514.5},
        {time: "2024-07-18T22:00:00", earning: 514.5},
        {time: "2024-07-18T23:30:00", earning: 513.2},

        {time: "2024-07-19T00:00:00", earning: 513.9},
        {time: "2024-07-19T04:00:00", earning: 514.4},
        {time: "2024-07-19T08:00:00", earning: 514.7},
        {time: "2024-07-19T12:00:00", earning: 515.1},
        {time: "2024-07-19T16:00:00", earning: 515.5},
        {time: "2024-07-19T20:00:00", earning: 514.9},

        {time: "2024-07-20T00:00:00", earning: 514.9},
        {time: "2024-07-20T12:00:00", earning: 515.4},
        {time: "2024-07-20T20:00:00", earning: 515.2},

        {time: "2024-07-21T00:00:00", earning: 514.5},
        {time: "2024-07-21T04:00:00", earning: 514.1},
        {time: "2024-07-21T08:00:00", earning: 514.3},
        {time: "2024-07-21T12:00:00", earning: 514.7},
        {time: "2024-07-21T16:00:00", earning: 514.9},
        {time: "2024-07-21T19:00:00", earning: 513.2},
        {time: "2024-07-21T20:00:00", earning: 514.2},

        {time: "2024-07-22T00:00:00", earning: 515},
        {time: "2024-07-23T00:00:00", earning: 515},
        {time: "2024-07-24T00:00:00", earning: 514},
        {time: "2024-07-25T00:00:00", earning: 513},
        {time: "2024-07-26T00:00:00", earning: 514},
        {time: "2024-07-27T00:00:00", earning: 514},
        {time: "2024-07-28T00:00:00", earning: 513},
        {time: "2024-07-29T00:00:00", earning: 514},
        {time: "2024-07-30T00:00:00", earning: 514},
        {time: "2024-07-31T00:00:00", earning: 515},
    ];

    function dateBetween(yourDate, date1, date2) {
        const date = new Date(yourDate)
        const isAfterStartDate = date1 ? date >= new Date(date1) : true
        const isBeforeEndDate = date2 ? date <= new Date(date2) : true
        return isAfterStartDate && isBeforeEndDate
    }

    function handleDateRange() {
        let toDate = null
        let fromDate = null
        if (selectedTimeFrameIndex !== null) {
            toDate = new Date("2024-07-21T20:00:00")
            fromDate = new Date(toDate);
            switch (timeFrame[selectedTimeFrameIndex].timeUnit) {
                case 'hour' : {
                    fromDate.setHours(fromDate.getHours() - timeFrame[selectedTimeFrameIndex].range)
                    break
                }
                case 'day' : {
                    fromDate.setDate(fromDate.getDate() - timeFrame[selectedTimeFrameIndex].range)
                    break
                }
                case 'week' : {
                    fromDate.setDate(fromDate.getDate() - timeFrame[selectedTimeFrameIndex].range * 7)
                    break
                }
                case 'month' : {
                    fromDate.setMonth(fromDate.getMonth() - timeFrame[selectedTimeFrameIndex].range)
                    break
                }
                case 'year' : {
                    fromDate.setFullYear(fromDate.getFullYear() - timeFrame[selectedTimeFrameIndex].range)
                    break
                }
            }
        }
        return {fromDate, toDate}
    }

    function getChartData() {
        const {fromDate, toDate} = handleDateRange()
        const filterDataDateRange = data => dateBetween(data.time, fromDate, toDate)
        const filteredData = data.filter(filterDataDateRange)
        return {
            labels: filteredData.map(d => d.time),
            datasets: [{
                data: filteredData.map(d => d.earning),
                fill: true,
                borderColor: "#ffce30",
                borderWidth: 2,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx
                    const gradient = ctx.createLinearGradient(0, 0, 0, 600)
                    gradient.addColorStop(0, "rgba(255,214,81,0.4)")
                    gradient.addColorStop(0.2, "rgba(255,209,63,0.15)")
                    gradient.addColorStop(0.5, "rgba(50,50,50,0)")
                    return gradient
                },
                tension: 0
            }]
        };
    }

    const config = {
        type: 'line',
        data: getChartData()
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: selectedTimeFrameIndex ? timeFrame[selectedTimeFrameIndex].timeUnit : null,
                },
                ticks: {
                    stepSize: 1,
                    color: 'white',
                },
            },
            y: {
                ticks: {
                    color: 'white',
                    stepSize: 1,
                    callback: (value) => {
                        return value + ' MAD'
                    }
                }
            },
        }
    };

    function handleCheckedTimeFrame(index) {
        if (index === selectedTimeFrameIndex) {
            setSelectedTimeFrameIndex(null)
        } else {
            setSelectedTimeFrameIndex(index)
        }
    }

    return (
        <div>
            {selectedCompany !== null &&
                <div className="row">
                <div className="col-md-7">
                    <Box>
                        <Flex className="row">
                            <div className="h-100 col-lg-6 d-flex align-items-center">
                                {/*
                                <Box h="50px" w="50px">
                                    <img alt='' src="/img/ATW.png"/>
                                </Box> */}
                                <Box ml={'15px'}>
                                    <Text fontSize={'20px'} fontWeight='bold'
                                          m={'5px 0 0 0'}>{selectedCompany.name}</Text>
                                    <Text fontSize={'12px'} mt={0}>{selectedCompany.name}</Text>
                                </Box>
                                <Box ml={'15px'}>
                                    <Text fontSize={'16px'} fontWeight='bold'
                                          m={'5px 0 0 0'}>{selectedCompany.price} MAD</Text>
                                    <Flex fontSize={'12px'} justifyContent={'end'} mt={0}>
                                        <Box textAlign='center'
                                             bgColor={'#ffce30'}
                                             color={'#000'}
                                             fontSize={'10px'}
                                             fontWeight={'bold'}
                                             width={"50px"}
                                             borderRadius={"10px"}>
                                            {selectedCompany.change_percentage >= 0 ?
                                                `+${selectedCompany.change_percentage}%` : `${selectedCompany.change_percentage}%`}
                                        </Box>
                                    </Flex>
                                </Box>
                            </div>
                            <div className="h-100 col-lg-6 d-flex align-items-center">
                                <Box className="technical-analysis">
                                    <Flex>
                                        {timeFrame.map((time, index) =>
                                            <div className="time-frame" key={index} style={{marginRight: '5px'}}>
                                                <input checked={selectedTimeFrameIndex === index}
                                                       onClick={() => handleCheckedTimeFrame(index)}
                                                       type="checkbox"
                                                       className="btn-check"
                                                       id={"btn-check-time-frame-" + index}
                                                       autoComplete="off"/>
                                                <label className="btn btn-outline-warning"
                                                       htmlFor={"btn-check-time-frame-" + index}>
                                                    {time.label}
                                                </label>
                                            </div>
                                        )}
                                    </Flex>
                                </Box>
                            </div>
                        </Flex>
                    </Box>
                    <Box height='400px' mt={5}>
                        <Line data={config.data} options={options}/>
                    </Box>
                    <Box>
                        <Text fontSize={'24px'} color={'#ffce30'}>About {selectedCompany.name}</Text>
                        <Flex>
                            <Box flex={1}>
                                <Text mb={0} fontWeight={'bold'} fontSize={'14px'}>Sector</Text>
                                <Text fontSize={'14px'}>NaN</Text>
                            </Box>
                            <Box flex={1}>
                                <Text mb={0} fontWeight={'bold'} fontSize={'14px'}>Industry</Text>
                                <Text fontSize={'14px'}>{selectedCompany.sector}</Text>
                            </Box>
                            <Box flex={1}>
                                <Text mb={0} fontWeight={'bold'} fontSize={'14px'}>CEO</Text>
                                <Text fontSize={'14px'}>{selectedCompany.ceo}</Text>
                            </Box>
                            <Box flex={1}>
                                <Text mb={0} fontWeight={'bold'} fontSize={'14px'}>Website</Text>
                                <Text fontSize={'14px'}>{selectedCompany.website}</Text>
                            </Box>
                        </Flex>
                        <Flex>
                            <Box flex={1}>
                                <Text mb={0} fontWeight={'bold'} fontSize={'14px'}>Headquarters</Text>
                                <Text fontSize={'14px'}>{selectedCompany.headquarters}</Text>
                            </Box>
                            <Box flex={1}>
                                <Text mb={0} fontWeight={'bold'} fontSize={'14px'}>Employees (FY)</Text>
                                <Text fontSize={'14px'}>{selectedCompany.num_of_employees}</Text>
                            </Box>
                            <Box flex={1}>
                                <Text mb={0} fontWeight={'bold'} fontSize={'14px'}>Founded</Text>
                                <Text fontSize={'14px'}>{selectedCompany.foundation_date | 0}</Text>
                            </Box>
                            <Box flex={1}>
                                <Text mb={0} fontWeight={'bold'} fontSize={'14px'}>FIGI</Text>
                                <Text fontSize={'14px'}>NaN</Text>
                            </Box>
                        </Flex>
                    </Box>
                </div>
                <div className="col-md-5">
                    <Box height='500px' fontSize={'14px'}>
                        <Box>
                            <Text fontWeight="bold" fontSize="24px" color={"#ffce30"} mb={4}>
                                Upcoming earnings
                            </Text>
                            <Flex justifyContent="space-between" mb={4}>
                                <Box flex={1}>
                                    <Text mb={0}>Next report date</Text>
                                    <Text>NaN</Text>
                                </Box>
                                <Box flex={1}>
                                    <Text mb={0}>Report Period</Text>
                                    <Text>NaN</Text>
                                </Box>
                            </Flex>
                            <Flex justifyContent="space-between" mb={4}>
                                <Box flex={1}>
                                    <Text mb={0}>EPS Estimate</Text>
                                    <Text>{selectedCompany.eps_estimate} MAD</Text>
                                </Box>
                                <Box flex={1}>
                                    <Text mb={0}>Revenue Estimate</Text>
                                    <Text>{selectedCompany.revenue_estimate} MAD</Text>
                                </Box>
                            </Flex>
                        </Box>
                        {/* Key Stats */}
                        <Box mt={8} mb={8}>
                            <Text fontWeight="bold" fontSize="24px" color={"#ffce30"} mb={4}>
                                Key Stats
                            </Text>
                            <Flex justifyContent="space-between" mb={4}>
                                <Box flex={1}>
                                    <Text mb={0}>Market Capitalization</Text>
                                    <Text>{Utils.shortenNumber(selectedCompany.market_cap, true)}</Text>
                                </Box>
                                <Box flex={1}>
                                    <Text mb={0}>Dividend Yield (Indicated)</Text>
                                    <Text>{selectedCompany.dividend_yield_percentage >= 0 ?
                                        `+${selectedCompany.dividend_yield_percentage}%` : `${selectedCompany.dividend_yield_percentage}%`}</Text>
                                </Box>
                            </Flex>
                            <Flex justifyContent="space-between" mb={4}>
                                <Box flex={1}>
                                    <Text mb={0}>Price to Earnings Ratio (TTM)</Text>
                                    <Text>NaN</Text>
                                </Box>
                                <Box flex={1}>
                                    <Text mb={0}>Basic EPS (TTM)</Text>
                                    <Text>MAD NaN</Text>
                                </Box>
                            </Flex>
                            <Flex justifyContent="space-between" mb={4}>
                                <Box flex={1}>
                                    <Text mb={0}>Net Income (FY)</Text>
                                    <Text>{Utils.shortenNumber(selectedCompany.net_income, true)}</Text>
                                </Box>
                                <Box flex={1}>
                                    <Text mb={0}>Revenue (FY)</Text>
                                    <Text>MAD NaN</Text>
                                </Box>
                            </Flex>
                            <Flex justifyContent="space-between" mb={4}>
                                <Box flex={1}>
                                    <Text mb={0}>Shares Float</Text>
                                    <Text>{Utils.shortenNumber(selectedCompany.share_float, true)}</Text>
                                </Box>
                                <Box flex={1}>
                                    <Text mb={0}>Beta (1Y)</Text>
                                    <Text>{selectedCompany.beta}</Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </div>
            </div>
            }
        </div>
    );
};

export default CompanyOverviewChart;