import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch} from "react-icons/fa";
import {getAllCustomers, getAllProducers} from "../../../../../../services/marketDataServices/CompanyService";

const ProducersAndCustomersTable = (selectedCompany) => {
    const [customers, setCustomers] = useState(null)
    const [producers, setProducers] = useState(null)
    useEffect(() => {
        getAllCustomers().then(response => {
            setCustomers(response.data.filter(customer => customer.companies.includes(selectedCompany.name)))
        }).catch(error => {
            console.log(error)
        })
        getAllProducers().then(response => {
            setProducers(response.data.filter(producer => producer.companies.includes(selectedCompany.name)))
        }).catch(error => {
            console.log(error)
        })
    }, [selectedCompany]);
    return (
        <div>
            {/*producers table*/}
            <Text fontSize={'24px'} color={'#ffce30'}>Producers and Costumers</Text>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} fontSize={"18px"} color='white'>
                Producers (Suppliers)
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex justifyContent={'end'} flex={1} mr={10}>
                    <Menu>
                        <MenuButton
                            as={Button}
                            height={'35px'}
                            bgColor="#161616"
                            color="white"
                            borderWidth={"1px 0 1px 1px"}
                            borderRadius={"5px 0 0px 5px"}
                            borderColor={"rgba(119,119,119,0.5)"}
                            _hover={{bgColor: "#242424"}}
                            rightIcon={<FiChevronDown/>}
                            _focus={{bgColor: "#242424"}}
                        >
                            <Flex>
                                <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                    <FiFilter fill={"white"}/>
                                </Box>
                                All
                            </Flex>
                        </MenuButton>
                        <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                            <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                        </MenuList>
                    </Menu>
                    <InputGroup width={'300px'}>
                        <InputLeftElement pb={1}>
                            <FaSearch/>
                        </InputLeftElement>
                        <Input type="text" height='35px' placeholder="Search"
                               _placeholder={{color: '#fff', opacity: 0.7}}
                               _focus={{
                                   outline: 'none',
                                   boxShadow: 'none',
                                   borderColor: 'rgba(119,119,119,0.5)'
                               }}
                               _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                               borderColor={"rgba(119,119,119,0.5)"}
                               borderWidth="1px"
                               borderRadius={"0px 5px 5px 0px"}
                        />
                    </InputGroup>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>Supplier Name</Th>
                                <Th>Service/Product Provided</Th>
                                <Th>Location</Th>
                                <Th>Relationship Duration</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {producers?.map((producer) => (
                                <Tr key={producer.id}>
                                    <Td>{producer.name}</Td>
                                    <Td>NaN</Td>
                                    <Td>NaN</Td>
                                    <Td>NaN</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>

            {/*customers table*/}
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Customers
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex justifyContent={'end'} flex={1} mr={10}>
                    <Menu>
                        <MenuButton
                            as={Button}
                            height={'35px'}
                            bgColor="#161616"
                            color="white"
                            borderWidth={"1px 0 1px 1px"}
                            borderRadius={"5px 0 0px 5px"}
                            borderColor={"rgba(119,119,119,0.5)"}
                            _hover={{bgColor: "#242424"}}
                            rightIcon={<FiChevronDown/>}
                            _focus={{bgColor: "#242424"}}
                        >
                            <Flex>
                                <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                    <FiFilter fill={"white"}/>
                                </Box>
                                All
                            </Flex>
                        </MenuButton>
                        <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                            <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>1</MenuItem>
                            <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>2</MenuItem>
                        </MenuList>
                    </Menu>
                    <InputGroup width={'300px'}>
                        <InputLeftElement pb={1}>
                            <FaSearch/>
                        </InputLeftElement>
                        <Input type="text" height='35px' placeholder="Search"
                               _placeholder={{color: '#fff', opacity: 0.7}}
                               _focus={{
                                   outline: 'none',
                                   boxShadow: 'none',
                                   borderColor: 'rgba(119,119,119,0.5)'
                               }}
                               _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                               borderColor={"rgba(119,119,119,0.5)"}
                               borderWidth="1px"
                               borderRadius={"0px 5px 5px 0px"}
                        />
                    </InputGroup>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>Customer Name</Th>
                                <Th>Sector</Th>
                                <Th>Location</Th>
                                <Th>Relationship Duration</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {customers?.map((customer) => (
                                <Tr key={customer.id}>
                                    <Td>{customer.name}</Td>
                                    <Td>{customer.sector}</Td>
                                    <Td>NaN</Td>
                                    <Td>NaN</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default ProducersAndCustomersTable;