import React from 'react';
import BalanceSheetAssetsTable from "../BalanceSheetAssetsTable";
import BalanceSheetLiabilitiesTable from "../BalanceSheetLiabilitiesTable";
import IncomeTable from "../IncomeTable";
import CashFlowTable from "../CashFlowTable";
import AssetsChart from "../charts/AssetsChart";
import LiabilitiesChart from "../charts/LiabilitiesChart";
import IncomeChart from "../charts/IncomeChart";
import CashFlowChart from "../charts/CashFlowChart";

const AnalysisComponent = () => {
    return (
        <div>
            <BalanceSheetAssetsTable/>
            <BalanceSheetLiabilitiesTable/>
            <IncomeTable/>
            <CashFlowTable/>
            <AssetsChart/>
            <LiabilitiesChart/>
            <IncomeChart/>
            <CashFlowChart/>
        </div>
    );
};

export default AnalysisComponent;