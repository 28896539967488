export class Utils {
    // function to return range between 2 years as an array.
    static getYearsArray = (from, to) => {
        const array = []
        for (let i = from; i < to + 1; i++) {
            array.push(i)
        }
        return array
    }
    // function to separate every 3 digit with custom separator.
    static formatNumber = (number, separator) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }

    static shortenNumber = (number, fullSymbole) => {
        if (number === null || number === undefined) return "0";
        if (Math.abs(number) < 1000) return number.toString();

        let units = null;
        if (fullSymbole === true) {
            units = ["", "K", " Million", " Billion", " Trillion", "P"]
        } else {
            units = ["", "K", "M", "B", "T", "P"]
        }
        const order = Math.floor(Math.log10(Math.abs(number)) / 3);
        const unit = units[order] || "E"; // Fallback to "E" for very large numbers

        const scaledNum = (number / Math.pow(1000, order)).toFixed(1); // 1 decimal point
        return scaledNum + unit;
    }
}