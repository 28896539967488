// Import necessary dependencies and components
import React from 'react';
import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { FiChevronDown } from 'react-icons/fi';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutPieChart({ title, chartData, centerText }) {
  // Chart options configuration
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          boxWidth: 10,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 10
          }
        }
      },
      title: {
        display: true,
        color: 'white',
        font: {
          size: 14,
        },
      },
    },
  };

  // Helper function to render menu button
  const renderMenuButton = (text, icon) => (
    <MenuButton
      as={Box}
      height='35px'
      bgColor="#161616"
      color="white"
      m="0 0 0 0"
      border="1.5px solid white"
      display="flex"
      alignItems="center"
      px={3}
      cursor="pointer"
      width="fit-content"
      borderRadius="4px"
    >
      <Flex alignItems="center">
        <Box>{text}</Box>
        <Box ml={1}>{icon}</Box>
      </Flex>
    </MenuButton>
  );

  return (
    <Box
      borderRadius="lg"
      bg="#0A0A0A"
      p={4}
      boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
      minW="100%"
    >
      {/* Chart title */}
      <Text fontWeight="bold" fontSize="18px" px={6}>{title}</Text>
      {/* Time period selector */}
      <Box width="100%" height="50px" borderBottom="2px solid white" px={6}>
        <Menu width="fit-content">
          {renderMenuButton('Today', <FiChevronDown style={{ marginLeft: "20px" }} />)}
          <MenuList bgColor="#121212" borderColor="transparent">
            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Tomorrow</MenuItem>
            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Next Week</MenuItem>
            <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Next Month</MenuItem>
          </MenuList>
        </Menu>
      </Box>
      
      {/* Chart and legend container */}
      <Flex justifyContent="center" gap={{ base: 4, sm: 10, md: 20 }} alignItems="center" pb={4} px={6}>
        {/* Doughnut chart */}
        <Box width={{ xs: "140px", base: "180px", sm: "200px", md: "220px" }} height={{ base: "180px", sm: "200px", md: "220px" }}>
          <Box position="relative" width="100%" height="100%">
            <Doughnut 
              data={chartData} 
              options={{
                ...options,
                plugins: {
                  ...options.plugins,
                  legend: {
                    display: false,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              }} 
            />
            {/* Center text in doughnut chart */}
            <Box
              position="absolute"
              top="55%"
              left="50%"
              transform="translate(-50%, -50%)"
              textAlign="center"
            >
              <Text m={0} p={0} fontSize={{ base: "sm", md: "md" }} fontWeight="bold">{centerText.main}</Text>
              <Text m={0} p={0} fontSize={{ base: "xs", md: "sm" }}>{centerText.sub}</Text>
            </Box>
          </Box>
        </Box>
        {/* Legend */}
        <Box width={{ base: "120px", md: "150px" }} ml={4}>
          {chartData.labels.map((label, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <Box
                width="10px"
                height="10px"
                borderRadius="50%"
                backgroundColor={chartData.datasets[0].backgroundColor[index]}
                mr={2}
              />
              <Box fontSize={{ base: "2xs", md: "xs" }} color="gray.400">{label}</Box>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  );
}

export default DoughnutPieChart;