import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ProfitLossChart = () => {
  const options = {
    chart: {
      type: 'area',
      height: 350,
      background: '#000000',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },
    title: {
      text: 'Profit/Loss Chart',
      align: 'center',
      style: {
        fontSize: '20px',
        color: '#FFD700'
      }
    },
    xaxis: {
      categories: Array(11).fill('22009'),
      tickAmount: 10,
      labels: {
        formatter: function(value) {
          return value;
        }
      },
      title: {
        text: 'Underlying Price',
        offsetY: 10
      }
    },
    yaxis: {
      tickAmount: 7,
      min: -150000,
      max: 150000,
      labels: {
        formatter: function(value) {
          return value.toLocaleString();
        }
      },
      title: {
        text: 'Profit/Loss',
        rotate: -90,
        offsetX: -10
      }
    },
    colors: ['#FF0000', '#00FF00'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    grid: {
      borderColor: '#555555',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft',
        offsetY: 30,
        offsetX: 60
      },
      x: {
        show: true,
        formatter: function(value) {
          return 'Jan 30, 01:12:16 AM';
        }
      },
      y: {
        formatter: function(value) {
          return `P&L: +${value.toLocaleString()}\nChg from Spot: (3.58%)`;
        }
      },
      style: {
        fontSize: '12px',
        fontFamily: undefined
      },
      theme: 'dark'
    },
    markers: {
      size: 0
    }
  };

  const series = [
    {
      name: 'Loss',
      data: [-100000, -80000, -60000, -40000, -20000, 0, 0, 0, 0, 0, 0]
    },
    {
      name: 'Profit',
      data: [0, 0, 0, 0, 0, 20000, 40000, 60000, 80000, 100000, 150000]
    }
  ];

  return (
    <div style={{ background: '#000000', padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        {['1D', '5D', '1M', '6M', '1Y', '5Y', 'Max'].map((period, index) => (
          <button
            key={index}
            style={{
              background: 'transparent',
              border: 'none',
              color: index === 0 ? '#FFD700' : '#ffffff',
              marginLeft: '10px',
              cursor: 'pointer'
            }}
          >
            {period}
          </button>
        ))}
      </div>
      <ReactApexChart options={options} series={series} type="area" height={450} />
    </div>
  );
};

export default ProfitLossChart;