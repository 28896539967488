import React from 'react'
import BackTestComponent from '../BackTestComponent'
import DrawdownChart from '../DrawdownChart'
import MonthlyReturnsChart from '../MonthlyReturnsChart'
import PerformanceChart from '../PerformanceChart'

function BackTestingComponents() {
    return (
        <div>
            <BackTestComponent />
            <PerformanceChart />
            <h5 style={{ marginBottom: '20px' }}>Drawdown Chart</h5>
            <DrawdownChart />
            <h5 style={{ marginTop: '40px', marginBottom: '20px' }}>Monthly Returns Chart</h5>
            <MonthlyReturnsChart />
        </div>
    )
}

export default BackTestingComponents
