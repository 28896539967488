import React from 'react';
import {Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import Overview from "../overview/layout/Overview";
import News from "../news/News";
import MacroEconomicDataAnalysisComponent from "../../stockMarketComponents/macroEconomicDataAnalysis/MacroEconomicDataAnalysisComponent";
import TechnicalRealTimeCurrencyTable from "../technical/TechnicalRealTimeCurrencyTable";

const ForexMarketComponent = () => {
    return (
        <div>
            <Tabs color="white" variant='unstyled'>
                <TabList className="tab-list" m={"auto"} justifyContent={"center"} fontSize={"24px"}>
                    <Tab _selected={{color: "#ffce30"}}>Overview</Tab>
                    <Tab _selected={{color: "#ffce30"}}>Technical</Tab>
                    <Tab _selected={{color: "#ffce30"}}>Macro-Economic data analysis</Tab>
                    <Tab _selected={{color: "#ffce30"}}>News</Tab>
                </TabList>
                <TabIndicator mt='-1.5px' height='2px' bg={"#ffce30"} borderRadius='1px'/>
                <TabPanels>
                    <TabPanel>
                        <Overview/>
                    </TabPanel>
                    <TabPanel>
                        <TechnicalRealTimeCurrencyTable/>
                    </TabPanel>
                    <TabPanel>
                        <MacroEconomicDataAnalysisComponent/>
                    </TabPanel>
                    <TabPanel>
                        <News/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default ForexMarketComponent;