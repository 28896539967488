import React, { useState, useEffect } from "react";
import { Box, Select, Text, Input, Button } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register required Chart.js components
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const Arima = () => {
    const [ticker, setTicker] = useState(""); // Selected company symbol
    const [startDate, setStartDate] = useState(""); // Start date
    const [endDate, setEndDate] = useState(""); // End date
    const [chartData, setChartData] = useState(null); // Data for the chart
    const [tickerSuggestions, setTickerSuggestions] = useState(["ATW", "GAZ"]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    // Function to fetch data from the API
    const fetchData = async () => {
        // if (!ticker || !startDate || !endDate) {
        //     alert("Please select all fields.");
        //     return;
        // }

        const apiURL = `https://api.xcapitalterminal.com/api/v1/arima-predict?ticker=${ticker}&start_date=${startDate}&end_date=${endDate}`;

        try {
            const response = await axios.get(apiURL);
            const { test_actuals, test_predictions, test_dates } = response.data;

            // Combine data into an array of objects for sorting
            const combinedData = test_dates.map((date, index) => ({
                date,
                actual: test_actuals[index],
                predicted: test_predictions[index],
            }));

            // Sort data by date
            combinedData.sort((a, b) => new Date(a.date) - new Date(b.date));

            // Separate sorted data back into individual arrays
            const sortedDates = combinedData.map(item => item.date);
            const sortedActualValues = combinedData.map(item => item.actual);
            const sortedPredictedValues = combinedData.map(item => item.predicted);


            // Update chart data
            setChartData({
                labels: sortedDates,
                datasets: [
                    {
                        label: "Actual Values",
                        data: sortedActualValues,
                        borderColor: "#ffce30",
                        borderWidth: 2,
                        pointBackgroundColor: "#ffce30",
                        tension: 0.4,
                    },
                    {
                        label: "Predicted Values",
                        data: sortedPredictedValues,
                        borderColor: "#6b6b6b",
                        borderWidth: 2,
                        pointBackgroundColor: "#6b6b6b",
                        borderDash: [5, 5],
                        tension: 0.4,
                    },
                ],
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
        },
        scales: {
            x: {
                grid: {
                    color: "#444444",
                },
                ticks: {
                    color: "#ffffff",
                },
            },
            y: {
                grid: {
                    color: "#444444",
                },
                ticks: {
                    color: "#ffffff",
                },
            },
        },
    };

    // Filter suggestions based on user input
    const filteredSuggestions = tickerSuggestions.filter(option =>
        option.toLowerCase().includes(ticker.toLowerCase())
    );

    return (
        <div>
            <Box marginTop={8}>
                <Text color={"#ffce30"} fontSize={"24px"} fontWeight={"bold"}>
                    ARIMA
                </Text>
                <Box>
                    <Text fontSize={"18px"}>Description</Text>
                    <Text>
                        Autoregressive Integrated Moving Average (ARIMA) is a statistical model that uses time series
                        data to predict future values.
                    </Text>
                    <Text fontSize={"18px"}>Purpose</Text>
                    <Text>
                        Forecasting stock prices, returns, and trading volumes. Useful for short to medium-term
                        predictions in relatively stable market conditions.
                    </Text>
                </Box>
            </Box>
            <Box>
                <div className="filter-bar d-flex">
                    <div className="element">
                        {/* <Select
                            placeholder="Select Company"
                            onChange={(e) => setTicker(e.target.value)}
                            width="auto"
                        >
                            <option value="ATW">ATW</option>
                            <option value="Company2">Company 2</option>
                        </Select> */}
                        <Input
                            type="text"
                            placeholder="Search Company"
                            value={ticker}
                            onChange={(e) => setTicker(e.target.value)}
                            onFocus={() => setShowSuggestions(true)} // Show suggestions on focus
                        />
                        {ticker && showSuggestions && filteredSuggestions.length > 0 && (
                            <ul className="ticker-suggestions">
                                {filteredSuggestions.map((suggestion, index) => (
                                    <li key={index} onClick={() => {
                                        setTicker(suggestion)
                                        setShowSuggestions(false);
                                        }}>
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="element">
                        <Input
                            type="date"
                            placeholder="Start Date"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="element">
                        <Input
                            type="date"
                            placeholder="End Date"
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div className="element">
                        <Button colorScheme="yellow" onClick={fetchData}>
                            Fetch Data
                        </Button>
                    </div>
                </div>
            </Box>
            {/* <Box marginTop={10} height={"500px"} bgColor={"#1f1f1f"} borderRadius={"10px"} padding={4}> */}
            <Box marginTop={10} height={"500px"} borderRadius={"10px"} padding={4}>
                {chartData ? (
                    <Line data={chartData} options={chartOptions} />
                ) : (
                    <Text color={"#ffffff"}>No data to display. Please select parameters and fetch data.</Text>
                )}
            </Box>
        </div>
    );
};

export default Arima;
