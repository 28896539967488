import React from 'react';
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import OverviewComponent from "../overview/layout/OverviewComponent";
import FuturesComponent from "../futures/layout/FuturesComponent";
import ForwardsComponents from "../forwards/layout/ForwardsComponents";
import OptionsComponent from "../options/layout/OptionsComponent";
import NewsComponent from "../news/NewsComponent";


const DerivativesMarketsComponent = () => {
    return (
        <div>
            <Tabs color="white" variant='unstyled'>
                <TabList className="tab-list" m={"auto"} justifyContent={"center"} fontSize={"24px"}>
                    <Tab _selected={{ color: "#ffce30" }}>Overview</Tab>
                    <Tab _selected={{ color: "#ffce30" }}>Futures</Tab>
                    <Tab _selected={{ color: "#ffce30" }}>Forwards</Tab>
                    <Tab _selected={{ color: "#ffce30" }}>Options</Tab>
                    <Tab _selected={{ color: "#ffce30" }}>News</Tab>
                </TabList>

                <TabIndicator mt='-1.5px' height='2px' bg={"#ffce30"} borderRadius='1px' />
                  
                <TabPanels>
                    <TabPanel>
                        <OverviewComponent />
                    </TabPanel>
                    <TabPanel>
                        <FuturesComponent />
                    </TabPanel>
                    <TabPanel>
                        <ForwardsComponents />  
                    </TabPanel>
                    <TabPanel>
                        <OptionsComponent />
                    </TabPanel>
                    <TabPanel>
                        <NewsComponent />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default DerivativesMarketsComponent;