// Import necessary dependencies and components
import React from 'react'
import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Button, InputGroup, InputLeftElement, Input, TableContainer, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { FiChevronDown, FiFilter } from 'react-icons/fi'
import { FaSearch } from 'react-icons/fa'
import { TbCaretUpDownFilled } from "react-icons/tb";
import { HiMenuAlt2 } from "react-icons/hi";

// Mock news data for demonstration purposes
const newsData = [
    { time: '2 hours ago', symbol: 'GC', headline: 'Gold Futures Rise on Global Economic Concerns', provider: 'Reuters' },
    { time: '3 hours ago', symbol: 'ES', headline: 'S&P 500 Futures Drop Amid Tech Sector Weakness', provider: 'Bloomberg' },
    { time: '4 hours ago', symbol: 'CL', headline: 'Oil Futures Volatile as OPEC+ Discusses Production Cuts', provider: 'MarketWatch' },
    { time: '5 hours ago', symbol: 'ZC', headline: 'Corn Futures Rally on Weather Concerns', provider: 'Reuters' },
    { time: '6 hours ago', symbol: 'NG', headline: 'Natural Gas Futures Decline on Inventory Report', provider: 'Bloomberg' },
    { time: '7 hours ago', symbol: 'SI', headline: 'Silver Futures Follow Gold Higher in Safe-Haven Rally', provider: 'MarketWatch' },
    { time: '8 hours ago', symbol: 'ZW', headline: 'Wheat Futures Surge on Export Demand', provider: 'Reuters' },
    { time: '9 hours ago', symbol: 'HG', headline: 'Copper Futures Hit New High on Supply Constraints', provider: 'Bloomberg' },
    { time: '10 hours ago', symbol: 'ZS', headline: 'Soybean Futures Advance on Chinese Demand', provider: 'MarketWatch' },
    { time: '11 hours ago', symbol: 'ZB', headline: 'Treasury Futures React to Fed Policy Speculation', provider: 'Reuters' },
    { time: '12 hours ago', symbol: 'NQ', headline: 'Nasdaq Futures Decline on Tech Earnings Miss', provider: 'Bloomberg' },
    { time: '13 hours ago', symbol: 'RTY', headline: 'Russell Futures Show Small-Cap Strength', provider: 'MarketWatch' }
];

function NewsComponent() {
    return (
        <div>
            {/* Header */}
            <Flex bg="#121212" justifyContent="center" w='100%' h="40px" p={2} fontSize="18px" color='white' position="relative">
                <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                    Options News
                    <Box position="absolute" right="10px">
                        <HiMenuAlt2 />
                    </Box>
                </Flex>
            </Flex>
            {/* Filter and search section */}
            <Box bg="#171717" w='100%' p={2} fontSize="18px" color='white'>
                <Flex>
                    <Flex flex={1} justifyContent='end' mr={10}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height='35px'
                                bgColor="#161616"
                                color="white"
                                borderWidth="1px 0 1px 1px"
                                borderRadius="5px 0 0px 5px"
                                borderColor="#303235"
                                _hover={{ bgColor: "#242424" }}
                                rightIcon={<FiChevronDown />}
                                _focus={{ bgColor: "#242424" }}
                            >
                                <Flex>
                                    <Box m="5px 10px 0 0" fontSize='12px'>
                                        <FiFilter fill="white" />
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#303235" borderColor="transparent" minWidth='100px'>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Reuters</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>Bloomberg</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{ bgColor: "#242424" }}>MarketWatch</MenuItem>
                            </MenuList>
                        </Menu>
                        {/* Search input */}
                        <InputGroup width='300px'>
                            <InputLeftElement pb={1}>
                                <FaSearch />
                            </InputLeftElement>
                            <Input 
                                type="text" 
                                height='35px' 
                                placeholder="Search"
                                _placeholder={{ color: '#fff', opacity: 0.7 }}
                                _focus={{
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: 'rgba(119,119,119,0.5)'
                                }}
                                _hover={{ borderColor: 'rgba(119,119,119,0.5)' }}
                                borderColor="#303235"
                                borderWidth="1px"
                                borderRadius="0px 5px 5px 0px"
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            {/* News table */}
            <Box bg="#121212" w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color='white' className="table-header">
                                {['Time', 'Symbol', 'Headline', 'Provider'].map(header => (
                                    <Th key={header}>
                                        <Flex alignItems='center' gap={1}>{header} <TbCaretUpDownFilled /></Flex>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {newsData.map((news, idx) => (
                                <Tr key={idx}>
                                    <Td>{news.time}</Td>
                                    <Td>{news.symbol}</Td>
                                    <Td>{news.headline}</Td>
                                    <Td>{news.provider}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default NewsComponent