// Import necessary components from React and Chakra UI
import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Flex } from "@chakra-ui/react";

function EventComponent() {
    return (
        <div width="100%" >
            <Box>
                {/* Header for the event component */}
                <Flex alignItems={"center"} bg={"#1f1f1f"} h="60px" p={2} px={4} fontSize={"18px"} color='white'>
                    Portfolio Events
                </Flex>

                <Box bg={"#121212"} color='white'>
                    {(() => {
                        // Mock data for portfolio events
                        const eventData = [
                            { stock: 'AAPL', event: 'Earnings Report', date: 'Jul 28, 2023' },
                            { stock: 'GOOGL', event: 'Stock Split', date: 'Aug 15, 2023' },
                            { stock: 'TSLA', event: 'Product Launch', date: 'Sep 5, 2023' },
                            { stock: 'AMZN', event: 'Investor Day', date: 'Oct 12, 2023' },
                            { stock: 'MSFT', event: 'Dividend Payout', date: 'Nov 3, 2023' }
                        ];

                        return (
                            // Table container with scrollable content
                            <TableContainer maxHeight="250px" overflowY="auto">
                                <Table variant='unstyled'>
                                    {/* Sticky header for the table */}
                                    <Thead position="sticky" top={0} bg="#121212">
                                        <Tr borderBottom="1px solid #333333">
                                            <Th color="gray" textTransform="uppercase">STOCK</Th>
                                            <Th color="gray" textTransform="uppercase">EVENT</Th>
                                            <Th color="gray" textTransform="uppercase">DATE</Th>
                                        </Tr>
                                    </Thead>
                                    {/* Table body with event data */}
                                    <Tbody>
                                        {eventData.map((item, index) => (
                                            <Tr key={index}>
                                                <Td>{item.stock}</Td>
                                                <Td>{item.event}</Td>
                                                <Td>{item.date}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        );
                    })()}
                </Box>
            </Box>
        </div>
    )
}

export default EventComponent