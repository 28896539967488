import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const RSIChart = () => {
  const [optionsRSI] = useState({
    chart: {
      type: 'line',
      height: 290,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      background: '#000',
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ['#ffce30'],
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: ['#ffce30'],
        stops: [0, 100],
      },
    },
    title: {
      text: 'RSI (6,14,24)',
      align: 'left',
      style: {
        color: '#fff',
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#ffce30',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        style: {
          colors: ['#fff'],
        },
      },
    },
    grid: {
      show: true,
      borderColor: '#666',
    },
    tooltip: {
        theme: 'dark',
      },
    annotations: {
      yaxis: [
        {
          y: 40, 
          borderColor: '#ff0000',
          label: {
            borderColor: '#ff0000',
            style: {
              color: '#fff',
              background: '#ff0000',
            },
            text: '40.00',
          },
        },
      ],
    },
  });

  const [series] = useState([
    {
      name: 'RSI',
      data: [
        { x: new Date('2023-10-01').getTime(), y: 30 },
        { x: new Date('2023-10-02').getTime(), y: 50 },
        { x: new Date('2023-10-03').getTime(), y: 45 },
        { x: new Date('2023-10-04').getTime(), y: 70 },
        { x: new Date('2023-10-05').getTime(), y: 60 },
        { x: new Date('2023-10-06').getTime(), y: 55 },
        { x: new Date('2023-10-07').getTime(), y: 40 },
      ],
    },
  ]);

  return (
    <div id="chart-container" style={{ background: 'black', padding: '20px' }}>
      <Chart options={optionsRSI} series={series} type="line" height={290} />
    </div>
  );
};

export default RSIChart;
