import React, {useState} from 'react';
import {Box, Table, TableContainer, Tbody, Td, Text, Tr, Button, Flex} from "@chakra-ui/react";
import { IgrRadialGaugeModule } from 'igniteui-react-gauges';
import {IgrRadialGauge} from "igniteui-react-gauges"
IgrRadialGaugeModule.register();


const CompanyTechnicalAnalysis = () => {
    const company = {name: "ATTIJARIWAFA BANK", abbr: "ATW"}
    const timeFrame = ['1 minute', '2 minutes', '5 minutes', '30 minutes', '1 hour', '2 hours', '4 hours', '1 day', '1W', '1M']
    const [selectedTimeFrame, setSelectedTimeFrame] = useState(null)

    function handleCheckedTimeFrame(index) {
        if (index === selectedTimeFrame) {
            setSelectedTimeFrame(null)
        } else {
            setSelectedTimeFrame(index)
        }
    }

    return (
        <Box>
            <Box mt={8}>
                <Text color={'#ffce30'} fontSize={'24px'}>{company.abbr} technical analysis</Text>
                <Text fontSize={'20px'} maxW={'80%'}>
                    This gauge displays a real-time technical analysis overview for your selected timeframe.
                    The summary of {company.name} is based on the most popular technical indicators,
                    such as Moving Averages,
                    Oscillators and Pivots.
                </Text>

                <Box>
                    <Flex overflowX="auto">
                        {timeFrame.map((time, index) =>
                            <Box key={index}>
                                <Button
                                    onClick={() => handleCheckedTimeFrame(index)}
                                    variant={selectedTimeFrame === index ? "solid" : "outline"}
                                    colorScheme="yellow"
                                    size="sm"
                                    mx={1}
                                >
                                    {time}
                                </Button>
                            </Box>
                        )}
                    </Flex>
                </Box>
                <Box>
                    <Flex mt={10} flexDirection={{ base: "column", md: "row" }} alignItems={{ base: "center", md: "flex-start" }}>
                        <Box w={{ base: "100%", md: "33.33%" }} h="300px" pl={5} mb={{ base: 5, md: 0 }} display="flex" justifyContent="center">
                            <IgrRadialGauge
                                tickStartExtent={0.45}
                                tickEndExtent={0.6}
                                tickStrokeThickness={2}
                                tickBrush={"#ffce30"}
                                minorTickCount={2}
                                minorTickEndExtent={0.5}
                                minorTickStartExtent={0.575}
                                minorTickStrokeThickness={1}
                                minorTickBrush={"#ffce30"}
                                height="250px" width="250px"
                                minimumValue={0} value={50}
                                backingBrush={"transparent"}
                                fontBrush={'white'}
                                scaleBrush={'transparent'}
                                backingStrokeThickness={1}
                                labelExtent={0}
                                backingInnerExtent={5}
                                maximumValue={80} interval={10}/>
                        </Box>
                        <Box w={{ base: "100%", md: "33.33%" }} h="300px" pl={5} mb={{ base: 5, md: 0 }} display="flex" justifyContent="center">
                            <IgrRadialGauge
                                tickStartExtent={0.45}
                                tickEndExtent={0.6}
                                tickStrokeThickness={2}
                                tickBrush={"#ffce30"}
                                minorTickCount={2}
                                minorTickEndExtent={0.5}
                                minorTickStartExtent={0.575}
                                minorTickStrokeThickness={1}
                                minorTickBrush={"#ffce30"}
                                height="250px" width="250px"
                                minimumValue={0} value={50}
                                backingBrush={"transparent"}
                                fontBrush={'white'}
                                scaleBrush={'transparent'}
                                backingStrokeThickness={1}
                                labelExtent={0}
                                backingInnerExtent={5}
                                maximumValue={80} interval={10}/>
                        </Box>
                        <Box w={{ base: "100%", md: "33.33%" }} h="300px" pl={5} display="flex" justifyContent="center">
                            <IgrRadialGauge
                                tickStartExtent={0.45}
                                tickEndExtent={0.6}
                                tickStrokeThickness={2}
                                tickBrush={"#ffce30"}
                                minorTickCount={2}
                                minorTickEndExtent={0.5}
                                minorTickStartExtent={0.575}
                                minorTickStrokeThickness={1}
                                minorTickBrush={"#ffce30"}
                                height="250px" width="250px"
                                minimumValue={0} value={50}
                                backingBrush={"transparent"}
                                fontBrush={'white'}
                                scaleBrush={'transparent'}
                                backingStrokeThickness={1}
                                labelExtent={0}
                                backingInnerExtent={5}
                                maximumValue={80} interval={10}/>
                        </Box>
                    </Flex>
                </Box>
                
                <Box mt={10}>
                    <Box bg={"#1f1f1f"} textAlign="center" h="40px" p={2} fontSize={"18px"} color='white'>
                        Summary
                    </Box>
                    <Box bg={"#121212"} color='white'>
                        <TableContainer>
                            <Table variant='simple'>
                                <Tbody>
                                    <Tr>
                                        <Td>Moving Averages:</Td>
                                        <Td>Buy</Td>
                                        <Td>Buy: (12)</Td>
                                        <Td>Sell: (0)</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Technical Indicators:</Td>
                                        <Td>Buy</Td>
                                        <Td>Buy: (9)</Td>
                                        <Td>Sell: (0)</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CompanyTechnicalAnalysis;