import React from 'react';
import {
    Box,
    Button,
    Flex, Input, InputGroup, InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer, Tbody, Td, Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {FaSearch, FaCalendarAlt, FaStar} from "react-icons/fa";

const EconomicCalendarTableComponent = () => {
    const economicCalendarData = [
        {
            time: '09:00',
            underlyingAsset: 'USD',
            impact: 3,
            event: 'GDP Growth Rate',
            actual: '2.1',
            forecast: '2.0',
            previous: '1.9',
            unit: '%'
        },
        {
            time: '10:30',
            underlyingAsset: 'EUR',
            impact: 4,
            event: 'Interest Rate Decision',
            actual: '4.5',
            forecast: '4.5',
            previous: '4.0',
            unit: '%'
        },
        {
            time: '12:00',
            underlyingAsset: 'GBP',
            impact: 2,
            event: 'Manufacturing PMI',
            actual: '51.2',
            forecast: '50.8',
            previous: '50.5',
            unit: '%'
        },
        {
            time: '14:30',
            underlyingAsset: 'JPY',
            impact: 1,
            event: 'Trade Balance',
            actual: '-2.5',
            forecast: '-2.0',
            previous: '-1.8',
            unit: '%'
        }
    ];

    const renderStars = (count) => {
        return [...Array(count)].map((_, i) => (
            <FaStar key={i} color="#FFD700" style={{display: 'inline'}} />
        ));
    };

    return (
        <div>
            <Box bg={"#1f1f1f"} textAlign="center" w='100%' h="40px" p={2} mt={"40px"} fontSize={"18px"} color='white'>
                Economic Calendar
            </Box>
            <Box bg={"#171717"} w='100%' p={2} fontSize={"18px"} color='white'>
                <Flex>
                    <Flex flex={1} justifyContent={'start'} mr={10}>
                        <Box 
                            as="button"
                            height='35px'
                            width='35px'
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgColor="#161616"
                            borderRadius="5px"
                            borderColor="rgba(119,119,119,0.5)"
                            borderWidth="1px"
                            mr={2}
                            _hover={{bgColor: "#242424"}}
                        >
                            <FaCalendarAlt color="white" />
                        </Box>
                        <Menu>
                            <MenuButton
                                as={Button}
                                height={'35px'}
                                bgColor="#161616"
                                color="white"
                                borderWidth={"1px 0 1px 1px"}
                                borderRadius={"5px 0 0px 5px"}
                                borderColor={"rgba(119,119,119,0.5)"}
                                _hover={{bgColor: "#242424"}}
                                rightIcon={<FiChevronDown/>}
                                _focus={{bgColor: "#242424"}}
                            >
                                <Flex>
                                    <Box m={"5px 10px 0 0"} fontSize={'12px'}>
                                        <FiFilter fill={"white"}/>
                                    </Box>
                                    All
                                </Flex>
                            </MenuButton>
                            <MenuList bgColor="#121212" borderColor={"transparent"} minWidth={'100px'}>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>High Impact</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Medium Impact</MenuItem>
                                <MenuItem bgColor="#121212" _hover={{bgColor: "#242424"}}>Low Impact</MenuItem>
                            </MenuList>
                        </Menu>
                        <InputGroup width={'300px'}>
                            <InputLeftElement pb={1}>
                                <FaSearch/>
                            </InputLeftElement>
                            <Input type="text" height='35px' placeholder="Search events"
                                   _placeholder={{color: '#fff', opacity: 0.7}}
                                   _focus={{
                                       outline: 'none',
                                       boxShadow: 'none',
                                       borderColor: 'rgba(119,119,119,0.5)'
                                   }}
                                   _hover={{borderColor: 'rgba(119,119,119,0.5)'}}
                                   borderColor={"rgba(119,119,119,0.5)"}
                                   borderWidth="1px"
                                   borderRadius={"0px 5px 5px 0px"}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
            </Box>
            <Box bg={"#121212"} w='100%' color='white'>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr color={'white'} className="table-header">
                                <Th>Time</Th>
                                <Th>Underlying Asset</Th>
                                <Th>Impact</Th>
                                <Th>Event</Th>
                                <Th>Actual</Th>
                                <Th>Forecast</Th>
                                <Th>Previous</Th>
                                <Th>Unit</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {economicCalendarData.map((event, index) =>
                                <Tr key={index}>
                                    <Td>{event.time}</Td>
                                    <Td>{event.underlyingAsset}</Td>
                                    <Td>{renderStars(event.impact)}</Td>
                                    <Td>{event.event}</Td>
                                    <Td>{event.actual}</Td>
                                    <Td>{event.forecast}</Td>
                                    <Td>{event.previous}</Td>
                                    <Td>{event.unit}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default EconomicCalendarTableComponent;